import React, { Component, Fragment } from "react";
import { Card, Grid, Tab, TabbedCard, Table } from "tabler-react";
import CallerInfo from "pages/callsBoard/CallerInfo";
import CallSection from "pages/calls/CallSection";
import { connect } from "react-redux";
import InterruptionsList from "pages/interruptions/InterruptionsList";
import { actionCleanCallNavigation, actionGetCalls } from "redux/actions/CallsActions";
import { actionCleanClient } from "redux/actions/ClientsActions";
import CallUpdate from "pages/calls/CallUpdate";
import { Helpers } from "helpers/Helpers";
import { DATETIME_FORMAT_NO_SECONDS } from "constants/ConstantsDate";

class CallsBoard extends Component {
  constructor(props) {
    super(props); 

    this.state = {
      search: "",
      currentPage: 1,
      callsPerPage: 10,
      nClientId: null,
      activeLoanTab: false,
      bOtherDevicesTab: false,
      oCallUpdate: null,
      // Si se obtiene el parametro con el id del cliente se inicializa en la pestaña Llamadas, de lo contrario en Información
      sInitialTab: this.props.match.params.nClientId ? 'Llamadas' : 'Información',
      // Manejo de página actual para listado de llamadas
      nCallListPage: 1,
      nRandomKey: Math.random(),

      rend: true
    };
  }

  componentDidMount = () => {

    if (this.props.match.params.nClientId) {
      this.props.get(this.props.match.params.nClientId, 0, 3);
      this.props.get(this.props.match.params.nClientId, 0, 10);
    } else if (this.props.propsClient.oClientBoard && this.props.propsClient.oClientBoard.id) {
      this.props.get(this.props.propsClient.oClientBoard.id, 0, 3);
      this.props.get(this.props.propsClient.oClientBoard.id, 0, 10);
    } 

    
  }

  componentDidUpdate = async (oLastProps) => {

    if (oLastProps.oCallSummary.oCallAnswer && !this.props.oCallSummary.oCallAnswer && this.props.oCallSummary.oLastCall && this.state.oCallUpdate === null && this.props.oCallSummary.oLastCall.client_id) {
      let oCallUpdate = JSON.parse(JSON.stringify(this.props.oCallSummary.oLastCall)),
      start = oCallUpdate.start + "", aDateTimeStart = start.split(' ');
      oCallUpdate.startDate = await this.formatDate(aDateTimeStart[0])
      oCallUpdate.startTime = await this.formatTime(aDateTimeStart[1]);
      this.setState({ oCallUpdate });
      this.props.cleanCall();
       
    } 
     
  }
  // !Estos dos metodos deben estar en las actions de ayuda o en el helper, de momento serán duplicadas aquí y en CallSection.js
  formatDate = (sDate) => {
    let aDate = sDate.split('-');
    let oDate = Helpers.ConvertTZ(new Date(aDate[0], (parseInt(aDate[1]) - 1), aDate[2]));
    return oDate;
  }

  formatTime = (sTime) => {
    let atime = sTime.split(':');
    if (parseInt(atime[0]) < 10) { atime[0] = '0' + parseInt(atime[0]); }
    if (parseInt(atime[1]) < 10) { atime[1] = '0' + parseInt(atime[1]); }
    if (parseInt(atime[2]) < 10) { atime[2] = '0' + parseInt(atime[2]); }
    let sNewTime = atime.join(':');
    return sNewTime;
  }

  handleUpdateCall = () => {
    this.setState({ oCallUpdate: null });
  }

  changeActiveLoanTab = (bStatus = true) => {
    this.setState({ activeLoanTab: bStatus, bOtherDevicesTab: false });
  };

  changeActiveOtherDevices = (bStatus = true) => {
    this.setState({ bOtherDevicesTab: bStatus, activeLoanTab: false });
  };

  changeActiveDeviceTab = () => {
    this.setState({ bOtherDevicesTab: false, activeLoanTab: false });
  };

  setInitialTab = () => {
    this.setState({ nRandomKey: `tab${Math.random()}`, sInitialTab: "Información" });
     
  }

  componentWillUnmount = () => {
    this.props.cleanClient();
  };
 

  render = () => {
    
    const { oClientBoard: oClient } = this.props.propsClient;    
    const {aCalls } = this.props.oCallSummary;
    const { aCallSummary } = this.props.oCallSummary;
    const { activeLoanTab, oCallUpdate, sInitialTab, nCallListPage, bOtherDevicesTab, nRandomKey } = this.state;

    const imap = []

    if(this.props.oCallSummary.oCallAnswer){
      imap.push(1, 4)
    }else{
      imap.push(0, 3)
    }
    
    return oClient && oClient !== null ?
       
      <Grid.Row className="calls-board-container"> 
        <Grid.Col width={12} md={3}>
          <CallerInfo
            nClientId={oClient.id && 0}
            activeLoanTab={activeLoanTab}
            bOtherDevicesTab={bOtherDevicesTab}
            changeActiveLoanTab={this.changeActiveLoanTab}
            changeActiveOtherDevices={this.changeActiveOtherDevices}
            changeActiveDeviceTab={this.changeActiveDeviceTab}
            setInitialTab={this.setInitialTab} 
            /> 
        </Grid.Col> 

        <Grid.Col width={12} md={9}> 
          {
            oCallUpdate ?
              <Card className='p-5'>
                <CallUpdate activeEdit={this.handleUpdateCall} nClientId={oClient.id} oClient={oClient} oCall={oCallUpdate}  />
              </Card>
              :
              <TabbedCard key={`tab${nRandomKey}`} initialTab={sInitialTab}>
                <Tab title="Información"> 
                  <Grid.Row >
                    <Grid.Col width={12} md={8}>
                      <Grid.Row>
                        <Grid.Col width={12}>
                          <Card statusColor="green">
                            <Card.Header>
                              <Card.Title>Llamadas</Card.Title>
                            </Card.Header>
                            <Card.Body>
                              <Table className="card-table table-vcenter table-sm table-hover text-nowrap">
                                <Table.Header>
                                  <Table.Row>
                                    <Table.ColHeader>Inicio </Table.ColHeader>
                                    <Table.ColHeader>Duración</Table.ColHeader>
                                    <Table.ColHeader>Motivo</Table.ColHeader>
                                  </Table.Row>
                                </Table.Header>
                                <Table.Body> 

                                   
                                  { 
                                   this.props.oCallSummary.oCallAnswer &&
                                   aCalls.data.slice(imap[0],imap[1]) &&
                                   aCalls.data.slice(imap[0], imap[1]).map((call) => (
                                      <Table.Row key={"call" + call.id}>
                                        <Table.Col>{Helpers.CastDate(call.start, DATETIME_FORMAT_NO_SECONDS)}</Table.Col>
                                        <Table.Col>{call.duration_short ? call.duration_short : "S/D"}</Table.Col>
                                        <Table.Col>{call.show_reason && call.show_reason.length > 40 ? `${call.show_reason.slice(0, 40)}...` : call.show_reason}</Table.Col>
                                      </Table.Row>
                                    ))
                                  }
                                  
                                  {  
                                    !this.props.oCallSummary.oCallAnswer &&
                                    aCallSummary.data &&
                                    aCallSummary.data.map((call) => (
                                      <Table.Row key={"call" + call.id}>
                                        <Table.Col>{Helpers.CastDate(call.start, DATETIME_FORMAT_NO_SECONDS)}</Table.Col>
                                        <Table.Col>{call.duration_short ? call.duration_short : "S/D"}</Table.Col>
                                        <Table.Col>{call.show_reason && call.show_reason.length > 40 ? `${call.show_reason.slice(0, 40)}...` : call.show_reason}</Table.Col>
                                      </Table.Row>
                                    ))
                                   
                                  }
                                   
                                  
                                </Table.Body>
                              </Table>
                            </Card.Body>
                          </Card>
                        </Grid.Col>
                        <Grid.Col width={6}>
                          <Card statusColor="green">
                            <Card.Header>
                              <Card.Title>Contactos</Card.Title>
                            </Card.Header>
                            <Card.Body>
                              {
                                activeLoanTab && oClient.loan != null ? oClient.loan.contacts &&
                                  oClient.loan.contacts.split("\n").map((item, key) =>
                                    <Fragment key={key}>
                                      {item}
                                      <br />
                                    </Fragment>
                                  )
                                  : oClient.contacts && oClient.contacts.split("\n").map((item, key) =>
                                    <Fragment key={key}>
                                      {item}
                                      <br />
                                    </Fragment>
                                  )
                              }
                            </Card.Body>
                          </Card>
                        </Grid.Col>
                        <Grid.Col width={6}>
                          <Grid.Row>
                            <Card statusColor="green">
                              <Card.Header>
                                <Card.Title>Observaciones</Card.Title>
                              </Card.Header>
                              <Card.Body>
                                {(activeLoanTab && oClient.loan)
                                  ? oClient.loan.observation
                                  : oClient.observation}
                              </Card.Body>
                            </Card>
                            <Card statusColor="green">
                              <Card.Header>
                                <Card.Title>Ficha médica</Card.Title>
                              </Card.Header>
                              <Card.Body>
                                {oClient.show_medical_record && oClient.show_medical_record}
                              </Card.Body>
                            </Card>
                            <Card statusColor="green">
                              <Card.Header>
                                <Card.Title>Medicación</Card.Title>
                              </Card.Header>
                              <Card.Body>
                                {oClient.show_medicines && oClient.show_medicines}
                              </Card.Body>
                            </Card>
                          </Grid.Row>
                        </Grid.Col>
                      </Grid.Row>
                    </Grid.Col>
                    <Grid.Col width={12} md={4}>
                      <Grid.Row>
                        <Grid.Col width={12}>
                          <Card statusColor="red">
                            <Card.Header className="alert-danger">
                              <Card.Title>Emergencia</Card.Title>
                            </Card.Header>
                            <Card.Body>
                              {
                                (activeLoanTab && oClient.loan)
                                  ? oClient.loan.show_mobile_emergency
                                  && <div dangerouslySetInnerHTML={{ __html: oClient.loan.show_mobile_emergency }} />
                                  : oClient.mobileEmergency
                                  && <div dangerouslySetInnerHTML={{ __html: oClient.mobileEmergency }} />
                              }
                            </Card.Body>
                          </Card>
                        </Grid.Col>
                        <Grid.Col width={12}>
                          <Card statusColor="red">
                            <Card.Header className="alert-danger">
                              <Card.Title>Sociedad médica</Card.Title>
                            </Card.Header>
                            <Card.Body>
                              {
                                (activeLoanTab && oClient.loan)
                                  ? oClient.loan.show_medical_society
                                  && <div dangerouslySetInnerHTML={{ __html: oClient.loan.show_medical_society }} />
                                  : oClient.medicalSociety
                                  && <div dangerouslySetInnerHTML={{ __html: oClient.medicalSociety }} />
                              }
                            </Card.Body>
                          </Card>
                        </Grid.Col>
                        <Grid.Col width={12}>
                          <Card statusColor="red">
                            <Card.Header>
                              <Card.Title>Doctor/a</Card.Title>
                            </Card.Header>
                            <Card.Body>
                              {oClient.show_private_doctor && oClient.show_private_doctor}
                            </Card.Body>
                          </Card>
                        </Grid.Col>
                      </Grid.Row>
                    </Grid.Col>
                  </Grid.Row>
                </Tab>
                <Tab title="Llamadas">
                  <CallSection
                    callsPerPage={20}
                    nClientId={oClient.id}
                    oClient={oClient}
                    nCallListPage={nCallListPage}
                    fChangePage={(nPage) => this.setState({ nCallListPage: nPage })} />
                </Tab>
                {
                  oClient.midespartner !== null &&
                  <Tab title="Corte / Interrupciones">
                    <InterruptionsList nClientId={oClient.id} />
                  </Tab>
                }
              </TabbedCard>
          }
        </Grid.Col>
      </Grid.Row>
      : null
  };
}

const mapStateToProps = (state) => ({
  oCallSummary: state.callsReducer,
  propsClient: state.clientsReducer,
  
});


const mapDispatchToProps = (dispatch) => ({
  get: (nClientId, nPage, nCallsPerPage) => {
    dispatch(actionGetCalls(nClientId, nPage, nCallsPerPage));
  },

  cleanClient: () => {
    dispatch(actionCleanClient());
  },
  cleanCall: () => {
    dispatch(actionCleanCallNavigation());
  },
  
});

export default connect(mapStateToProps, mapDispatchToProps)(CallsBoard);
