import React from 'react';
import { Icon } from "tabler-react";
import { Link } from "react-router-dom";

export function CancelButton(props) {
  let { BackTo } = props;
  return (
    <Link className="btn btn-secondary ml-3" to={BackTo}>
      <Icon name="slash" className="mr-2"/>
      Cancelar
    </Link>
  );
}