import React, { Component } from "react";
import { Card, Form, Table, Alert, Grid, Button } from "tabler-react";
import { connect } from "react-redux";
import { actionGetClient } from "redux/actions/ClientsActions";
import {
  actionCleanReports,
  actionGetDebtors,
} from "redux/actions/ReportsActions";
import { Pagination } from "components/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { CONSTANTS } from "constants/Constants";

class ReportDebtors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sSearch: "",
      currentPage: 1,
      nClientsPerPage: 10,
      oTimeOutId: null,
    };
  }

  componentDidMount = () => {
    this.get();
  };
  componentWillUnmount = () => {
    this.props.cleanReports();
  };

  get = async () => {
    await this.props.get(0, this.state.nClientsPerPage, null);
  };

  _handleSearchChange = (event) => {
    let { value } = event.target;
    clearTimeout(this.state.oTimeOutId);
    let oTimeOutId = setTimeout(() => {
      this.props.get(0, this.state.nClientsPerPage, value);
    }, 800);
    this.setState({ sSearch: value, oTimeOutId });
  };

  _handlechangeCount = async (event) => {
    let { value } = event.target;
    await this.setState({ nClientsPerPage: value, currentPage: 1 });
    this.props.get(0, value, this.state.sSearch);
  };

  _handleNextPage = async () => {
    let nClientsPerPage = this.state.nClientsPerPage;
    let nPage = this.state.currentPage * nClientsPerPage;
    await this.setState({ currentPage: this.state.currentPage + 1 });
    this.props.get(nPage, this.state.nClientsPerPage, this.state.sSearch);
  };

  _handlePreviousPage = async () => {
    await this.setState({ currentPage: this.state.currentPage - 2 });
    let nClientsPerPage = this.state.nClientsPerPage;
    let nPage;
    nPage = this.state.currentPage * nClientsPerPage;
    this.setState({ currentPage: this.state.currentPage + 1 });
    this.props.get(nPage, this.state.nClientsPerPage, this.state.sSearch);
  };

  _handleGoToPage = async (nPage) => {
    let nClientsPerPage = this.state.nClientsPerPage, nSkip = 0;
    nSkip = (nPage - 1) * nClientsPerPage;
    await this.setState({ currentPage: nPage });
    this.props.get(nSkip, nClientsPerPage, this.state.sSearch);
  };

  _handleexportExcel = () => {
    window.open(CONSTANTS.API_URL + "reports/excel/debtors?search=" + this.state.sSearch, "_blank");
  };

  goToView = (event, nClientId, sRoute) => {
    event.stopPropagation();
    this.props.getClient(nClientId);
    this.props.history.push(sRoute);
  };

  renderTable = () => {
    const { aDebtors } = this.props.oDebtor; //destructuracion de objeto
    return aDebtors.data.map((oClient, index) => {
      return (
        <Table.Row key={"client" + oClient.id}>
          <Table.Col className="cursor-pointer">
            <span
              onClick={(event) =>
                this.goToView(event, oClient.id, "/clients/edit/" + oClient.id)
              }
            >
              {oClient.fullname}
            </span>
          </Table.Col>
          <Table.Col className="text-center">{oClient.code}</Table.Col>
          <Table.Col>{oClient.principal_phone}</Table.Col>
          <Table.Col>{oClient.principal_address ? oClient.principal_address.length > 35 ? oClient.principal_address.substr(0, 32) + "..." : oClient.principal_address : "S/D"}</Table.Col>
          <Table.Col className="text-center isActive-indicator">
            {oClient.debt}
          </Table.Col>
          <Table.Col className="text-center isActive-indicator">
            {parseInt(oClient.debt) > 0 ? oClient.feevalue > 0 ? (Math.ceil(parseInt(oClient.debt) / parseInt(oClient.feevalue))) : 0 : 0}{" "}Meses
          </Table.Col>
        </Table.Row>
      );
    });
  };

  render = () => {
    const { aDebtors } = this.props.oDebtor; //destructuracion de objeto
    const { nClientsPerPage, sSearch } = this.state; //destructuracion de objeto

    return (
      <Card>
        <Card.Header>
          <Card.Title className="mr-auto">Deudores</Card.Title>
          <Form.Input
            icon="search"
            placeholder="Buscar deuda mayor a"
            value={sSearch}
            onChange={this._handleSearchChange}
          />
          <Button
            color="primary"
            className="ml-2"
            onClick={this._handleexportExcel}
          >
            <FontAwesomeIcon
              icon={faFileExcel}
              className="mr-2"
            ></FontAwesomeIcon>
            Exportar a excel
          </Button>
        </Card.Header>
        {aDebtors.data && aDebtors.data.length > 0 ? (
          <>
            <div className="table-responsive">
              <Table className="card-table table-vcenter table-sm table-hover text-nowrap">
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Apellido y Nombre</Table.ColHeader>
                    <Table.ColHeader alignContent="center">Número de socio</Table.ColHeader>
                    <Table.ColHeader>Teléfono</Table.ColHeader>
                    <Table.ColHeader>Domicilio</Table.ColHeader>
                    <Table.ColHeader alignContent="center">
                      Deuda
                    </Table.ColHeader>
                    <Table.ColHeader alignContent="center">
                      Meses impagos
                    </Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{this.renderTable()}</Table.Body>
              </Table>
            </div>
            <Card.Footer>
              <Pagination
                nextPageAction={this._handleNextPage}
                previousPageAction={this._handlePreviousPage}
                goToPageAction={this._handleGoToPage}
                currentPage={this.state.currentPage}
                allRecords={aDebtors.allRecords}
                showing={Array.isArray(aDebtors.data) ? aDebtors.data.length : 0}
                nLastPage={Math.ceil(parseInt(aDebtors.allRecords) / parseInt(nClientsPerPage))}
                showAllOptions={true}
                _handlechangeCount={this._handlechangeCount}
              />
            </Card.Footer>
          </>
        ) : (
          <Card.Body>
            <Grid.Row>
              <Grid.Col>
                <Alert type="info" icon="info">
                  No hemos obtenido datos de clientes
                </Alert>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        )}
      </Card>
    );
  };
}

const mapStateToProps = (state) => ({
  oDebtor: state.reportsReducers,
});

const mapDispatchToProps = (dispatch) => ({
  get: (nPage, nClientsPerPage, sSearch) => {
    dispatch(actionGetDebtors(nPage, nClientsPerPage, sSearch));
  },
  getClient: (idClient) => {
    dispatch(actionGetClient(idClient));
  },
  cleanReports: () => {
    dispatch(actionCleanReports());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportDebtors);
