import { CONSTANTS_ACTIONS } from 'constants/ConstantsActions';

const aInitialState = {
    bStatus: false,
    oClient: null,
    aLoans: [],
    oLoan: {},

    oLoansReport: null,
}
/**
 * Reducer Mostrar las Ciudades y codigo de area por departamento.
 * 
 * @param {Array} aState Estado del reducer, al momento de inicializarse se tomara aInitialState
 * @param {object} oAction Objeto donde se encuentra el tipo de estado que se desea actualizar y los respectivos datos
 * 
 * @author Franko Zerpa
 */
export const loansReducer = (aState = aInitialState, oAction) => {
    switch (oAction.type) {
        case CONSTANTS_ACTIONS.LOANS:
            return { ...aState, aLoans: oAction.data.data, oClient: oAction.data.client }
        case CONSTANTS_ACTIONS.LOANS_REPORT:
            return { ...aState, oLoansReport: oAction.data };
        // case CONSTANTS_ACTIONS.CLEAN_LOANS:
        //     return { ...aState, aLoans: [] }
        case CONSTANTS_ACTIONS.SAVE_LOAN:
            return { ...aState, bStatus: true }
        // case CONSTANTS_ACTIONS.UPDATE_LOAN:
        //     return { ...aState, bStatus: true }
        case CONSTANTS_ACTIONS.CLEAN_LOANS:
            return aInitialState;
        case CONSTANTS_ACTIONS.ACTIVE_LOAN:
            return { ...aState, bStatus: true }
        default:
            return aState;
    }
}


