import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Form, Table, Alert, Grid, Icon } from "tabler-react";
import { TableAction } from "components/buttons/TableAction";
import { connect } from "react-redux";
import Modal from "components/Modal";
import {
  actionDeleteDevice,
  actionGetDevices,
} from "redux/actions/DevicesActions";
import { actionGetStates } from "redux/actions/StatesActions";
import { actionGetCities } from "redux/actions/CitiesActions";
import Autosuggest from "react-autosuggest";
import {
  actionGetLoans,
  actionCleanLoans,
  actionSaveLoan,
  actionUpdateLoan,
  actionActiveLoan,
} from "redux/actions/LoansActions";
import { actionGetMedicalSocieties } from "redux/actions/MedicalSocietiesActions";
import { actionGetMobileEmergencies } from "redux/actions/MobileEmergenciesActions";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CONSTANTS } from 'constants/Constants';
import { Helpers } from "helpers/Helpers";
import { DATE_FORMAT_MYSQL } from "constants/ConstantsDate";
import { CONSTANTS_MESSAGE } from "constants/ConstantsMessages";
import { actionMessage } from "redux/actions/SharedActions";
import TableColSmall from "components/table/TableColSmall";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";

const oEmptyCity = { id: null, name: "Ciudad" }, oEmptyState = { id: null, name: "Departamento" },
  oInitialLoan = {
    id: null,
    active: true,
    loanStartDate: Helpers.GetDateForDatepicker(),
    loanEndDate: Helpers.GetDateForDatepicker(),
    devicephone: "",
    address: {
      city: { id: null, name: "", zipcode: "" },
      state: { id: null, name: "" },
      domicile: "",
    },
    observation: "",
    contacts: "",
    device: {},
    device_model: "",
    show_mobile_emergency: "",
    show_medical_society: ""
  };

class LoansList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      oLoan: oInitialLoan,
      departament: oEmptyState,
      city: oEmptyCity,
      address: "",
      zipcode: "",
      showEndLoan: false,
      isModalLoanOpen: false,
      oSelectedDevice: "",
      lDeviceSuggestions: [],
      lMobileEmergencySuggestions: [],
      oSelectedMobileEmergency: "",
      lMedicalSocietySuggestions: [],
      oSelectedMedicalSociety: "",
      aCities: [],
      aStates: []
    }
    this.props.getDevices();
    this.props.getStates();
    this.props.getCities(1);
    this.props.getMobileEmergencies();
    this.props.getMedicalSocieties();
  }

  componentDidMount = () => {
    let nClientId = +this.props.match.params.nClientId;
    this.getLoans(nClientId);
  }

  componentDidUpdate = (oLastProps) => {
    if (this.props.oLoans.oClient && this.props.match.params.nClientId != this.props.oLoans.oClient.id) {
      this.getLoans(this.props.match.params.nClientId);
    }
    if (oLastProps.propsCities.aCities !== this.props.propsCities.aCities) {
      let aCitiesCopy = JSON.parse(JSON.stringify(this.props.propsCities.aCities));
      aCitiesCopy.unshift(oEmptyCity);
      this.setState({ aCities: aCitiesCopy });
    }
    if (oLastProps.propsStates.aStates !== this.props.propsStates.aStates) {
      let aStatesCopy = JSON.parse(JSON.stringify(this.props.propsStates.aStates));
      aStatesCopy.unshift(oEmptyState);
      this.setState({ aStates: aStatesCopy });
    }
  }

  componentWillUnmount = () => {
    this.props.clean();
  }

  getLoans = (nClientId = null) => {
    // Si no se recibe id, se navega una pantalla anterior en la navegación
    if (!nClientId)
      this.props.history.goBack();
    this.props.getLoans(nClientId);
  }

  _handleCheckedShowEndLoan = (event) => {
    let { checked } = event.target;
    let oLoan = this.state.oLoan;
    oLoan.loanEndDate = Helpers.ConvertTZ(new Date());
    this.setState({ showEndLoan: checked });
  }

  _handleChangeState = async (oEvent) => {
    const { value: nDepartment } = oEvent.target;
    let oDepartment = this.state.aStates.find(oDepartment => oDepartment.id == nDepartment);
    await this.setState({ departament: oDepartment, zipcode: "", city: { id: null, name: "Ciudad" } });
    await this.props.getCities(oDepartment.id);
    this.setAddress();
  }

  _handleChangeZipCode = (oEvent) => {
    let { value } = oEvent.target;
    this.setState({ zipcode: value });
    this.setAddress();
  }

  _handleChangeCity = async (oEvent) => {
    let { value: nCity } = oEvent.target;
    let oCity = this.state.aCities.find(oCity => oCity.id == nCity);
    await this.setState({ city: oCity, zipcode: oCity.zipcode });
    this.setAddress();
  }

  _handleChangeAddress = async (event) => {
    let { value } = event.target;
    await this.setState({ address: value });
    this.setAddress();
  }

  setAddress = async () => {
    let oLoan = this.state.oLoan;
    oLoan.address = this.state.address;
    oLoan.city = { id: this.state.city.id, name: this.state.city.name, zipcode: this.state.city.zipcode };
    oLoan.state = { id: this.state.departament.id, name: this.state.departament.name };
    await this.setState({ oLoan });
  }

  _handleCheckedChange = (event) => {
    let { name, checked } = event.target;
    let oLoan = this.state.oLoan;
    oLoan[name] = checked ? 1 : 0;
    this.setState({ oLoan });
  }

  _handleEditLoan = async (oLoan) => {
    let aDateStart = oLoan.start.split("-");
    oLoan.loanStartDate = Helpers.ConvertTZ(new Date(aDateStart[0], parseInt(aDateStart[1] - 1), aDateStart[2]));
    if (oLoan.end) {
      let aDateEnd = oLoan.end.split("-");
      oLoan.loanEndDate = Helpers.ConvertTZ(new Date(aDateEnd[0], parseInt(aDateEnd[1]) - 1, aDateEnd[2]));
      this.setState({ showEndLoan: true });
    } else {
      oLoan.loanEndDate = Helpers.DateNowTz();
      this.setState({ showEndLoan: false });
    }
    this.setState({ oSelectedDevice: oLoan.device_model });
    this.setState({ oSelectedMedicalSociety: oLoan.show_medical_society });
    this.setState({ oSelectedMobileEmergency: oLoan.show_mobile_emergency });
    if (oLoan.state) {
      this.props.getCities(oLoan.state.id);
      await this.setState({ city: oLoan.city, zipcode: oLoan.city && oLoan.city.zipcode ? oLoan.city.zipcode : "", departament: oLoan.state });
    }
    this.setState({ address: oLoan.address });
    this.setState({ oLoan, isModalLoanOpen: true });
  }

  _handleChange = (event) => {
    const { name, value } = event.target;
    let oLoan = this.state.oLoan;
    oLoan[name] = value;
    this.setState({ oLoan });
  }

  _handleLoanStartDateChange = (selectedDate) => {
    let oLoan = this.state.oLoan;
    oLoan.loanStartDate = selectedDate;
    this.setState({ oLoan });
  }

  _handleLoanEndDateChange = (selectedDate) => {
    let oLoan = this.state.oLoan;
    oLoan.loanEndDate = selectedDate;
    this.setState({ oLoan });
  }
  // Use your imagination to render suggestions.
  renderDeviceSuggestion = (oDeviceSuggestion) => (
    <div className="suggestion-item">
      <span>{oDeviceSuggestion.name}</span>
      <span
        className="icon ml-auto icon-action"
        onClick={() => this.deleteDevice(oDeviceSuggestion)}
      >
        <Icon name="trash" />
      </span>
    </div>
  )

  // Funcion para eliminar un elemento de la lista autocompletada
  deleteDevice = (oSelectedDevice) => {
    this.props.deleteDevice(oSelectedDevice.id);
  }

  // Funcion que se ejecuta cada vez que cambia el valor entrado en el input de dispositivos
  onChangeDevice = (event, { newValue }) => {
    this.setState({
      oSelectedDevice: newValue,
    });

    let oLoan = this.state.oLoan;
    oLoan.device_model = newValue;
    this.setState({ oLoan });
  }

  // Funcion que filtra la lista de dispositivos y devuelve los sugeridos segun el valor introducido
  onDeviceSuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const { aDevices } = this.props.propsDevices;
    const lDeviceSuggestions = inputLength === 0 ? [] : aDevices.filter((oDevice) => oDevice.name.toLowerCase().slice(0, inputLength) === inputValue);
    this.setState({ lDeviceSuggestions });
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onDeviceSuggestionsClearRequested = () => {
    this.setState({
      lDeviceSuggestions: [],
    });
  }
  // Use your imagination to render suggestions.
  renderMobileEmergencySuggestion = (oMobileEmergencySuggestion) => (
    <div className="suggestion-item">
      <span>{oMobileEmergencySuggestion.name}</span>
    </div>
  )
  // Funcion que se ejecuta cada vez que cambia el valor entrado en el input de emergencia movil
  onChangeMobileEmergency = (event, { newValue }) => {
    this.setState({
      oSelectedMobileEmergency: newValue,
    });
    let oLoan = this.state.oLoan;
    oLoan.show_mobile_emergency = newValue;
    this.setState({ oLoan });
  }
  // Funcion que filtra la lista de emergencia movil y devuelve los sugeridos segun el valor introducido
  onMobileEmergencySuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const { aMobileEmergencies } = this.props.oMobileEmergencies;
    const lMobileEmergencySuggestions = inputLength === 0 ? [] : aMobileEmergencies.filter((oMobileEmergency) => oMobileEmergency.name.toLowerCase().slice(0, inputLength) === inputValue);
    this.setState({ lMobileEmergencySuggestions });
  }
  // Autosuggest will call this function every time you need to clear suggestions.
  onMobileEmergencySuggestionsClearRequested = () => {
    this.setState({
      lMobileEmergencySuggestions: [],
    });
  }
  // Use your imagination to render suggestions.
  renderMedicalSocietySuggestion = (oMedicalSocietySuggestion) => (
    <div className="suggestion-item">
      <span>{oMedicalSocietySuggestion.name}</span>
    </div>
  )
  // Funcion que se ejecuta cada vez que cambia el valor entrado en el input de emergencia movil
  onChangeMedicalSociety = (event, { newValue }) => {
    this.setState({
      oSelectedMedicalSociety: newValue,
    });
    let oLoan = this.state.oLoan;
    oLoan.show_medical_society = newValue;
    this.setState({ oLoan });
  }
  // Funcion que filtra la lista de emergencia movil y devuelve los sugeridos segun el valor introducido
  onMedicalSocietySuggestionsFetchRequested = ({ value }) => {
    const sValue = value.trim().toLowerCase();
    const nValueLength = sValue.length;
    const { aMedicalSocieties } = this.props.oMedicalSocieties;

    const lMedicalSocietySuggestions = nValueLength === 0 ? [] : aMedicalSocieties.filter((oMedicalSociety) => oMedicalSociety.name.toLowerCase().slice(0, nValueLength) === sValue)
    this.setState({ lMedicalSocietySuggestions });
  }
  // Autosuggest will call this function every time you need to clear suggestions.
  onMedicalSocietySuggestionsClearRequested = () => {
    this.setState({
      lMedicalSocietySuggestions: [],
    });
  }

  renderLoanModal = () => {
    const {
      oLoan,
      lDeviceSuggestions,
      oSelectedDevice,
      lMobileEmergencySuggestions,
      oSelectedMobileEmergency,
      lMedicalSocietySuggestions,
      oSelectedMedicalSociety,
      address,
      city,
      departament,
      zipcode,
      aStates,
      aCities
    } = this.state;
    return (
      this.state.isModalLoanOpen && (
        <Modal isOpen={this.state.isModalLoanOpen} handleActionModal={this._handleSaveLoan} handleCloseModal={this._handleCloseModal}
          modalTitle="Crear préstamo" customStyles={{ width: "750px" }}>
          <Grid.Row className="system-body">
            <Grid.Col width={6}>
              <Form.Group>
                <label className="custom-switch">
                  <input type="checkbox" name="active" className="custom-switch-input" defaultChecked={oLoan.active} value={oLoan.active} onChange={this._handleCheckedChange} />
                  <span className="custom-switch-indicator"></span>
                  <span className="custom-switch-description font-weight-bold">
                    Activo
                  </span>
                </label>
              </Form.Group>
            </Grid.Col>
            <Grid.Col width={6}>
            </Grid.Col>
            <Grid.Col width={6}>
              <Form.Group label="Fecha inicio">
                <DatePicker className="datepicker" locale={es} selected={oLoan.loanStartDate ? oLoan.loanStartDate : Helpers.GetDateForDatepicker()} dateFormat={"dd/MM/yyyy"} showYearDropdown onChange={this._handleLoanStartDateChange} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col width={6}>
              <Form.Group label="Fecha fin">
                <DatePicker className="datepicker" locale={es} selected={oLoan.loanEndDate ? oLoan.loanEndDate : Helpers.GetDateForDatepicker()} dateFormat={"dd/MM/yyyy"} showYearDropdown onChange={this._handleLoanEndDateChange} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col width={6}>
              <Form.Group label="Aparato">
                <Autosuggest
                  suggestions={lDeviceSuggestions}
                  onSuggestionsFetchRequested={this.onDeviceSuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onDeviceSuggestionsClearRequested}
                  getSuggestionValue={(oDeviceSuggestion) => oDeviceSuggestion.name}
                  renderSuggestion={this.renderDeviceSuggestion}
                  inputProps={{
                    className: "form-control",
                    value: oSelectedDevice,
                    onChange: this.onChangeDevice,
                  }}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col width={6}>
              <Form.Group label="Teléfono aparato">
                <Form.Input name="devicephone" placeholder="Teléfono aparato" value={oLoan.devicephone} onChange={this._handleChange} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col width={12}>
              <Form.Group label="Domicilio">
                <Form.InputGroup>
                  <Form.Input name="address" placeholder="Domicilio" value={address} onChange={this._handleChangeAddress} className="field-address" />
                  <Form.Select name="cities" className="col-3" onChange={this._handleChangeCity} value={city ? city.id : ""}>
                    {
                      aCities.map((oCity) =>
                        <option key={`city${oCity.id}`} value={oCity.id}>{oCity.name}</option>
                      )
                    }
                  </Form.Select>
                  <Form.Select name="states" className="col-3" onChange={this._handleChangeState} value={departament ? departament.id : ""}>
                    {
                      aStates.map((oState) =>
                        <option key={`state${oState.id}`} value={oState.id}>{oState.name}</option>
                      )
                    }
                  </Form.Select>
                  <Form.Input name="zipcode" placeholder="Código postal" value={zipcode} onChange={this._handleChangeZipCode} className="field-zipcode" disabled />
                </Form.InputGroup>
              </Form.Group>
            </Grid.Col>
            <Grid.Col width={6}>
              <Form.Group label="Emergencia móvil">
                <Autosuggest
                  suggestions={lMobileEmergencySuggestions}
                  onSuggestionsFetchRequested={this.onMobileEmergencySuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onMobileEmergencySuggestionsClearRequested}
                  getSuggestionValue={(oMobileEmergencySuggestion) => oMobileEmergencySuggestion.name}
                  renderSuggestion={this.renderMobileEmergencySuggestion}
                  inputProps={{
                    className: "form-control",
                    value: oSelectedMobileEmergency,
                    onChange: this.onChangeMobileEmergency,
                  }}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col width={6}>
              <Form.Group label="Sociedad médica">
                <Autosuggest
                  suggestions={lMedicalSocietySuggestions}
                  onSuggestionsFetchRequested={this.onMedicalSocietySuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onMedicalSocietySuggestionsClearRequested}
                  getSuggestionValue={(oMedicalSocietySuggestion) => oMedicalSocietySuggestion.name}
                  renderSuggestion={this.renderMedicalSocietySuggestion}
                  inputProps={{
                    className: "form-control",
                    value: oSelectedMedicalSociety,
                    onChange: this.onChangeMedicalSociety,
                  }}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col width={6}></Grid.Col>
            <Grid.Col width={6}></Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col width={6}>
              <Form.Group label="Contactos">
                <Form.Textarea name="contacts" placeholder="Contactos" value={oLoan.contacts} onChange={this._handleChange} rows={4} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col width={6}>
              <Form.Group label="Observaciones">
                <Form.Textarea name="observation" placeholder="Observaciones" value={oLoan.observation} onChange={this._handleChange} rows={4} />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Modal>
      )
    )
  }

  _handleOpenLoanModal = () => {
    this.setState({ isModalLoanOpen: true, oLoan: { id: null, active: true }, address: "", zipcode: "", departament: oEmptyState, city: oEmptyCity, oSelectedDevice: "", oSelectedMobileEmergency: "", oSelectedMedicalSociety: "" });
  }

  _handleexportExcel = () => {
    window.open(CONSTANTS.API_URL + 'loans/clientreport/' + this.props.oLoans.oClient.id, '_blank');
  }

  _handleSaveLoan = () => {
    if (!this.state.oLoan.device_model || this.state.oLoan.device_model.trim() === "") {
      this.props.message("El campo Aparato es obligatorio.", CONSTANTS_MESSAGE.TYPE_ERROR);
      return;
    } else if (!this.state.oLoan.devicephone || this.state.oLoan.devicephone.trim() === "") {
      this.props.message("El campo Teléfono aparato es obligatorio.", CONSTANTS_MESSAGE.TYPE_ERROR);
      return;
    }
    let oLoan = JSON.parse(JSON.stringify(this.state.oLoan));
    oLoan.client_id = this.props.oLoans.oClient.id;
    oLoan.loanStartDate = Helpers.CastDate(oLoan.loanStartDate, DATE_FORMAT_MYSQL);
    oLoan.loanEndDate = Helpers.CastDate(oLoan.loanEndDate, DATE_FORMAT_MYSQL);
    if (this.state.oLoan.id === null)
      this.props.saveLoan(oLoan);
    else
      this.props.updateLoan(oLoan);
    this._handleCloseModal();
  }

  _handleCloseModal = async () => {
    let nClientId = +this.props.match.params.nClientId;
    this.getLoans(nClientId);
    await this.setState({ isModalLoanOpen: false, oLoan: { id: null, active: true }, address: "", zipcode: "", departament: oEmptyState, city: oEmptyCity, oSelectedDevice: "", oSelectedMobileEmergency: "", oSelectedMedicalSociety: "" });
  }

  render = () => {
    const { oClient } = this.props.oLoans;
    const { aLoans } = this.props.oLoans;
    //const sName = oClient ? `${oClient.name ? oClient.name : ""} ${oClient.lastname ? oClient.lastname : ""}` : null;
    const sName = oClient ? `${oClient.lastname ? oClient.lastname : ""} ${oClient.secondsurname  ? oClient.secondsurname  : ""} ${oClient.name ? oClient.name : ""} ${oClient.secondname ? oClient.secondname : ""} ` : null;
 
    return (
      <Card>
        <Card.Header>
          <Card.Title className="mr-auto">
            {
              oClient && (
                (sName && sName.trim())
                  ? <span className="alert alert-success mr-2">{sName}</span>
                  : <i className="alert alert-success mr-2">Cliente sin nombre</i>
              )
            }
            Listado de préstamos
          </Card.Title>
          <Button color="primary" className="ml-2" onClick={this._handleexportExcel}>
            <FontAwesomeIcon icon={faFileExcel} className="mr-2"></FontAwesomeIcon>
            Exportar a excel
          </Button>
          <Button className="btn btn-primary ml-3" onClick={() => this._handleOpenLoanModal(oClient)}>
            <Icon name="plus" className="mr-2" />
            Nuevo
          </Button>
          <Link className="btn btn-secondary ml-3" to={"/clients"}>
            <Icon name="corner-up-right" className="mr-2" />
            Volver
          </Link>
        </Card.Header>
        {
          aLoans && aLoans.length > 0 ?
            <div className="table-responsive">
              <Table className="card-table table-vcenter table-sm table-hover text-nowrap mb-4">
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Teléfono</Table.ColHeader>
                    <Table.ColHeader>Fecha inicio</Table.ColHeader>
                    <Table.ColHeader>Fecha fin</Table.ColHeader>
                    <Table.ColHeader>Observación</Table.ColHeader>
                    <Table.ColHeader className="text-center">Devolución</Table.ColHeader>
                    <Table.ColHeader></Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{this.renderTable()}</Table.Body>
              </Table>
            </div>
            :
            <Card.Body>
              <Grid.Row>
                <Grid.Col>
                  <Alert type="info" icon="info">
                    El cliente no tiene préstamos asociados
                  </Alert>
                </Grid.Col>
              </Grid.Row>
            </Card.Body>
        }
        {this.renderLoanModal()}
      </Card>
    )
  }

  renderTable = () => {
    const { aLoans, oClient } = this.props.oLoans;
    return aLoans.map((oLoan) => (
      <Table.Row key={`loan_${oLoan.id}`}>
        <Table.Col>{oLoan.devicephone}</Table.Col>
        <Table.Col>
          {oLoan.start ? Helpers.CastDate(oLoan.start) : '-'}
        </Table.Col>
        <Table.Col>
          {oLoan.end ? Helpers.CastDate(oLoan.end) : '-'}
        </Table.Col>
        <TableColSmall value={oLoan.observation} length={50} />
        <Table.Col className="text-center isActive-indicator">
          <div className='form-group vertical-align m-1'>
            <label className="custom-switch">
              <input type="checkbox" name="active" className="custom-switch-input" defaultChecked={oLoan.returned} onChange={() => this.props.activeLoan(oClient.id, oLoan.id)} />
              <span className="custom-switch-indicator"></span>
              <span className="custom-switch-description font-weight-bold"></span>
            </label>
          </div>
        </Table.Col>
        <Table.Col className="text-center">
          <TableAction iconName="edit" tooltip="Editar" onClick={() => this._handleEditLoan(oLoan)} />
        </Table.Col>
      </Table.Row>
    ))
  }


}

const mapStateToProps = (state) => ({
  propsStates: state.statesReducer,
  propsCities: state.citiesReducer,
  propsDevices: state.devicesReducer,
  oMobileEmergencies: state.mobileEmergenciesReducer,
  oMedicalSocieties: state.medicalSocietiesReducer,
  oLoans: state.loansReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getLoans: (nClientId) => {
    dispatch(actionGetLoans(nClientId));
  },
  getDevices: () => {
    dispatch(actionGetDevices());
  },
  getMobileEmergencies: () => {
    dispatch(actionGetMobileEmergencies());
  },
  getMedicalSocieties: () => {
    dispatch(actionGetMedicalSocieties());
  },
  getStates: () => {
    dispatch(actionGetStates());
  },
  getCities: (nStateId) => {
    dispatch(actionGetCities(nStateId));
  },
  saveLoan: (oLoan) => {
    dispatch(actionSaveLoan(oLoan));
  },
  updateLoan: (oLoan) => {
    dispatch(actionUpdateLoan(oLoan));
  },
  activeLoan: (nClientId, nLoanId) => {
    dispatch(actionActiveLoan(nClientId, nLoanId));
  },
  deleteDevice: (nDeviceId) => {
    dispatch(actionDeleteDevice(nDeviceId));
  },
  clean: () => {
    dispatch(actionCleanLoans());
  },
  message: (msg, type) => {
    dispatch(actionMessage(msg, type));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LoansList);
