import React, { Component } from "react";
import { connect } from "react-redux";
import {
  actionGetClient,
  actionGetAmountOfClientsByFee,
  actionChangeClientsFee,
  actionGenerateClientsFees,
  actionGetClientsBilling,
  actionCleanClients,
  actionGenerateClientsCardFees
} from "redux/actions/ClientsActions";
import {
  Card,
  Form,
  Table,
  Alert,
  Grid,
  Button,
  Icon,
  Dropdown,
} from "tabler-react";
import { TableAction } from "components/buttons/TableAction";
import { Pagination } from "components/Pagination";
import {
  actionGetClientSinglePagination,
  actionImportFile,
} from "../../redux/actions/PaymentsActions";
import Modal from "components/Modal";
import { CONSTANTS } from "../../constants/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { Helpers } from "helpers/Helpers";

class BillingList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      oTimeOutId: null,
      sSearch: "",
      currentPage: 1,
      nClientsPerPage: 10,
      nclientId: null,
      sFilterDate: "billing",
      nMonth: parseInt(Helpers.ConvertTZ(new Date()).getMonth()) + 1,
      nYear: Helpers.ConvertTZ(new Date()).getFullYear(),
      oPayment: {
        aMount: 0,
        importFile: "",
        paymentDate: Helpers.ConvertTZ(new Date()),
        paymethod: "abitab",
      },
      oPaymentEmpty: {
        aMount: 0,
        importFile: {},
        paymentDate: Helpers.ConvertTZ(new Date()),
        paymethod: "abitab",
      },
      isModalLoadPaymentsOpen: false,
      isModalChangeFeeOpen: false,
      isModalConfirmChangeFeeOpen: false,
      isModalConfirmGenerateFeesModal: false,
      isModalConfirmGenerateCardFeesModal: false,
      lastFeeAmount: 0,
      nextFeeAmount: 0,
      oForm: new FormData(),
      bMidesChangeFee: 0
    };
  }

  componentDidMount = () => {
    this.props.get(0, this.state.nClientsPerPage, null);
  };
  componentDidUpdate = () => { };

  componentWillUnmount = () => {
    this.props.cleanClients();
  };

  _handleexportExcel = (payMethod) => {
    if (payMethod === "collectionAndBilling")
      window.open(CONSTANTS.API_URL + "reportbilling?search=" + this.state.sSearch, "_blank");
    window.open(
      CONSTANTS.API_URL +
      "reports?search=" +
      this.state.sSearch +
      "&filterDate=" +
      payMethod +
      "&startDate=" +
      this.state.nYear +
      "-" +
      this.state.nMonth +
      "-01&endDate=" +
      this.state.nYear +
      "-" +
      this.state.nMonth +
      "-31",
      "_blank"
    );
  };

  _handleSearchChange = (event) => {
    let { value } = event.target;
    this.setState({ currentPage: 1 });
    clearTimeout(this.state.oTimeOutId);
    let oTimeOutId = setTimeout(() => {
      this.props.get(0, this.state.nClientsPerPage, value);
    }, 800);
    this.setState({ sSearch: value, oTimeOutId });
  };

  _handlechangeCount = async (event) => {
    let { value } = event.target;
    let { sSearch } = this.state;
    await this.setState({ nClientsPerPage: value, currentPage: 1 });
    this.props.get(0, value, sSearch);
  };

  _handleNextPage = async () => {
    let nClientsPerPage = this.state.nClientsPerPage;
    let nPage = parseInt(this.state.currentPage) * parseInt(nClientsPerPage);
    await this.setState({ currentPage: this.state.currentPage + 1 });
    this.props.get(nPage, this.state.nClientsPerPage, this.state.sSearch);
  };
  _handlePreviousPage = async () => {
    await this.setState({ currentPage: this.state.currentPage - 2 });
    let nClientsPerPage = this.state.nClientsPerPage;
    let nPage;
    if (this.state.currentPage <= 1) {
      nPage = 0;
    } else {
      nPage = this.state.currentPage * nClientsPerPage;
    }
    this.setState({ currentPage: this.state.currentPage + 1 });
    this.props.get(nPage, this.state.nClientsPerPage, this.state.sSearch);
  };
  _handleGoToPage = async (nPage) => {
    let nClientsPerPage = this.state.nClientsPerPage;
    this.setState({ currentPage: nPage });
    let nSkip;
    if (this.state.currentPag <= 1) {
      nSkip = 0;
    } else {
      nSkip = (nPage - 1) * nClientsPerPage;
    }
    this.props.get(nSkip, this.state.nClientsPerPage, this.state.sSearch);
  };

  renderTable = () => {
    return this.props.propsClients.aClients.data.map((oClient) => {
      return (
        <Table.Row key={oClient.id}>
          <Table.Col>
            {
              (oClient.fullname && oClient.fullname.trim())
                ? oClient.fullname
                : <i className='text-muted'>Cliente sin nombre</i>
            }
          </Table.Col>
          <Table.Col className="text-center">{oClient.code}</Table.Col>
          <Table.Col className="text-center">{oClient.feevalue}</Table.Col>
          <Table.Col className="text-center">{oClient.debt}</Table.Col>
          <Table.Col className="text-center d-flex">
            <TableAction
              iconName="file-text"
              tooltip="Servicios adicionales"
              onClick={() => this.goToView(oClient.id, "/additionalServices")}
            />
            <TableAction
              iconName="eye"
              tooltip="Detalles de pagos"
              onClick={() => this.goToEdit(oClient.id, "/billing/edit")}
            />
          </Table.Col>
        </Table.Row>
      );
    });
  };

  goToEdit = (nClientId, sRoute) => {
    this.props.getClientsSinglePagination(nClientId, null);
    this.props.history.push(sRoute + "/" + nClientId);
  };
  goToView = (nClientId, sRoute) => {
    this.props.getClient(nClientId);
    this.props.history.push(sRoute);
  };

  loadPaymentsModal = () => {
    let oPayment = this.state.oPaymentEmpty;
    this.setState({ oPayment });
    this.setState({ isModalLoadPaymentsOpen: true });
  };
  _handleCloseModal = () => {
    this.setState({
      oPayment: { ...this.state.oPaymentEmpty },
      isModalLoadPaymentsOpen: false,
    });
  };
  //----------------------------------- Fee modal functions --------------------------------------
  loadChangeFeeModal = () => {
    this.setState({ isModalChangeFeeOpen: true });
  };
  _handleCloseChangeFeeModal = () => {
    this.setState({
      isModalChangeFeeOpen: false,
      lastFeeAmount: 0,
      nextFeeAmount: 0,
      bMidesChangeFee: 0
    });
  };
  changeFeeAmount = () => {
    this.props.getAmountOfClientsByFee(this.state.lastFeeAmount, this.state.bMidesChangeFee);
    this.setState({
      isModalChangeFeeOpen: false,
      isModalConfirmChangeFeeOpen: true,
    });
    
  };
  _handleChangeLastFee = (event) => {
    const { value } = event.target;
    if (!isNaN(value)) {
      this.setState({ lastFeeAmount: value });
    }
  };
  _handleChangeNextFee = (event) => {
    const { value } = event.target;
    if (!isNaN(value)) {
      this.setState({ nextFeeAmount: value });
    }
  };
  renderChangeFeeModal = () => {
    const { lastFeeAmount, nextFeeAmount } = this.state;
    return (
      this.state.isModalChangeFeeOpen && (
        <Modal
          isOpen={this.state.isModalChangeFeeOpen}
          handleActionModal={this.changeFeeAmount}
          handleCloseModal={this._handleCloseChangeFeeModal}
          modalTitle="Cambio de cuotas"
          customStyles={{
            width: "750px",
          }}
        >
          <Grid.Row className="system-body">
            <Grid.Col width={6}>
              <Form.Group label="Anterior">
                <Form.Input
                  name="lastFeeAmount"
                  value={lastFeeAmount}
                  placeholder="0"
                  onChange={this._handleChangeLastFee}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col width={6}>
              <Form.Group label={"Cambiar a"}>
                <Form.Input
                  name="nextFeeAmount"
                  value={nextFeeAmount}
                  placeholder="0"
                  onChange={this._handleChangeNextFee}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row className="system-body">
            <Grid.Col width={6}>
              <Form.Group label="Tipo de clientes">
                <Form.Select name="bMidesChangeFee" value={this.state.bMidesChangeFee} onChange={this._handleChangeTypeClient}>
                  <option value={0}>No Mides</option>
                  <option value={1}>Mides</option>
                </Form.Select>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Modal>
      )
    );
  };

  applyChangeFeeAmount = async () => {
    let nClientsPerPage = this.state.nClientsPerPage;
    let nPage = parseInt(this.state.currentPage) * parseInt(nClientsPerPage);
    this.props.changeClientsFee(this.state.lastFeeAmount, this.state.nextFeeAmount, this.state.bMidesChangeFee);
    await this.setState({
      isModalConfirmChangeFeeOpen: false,
      lastFeeAmount: 0,
      nextFeeAmount: 0,
      bMidesChangeFee: 0
    });
    this.props.get(
      nPage - nClientsPerPage,
      nClientsPerPage,
      this.state.sSearch
    );
  };

  _handleCloseConfirmChangeFeeModal = () => {
    this.setState({
      lastFeeAmount: 0,
      nextFeeAmount: 0,
      isModalConfirmChangeFeeOpen: false,
      bMidesChangeFee: 0
    });
  };
  renderConfirmChangeFeeModal = () => {
    const { lastFeeAmount, nextFeeAmount } = this.state;
    return (
      this.props.propsClients.nClientsAmountByFee !== null && (
        <Modal
          isOpen={this.state.isModalConfirmChangeFeeOpen}
          handleActionModal={this.applyChangeFeeAmount}
          handleCloseModal={this._handleCloseConfirmChangeFeeModal}
          modalTitle="Confirmar"
          customStyles={{
            width: "550px",
          }}
        >
          <Grid.Row className="system-body">
            <Grid.Col width={12}>
              Se van a actualizar{" "}
              <b>{this.props.propsClients.nClientsAmountByFee}</b> clientes de
              la cuota <b>$ {lastFeeAmount}</b> a <b>$ {nextFeeAmount}</b>
            </Grid.Col>
          </Grid.Row>
        </Modal>
      )
    );
  };

  /** FUNCIONES DE GENERACION DE CUOTAS DE CLIENTES */
  /* ---------------------------------------------------------------------------------------------- */
  applyGenerateClientsFees = async () => {
    let nClientsPerPage = this.state.nClientsPerPage;
    let nPage = parseInt(this.state.currentPage) * parseInt(nClientsPerPage);
    let sSearch = this.state.sSearch;
    this.props.generateClientsFee(
      nPage - nClientsPerPage,
      nClientsPerPage,
      sSearch
    );
    await this.setState({
      isModalConfirmGenerateFeesModal: false,
    });
  };
  loadConfirmGenerateFeesModal = () => {
    this.setState({ isModalConfirmGenerateFeesModal: true });
  };
  _handleCloseConfirmGenerateFeesModal = () => {
    this.setState({
      isModalConfirmGenerateFeesModal: false,
    });
  };
  renderGenerateClientsFeeModal = () => {
    return (
      <Modal
        isOpen={this.state.isModalConfirmGenerateFeesModal}
        handleActionModal={this.applyGenerateClientsFees}
        handleCloseModal={this._handleCloseConfirmGenerateFeesModal}
        modalTitle="Confirmar"
        customStyles={{
          width: "550px",
        }}
      >
        <Grid.Row className="system-body">
          <Grid.Col width={12}>¿Generar las cuotas para los clientes?</Grid.Col>
        </Grid.Row>
      </Modal>
    );
  };
  /* ---------------------------------------------------------------------------------------------- */

  applyGenerateClientsCardFees = async () => {
    let nClientsPerPage = this.state.nClientsPerPage;
    let nPage = parseInt(this.state.currentPage) * parseInt(nClientsPerPage);
    let sSearch = this.state.sSearch;
    this.props.generateClientsCardFee(
      nPage - nClientsPerPage,
      nClientsPerPage,
      sSearch
    );
    await this.setState({
      isModalConfirmGenerateCardFeesModal: false,
    });
  };
  loadConfirmGenerateCardFeesModal = () => {
    this.setState({ isModalConfirmGenerateCardFeesModal: true });
  };
  _handleCloseConfirmGenerateCardFeesModal = () => {
    this.setState({
      isModalConfirmGenerateCardFeesModal: false,
    });
  };
  renderGenerateClientsCardFeeModal = () => {
    return (
      <Modal
        isOpen={this.state.isModalConfirmGenerateCardFeesModal}
        handleActionModal={this.applyGenerateClientsCardFees}
        handleCloseModal={this._handleCloseConfirmGenerateCardFeesModal}
        modalTitle="Confirmar"
        customStyles={{
          width: "550px",
        }}
      >
        <Grid.Row className="system-body">
          <Grid.Col width={12}>¿Generar las cuotas para los clientes con tarjeta?</Grid.Col>
        </Grid.Row>
      </Modal>
    );
  };
  /* ---------------------------------------------------------------------------------------------- */
  _handleCheckedChange = (event) => {
    let { name, checked } = event.target;
    let oPayment = this.state.oPayment;
    oPayment[name] = checked;
    this.setState({ oPayment });
  };
  _handleChange = (event) => {
    const { name, value } = event.target;
    let oPayment = this.state.oPayment;
    oPayment[name] = value;
    this.setState({ oPayment });
  };
  _handleChangeTypeClient = (event) => {
    const { value } = event.target;
    this.setState({ bMidesChangeFee: value });
  };
  _handlePaymentChange = (selectedDate) => {
    let oClientTemp =
      this.state.nPage === 1
        ? { ...this.state.oClient }
        : { ...this.state.oPartner };
    oClientTemp.birthdate = selectedDate;
    this.saveChange(oClientTemp);
  };
  _handlePaymentDateChange = (selectedDate) => {
    let oPayment = this.state.oPayment;
    oPayment.paymentDate = selectedDate;
    this.setState({ oPayment });
  };
  sendImportPayment = () => {
    let oPayment = this.state.oPayment;
    const oForm = new FormData();

    oForm.append("file", oPayment.importFile);
    oForm.append("aMount", oPayment.aMount);
    oForm.append("paymentDate", oPayment.paymentDate);
    oForm.append("paymethod", oPayment.paymethod);
    oForm.append("observation", oPayment.observation);

    if (this.state.oPayment.importFile !== "") {
      this.props.sendImportFile(oForm, oPayment.paymethod);
      this.setState({
        oPayment: { ...this.state.oPaymentEmpty },
        isModalLoadPaymentsOpen: false,
      });
      this.goToReportPayments(oPayment.paymethod, "billing/paymentreport");
    } else {
    }
  };

  goToReportPayments = (payMethod, sRoute) => {
    this.props.history.push(sRoute + "/" + payMethod);
  };

  addFile = (file) => {
    let oPayment = this.state.oPayment;
    oPayment.importFile = file[0];
  };

  renderPaymenImportModal = () => {
    const { oPayment } = this.state;
    return (
      this.state.isModalLoadPaymentsOpen && (
        <Modal
          isOpen={this.state.isModalLoadPaymentsOpen}
          handleActionModal={this.sendImportPayment}
          handleCloseModal={this._handleCloseModal}
          modalTitle="Cargar archivo de pagos"
          customStyles={{
            width: "750px",
          }}
        >
          <Grid.Row className="system-body">
            {/*
            Queda comentado por posible uso mas adelante!! 
            <Grid.Col width={6}>
              <Form.Group label={"Fecha"}>
                <DatePicker
                  className="form-control"
                  locale={es}
                  dateFormat="dd/MM/yyyy"
                  selected={oPayment.paymentDate ? oPayment.paymentDate : ""}
                  onChange={this._handlePaymentDateChange}
                  showYearDropdown
                />
              </Form.Group>
            </Grid.Col> */}
            <Grid.Col width={6}>
              <Form.Group label="Metodo de pago">
                <Form.Select name="paymethod" value={oPayment.paymethod} onChange={this._handleChange}>
                  <option value={"abitab"}>Abitab</option>
                  <option value={"redpagos"}>RedPagos</option>
                  <option value={"sistarbanc"}>SistarBanc</option>
                  <option value={"facilpago"}>FacilPago</option>
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col width={6}>
              <Form.Group label={"Archivo de importacion"}>
                <input type="file" onChange={(e) => this.addFile(e.target.files)} accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel' />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Modal>
      )
    );
  };

  render = () => {
    const { aClients } = this.props.propsClients;
    const { nClientsPerPage, currentPage } = this.state;
    return (
      <Card>
        <Card.Header>
          <Card.Title className="mr-auto">Cobros y facturación</Card.Title>
          <Form.Input
            icon="search"
            placeholder="Buscar"
            onChange={this._handleSearchChange}
          />

          <Button
            className="btn btn-primary ml-3"
            type="button"
            color="primary"
            onClick={this.loadPaymentsModal}
          >
            <Icon name="chevrons-up" className="mr-2" />
            Cargar Pagos
          </Button>

          <Dropdown
            className="ml-3"
            type="button"
            color="primary"
            arrow
            icon="chevrons-up"
            triggerContent="Generar"
            itemsObject={[
              {
                value: "Generar Cuotas Tarjetas",
                onClick: this.loadConfirmGenerateCardFeesModal,
              },
              {
                value: "Generar Cuotas",
                onClick: this.loadConfirmGenerateFeesModal,
              },
            ]}
          />

          <Button
            className="btn btn-primary ml-3"
            onClick={this.loadChangeFeeModal}
          >
            <Icon name="dollar-sign" className="mr-2" />
            Cambio de cuotas
          </Button>
          {aClients.data && aClients.data.length > 0 ? (
            <>
              <Dropdown
                className="ml-3"
                type="button"
                color="primary"
                arrow
                icon="chevrons-up"
                triggerContent="Planilla de Cobro"
                itemsObject={[
                  {
                    value: "ABITAB",
                    onClick: () => this._handleexportExcel("abitab"),
                  },
                  {
                    value: "REDPAGOS",
                    onClick: () => this._handleexportExcel("redpagos"),
                  },
                  {
                    value: "FACILPAGO",
                    onClick: () => this._handleexportExcel("facilpago"),
                  },
                  {
                    value: "SISTARBANC",
                    onClick: () => this._handleexportExcel("sistarbanc"),
                  },
                ]}
              />
            </>
          ) : (
            ""
          )}
          <Button
            color="primary"
            className="ml-2"
            onClick={() => this._handleexportExcel("collectionAndBilling")}
          >
            <FontAwesomeIcon
              icon={faFileExcel}
              className="mr-2"
            ></FontAwesomeIcon>
            Exportar
          </Button>
        </Card.Header>

        {Array.isArray(this.props.propsClients.aClients.data) &&
          this.props.propsClients.aClients.data.length > 0 ? (
          <>
            <div className="table-responsive">
              <Table className="card-table table-vcenter table-sm table-hover text-nowrap">
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Apellido Nombre</Table.ColHeader>
                    <Table.ColHeader className="text-center">
                      Nro de Socio
                    </Table.ColHeader>
                    <Table.ColHeader className="text-center">
                      Cuota
                    </Table.ColHeader>
                    <Table.ColHeader className="text-center">
                      Deuda pendiente
                    </Table.ColHeader>
                    <Table.ColHeader className="text-center"></Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{this.renderTable()}</Table.Body>
              </Table>
            </div>
            <Card.Footer>
              <Pagination
                nextPageAction={this._handleNextPage}
                previousPageAction={this._handlePreviousPage}
                goToPageAction={this._handleGoToPage}
                currentPage={currentPage}
                allRecords={aClients.allRecords}
                showing={Array.isArray(aClients.data) ? aClients.data.length : 0}
                nLastPage={Math.ceil(
                  parseInt(aClients.allRecords) / parseInt(nClientsPerPage)
                )}
                showAllOptions={true}
                _handlechangeCount={this._handlechangeCount}
              />
            </Card.Footer>
          </>
        ) : (
          <Card.Body>
            <Grid.Row>
              <Grid.Col>
                <Alert type="info" icon="info">
                  No hemos obtenido datos de clientes
                </Alert>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        )}
        {this.renderPaymenImportModal()}
        {this.renderChangeFeeModal()}
        {this.renderConfirmChangeFeeModal()}
        {this.renderGenerateClientsFeeModal()}
        {this.renderGenerateClientsCardFeeModal()}
      </Card>
    );
  };
}

const mapStateToProps = (state) => ({
  propsClients: state.clientsReducer,
  nAllRecords: state.nAllRecordsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getClient: (idClient) => {
    dispatch(actionGetClient(idClient));
  },

  sendImportFile: (oPayment, paymethod) => {
    dispatch(actionImportFile(oPayment, paymethod));
  },

  changeClientsFee: (nLastFee, nNextFee, bMidesChangeFee) => {
    dispatch(actionChangeClientsFee(nLastFee, nNextFee, bMidesChangeFee));
  },

  generateClientsFee: (nPage, nClientsPerPage, sSearch) => {
    dispatch(actionGenerateClientsFees(nPage, nClientsPerPage, sSearch));
  },
  generateClientsCardFee: (nPage, nClientsPerPage, sSearch) => {
    dispatch(actionGenerateClientsCardFees(nPage, nClientsPerPage, sSearch));
  },

  getAmountOfClientsByFee: (nFeeAmount, bMidesChangeFee) => {
    dispatch(actionGetAmountOfClientsByFee(nFeeAmount, bMidesChangeFee));
  },

  getClientsSinglePagination: (nIdClient, nItemIndex) => {
    dispatch(actionGetClientSinglePagination(nIdClient, nItemIndex));
  },
  get: (nPage, nClientsPerPage, sSearch) => {
    dispatch(actionGetClientsBilling(nPage, nClientsPerPage, sSearch));
  },
  cleanClients: () => {
    dispatch(actionCleanClients());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingList);
