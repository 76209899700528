import { CONSTANTS } from "constants/Constants";
import oAxiosInstance from "redux/interceptors/Interceptors";
const FEES_URL = CONSTANTS.API_URL + "fee";

export const GetFeesByClientAndRange = (nClientId, dToDate) => {
  return oAxiosInstance
    .get(FEES_URL + "/getByDateRange", {
      params: {
        client_id: nClientId,
        dToDate: dToDate
      },
    })
    //.then((oResult) => oResult.data);
    .then((oResult) => oResult.data)
};
