import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Form, Table, Alert, Grid, Icon } from "tabler-react";
import { TableAction } from "components/buttons/TableAction";
import { connect } from "react-redux";
import Modal from "components/Modal";
import { actionGetStates } from "redux/actions/StatesActions";
import { actionGetCities } from "redux/actions/CitiesActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThList, faTrash } from "@fortawesome/free-solid-svg-icons";
import { actionGetVoucherTypes } from "redux/actions/VoucherTypesActions";
import {
  actionGetReceipts,
  actionSaveReceipt,
  actionUpdateReceipt,
  actionResetReceipts,
  actionDeleteAdditionalService
} from "redux/actions/ReceiptsActions";
import {
  actionGetClientSinglePagination,
  actionProcessPaymentService,
} from "redux/actions/PaymentsActions";
import { CONSTANTS } from "constants/Constants";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { Helpers } from "helpers/Helpers";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";


class AdditionalServicesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      client_id: null,
      nreceiptdate: Helpers.GetDateForDatepicker(),
      receiptdate: Helpers.DateNowTz(),
      concept: "",
      isBusiness: false,
      rut: "",
      socialreason: "",
      domicile: "",
      corner1: "",
      customername: "",
      customeridentificationcard: "",
      bInvoiceNow: false,
      service_description: "",
      service_price: 0,
      subtotal: null,
      department: { id: null, name: "Departamento" },
      city: { id: null, name: "Ciudad" },
      zipcode: "",
      voucher_type_id: 1,
      aServices: [],
      isModalAdditionalServiceOpen: false,
      isModalConfirmInvoice: false,
      isModalMessageError: false,
      aCities: [],
      aStates: [],
      oClient: null,
      oAdditionalService: null,
      address_id: null,
      oDeleteAdditionalService: null,
      isModalDeleteAdditionalService: false
    };
    this.props.getTypeCmps();
    this.props.getStates();
    this.props.getCities(1);
  }

  componentDidMount = (oLastProps) => { };

  componentDidUpdate = (oLastProps) => {
    if (oLastProps.propsCities.aCities !== this.props.propsCities.aCities) {
      let aCitiesCopy = JSON.parse(JSON.stringify(this.props.propsCities.aCities)), oEmptyCity = { id: null, name: "Ciudad" };
      aCitiesCopy.unshift(oEmptyCity);
      this.setState({ aCities: aCitiesCopy });
    }
    if (oLastProps.propsStates.aStates !== this.props.propsStates.aStates) {
      let aStatesCopy = JSON.parse(JSON.stringify(this.props.propsStates.aStates)), oEmptyState = { id: null, name: "Departamento" };
      aStatesCopy.unshift(oEmptyState);
      this.setState({ aStates: aStatesCopy });
    }
    if (this.props.propsClient.oClient !== this.state.oClient) {
      this.setClient();
    }
  };

  componentWillUnmount = () => {
    this.props.cleanReceipts();
  };

  setClient = async () => {
    const { oClient } = this.props.propsClient;
    await this.setState({ oClient, client_id: oClient.id });
    this.getAdditionalServices();
  };

  getAdditionalServices = () => {
    const { oClient } = this.state;
    if (oClient) {
      this.props.getAdditionalServices(oClient.id);
      this.setState({ isBusiness: oClient.company ? true : false })
    }
  };

  goToEdit = (nClientId, sRoute) => {
    this.props.getClientsSinglePagination(nClientId, null);
    this.props.history.push(sRoute + "/" + nClientId);
  };

  _handleChangeValue = (oEvent) => {
    /* 
    if(oEvent.target.value.length > 80){
      return 
    } */

    this.setState({ [oEvent.target.name]: oEvent.target.value });
  };

  _handleCheckedChange = (oEvent) => {
    this.setState({ [oEvent.target.name]: oEvent.target.checked });
  };

  _handleServiceDateChange = selectedDate => {
    this.setState({ nreceiptdate: selectedDate });
  };

  _handleChangeState = async (oEvent) => {
    const { value: nDepartment } = oEvent.target;
    let oDepartment = this.state.aStates.find(oDepartment => oDepartment.id == nDepartment);
    await this.setState({ department: oDepartment, zipcode: "", city: { id: null, name: "Ciudad" } });
    await this.props.getCities(oDepartment.id);
  };

  _handleChangeCity = async (oEvent) => {
    let { value: nCity } = oEvent.target;
    let oCity = this.state.aCities.find(oCity => oCity.id == nCity);
    await this.setState({ city: oCity, zipcode: oCity.zipcode });
  };

  _handleAddService = () => {

    
    let { service_description, service_price, aServices, subtotal } = this.state; 

    if(service_description.length > 80){
      alert('La descripción del Servicio no debe superar los 80 caracteres')
      return
    }
    
    if (service_description && service_price) {
      aServices.push({ name: service_description, price: service_price });
      subtotal = aServices.reduce((a, b) => ({ price: parseFloat(a.price) + parseFloat(b.price) })).price;
      subtotal = parseInt(subtotal);
      this.setState({ aServices, service_description: "", service_price: 0, subtotal });
    }

  };


   
  
  _handleRemoveService = (index) => {
    let { aServices, subtotal } = this.state;
    let nAmountServiceRemove = aServices[index].price;
    aServices.splice(index, 1);
    subtotal -= parseInt(nAmountServiceRemove);
    this.setState({ aServices, subtotal });
  };

  _handleCloseModal = async () => {
    this.setState({ isModalAdditionalServiceOpen: false });
    this._flushModal();
    this.getAdditionalServices();
  };

  _flushModal = () => {
    this.setState({
      id: null,
      rut: "",
      socialreason: "",
      domicile: "",
      corner1: "",
      department: { id: null, name: "Departamento" },
      city: { id: null, name: "Ciudad" },
      zipcode: "",
      voucher_type_id: 1,
      nreceiptdate: Helpers.GetDateForDatepicker(),
      receiptdate: Helpers.DateNowTz(),
      aServices: [],
      service_description: "",
      service_price: 0,
      subtotal: 0,
      customername: "",
      customeridentificationcard: "",
      bInvoiceNow: false,
      isBusiness: false,
      oAdditionalService: null
    });
  }

  _handleCloseMessageErrorModal = () => {
    this.setState({ isModalMessageError: false, sError: "" });
  };

  _handleCloseConfirmInvoiceModal = () => {
    this.setState({ isModalConfirmInvoice: false });
  };

  applyGenerateInvoice = async () => {
    const { oAdditionalService } = this.state;
    let nAmountPayment = parseInt(oAdditionalService.subtotal);
    let sObservation = "";
    oAdditionalService.aServices.map((oService) => (sObservation += sObservation === "" ? oService.name : ", " + oService.name));
    let oPayment = { amount: nAmountPayment, client_id: oAdditionalService.client_id, observation: sObservation != "" ? sObservation : "Pago de servicios." };
    if (oAdditionalService.isBusiness) {
      oPayment.company_rut = oAdditionalService.rut;
      oPayment.company_address = oAdditionalService.domicile;
      oPayment.company_social_reason = oAdditionalService.socialreason;
    }
    await this.props.processPaymentService(oPayment, oAdditionalService);
    await this.setState({ isModalConfirmInvoice: false });
    this._flushModal();
  };

  formatDate = (sDate) => {
    let aDate = sDate.split("-");
    let oDate = new Date(aDate[0], parseInt(aDate[1]) - 1, aDate[2]);
    return oDate;
  };

  _handleEditAdditionalService = async (oAdditionalService) => {
    if (parseInt(oAdditionalService.isBusiness)) {
      this.props.getCities(oAdditionalService.address.state_id);
      this.setState({ address_id: oAdditionalService.address.id, department: oAdditionalService.address.state, city: oAdditionalService.address.city, zipcode: oAdditionalService.address.city ? oAdditionalService.address.city.zipcode : "", domicile: oAdditionalService.address.domicile, corner1: oAdditionalService.address.corner1, rut: oAdditionalService.rut, socialreason: oAdditionalService.socialreason });
    }
    else
      this.setState({ customername: oAdditionalService.customername, customeridentificationcard: oAdditionalService.customeridentificationcard });
    this.setState({ id: oAdditionalService.id, isBusiness: parseInt(oAdditionalService.isBusiness), aServices: oAdditionalService.services, client_id: oAdditionalService.client_id, subtotal: oAdditionalService.subtotal, voucher_type_id: oAdditionalService.voucher_type_id, nreceiptdate: this.formatDate(oAdditionalService.receiptdate) });
    this.setState({ isModalAdditionalServiceOpen: true });
  };

  _handleOpenAdditionalServiceModal = () => {
    this.setState({ isModalAdditionalServiceOpen: true });
  };


  _validBusiness = (rut, socialreason, domicile) => {
    if (rut && socialreason) {
      if (domicile)
        return "";
      return "Complete los campos relacionados a la dirección.";
    }
    return "Complete el rut y la razón social.";
  }

  _validClient = (customername, customeridentificationcard) => {
    //Se comenta por posible uso futuro Juan Andres Vega 20/07/21
    /*if (customername && customeridentificationcard)
      return "";
    return "Complete nombre, apellido y cédula.";*/
    return "";
  }

  _validServices = (aServices) => {
    if (aServices && aServices.length > 0)
      return "";
    return "No hay servicios cargados.";
  }

  loadConfirmInvoiceModal = async (oAdditionalService, sOption = "") => {
 
    if (sOption === "")
      this.setState({ oAdditionalService: oAdditionalService, isModalConfirmInvoice: true });
    else {
      let oAdditionalServiceNew = {
        id: oAdditionalService.id,
        bInvoiceNow: 1,
        isBusiness: parseInt(oAdditionalService.isBusiness),
        aServices: oAdditionalService.services,
        client_id: oAdditionalService.client_id,
        subtotal: oAdditionalService.subtotal,
        voucher_type_id: oAdditionalService.voucher_type_id,
        nreceiptdate: this.formatDate(oAdditionalService.receiptdate)
      }
      
      if (oAdditionalServiceNew.isBusiness) {
        //oAdditionalServiceNew.city = oAdditionalService.address.city ? parseInt(oAdditionalService.address.city.id) : null;
        //oAdditionalServiceNew.department = oAdditionalService.address.state ? parseInt(oAdditionalService.address.state.id) : null;
        oAdditionalServiceNew.domicile = this.state.oClient.addresses[0].domicile; //oAdditionalService.address.domicile;
        //oAdditionalServiceNew.corner1 = oAdditionalService.address.corner1;
        oAdditionalServiceNew.rut = oAdditionalService.rut;
        oAdditionalServiceNew.socialreason = oAdditionalService.socialreason;
      }
      else {
        oAdditionalServiceNew.customername = oAdditionalService.customername;
        oAdditionalServiceNew.customeridentificationcard = oAdditionalService.customeridentificationcard;
      }
      this.setState({ oAdditionalService: oAdditionalServiceNew, isModalConfirmInvoice: true });
    }
  };

  amountOfService = (aServices) => {
    let nTotal = 0;
    aServices.map((oService) => (nTotal += parseInt(oService.price)));
    return nTotal;
  };

  conceptOfServices = (aServices) => {
    let sConcept = "";
    aServices.map((oService) => sConcept === "" ? (sConcept = oService.name) : (sConcept += ", " + oService.name));
    if (sConcept.length > 60) {
      sConcept = sConcept.substr(0, 57) + "...";
    }
    return sConcept;
  };

  _handleSaveAdditionalService = async () => {
    const { id, isBusiness, bInvoiceNow, customername, customeridentificationcard, domicile, corner1, department, city, zipcode, aServices, subtotal, client_id, voucher_type_id, nreceiptdate } = this.state;
    const { oClient } = this.props.propsClient;
    let sRut = oClient.company && oClient.company.rut ? oClient.company.rut : "";
    let sSocialReason = oClient.company && oClient.company.name ? oClient.company.name : "";
    let sDomicile = oClient.company && oClient.company.domicile ? oClient.company.domicile : "";

    console.log(id)
     
    if (id) {
      if (isBusiness) {
        let sReturn = this._validBusiness(sRut, sSocialReason, sDomicile);
        if (sReturn === "") {
          sReturn = this._validServices(aServices);
          if (sReturn === "") {
            let oAdditionalService = { client_id: client_id, id: id, isBusiness: isBusiness, bInvoiceNow: bInvoiceNow, rut: sRut, socialreason: sSocialReason, domicile: sDomicile, aServices: aServices, subtotal: subtotal, voucher_type_id: parseInt(voucher_type_id), nreceiptdate: Helpers.FormatDateToMysql(nreceiptdate) };
            if (bInvoiceNow) {
              this.loadConfirmInvoiceModal(oAdditionalService);
              this.setState({ isModalAdditionalServiceOpen: false });
            }
            else {
              await this.props.updateReceipt(oAdditionalService);
              this.setState({ isModalAdditionalServiceOpen: false });
              this._flushModal();
            }
          }
          else
            this.setState({ sError: sReturn, isModalMessageError: true });
        }
        else
          this.setState({ sError: sReturn, isModalMessageError: true });
      }
      else {
        let sReturn = this._validClient(customername, customeridentificationcard);
        if (sReturn === "") {
          sReturn = this._validServices(aServices);
          if (sReturn === "") {
            let oAdditionalService = { client_id: client_id, id: id, isBusiness: isBusiness, bInvoiceNow: bInvoiceNow, customername: customername, customeridentificationcard: customeridentificationcard, aServices: aServices, subtotal: subtotal, voucher_type_id: parseInt(voucher_type_id), nreceiptdate: Helpers.FormatDateToMysql(nreceiptdate) };
            if (bInvoiceNow) {
              this.loadConfirmInvoiceModal(oAdditionalService);
              this.setState({ isModalAdditionalServiceOpen: false });
            }
            else {
              await this.props.updateReceipt(oAdditionalService);
              this.setState({ isModalAdditionalServiceOpen: false });
              this._flushModal();
            }
          }
          else
            this.setState({ sError: sReturn, isModalMessageError: true });
        }
        else
          this.setState({ sError: sReturn, isModalMessageError: true });
      }
    }
    else {
      if (isBusiness) {
        let sReturn = this._validBusiness(sRut, sSocialReason, sDomicile, department);
        if (sReturn === "") {
          sReturn = this._validServices(aServices);
          if (sReturn === "") {
            let oAdditionalService = { client_id: client_id, id: id, isBusiness: isBusiness, bInvoiceNow: bInvoiceNow, rut: sRut, socialreason: sSocialReason, domicile: sDomicile, corner1: corner1, department: department.id, city: city.id, zipcode: zipcode, aServices: aServices, subtotal: subtotal, voucher_type_id: parseInt(voucher_type_id), nreceiptdate: Helpers.FormatDateToMysql(nreceiptdate) };
            if (bInvoiceNow) {
              this.loadConfirmInvoiceModal(oAdditionalService);
              this.setState({ isModalAdditionalServiceOpen: false });
            }
            else {
              await this.props.saveReceipt(oAdditionalService);
              this.setState({ isModalAdditionalServiceOpen: false });
              this._flushModal();
            }
          }
          else
            this.setState({ sError: sReturn, isModalMessageError: true });
        }
        else
          this.setState({ sError: sReturn, isModalMessageError: true });
      }
      else {
        let sReturn = this._validClient(customername, customeridentificationcard);
        if (sReturn === "") {
          sReturn = this._validServices(aServices);
          if (sReturn === "") {
            let oAdditionalService = { client_id: client_id, id: id, isBusiness: isBusiness, bInvoiceNow: bInvoiceNow, customername: customername, customeridentificationcard: customeridentificationcard, aServices: aServices, subtotal: subtotal, voucher_type_id: parseInt(voucher_type_id), nreceiptdate: Helpers.FormatDateToMysql(nreceiptdate) };
            if (bInvoiceNow) {
              this.loadConfirmInvoiceModal(oAdditionalService);
              this.setState({ isModalAdditionalServiceOpen: false });
            }
            else {
              await this.props.saveReceipt(oAdditionalService);
              this.setState({ isModalAdditionalServiceOpen: false });
              this._flushModal();
            }
          }
          else
            this.setState({ sError: sReturn, isModalMessageError: true });
        }
        else
          this.setState({ sError: sReturn, isModalMessageError: true });
      }
    }
  };

  _handleExportExcel = (nClientId) => {
    window.open(CONSTANTS.API_URL + "reportServices?client=" + nClientId + "_blank");
  }

  _delete = (oAdditionalService) => {
    this.setState({ isModalDeleteAdditionalService: true, oDeleteAdditionalService: oAdditionalService });
  }

  _applyDeleteAdditionalService = () => {
    const { oDeleteAdditionalService } = this.state;
    this.props.deleteAdditionalService(oDeleteAdditionalService);
    this.setState({ isModalDeleteAdditionalService: false, oDeleteAdditionalService: null });
  }

  _handleCloseDeleteAdditionalService = () => {
    this.setState({ isModalDeleteAdditionalService: false, oDeleteAdditionalService: null });
  };

  renderDeleteDeviceShipped = () => {
    const { isModalDeleteAdditionalService } = this.state;
    return (
      <Modal
        isOpen={isModalDeleteAdditionalService}
        handleActionModal={this._applyDeleteAdditionalService}
        handleCloseModal={this._handleCloseDeleteAdditionalService}
        modalTitle="Confirmar"
        modalActionCaption="Eliminar"
        modalActionFaIcon={faTrash}
        customStyles={{
          width: "550px",
        }}
      >
        <Grid.Row className="system-body">
          <Grid.Col width={12}>¿Desea eliminar el servicio adicional?</Grid.Col>
        </Grid.Row>
      </Modal>
    );
  };

  render() {
    const { aReceipts } = this.props.oReceipts;
    const { oClient } = this.props.propsClient; 
    return (
      oClient && (
        <Card>
          <Card.Header>
            <Card.Title className="mr-auto">
              <span className="alert alert-success">{oClient.fullname} | #{oClient.code}</span>{" "}
              Listado de servicios adicionales
            </Card.Title>
            <Button className="btn btn-primary ml-3" onClick={() => this._handleOpenAdditionalServiceModal()}>
              <Icon name="plus" className="mr-2" />
              Nuevo
            </Button>
            <Button className="btn btn-primary ml-3" onClick={() => this.goToEdit(oClient.id, "/billing/edit")}>
              <Icon name="eye" className="mr-2" />
              Detalles de pagos
            </Button>
            <Button color="primary" className="ml-2" onClick={() => this._handleExportExcel(oClient.id)}>
              <FontAwesomeIcon icon={faFileExcel} className="mr-2"></FontAwesomeIcon>
              Exportar a excel
            </Button>
            <Link className="btn btn-secondary ml-3" to={"/billing"}>
              <Icon name="corner-up-right" className="mr-2" />
              Volver
            </Link>
           
          </Card.Header>
          {aReceipts.length > 0 ? (
            <div className="table-responsive">
              <Table className="card-table table-vcenter table-sm table-hover text-nowrap">
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Fecha</Table.ColHeader>
                    <Table.ColHeader className="text-center">Concepto</Table.ColHeader>
                    <Table.ColHeader className="text-center">Total</Table.ColHeader>
                    <Table.ColHeader className="text-center"></Table.ColHeader>
                    <Table.ColHeader className="text-center"></Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{this.renderTable(aReceipts)}</Table.Body>
              </Table>
            </div>
          ) : (
            <Card.Body>
              <Grid.Row>
                <Grid.Col>
                  <Alert type="info" icon="info">
                    No hemos obtenido datos de servicios adicionales para el cliente
                  </Alert>
                </Grid.Col>
              </Grid.Row>
            </Card.Body>
          )}
          {this.renderAdditionalServiceModal()}
          {this.renderConfirmInvoiceModal()}
          {this.renderMessageErrorModal()}
          {this.renderDeleteDeviceShipped()}
        </Card>
      )
    );
  }

  renderTable = (aAdditionalServices) => {
    
    return aAdditionalServices.map((oAdditionalService) => (
      
      <Table.Row key={"receipt" + oAdditionalService.id}>
        <Table.Col>{oAdditionalService.receiptdate.split("-").reverse().join("/")}</Table.Col>
        <Table.Col className="text-center">{this.conceptOfServices(oAdditionalService.services)}</Table.Col>
        <Table.Col className="text-center">{this.amountOfService(oAdditionalService.services)}</Table.Col>
        <Table.Col className="text-text-center d-flex px-2">
          {parseInt(oAdditionalService.in_debt) === 0 ? (
            <> 
              <TableAction iconName="edit" tooltip="Editar" onClick={() => this._handleEditAdditionalService(oAdditionalService)} />
              <TableAction iconName="trash" tooltip="Eliminar" className='m-auto' onClick={() => this._delete(oAdditionalService)} />
            </>
          ) : (
            ""
          )}
        </Table.Col>
         
        <Table.Col className="text-left">
          {parseInt(oAdditionalService.in_debt) === 0 ? (
            <Button RootComponent="button" color="success" onClick={() => this.loadConfirmInvoiceModal(oAdditionalService, "bill")}>Facturar</Button>
          ) : (
            <TableAction iconName="check" tooltip="Facturado" />
          )}
          
        </Table.Col>
      </Table.Row>
    ));
  };

  renderAdditionalServiceModal = () => {
    const {
      isBusiness,
      voucher_type_id,
      nreceiptdate,
      service_description,
      service_price,
      domicile,
      corner1,
      zipcode,
      city,
      department,
      aCities,
      aStates,
      aServices,
      subtotal,
      bInvoiceNow } = this.state;
    const { oClient } = this.props.propsClient;
    const { aVoucherTypes } = this.props.propsTypeCmps;

    return (
      <Modal
        isOpen={this.state.isModalAdditionalServiceOpen}
        handleActionModal={this._handleSaveAdditionalService}
        handleCloseModal={this._handleCloseModal}
        modalTitle="Crear servicio adicional"
        customStyles={{
          width: "750px",
        }}
      >
        <Grid.Row className="system-body">

          <Grid.Col width={12}>
            <Form.Group>
              <label className="custom-switch">
                <input
                  key="isBusinessAditionalServices"
                  id="isBusiness"
                  type="checkbox"
                  name="isBusiness"
                  className="custom-switch-input"
                  defaultChecked={isBusiness}
                  value={isBusiness}
                  onChange={this._handleCheckedChange}
                />
                <span className="custom-switch-indicator"></span>
                <span className="custom-switch-description font-weight-bold">
                  Empresa
                </span>
              </label>
            </Form.Group>
          </Grid.Col>

          {isBusiness ? (
            <>
              <Grid.Col width={6}>
                <Form.Group label="RUT">
                  <Form.Input id="rut" name="rut" placeholder="RUT" value={oClient.company && oClient.company.rut ? oClient.company.rut : ""} onChange={this._handleChangeValue} disabled />
                </Form.Group>
              </Grid.Col>

              <Grid.Col width={6}>
                <Form.Group label="Razón social">
                  <Form.Input id="socialreason" name="socialreason" placeholder="Razón social" value={oClient.company && oClient.company.name ? oClient.company.name : ""} onChange={this._handleChangeValue} disabled />
                </Form.Group>
              </Grid.Col>
              <Grid.Col width={12}>
                <Form.Group label="Domicilio">
                  <Form.InputGroup>
                    <Form.Input
                      id="domicile"
                      name="domicile"
                      placeholder="Domicilio"
                      value={oClient.company && oClient.company.rut ? oClient.company.domicile : domicile }
                      onChange={this._handleChangeValue}
                      className="field-address"
                    />
                    {/* <Form.Input
                      id="corner1"
                      name="corner1"
                      placeholder="Esquina"
                      value={corner1}
                      onChange={this._handleChangeValue}
                      className="field-address"
                    />
                    <Form.Select
                      id="city"
                      name="city"
                      className="col-3"
                      value={city ? city.id : ""}
                      onChange={this._handleChangeCity}
                    >
                      {aCities.map((oCity) => (
                        <option
                          key={"city" + oCity.id}
                          value={oCity.id}
                        >
                          {oCity.name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Select
                      name="department"
                      className="col-3"
                      value={department ? department.id : ""}
                      onChange={this._handleChangeState}
                    >
                      {aStates.map((oState) => (
                        <option
                          key={"department" + oState.id}
                          value={oState.id}
                        >
                          {oState.name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Input
                      id="zipcode"
                      name="zipcode"
                      placeholder="Código postal"
                      value={city ? zipcode : ""}
                      className="field-zipcode"
                      onChange={this._handleChangeValue}
                    /> */}
                  </Form.InputGroup>
                </Form.Group>
              </Grid.Col>
            </>
          ) : (
            <>
              <Grid.Col width={6}>
                <Form.Group label="Nombre y apellidos">
                  <Form.Input
                    id="customername"
                    name="customername"
                    placeholder="Nombre y apellidos"
                    value={oClient.fullname ? oClient.fullname : " "}
                    onChange={this._handleChangeValue}
                    disabled
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col width={3}>
                <Form.Group label="Cédula">
                  <Form.Input
                    id="customeridentificationcard"
                    name="customeridentificationcard"
                    placeholder="Cédula"
                    value={oClient.identificationcard ? oClient.identificationcard : " "}
                    onChange={this._handleChangeValue}
                    disabled
                  />
                </Form.Group>
              </Grid.Col>
            </>
          )}
          <Grid.Col width={6}>
            <Form.Group label="Tipo de recibo">
              <Form.Select
                id="voucher_type_id"
                name="voucher_type_id"
                value={voucher_type_id}
                onChange={this._handleChangeValue}
              >
                {aVoucherTypes.map((type, index) => (
                  <option key={"voucher_type_index" + index} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={6}>
            <Form.Group label="Fecha">
              <DatePicker className="datepicker" locale={es} selected={nreceiptdate} dateFormat={"dd/MM/yyyy"} onChange={this._handleServiceDateChange} showYearDropdown />
            </Form.Group>
          </Grid.Col>

          <Grid.Col width={12}>
            <Form.Group label="Servicio">
              <Form.InputGroup>
                <Form.Input
                  id="service_description"
                  name="service_description"
                  placeholder="Descripción del servicio"
                  value={service_description}
                  onChange={this._handleChangeValue}
                  className="field-address"
                />
                <Form.Input
                  id="service_price"
                  name="service_price"
                  placeholder="Precio"
                  value={service_price}
                  onChange={this._handleChangeValue}
                  className="field-zipcode"
                  type="number"
                  min="0"
                />
                <Form.InputGroupAppend>
                  <Button RootComponent="button" color="primary" onClick={this._handleAddService}                  >
                    <Icon name="plus" />
                  </Button>
                </Form.InputGroupAppend>
              </Form.InputGroup>
            </Form.Group>

            {aServices &&
              aServices.length > 0 ? aServices.map((elem, index) => (
                <Form.Group key={"service" + index} className="mb-1" width={8}                >
                  <Form.InputGroup>
                    <Form.InputGroupPrepend>
                      <Form.InputGroupText>
                        <FontAwesomeIcon icon={faThList}></FontAwesomeIcon>
                      </Form.InputGroupText>
                    </Form.InputGroupPrepend>
                    {
                      <Form.Input disabled value={elem.name + ":  " + elem.price + "  "} />
                    }
                    <Form.InputGroupAppend>
                      <Button RootComponent="button" color="danger" onClick={() => this._handleRemoveService(index)}                      >
                        <Icon name="trash" />
                      </Button>
                    </Form.InputGroupAppend>
                  </Form.InputGroup>
                </Form.Group>
              ))
              : ""}
          </Grid.Col>

          <Grid.Col width={6}></Grid.Col>

          <Grid.Col width={6}>
            <Form.Group label="Total">
              <Form.Input
                id="subtotal"
                name="subtotal"
                placeholder="00.00"
                readOnly
                value={subtotal}
                onChange={this._handleChangeValue}
                disabled
              />
            </Form.Group>
          </Grid.Col>

          <Grid.Col width={6}></Grid.Col>

          <Grid.Col width={6}>
            <Form.Group label="">
              <label className="custom-switch">
                <input
                  id="bInvoiceNow"
                  type="checkbox"
                  name="bInvoiceNow"
                  className="custom-switch-input"
                  defaultChecked={bInvoiceNow}
                  value={bInvoiceNow}
                  onChange={this._handleCheckedChange}
                />
                <span className="custom-switch-indicator"></span>
                <span className="custom-switch-description font-weight-bold">
                  Facturar
                </span>
              </label>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      </Modal >
    );
  };

  renderConfirmInvoiceModal = () => {
    return (
      <Modal
        isOpen={this.state.isModalConfirmInvoice}
        handleActionModal={this.applyGenerateInvoice}
        handleCloseModal={this._handleCloseConfirmInvoiceModal}
        modalTitle="Confirmar factura"
        customStyles={{
          width: "550px",
        }}
      >
        <Grid.Row className="system-body">
          <Grid.Col width={12}>¿Desea facturar el servicio?</Grid.Col>
        </Grid.Row>
      </Modal>
    );
  };

  renderMessageErrorModal = () => {
    const { sError } = this.state;
    return (
      <Modal
        isOpen={this.state.isModalMessageError}
        modalHideAction={true}
        handleCloseModal={this._handleCloseMessageErrorModal}
        modalTitle="Error"
        customStyles={{
          width: "480px",
        }}
      >
        <Grid.Row className="system-body">
          <Grid.Col width={12}>{sError}</Grid.Col>
        </Grid.Row>
      </Modal>
    );
  };

}

const mapStateToProps = (state) => ({
  propsClient: state.clientsReducer,
  propsStates: state.statesReducer,
  propsCities: state.citiesReducer,
  propsTypeCmps: state.voucherTypesReducer,
  oReceipts: state.receiptsReducer,
});



const mapDispatchToProps = (dispatch) => ({
  getClientsSinglePagination: (nIdClient, nItemIndex) => {
    dispatch(actionGetClientSinglePagination(nIdClient, nItemIndex));
  },
  getAdditionalServices: (nClientId) => {
    dispatch(actionGetReceipts(nClientId));
  },
  getTypeCmps: () => {
    dispatch(actionGetVoucherTypes());
  },
  getStates: () => {
    dispatch(actionGetStates());
  },
  getCities: (nStateId) => {
    dispatch(actionGetCities(nStateId));
  },
  saveReceipt: (oReceipt) => {
    dispatch(actionSaveReceipt(oReceipt));
  },
  updateReceipt: (oReceipt) => {
    dispatch(actionUpdateReceipt(oReceipt));
  },
  processPaymentService: (oPayment, oReceipt) => {
    dispatch(actionProcessPaymentService(oPayment, oReceipt));
  },
  cleanReceipts: () => {
    dispatch(actionResetReceipts());
  },
  deleteAdditionalService: (oAdditionalService) => {
    dispatch(actionDeleteAdditionalService(oAdditionalService));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalServicesList);