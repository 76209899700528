import 'styles.scss';
import Layout from 'pages/Layout';
import 'tabler-react/dist/Tabler.css';
import { Provider } from 'react-redux';
import store, {persistor} from 'redux/Store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import "react-datepicker/dist/react-datepicker.css";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastContainer />
        <Layout />
      </PersistGate>
    </Provider>
  );
}

export default App;
