import { CONSTANTS_ACTIONS } from "constants/ConstantsActions";
import { CONSTANTS_MESSAGE } from "constants/ConstantsMessages";
import {
  actionActiveLoading,
  actionDesactiveLoading,
  actionDispatch,
  actionMessage,
  actionSetResponseCode,
} from "redux/actions/SharedActions";
import { GetClientsPerDate, GetDebtorsClients, GetDevicesShipped } from "redux/services/ClientServices";
import { BirthdateReport, CallsReport, UnsubscriptionReport, UnsubscriptionRequestReport, WelcomeReport } from "redux/services/ReportServices";

export const actionGetBatteryChanges = (
  nPage = 1,
  nClientsPerPage = 10,
  sSearch = "",
  sFilterDate = "changebatterybracelet",
  dStartDate = null,
  dEndDate = null
) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    GetClientsPerDate(
      nPage,
      nClientsPerPage,
      sSearch,
      sFilterDate,
      dStartDate,
      dEndDate
    )
      .then((oSuccess) => {
        dispatch(
          actionDispatch(CONSTANTS_ACTIONS.BATTERY_CHANGES, oSuccess.data)
        );
      })
      .catch((oError) => {
        if (oError.response) {
          dispatch(actionSetResponseCode(oError.response.status));
        }
        dispatch(actionDispatch(CONSTANTS_ACTIONS.BATTERY_CHANGES, []));
        dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
      })
      .finally(() => {
        dispatch(actionDesactiveLoading());
      });
  };
};


export const actionGetUnsubscription = (nPage = 1, nClientsPerPage = 10, sSearch = null) => {
  return (dispatch) => {
    
    dispatch(actionActiveLoading());
    UnsubscriptionReport(nPage, nClientsPerPage, sSearch).then(oSuccess => {
      dispatch(actionDispatch(CONSTANTS_ACTIONS.DEATHS, oSuccess));
    }).catch(() => {
      dispatch(actionDispatch(CONSTANTS_ACTIONS.DEATHS, { data: [], allRecords: 0 }));
      dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
    }).finally(() => dispatch(actionDesactiveLoading()));
  }
  
}

export const actionGetUnsubscriptionRequest = (nPage = 1, nClientsPerPage = 10, sSearch = null) => {
   
  return (dispatch) => {
    dispatch(actionActiveLoading());
    UnsubscriptionRequestReport(nPage, nClientsPerPage, sSearch).then(oSuccess => {
      dispatch(actionDispatch(CONSTANTS_ACTIONS.UNSUBSCRIBE_REQUEST, oSuccess));
    }).catch(() => {
      dispatch(actionDispatch(CONSTANTS_ACTIONS.UNSUBSCRIBE_REQUEST, { data: [], allRecords: 0 }));
      dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
    }).finally(() => dispatch(actionDesactiveLoading()));
  }
}

export const actionGetDebtors = (
  nPage = 1,
  nClientsPerPage = 10,
  sSearch = ""
) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    GetDebtorsClients(nPage, nClientsPerPage, sSearch)
      .then((oSuccess) => {
        dispatch(actionDispatch(CONSTANTS_ACTIONS.DEBTORS, oSuccess.data));
      })
      .catch((oError) => {
        if (oError.response) {
          dispatch(actionSetResponseCode(oError.response.status));
        }
        dispatch(actionDispatch(CONSTANTS_ACTIONS.DEBTORS, []));
        dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
      })
      .finally(() => {
        dispatch(actionDesactiveLoading());
      });
  };
};

export const actionGetWelcomeReport = (nPage, nYear, nMonth, nClientsPerPage) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    WelcomeReport(nPage, nYear, nMonth, nClientsPerPage).then(oSuccess => {
      dispatch(actionDispatch(CONSTANTS_ACTIONS.NEW_REVENUE, oSuccess));
    }).catch(() => {
      dispatch(actionDispatch(CONSTANTS_ACTIONS.NEW_REVENUE, { data: [], allRecords: 0 }));
      dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
    }).finally(() => {
      dispatch(actionDesactiveLoading());
    });
  }
}

export const actionGetTimeWithoutCalling = (nPage = 1, nClientsPerPage = 10, nType = 1, nFilterCount = 45) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    CallsReport(nPage, nClientsPerPage, nType, nFilterCount).then((oSuccess) => {
      dispatch(actionDispatch(CONSTANTS_ACTIONS.TIME_WITHOUT_CALLING, oSuccess));
    }).catch((oError) => {
      dispatch(actionDispatch(CONSTANTS_ACTIONS.TIME_WITHOUT_CALLING, { allRecords: 0, data: [] }));
      dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
    }).finally(() => dispatch(actionDesactiveLoading()));
  }
}

export const actionGetDevicesShipped = (
  nPage = 1,
  nClientsPerPage = 10,
  sSearch = ""
) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    GetDevicesShipped(nPage, nClientsPerPage, sSearch)
      .then((oSuccess) => {
        dispatch(actionDispatch(CONSTANTS_ACTIONS.DEVICES_SHIPPED, oSuccess.data));
      })
      .catch((oError) => {
        if (oError.response) {
          dispatch(actionSetResponseCode(oError.response.status));
        }
        dispatch(actionDispatch(CONSTANTS_ACTIONS.DEVICES_SHIPPED, []));
        dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
      })
      .finally(() => {
        dispatch(actionDesactiveLoading());
      });
  };
};

export const actionCleanReports = () => {
  return (dispatch) => {
    dispatch(actionDispatch(CONSTANTS_ACTIONS.CLEAN_REPORTS));
  };
};

export const actionGetBirthdateReport = (nPage, nClientsPerPage, nMonth) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    BirthdateReport(nPage, nClientsPerPage, nMonth).then(oSuccess => {
      dispatch(actionDispatch(CONSTANTS_ACTIONS.BIRTHDAY, oSuccess));
    }).catch(() => {
      dispatch(actionDispatch(CONSTANTS_ACTIONS.BIRTHDAY, { data: [], allRecords: 0 }));
      dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
    }).finally(() => {
      dispatch(actionDesactiveLoading());
    });
  }
}
