import React, { Component } from "react";
import { Card, Form, Table, Alert, Grid, Button } from "tabler-react";
import { connect } from "react-redux";
import { actionGetClient } from "redux/actions/ClientsActions";
import { actionCleanReports, actionGetWelcomeReport } from "redux/actions/ReportsActions";
import { Pagination } from "components/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTag ,faFileExcel, faQrcode } from "@fortawesome/free-solid-svg-icons";
import { DATEPICKER } from "constants/ConstantsComponents";
import { CONSTANTS } from "constants/Constants";
import TableColAddress from "components/table/TableColAddress";
import { Helpers } from "helpers/Helpers";
import { TableAction } from "components/buttons/TableAction";
//import { faUserTag } from "@fortawesome/free-solid-svg-icons";

class ReportNewRevenue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nPage: 1,
      nClientsPerPage: 10,
      nMonth: parseInt(Helpers.ConvertTZ(new Date()).getMonth()) + 1,
      nYear: Helpers.ConvertTZ(new Date()).getFullYear(),
    };
  }

  componentDidMount = () => {
    this.getYears();
    this.get();
  }

  componentWillUnmount = () => {
    this.props.cleanReports();
  }

  get = () => {
    const { nPage, nYear, nMonth, nClientsPerPage } = this.state;
    let nPages = ((nPage - 1) * nClientsPerPage);
    this.props.get(nPages, nYear, nMonth, nClientsPerPage);
  }

  getYears = () => {
    let date = Helpers.ConvertTZ(new Date()), actualYear = date.getFullYear(), actualMonth = date.getMonth() + 1, yearsList = [];
    const yearRange = actualYear - 2001;
    if (this.state.nMonth == null)
      this.setState({ nYear: actualYear, nMonth: actualMonth });
    for (let i = actualYear; i > actualYear - yearRange; i--)
      yearsList.push(i);
    return yearsList;
  }

  handleChangeValue = async (oEvent) => {
    const { value: nValue, name: sName } = oEvent.target;
    await this.setState({ nPage: 1, [sName]: nValue });
    this.get();
  }

  _handleexportExcel = () => {
    const { nYear, nMonth } = this.state;
    window.open(`${CONSTANTS.API_URL}reports/excel/welcome?month=${nMonth}&year=${nYear}`, "_blank");
  }

  _handlechangeCount = async (event) => {
    let { value } = event.target;
    await this.setState({ nClientsPerPage: value, nPage: 1 });
    this.get();
  }

  _handleNextPage = async () => {
    let { nPage } = this.state;
    await this.setState({ nPage: nPage + 1 });
    this.get();
  }

  _handlePreviousPage = async () => {
    let { nPage: nCurrentPage } = this.state,
      nPage = nCurrentPage - 1;
    if (nPage < 0)
      nPage = 0;
    await this.setState({ nPage: nPage });
    this.get();
  }

  _handleGoToPage = async (nPage) => {
    await this.setState({ nPage: nPage });
    this.get();
  }

  goToView = (event, nClientId, sRoute) => {
    event.stopPropagation();
    this.props.getClient(nClientId);
    this.props.history.push(sRoute);
  }

  downloadLabel = async (nClientId) => {
    window.open(`${CONSTANTS.API_URL}clients/downloadLabel/${nClientId}`, '_blank');
  }
  downloadAllLabels = async () => {
    const { nYear, nMonth } = this.state;
    window.open(`${CONSTANTS.API_URL}reports/allLabels?type=newRevenue&month=${nMonth}&year=${nYear}`, '_blank');
  }
  downloadAllQRs = async () => {
    const { nYear, nMonth } = this.state;
    window.open(`${CONSTANTS.API_URL}qrinterface?month=${nMonth}&year=${nYear}`, '_blank');
  }

  render = () => {
    const { aNewRevenue } = this.props.oNewRevenue;
    const { nPage, nClientsPerPage, nYear, nMonth } = this.state;
    return (
      <Card>
        <Card.Header>
          <Card.Title className="mr-auto">
            Bienvenida / Nuevos ingresos
          </Card.Title>
          <div className="card-filters mr-2">
            <Form.Select name="nMonth" onChange={this.handleChangeValue} value={nMonth} >
              {
                DATEPICKER.MONTH.map((oMonth, nIndex) =>
                  <option key={`${nIndex}_${oMonth}`} value={(nIndex + 1)}>{oMonth}</option>
                )
              }
            </Form.Select>
          </div>
          <div className="card-filters">
            <Form.Select name="nYear" onChange={this.handleChangeValue} value={nYear}>
              {
                this.getYears().map((oMonth, index) =>
                  <option key={`${index}_${oMonth}`} value={oMonth}>{oMonth}</option>
                )
              }
            </Form.Select>
          </div>
          <Button
            color="primary"
            className="ml-2"
            onClick={this.downloadAllLabels}
          >
            <FontAwesomeIcon
              icon={faUserTag}
              className="mr-2"
            ></FontAwesomeIcon>
            Etiquetas
          </Button>
          <Button
            color="primary"
            className="ml-2"
            onClick={this.downloadAllQRs}
          >
            <FontAwesomeIcon
              icon={faQrcode}
              className="mr-2"
            ></FontAwesomeIcon>
            Generar QR's
          </Button>
          <Button
            color="primary"
            className="ml-2"
            onClick={this._handleexportExcel}
          >
            <FontAwesomeIcon
              icon={faFileExcel}
              className="mr-2"
            ></FontAwesomeIcon>
            Exportar a excel
          </Button>
        </Card.Header>
        {
          aNewRevenue.data.length > 0 ?
            <>
              <div className="table-responsive">
                <Table className="card-table table-vcenter table-sm table-hover text-nowrap">
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>Apellido y Nombre</Table.ColHeader>
                      <Table.ColHeader>Número de socio</Table.ColHeader>
                      <Table.ColHeader>Teléfono</Table.ColHeader>
                      <Table.ColHeader>Domicilio</Table.ColHeader>
                      <Table.ColHeader>Socio Desde</Table.ColHeader>
                      <Table.ColHeader>Edad</Table.ColHeader>
                      <Table.ColHeader alignContent="center">Socio Mides</Table.ColHeader>
                      <Table.ColHeader alignContent="center"></Table.ColHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>{this.renderTable()}</Table.Body>
                </Table>
              </div>
              <Card.Footer>
                <Pagination
                  nextPageAction={this._handleNextPage}
                  previousPageAction={this._handlePreviousPage}
                  goToPageAction={this._handleGoToPage}
                  currentPage={nPage}
                  allRecords={aNewRevenue.allRecords}
                  showing={Array.isArray(aNewRevenue.data) ? aNewRevenue.data.length : 0}
                  nLastPage={Math.ceil(parseInt(aNewRevenue.allRecords) / parseInt(nClientsPerPage))}
                  showAllOptions={true}
                  _handlechangeCount={this._handlechangeCount}
                />
              </Card.Footer>
            </>
            :
            <Card.Body>
              <Grid.Row>
                <Grid.Col>
                  <Alert type="info" icon="info">
                    No hemos obtenido datos de clientes
                  </Alert>
                </Grid.Col>
              </Grid.Row>
            </Card.Body>
        }
      </Card>
    )
  }

  renderTable = () => {
    return this.props.oNewRevenue.aNewRevenue.data.map((oClient, index) =>
      <Table.Row key={"client" + oClient.id}>
        <Table.Col className="cursor-pointer">
          <span onClick={(event) => this.goToView(event, oClient.id, "/clients/edit/" + oClient.id)}>
            {
              ((!oClient.name || !oClient.name.trim()) && (!oClient.lastname || !oClient.lastname.trim()))
                ? <i className='text-muted'>Cliente sin nombre</i>
                : `${oClient.lastname ? oClient.lastname : ""} ${oClient.name ? oClient.name : ""}`
            }
          </span>
        </Table.Col>
        <Table.Col>{oClient.code}</Table.Col>
        <Table.Col>
          {
            oClient.principal_phone ? oClient.principal_phone.phonenumber : 'S/D'
          }
        </Table.Col>
        <TableColAddress address={oClient.principal_address ? oClient.principal_address.domicile : 'S/D'} />
        <Table.Col>{Helpers.CastDate(oClient.affiliationdate)}</Table.Col>
        <Table.Col>{oClient.age}</Table.Col>
        <Table.Col className="text-center isActive-indicator">
          <Form.Checkbox disabled className="d-inline" checked={oClient.is_mides} onChange={() => { }} label={" "} disabledisInline={false} />
        </Table.Col>
        <Table.Col className="text-center">
          <TableAction
            bFontAwesome={true}
            iconName={faUserTag}
            tooltip="Descargar etiqueta"
            onClick={() => this.downloadLabel(oClient.id)} />
        </Table.Col>        
      </Table.Row>
    )
  }
}

const mapStateToProps = (state) => ({
  oNewRevenue: state.reportsReducers,
});

const mapDispatchToProps = (dispatch) => ({
  getClient: (idClient) => {
    dispatch(actionGetClient(idClient));
  },
  get: (nPage = 0, nYear, nMonth, nClientsPerPage = 10) => {
    dispatch(actionGetWelcomeReport(nPage, nYear, nMonth, nClientsPerPage));
  },
  cleanReports: () => {
    dispatch(actionCleanReports());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportNewRevenue);
