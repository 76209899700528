import React from 'react';
import { Button, Icon } from "tabler-react";

export function SharedButton({ fAction, sLabel, sStyles = 'btn btn-primary ml-3', sIcon = null }) {
  return (
    <Button className={sStyles} onClick={fAction}>
      {sIcon != null && <Icon name={sIcon} className="mr-2" />}
      {sLabel}
    </Button>
  );
}