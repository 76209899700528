import React, { Component } from "react";
import ReactModal from "react-modal";
import { Card, Icon, Button } from "tabler-react";

export default class IncomingCall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Llamada entrante",
      actionCaption: "Atender",
      cancelCaption: "Ignorar",
      customStyles: props.customStyles,
    };
  }

  showModal = () => {
    const { calls } = this.props;
    calls.filter((oCall) => !oCall.notified);
    return calls.length > 0;
  }

  render() {
    const {
      customStyles,
    } = this.state;
    const {
      handleCloseModal,
      handleAnswerCall,
      calls
    } = this.props;
    return (
      <ReactModal isOpen={this.showModal()} contentLabel="onRequestClose"
        className="system-modal incomingcall-modal" overlayClassName="system-overlay incomingcall-overlay" ariaHideApp={false}>
        <div className="row m-0" style={{ width: "500px" }}>
          {calls.map((oCall, nIndex) => (
            <div key={`incoming_call_${nIndex}`} className="card system-modal-card col-12 my-1" >
              <Card.Header className='display-block'>
                <div className='row'>
                  <div className='col-4'>
                    <Button className='btn-danger-helpline' onClick={() => handleCloseModal(nIndex)}>
                      <Icon name="phone-off" className="mr-2" />
                      Ignorar
                    </Button>
                  </div>
                  <div className='col-4 p-0 text-center m-auto'>
                    {oCall.no_attend > 0 ? <h3 className="card-title" style={{ color: '#bf0918' }}>Llamada Entrante</h3> : <h3 className="card-title" style={{ color: '#0b7a75' }}>Llamada Entrante</h3>}
                  </div>
                  <div className='col-4 text-right'>
                    <Button className={"btn btn-success-helpline text-white"} onClick={() => handleAnswerCall(oCall, nIndex)}>
                      <Icon name="phone-incoming" className="mr-2" />
                      Atender
                    </Button>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className='text-center'>
              {oCall.no_attend > 0 ? <span style={{ color: '#bf0918' }}>{`${oCall.name} - ${oCall.number}`}</span> : <span style={{ color: '#0b7a75' }}>{`${oCall.name} - ${oCall.number}`}</span>}
               {/*  {`${oCall.name} - ${oCall.number}`} */}
              </Card.Body>
            </div>
          ))}
        </div>
      </ReactModal>
    );
  }
}
