import { CONSTANTS } from 'constants/Constants';
import oAxiosInstance from 'redux/interceptors/Interceptors';

const SHIPMENTS_URL = CONSTANTS.API_URL + 'shipments';

/**
 * Funcion para tomar todos los prestamos de un usuario
 * 
 * 
 * @author Franko Zerpa
 */
export const GetShipments = (nClientId) => {
    return oAxiosInstance.get(SHIPMENTS_URL + '/client/' + nClientId).then(oResult => oResult.data)
}



/**
 * 
 * @param {*} oData 
 */
export const SaveShipping = (oData) => {
    return oAxiosInstance.post(SHIPMENTS_URL, oData).then(oResult => oResult.data)
}

/**
 * 
 * @param {*} oData 
 */
export const UpdateShipping = (oData) => {
    return oAxiosInstance.put(SHIPMENTS_URL + '/' + oData.id, oData).then(oResult => oResult.data)
}
/**
 * 
 * @param {*} oData 
 */
export const DevolutionShipping = (nDeviceShippedId) => {
    return oAxiosInstance.post(SHIPMENTS_URL + '/devolution', { shippment_id: nDeviceShippedId }).then(oResult => oResult.data)
}

export const DeleteDeviceShipped = (nDeviceShippedId) => {
    return oAxiosInstance.post(`${SHIPMENTS_URL}/delete/${nDeviceShippedId}`).then(oResult => oResult.data);
}
