import { CONSTANTS_ACTIONS } from "constants/ConstantsActions";

const aInitialState = {
  aFees: [],
  dToDate: null,
  dUpDate: null,
  dDownDate: null,
};
/**
 * Reducer encargado de manejar todos los datos correspondiente a la parte de clientes.
 *
 * @param {Array} aState Estado del reducer, al momento de inicializarse se tomara aInitialState
 * @param {object} oAction Objeto donde se encuentra el tipo de estado que se desea actualizar y los respectivos datos
 *
 * @author Leandro Curbelo
 */
export const feesReducer = (aState = aInitialState, oAction) => {
  switch (oAction.type) {
    case CONSTANTS_ACTIONS.FEE_GET_BY_CLIENT_AND_RANGE:
      return {
        ...aState,
        aFees: oAction.data.data,
        dToDate: oAction.data.dToDate,
        dUpDate: oAction.data.dUpDate,
        dDownDate: oAction.data.dDownDate,
      };
    default:
      return aState;
  }
};
