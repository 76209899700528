import { CONSTANTS } from 'constants/Constants';
import oAxiosInstance from 'redux/interceptors/Interceptors';

const DEVICES_URL = CONSTANTS.API_URL + 'devices';

/**
 * Funcion para tomar todos losDepartamentos de uruguay
 * 
 * 
 * @author Franko Zerpa
 */
export const GetDevices = () => {
    return oAxiosInstance.get(DEVICES_URL+'').then(oResult => oResult.data)
}
export const DeleteDevice = (nDeviceid) => {
    return oAxiosInstance.delete(DEVICES_URL+'/'+nDeviceid).then(oResult => oResult.data) 
}


