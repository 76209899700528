import { CONSTANTS } from 'constants/Constants';
import oAxiosInstance from 'redux/interceptors/Interceptors';

const RECEIPTS_URL = CONSTANTS.API_URL + 'receipts';

/**
 * Funcion para tomar todos losDepartamentos de uruguay
 * 
 * 
 * @author Franko Zerpa
 */
export const GetReceipts = (nClientId) => {
    return oAxiosInstance.get(RECEIPTS_URL + '/client/' + nClientId).then(oResult => oResult.data)
}

/**
 * 
 * @param {*} oData 
 */
export const SaveReceipt = (oData) => {
    return oAxiosInstance.post(RECEIPTS_URL, oData).then(oResult => oResult.data)
}

/**
 * 
 * @param {*} oData 
 */
export const UpdateReceipt = (oData) => {
    return oAxiosInstance.put(RECEIPTS_URL + '/' + oData.id, oData).then(oResult => oResult.data)
}
/**
 * Funcion para tomar todos losDepartamentos de uruguay
 * 
 * 
 * @author Franko Zerpa
 */
export const GetReceiptReasons = () => {
    return oAxiosInstance.get(RECEIPTS_URL + '/get/reasons').then(oResult => oResult.data)
}

export const DeleteAdditionalService = (nAdditionalServiceId) => {
    return oAxiosInstance.post(`${RECEIPTS_URL}/delete/${nAdditionalServiceId}`).then(oResult => oResult.data);
}