import React, { Component } from "react";
import { Page } from "tabler-react";
import Header from "components/layout/Header";
import Body from "components/layout/Body";
import { RouteConfig } from "router/Router";
import Loading from "components/Loading";
import Message from "components/message/Message";
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import { actionCallAnswer, actionCallListener, actionEndCallAnswer, actionGetCalls, actionSetCallsIncoming, actionSetCallsNotified } from "redux/actions/CallsActions";
import { actionMessage, actionNavigateToBoard } from "redux/actions/SharedActions";
import IncomingCall from "components/IncomingCall";
import { actionGetClient } from "redux/actions/ClientsActions";
import AnswerCall from "components/AnswerCall";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nInterval: null,
      isCallModalOpen: false,
      oCallAnswer: null
    };
  }

  componentDidMount = () => {
    this.checkCalls();
  }

  componentDidUpdate = (oLastProps) => {
    if (oLastProps.propsCall.aCallIncoming !== this.props.propsCall.aCallIncoming && !this.props.propsCall.oCallAnswer) {
      let aNotified = [];
      let aCallsNewIncoming = this.props.propsCall.aCallsNewIncoming;
      this.props.propsCall.aCallIncoming.forEach((oCall) => {
        let bIsNotified = this.props.propsCall.aCallIncomingNotified.filter((oNotified) => oNotified.id === oCall.id).length > 0;
        if (bIsNotified)
          aNotified.push(oCall);
        if (!bIsNotified) {
          oCall.notified = true;
          aCallsNewIncoming.push(oCall);
          aNotified.push(oCall);
        }
      });
      aCallsNewIncoming.forEach((oCall, nIndex) => {
        let bIsExist = this.props.propsCall.aCallIncoming.filter((oIncoming) => oIncoming.id === oCall.id).length > 0;
        if (!bIsExist)
          aCallsNewIncoming.splice(nIndex, 1);
      })
      this.props.setCallsIncoming(aCallsNewIncoming);
      this.props.setCallsNotified(aNotified);
    }
  }

  componentWillUnmount = () => {
    this.clearStoreInterval();
  }

  checkCalls = async () => {
    let nInterval = setInterval(() => {
      const oUserSession = this.props.propsUserSession.oUserSession;
      if (this.props.propsLogin.bStatus && oUserSession && oUserSession.data && !oUserSession.data.admin) {      
        this.props.callListener();
      }
    }, 5200);
    await this.setState({ nInterval: nInterval });
  }

  clearStoreInterval = () => {
    if (this.state.nInterval !== null) {
      clearInterval(this.state.nInterval);
    }
    this.setState({ nInterval: null });
  }

  closeCallModal = async (nIndex) => {
    let aCallsNewIncoming = [];
    this.props.propsCall.aCallsNewIncoming.forEach((oCall, nIndexCall) => {
      if (nIndex !== nIndexCall)
        aCallsNewIncoming.push(oCall);
    });
    this.props.setCallsIncoming(aCallsNewIncoming);
  }

  handleAnswerCall = async (oCall, nIndex) => {
    this.props.answerCall(oCall.id);
    let aCallsNewIncoming = [];
    this.props.propsCall.aCallsNewIncoming.forEach((oCall, nIndexCall) => {
      if (nIndex !== nIndexCall)
        aCallsNewIncoming.push(oCall);
    });
    this.props.setCallsIncoming(aCallsNewIncoming);
    if (oCall.client_id) {
      await this.props.getClient(oCall.client_id);
      this.props.getCalls(oCall.client_id);
      this.props.navigateToBoard();
    }
  }

  closeAnswerCallModal = () => {
    const { oCallAnswer } = this.props.propsCall;
    if (oCallAnswer)
      this.props.endCallAnswer(oCallAnswer.id);
  }

  render = () => {
    const { oCallAnswer, aCallsNewIncoming } = this.props.propsCall;
    return (
      <Page>
        <Page.Main>
          <Router>
            <Header />
            <Body>
              {
                (!oCallAnswer && aCallsNewIncoming) &&
                <IncomingCall handleCloseModal={this.closeCallModal} handleAnswerCall={this.handleAnswerCall} calls={aCallsNewIncoming} />
              }
              {
                oCallAnswer &&
                <AnswerCall isOpen={oCallAnswer} handleCloseModal={this.closeAnswerCallModal}>
                  {`${oCallAnswer.name} - ${oCallAnswer.number}`}
                </AnswerCall>
              }
              <RouteConfig />
            </Body>
          </Router>
        </Page.Main>
        <Loading />
        <Message />
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  propsLogin: state.loginReducer,
  propsCall: state.callsReducer,
  propsUserSession: state.loginReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getClient: (idClient) => {
    dispatch(actionGetClient(idClient));
  },
  callListener: () => {
    dispatch(actionCallListener());
  },
  message: (sMessage, sType) => {
    dispatch(actionMessage(sMessage, sType));
  },
  setCallsNotified: (aCallsNotified) => {
    dispatch(actionSetCallsNotified(aCallsNotified));
  },
  setCallsIncoming: (aCallsNewIncoming) => {
    dispatch(actionSetCallsIncoming(aCallsNewIncoming));
  },
  answerCall: (nCallId) => {
    dispatch(actionCallAnswer(nCallId));
  },
  endCallAnswer: (nCallId) => {
    dispatch(actionEndCallAnswer(nCallId));
  },
  navigateToBoard: () => {
    dispatch(actionNavigateToBoard());
  },
  getCalls: (nClientId) => {
    dispatch(actionGetCalls(nClientId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
