import React from "react";
import { Table, Tooltip } from "tabler-react";

function TableColAddress(props) {
  const { address } = props;
  return (
    <Table.Col>
      <Tooltip content={address && address} placement="bottom">
        <span>
          {address && address.length > 50
            ? address.substring(0, 50) + "..."
            : address}
        </span>
      </Tooltip>
    </Table.Col>
  );
}

export default TableColAddress;
