import { CONSTANTS_ACTIONS } from 'constants/ConstantsActions';

const aInitialState = {
    aMobileEmergencies: []
}
/**
 * Reducer encargado de manejar todos los datos correspondiente a la parte de emergencia movil.
 * 
 * @param {Array} aState Estado del reducer, al momento de inicializarse se tomara aInitialState
 * @param {object} oAction Objeto donde se encuentra el tipo de estado que se desea actualizar y los respectivos datos
 * 
 * @author Leandro Curbelo
 */
export const mobileEmergenciesReducer = (aState = aInitialState, oAction) => {
    switch (oAction.type) {
        case CONSTANTS_ACTIONS.MOBILE_EMERGENCIES:
            return { aMobileEmergencies: oAction.data }
        default:
            return aState;
    }
}
