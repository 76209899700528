import { CONSTANTS_ACTIONS } from 'constants/ConstantsActions';
import { CONSTANTS_MESSAGE } from 'constants/ConstantsMessages';
import { actionActiveLoading, actionDesactiveLoading, actionDispatch, actionMessage, actionSetResponseCode } from 'redux/actions/SharedActions';
import { GetNotifications, SetViewedNotifications } from 'redux/services/NotificationServices';

/**
 * Funcion que toma todos los notificaciones del sistema.
 * 
 * 
 * @author
 */
export const actionGetNotifications = () => {
    return dispatch => {
        dispatch(actionActiveLoading());
        GetNotifications().then(oSuccess => {
            dispatch(actionDispatch(CONSTANTS_ACTIONS.NOTIFICATIONS, oSuccess.data.aNotifications));
            dispatch(actionDispatch(CONSTANTS_ACTIONS.NOTIFICATIONS_COUNT, oSuccess.data.nCountNotifications));
        }).catch((oError) => {
            if (oError.response) {
                dispatch(actionSetResponseCode(oError.response.status));
            }
            dispatch(actionDispatch(CONSTANTS_ACTIONS.NOTIFICATIONS, []));
            dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
};

export const actionSetViewedNotifications = () => {
    return dispatch => {
        dispatch(actionActiveLoading());
        SetViewedNotifications().then(oSuccess => {
            dispatch(actionDispatch(CONSTANTS_ACTIONS.NOTIFICATIONS_COUNT, oSuccess.data.nCountNotifications));
        }).catch((oError) => {
            if (oError.response) {
                dispatch(actionSetResponseCode(oError.response.status));
            }
            dispatch(actionDispatch(CONSTANTS_ACTIONS.NOTIFICATIONS, []));
            dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
};

export const actionResetNotifications = () => ({
    type: CONSTANTS_ACTIONS.RESET_NOTIFICATIONS
});
