import Modal from "components/Modal";
import React, { Component } from "react";
import { Table } from "tabler-react";

export default class PaymentsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: props.isModalOpen,
      valor: 5,
    };
  }

  render = () => {
    return this.props.isModalOpen ? (
      <Modal
        isOpen={this.props.isModalOpen}
        modalHideAction={true}
        handleCloseModal={this.props.onClose}
        modalCancelCaption={"Cerrar"}
        modalTitle="Pagos"
        customStyles={{
          width: "950px",
        }}
      >
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColHeader>Fecha</Table.ColHeader>
              <Table.ColHeader>Importe</Table.ColHeader>
              <Table.ColHeader>Observación</Table.ColHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.aPayments !== [] ? (
              this.props.aPayments.map((oPayment, idx) => {
                return (
                  <Table.Row key={oPayment.id}>
                    <Table.Col>{oPayment.formated_date}</Table.Col>
                    <Table.Col>{parseInt(oPayment.amount)}</Table.Col>
                    <Table.Col>{oPayment.observation}</Table.Col>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row>
                <Table.Col span={3}>Sin registros que mostrar</Table.Col>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Modal>
    ) : null;
  };
}
