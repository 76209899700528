import { CONSTANTS } from 'constants/Constants';
import oAxiosInstance from 'redux/interceptors/Interceptors';

const STATES_URL = CONSTANTS.API_URL + 'states';

/**
 * Funcion para tomar todos losDepartamentos de uruguay
 * 
 * 
 * @author Franko Zerpa
 */
export const GetCities = (nStateId) => {
    return oAxiosInstance.get(STATES_URL+'/'+nStateId+'/cities').then(oResult => oResult.data)
}
