import { CONSTANTS } from 'constants/Constants';
import oAxiosInstance from 'redux/interceptors/Interceptors';

const REPORTS_URL = CONSTANTS.API_URL + 'reports/';

/**
 * Funcion para toma los clientes que serán mostrados en reporte de bienvenida
 *
 * @author Leandro Curbelo
 */
export const WelcomeReport = (nPage, nYear, nMonth, nClientsPerPage) => {
  return oAxiosInstance.post(`${REPORTS_URL}welcome`, { page: nPage, year: nYear, month: nMonth, rows: nClientsPerPage }).then((oResult) => oResult.data);
}
/**
 * Funcion para toma los clientes que serán mostrados en reporte de bajas de servicio
 *
 * @author Leandro Curbelo
 */
export const UnsubscriptionReport = (nPage, nClientsPerPage, sSearch) => {
  return oAxiosInstance.post(`${REPORTS_URL}unsubscription`, { page: nPage, rows: nClientsPerPage, search: sSearch })
  .then((oResult) => oResult.data);
  
}
/**
 * Funcion para toma los clientes que serán mostrados en reporte de solicitud de bajas de servicio
 *
 * @author Leandro Curbelo
 */
export const UnsubscriptionRequestReport = (nPage, nClientsPerPage, sSearch) => {

  return oAxiosInstance.post(`${REPORTS_URL}unsubscription/request`, { page: nPage, rows: nClientsPerPage, search: sSearch }).then((oResult) => oResult.data)
   
}
/**
 * Función que toma el reporte de tiempo sin llamar a los clientes
 *
 * @author Leandro Curbelo
 */
export const CallsReport = (nPage, nCallsPerPage, nType, nFilterCount) => {
  return oAxiosInstance.post(`${REPORTS_URL}calls`, { page: nPage, rows: nCallsPerPage, type: nType, count: nFilterCount }).then((oResult) => oResult.data);
}

export const BirthdateReport = (nPage, nClientsPerPage, nMonth) => {
  return oAxiosInstance.post(`${REPORTS_URL}birthdate`, { page: nPage, rows: nClientsPerPage, month: nMonth }).then((oResult) => oResult.data);
}