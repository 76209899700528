import { CONSTANTS } from 'constants/Constants';
import oAxiosInstance from 'redux/interceptors/Interceptors';

const RELATIONS_URL = CONSTANTS.API_URL + 'relations';

/**
 * Funcion para tomar todos los prestamos de un usuario
 * 
 * 
 * @author Franko Zerpa
 */
export const GetRelations = (nClientId) => {
    return oAxiosInstance.get(RELATIONS_URL+'/client/'+nClientId).then(oResult => oResult.data)
}

/**
 * 
 * @param {*} oData 
 */
export const SaveRelation = (oData) => {
    return oAxiosInstance.post(RELATIONS_URL,oData).then(oResult => oResult.data)
}

/**
 * 
 * @param {*} oData 
 */
export const UpdateRelation = (oData) => {
    return oAxiosInstance.put(RELATIONS_URL+'/'+oData.id,oData).then(oResult => oResult.data)
}
