import { CONSTANTS_ACTIONS } from 'constants/ConstantsActions';
import { CONSTANTS_MESSAGE } from 'constants/ConstantsMessages';
import { actionActiveLoading, actionDesactiveLoading, actionDispatch, actionMessage, actionSetResponseCode } from 'redux/actions/SharedActions';
import { GetInterruptions,GetInterruptionReasons, SaveInterruption, UpdateInterruption } from 'redux/services/InterruptionsServices';

/**
 * Funcion que retorna todos interrupciones de un cliente.
 * 
 * @author Franko Zerpa
 */
export const actionGetInterruptions = (nClientId) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        GetInterruptions(nClientId).then(oSuccess => {
            dispatch(actionDispatch(CONSTANTS_ACTIONS.INTERRUPTIONS, oSuccess.data));
        }).catch((oError) => {
            if (oError.response){
                dispatch(actionSetResponseCode(oError.response.status));
            }
            dispatch(actionDispatch(CONSTANTS_ACTIONS.INTERRUPTIONS, []));
            dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
};


/**
 * Funcion que retorna todos los motivos de interrupción.
 * 
 * @author Franko Zerpa
 */
export const actionGetInterruptionReasons = () => {
    return dispatch => {
        dispatch(actionActiveLoading());
        GetInterruptionReasons().then(oSuccess => {
            dispatch(actionDispatch(CONSTANTS_ACTIONS.INTERRUPTION_REASONS, oSuccess.data));
        }).catch((oError) => {
            if (oError.response){
                dispatch(actionSetResponseCode(oError.response.status));
            }
            dispatch(actionDispatch(CONSTANTS_ACTIONS.INTERRUPTION_REASONS, []));
            dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
};




/**
 * Funcion que registra un  cliente en el sistema.
 * 
 * @param {object} oData objeto cliente
 * 
 * @author 
 */

export const actionSaveInterruption = (oData) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        SaveInterruption(oData).then(oSuccess => {
            dispatch(actionMessage("Guardado correctamente",CONSTANTS_MESSAGE.TYPE_SUCCESS ));
            dispatch(actionGetInterruptions(oData.client_id)); //enviar a listado de interrupciones
            dispatch(actionDispatch(CONSTANTS_ACTIONS.SAVE_INTERRUPTION));
        }).catch((oError) => {
            if (oError.response){
                dispatch(actionSetResponseCode(oError.response.status));
            }
            dispatch(actionMessage(oError));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
};

/**
 * Funcion que registra un  cliente en el sistema.
 * 
 * @param {object} oData objeto cliente
 * 
 * @author 
 */

export const actionUpdateInterruption = (oData) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        UpdateInterruption(oData).then(oSuccess => {
            dispatch(actionMessage("Actualizado correctamente",CONSTANTS_MESSAGE.TYPE_SUCCESS ));
            dispatch(actionGetInterruptions(oData.client_id)); //enviar a listado de interrupciones
            dispatch(actionDispatch(CONSTANTS_ACTIONS.UPDATE_INTERRUPTION));
        }).catch((oError) => {
            if (oError.response){
                dispatch(actionSetResponseCode(oError.response.status));
            }
            dispatch(actionMessage(oError));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
};


export const actionResetInterruptions = () => ({
    type: CONSTANTS_ACTIONS.RESET_INTERRUPTIONS
});
