export const MultiselectStyle = {
    multiselectContainer: { // To change css for multiselect (Width,height,etc..)
    },
    searchBox: { // To change search box element look
        "min-height": "38px",
        border: "1px solid rgba(0, 40, 100, 0.12)"
    },
    inputField: { // To change input field position or margin
        margin: "0 5px"
    },
    chips: { // To change css chips(Selected options)
        "border-radius": "3px",
        height: "1.5rem",
        "line-height": "1.5rem",
        backgroundColor: "#0096fb",
        color: "#ffffff",
        padding: "0 0.25rem 0 0.75rem"
    },
    optionContainer: { // To change css for option container 
    },
    option: { // To change css for dropdown options
        color: "#495057"
    },
    groupHeading: { // To chanage group heading style
    }
};