import React, { Component } from "react";
import Autosuggest from "react-autosuggest";
import { connect } from "react-redux";
import { actionGetCalls } from "redux/actions/CallsActions";
import { actionGetClientsAutosuggest, actionGetClientsBillingAutosuggest, actionCleanAutosuggest } from "redux/actions/ClientsActions";

class SearchClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oSelectedClient: "",
      oTimeOutId: null,
      sSearch: ""
    };
  }
  
  onChangeClient = (event, { newValue }) => {
    this.setState({ oSelectedClient: newValue });
  }

  onClientSuggestionsFetchRequested = ({ value }) => {
     
    const inputValue = value.trim().toLowerCase();
    this.setState({ sSearch: value.trim().toLowerCase() });
    clearTimeout(this.state.oTimeOutId);
    let oTimeOutId = setTimeout(() => {
      this.props.type === 'BILLING' ? this.props.getClientsSearchBilling(inputValue) : this.props.getClientsSearchCallerInfo(inputValue);
    }, 800);
    this.setState({ oTimeOutId });
  }

  onClientSuggestionsClearRequested = () => {
    this.props.cleanAutosuggest();
  }

  renderClientSuggestion = (oClientSuggestion) => {
    const sName = (oClientSuggestion.fullname && oClientSuggestion.fullname.trim()) ? oClientSuggestion.fullname : <i className='text-muted'>Cliente sin nombre</i>;
     
    return (
      <div className="suggestion-item">
        {sName}
      </div>
    )
  }

  render = () => {
    const { oSelectedClient } = this.state;
    const { aClientsAutosuggest: aClientsSuggest } = this.props.propsClient;
    return (
      <div className="board-header-search active-search">
        <div className="input-icon">
          <span className="input-icon-addon">
            <i className="fe fe-search"></i>
          </span>
          <Autosuggest
            suggestions={aClientsSuggest}
            onSuggestionsFetchRequested={this.onClientSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onClientSuggestionsClearRequested}
            getSuggestionValue={(oClientSuggestion) => {
              if (this.props.type === 'BILLING') {
                this.props.searchAction(oClientSuggestion.id, null)
              } else {
                this.props.searchAction(oClientSuggestion.id);
                this.props.getCallListBoard(oClientSuggestion.id);
                this.props.getCallList(oClientSuggestion.id);
                this.props.changeActiveDeviceTab();
                this.props.setInitialTab();
                this.props.updateSearch(this.state.sSearch);
              }
              
              this.setState({ oSelectedClient: "" });
              return "";
            }}
            renderSuggestion={this.renderClientSuggestion}
            inputProps={{
              className: "form-control",
              value: oSelectedClient,
              placeholder: "Buscar",
              onChange: this.onChangeClient,
              onKeyPress: (e) => {
                if (e.nativeEvent.key === 'Enter' && aClientsSuggest.length) {
                  this.props.searchAction(aClientsSuggest[0].id);
                  if (this.props.type === 'CALLERINFO') {
                    this.props.getCallListBoard(aClientsSuggest[0].id);
                    this.props.getCallList(aClientsSuggest[0].id);
                    this.props.changeActiveDeviceTab();
                    this.props.setInitialTab();
                  }
                  this.setState({ oSelectedClient: "" });
                  

                }
              },
            }}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  propsClient: state.clientsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  cleanAutosuggest: () => {
    dispatch(actionCleanAutosuggest());
  },
  getCallListBoard: (nClientId, nPage = 0, nCallsPerPage = 3, sSearch = "") => {
    dispatch(actionGetCalls(nClientId, nPage, nCallsPerPage, sSearch));
  },
  getCallList: (nClientId, nPage = 0, nCallsPerPage = 10, sSearch = "") => {
    dispatch(actionGetCalls(nClientId, nPage, nCallsPerPage, sSearch));
  },
  getClientsSearchCallerInfo: (sSearch) => {
    dispatch(actionGetClientsAutosuggest(sSearch))
  },
  getClientsSearchBilling: (sSearch) => {
    dispatch(actionGetClientsBillingAutosuggest(sSearch))
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchClient);
