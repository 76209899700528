import { CONSTANTS_ACTIONS } from 'constants/ConstantsActions';

const aInitialState = {
    aInterruptions: [],
    aInterruptionReasons: [],
    oInterruption: {},

}
/**
 * Reducer Mostrar las Ciudades y codigo de area por departamento.
 * 
 * @param {Array} aState Estado del reducer, al momento de inicializarse se tomara aInitialState
 * @param {object} oAction Objeto donde se encuentra el tipo de estado que se desea actualizar y los respectivos datos
 * 
 * @author Franko Zerpa
 */
export const interruptionsReducer = (aState = aInitialState, oAction) => {
    switch (oAction.type) {
        case CONSTANTS_ACTIONS.INTERRUPTIONS:
            return { ...aState, aInterruptions: oAction.data }
        case CONSTANTS_ACTIONS.SAVE_INTERRUPTION:
            return { ...aState, bStatus: true }
        case CONSTANTS_ACTIONS.UPDATE_INTERRUPTION:
            return { ...aState, bStatus: true }
        case CONSTANTS_ACTIONS.INTERRUPTION_REASONS:
            return { ...aState, aInterruptionReasons: oAction.data }
        case CONSTANTS_ACTIONS.RESET_INTERRUPTIONS:
            return { ...aState, bStatus: false }

        default:
            return aState;
    }
}


