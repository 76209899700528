import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { Card } from 'tabler-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneSlash, faPhoneVolume } from '@fortawesome/free-solid-svg-icons';

export default class AnswerCall extends Component {
  constructor(props) {
    super(props);
  }

  render() { 
    const { children, handleCloseModal } = this.props;
    return (
      <ReactModal isOpen={this.props.isOpen} contentLabel='onRequestClose'
        className='system-modal answer-modal' overlayClassName='system-overlay answer-overlay' ariaHideApp={false}>
        <div className='card system-modal-card'>
          <Card.Header>
            <h3 className='card-title answer-title col-8'>
              {children}
              <FontAwesomeIcon icon={faPhoneVolume} className="ml-4 icon-phone"></FontAwesomeIcon>
            </h3>
            <h3 className='col-3 p-1 card-title answer-title end-container border rounded cursor-pointer' onClick={handleCloseModal}>
              <FontAwesomeIcon icon={faPhoneSlash} className="mr-4 icon-phone-20"></FontAwesomeIcon>
              COLGAR
            </h3>
          </Card.Header>
        </div>
      </ReactModal>
    );
  }
}
