import { CONSTANTS_ACTIONS } from "constants/ConstantsActions";

const aInitialState = {
  aReceipts: [],
  oReceipt: {},
};
/**
 * Reducer Mostrar las Ciudades y codigo de area por departamento.
 *
 * @param {Array} aState Estado del reducer, al momento de inicializarse se tomara aInitialState
 * @param {object} oAction Objeto donde se encuentra el tipo de estado que se desea actualizar y los respectivos datos
 *
 * @author Franko Zerpa
 */
export const receiptsReducer = (aState = aInitialState, oAction) => {
  switch (oAction.type) {
    case CONSTANTS_ACTIONS.RECEIPTS:
      return { ...aState, aReceipts: oAction.data };
    case CONSTANTS_ACTIONS.SAVE_RECEIPT:
      return { ...aState, bStatus: true };
    case CONSTANTS_ACTIONS.UPDATE_RECEIPT:
      return { ...aState, bStatus: true };
    case CONSTANTS_ACTIONS.RESET_RECEIPTS:
      return aInitialState;
    default:
      return aState;
  }
};
