import { CONSTANTS_ACTIONS } from 'constants/ConstantsActions';
import { CONSTANTS_MESSAGE } from 'constants/ConstantsMessages';
import { actionActiveLoading, actionDesactiveLoading, actionDispatch, actionMessage, actionSetResponseCode } from 'redux/actions/SharedActions';
import { GetShipments, UpdateShipping, SaveShipping, DevolutionShipping, DeleteDeviceShipped } from 'redux/services/ShipmentsServices';

/**
 * Funcion que retorna todos interrupciones de un cliente.
 * 
 * @author Franko Zerpa
 */
export const actionGetShipments = (nClientId) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        GetShipments(nClientId).then(oSuccess => {
            dispatch(actionDispatch(CONSTANTS_ACTIONS.SHIPMENTS, oSuccess.data));
        }).catch((oError) => {
            if (oError.response) {
                dispatch(actionSetResponseCode(oError.response.status));
            }
            dispatch(actionDispatch(CONSTANTS_ACTIONS.SHIPMENTS, []));
            dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
};






/**
 * Funcion que registra un  cliente en el sistema.
 * 
 * @param {object} oData objeto cliente
 * 
 * @author 
 */

export const actionSaveShipping = (oData) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        SaveShipping(oData).then(oSuccess => {
            dispatch(actionMessage("Guardado correctamente", CONSTANTS_MESSAGE.TYPE_SUCCESS));
            dispatch(actionGetShipments(oData.client_id)); //enviar a listado de interrupciones
            dispatch(actionDispatch(CONSTANTS_ACTIONS.SAVE_SHIPPING));
        }).catch((oError) => {
            if (oError.response) {
                dispatch(actionSetResponseCode(oError.response.status));
            }
            dispatch(actionMessage(oError));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
};

/**
 * Funcion que registra un  cliente en el sistema.
 * 
 * @param {object} oData objeto cliente
 * 
 * @author 
 */

export const actionUpdateShipping = (oData, nClientId) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        UpdateShipping(oData).then(oSuccess => {
            dispatch(actionMessage("Actualizado correctamente", CONSTANTS_MESSAGE.TYPE_SUCCESS));
            dispatch(actionDispatch(CONSTANTS_ACTIONS.UPDATE_SHIPPING));
            dispatch(actionCleanShippments());
            dispatch(actionGetShipments(nClientId));
        }).catch((oError) => {
            if (oError.response) {
                dispatch(actionSetResponseCode(oError.response.status));
            }
            dispatch(actionMessage(oError));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
};
/**
 * Funcion que registra un  cliente en el sistema.
 * 
 * @param {object} oData objeto cliente
 * 
 * @author 
 */

export const actionDevolutionShipping = (nDeviceShippedId, nClientId) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        DevolutionShipping(nDeviceShippedId).then(oSuccess => {
            dispatch(actionMessage("Actualizado correctamente", CONSTANTS_MESSAGE.TYPE_SUCCESS));
            dispatch(actionDispatch(CONSTANTS_ACTIONS.UPDATE_SHIPPING));
            dispatch(actionCleanShippments());
            dispatch(actionGetShipments(nClientId));
        }).catch((oError) => {
            if (oError.response) {
                dispatch(actionSetResponseCode(oError.response.status));
            }
            dispatch(actionMessage(oError));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
};


export const actionResetShipments = () => ({
    type: CONSTANTS_ACTIONS.RESET_SHIPMENTS
});

export const actionCleanShippments = () => {
    return dispatch => {
        dispatch(actionDispatch(CONSTANTS_ACTIONS.CLEAN_SHIPPMENTS));
    }
}

export const actionDeleteDeviceShipped = (oData) => {
    return async dispatch => {
        DeleteDeviceShipped(oData.id).then(oSuccess => {
            dispatch(actionGetShipments(oData.client_id));
        }).catch(oError => {
            dispatch(actionMessage(oError.response.data.message ?? null, CONSTANTS_MESSAGE.TYPE_WARNING));
        });
    }
}
