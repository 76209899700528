import React, { Component } from "react";
import { Alert, Card, Icon, Table } from "tabler-react";
import { connect } from "react-redux";
import {
  actionGetClient,
  actionGetClientsFullList,
  actionResetClient,
  actionGetClientsAutosuggest,
  actionCleanAutosuggest
} from "redux/actions/ClientsActions";
import { actionGetCalls } from "redux/actions/CallsActions";
import { Helpers } from "helpers/Helpers";
import SearchClient from "components/SearchClient";
import TableColSmall from 'components/table/TableColSmall';
import { add } from "date-fns";

class CallerInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oClient: this.props.propsClient.oClientBoard,
      sSearchType: 'CALLERINFO',
      sSearch: ""
    };
  }

  componentDidMount = async () => { };

  componentDidUpdate = (oLastProps) => {
    const { oClientBoard } = this.props.propsClient;
    const { oCallAnswer } = this.props.propsCall;
    if (oCallAnswer && oClientBoard.loan && (oClientBoard.loan.devicephone.replace(/ /g, "") === oCallAnswer.number.replace(/ /g, "")))
      this.props.changeActiveLoanTab(oClientBoard.loan !== null);
    else if (oCallAnswer && oClientBoard.loan && oClientBoard.loan.devicephone.replace(/ /g, "") !== oCallAnswer.number.replace(/ /g, ""))
      this.props.changeActiveLoanTab(false);
    else if (!oCallAnswer && oClientBoard.loan && oClientBoard.loan.devicephone.replace(/ /g, "") === this.state.sSearch.replace(/ /g, "")) {
      this.props.changeActiveLoanTab();
      this.setState({ sSearch: "" });
    } else if (!oCallAnswer && oClientBoard.contracted_devices && oClientBoard.contracted_devices.length > 0) {
      oClientBoard.contracted_devices.forEach(oDevice => {
        if (oDevice.devicephone.replace(/ /g, "") === this.state.sSearch.replace(/ /g, "")) {
          this.props.changeActiveOtherDevices()
          this.setState({ sSearch: "" });
        }
      })
    }
  };

  updateSearch = (sSearch) => {
    this.setState({ sSearch: sSearch });
  }

  render = () => {
    const { activeLoanTab, bOtherDevicesTab, changeActiveDeviceTab, setInitialTab } = this.props;
    const { oClientBoard: oClient } = this.props.propsClient;
    const { oCallAnswer } = this.props.propsCall;
    const { address_principal } = this.props.propsCall.aCallSummary;

    const indexPrin = []
    oClient.addresses.forEach(function (addre, i) {
      
      if(addre.type == 'principal'){
        indexPrin.push(i)
      } 
     }) 
    
    
     
     
    if (!oClient) {
      return null;
    }
    return (
      <>
        <Card className="call-card">
          <SearchClient searchAction={this.props.get} type={this.state.sSearchType} changeActiveDeviceTab={changeActiveDeviceTab} setInitialTab={setInitialTab} updateSearch={this.updateSearch} />
          <Card.Body>
            <div className="alert alert-success text-center" role="alert">
              {
                oClient.midespartner &&
                <h5 className="m-0">
                  <Icon name="users" className="mr-2" />
                  MIDES ({oClient.midespartner}%)
                </h5>
              }
              <h1 className={`m-0 ${(oCallAnswer && oCallAnswer.client_id && oCallAnswer.client_id == oClient.id) && "call-answer-active"}`} style={{ color:`${oClient.no_attend === 1 ? '#bf0918' : ''}` }}>
                <Icon name="phone" className="mr-2" />
                {
                  oCallAnswer && oCallAnswer.client_id && oCallAnswer.client_id == oClient.id
                    ? oCallAnswer.number
                    : activeLoanTab && oClient.loan
                      ? oClient.loan.devicephone
                      : oClient.devicephone
                }
              </h1>
            </div>
            <Alert type="success" className="text-center">
              <h2 className="m-0" style={{ color:`${oClient.no_attend === 1 ? '#bf0918' : ''}` }}>
                {oClient.lastname} {oClient.secondsurname} {oClient.name} {oClient.secondname}
              </h2>
            </Alert>
            <ul className="nav nav-tabs">
              <li className="nav-item" onClick={() => this.props.changeActiveLoanTab(false)}>
                <span className={!activeLoanTab && !bOtherDevicesTab ? "nav-link active" : "nav-link"}>
                  Aparato
                </span>
              </li>
              {
                oClient.contracted_devices.length > 0 &&
                (
                  <li className="nav-item" onClick={() => this.props.changeActiveOtherDevices()}>
                    <span className={bOtherDevicesTab ? "nav-link active" : "nav-link"}>
                      Otros Aparatos
                    </span>
                  </li>
                )
              }
              {oClient.loan != null && (
                <li className="nav-item" onClick={() => this.props.changeActiveLoanTab(true)}>
                  <span className={activeLoanTab && !bOtherDevicesTab ? "nav-link active" : "nav-link"}>
                    Préstamo
                  </span>
                </li>
              )}
            </ul>
            <div className="margin-bottom-24"></div>
            {
              !bOtherDevicesTab ?
                <>
                  <Table className="table-hover table-sm">
                    <Table.Body>
                      {
                        oCallAnswer && oCallAnswer.device_model &&
                        <Table.Row>
                          <Table.ColHeader>{oCallAnswer.device_model}</Table.ColHeader>
                          <Table.Col>{oCallAnswer.number}</Table.Col>
                        </Table.Row>
                      }
                      <Table.Row>
                        <Table.ColHeader>ID del Socio</Table.ColHeader>
                        <Table.Col>{oClient.code}</Table.Col>
                      </Table.Row>
                      <Table.Row>
                        <Table.ColHeader>F. Afiliación</Table.ColHeader>
                        <Table.Col>
                          {oClient.affiliationdate ? Helpers.CastDate(oClient.affiliationdate) : "S/D"}
                        </Table.Col>
                      </Table.Row>
                      <Table.Row>
                        
                        <Table.ColHeader>Domicilio</Table.ColHeader>
                         
                        <TableColSmall 

                        value={ activeLoanTab && oClient.loan ? oClient.loan.address : (oClient.addresses[indexPrin[0]] ? oClient.addresses[indexPrin[0]].domicile : '')  } />

                      </Table.Row>
                      <Table.Row>
                        <Table.ColHeader>Esquina</Table.ColHeader>
                        <Table.Col>
                          {
                            (!activeLoanTab && oClient.addresses && oClient.addresses[ indexPrin[0] ]) ? oClient.addresses[ indexPrin[0] ].corner1 : '-'
                          }
                        </Table.Col>
                      </Table.Row>
                      <Table.Row>
                        <Table.ColHeader>Ciudad</Table.ColHeader>
                        <Table.Col>
                          {
                            (!activeLoanTab && oClient.addresses && oClient.addresses[ indexPrin[0] ] && oClient.addresses[ indexPrin[0] ].city) ? oClient.addresses[ indexPrin[0] ].city.name
                              : (activeLoanTab && oClient.loan && oClient.loan.city) ? oClient.loan.city.name : '-'
                          }
                        </Table.Col>
                      </Table.Row>
                      <Table.Row>
                        <Table.ColHeader>Departamento</Table.ColHeader>
                        <Table.Col>
                          {
                            (!activeLoanTab && oClient.addresses[ indexPrin[0] ] && oClient.addresses[ indexPrin[0] ].state) ? oClient.addresses[ indexPrin[0] ].state.name
                              : (activeLoanTab && oClient.loan && oClient.loan.state) ? oClient.loan.state.name : 'S/D'
                          }
                        </Table.Col>
                      </Table.Row>
                      <Table.Row>
                        <Table.ColHeader>Teléfonos</Table.ColHeader>
                        <Table.Col>{oClient.principal_phone}</Table.Col>
                      </Table.Row>
                      <Table.Row>
                        <Table.ColHeader>Celular/es</Table.ColHeader>
                        <Table.Col>
                          {oClient.phones !== undefined && oClient.phones.length > 0
                            ? oClient.phones.map((phone, index) => (
                              <>
                                {
                                  phone.phonenumber !== "" && (phone.type === 'Celular' || phone.type === 'Vivienda') &&
                                  <Table.Row key={index + 'phones'}>
                                    {phone.phonenumber}
                                  </Table.Row>
                                }
                              </>
                            ))
                            : null}
                        </Table.Col>
                      </Table.Row>
                      <Table.Row>
                        <Table.ColHeader>F. Nacimiento</Table.ColHeader>
                        <Table.Col>
                          {oClient.birthdate ? Helpers.CastDate(oClient.birthdate) : "S/D"}
                        </Table.Col>
                      </Table.Row>
                      <Table.Row>
                        <Table.ColHeader>Edad</Table.ColHeader>
                        <Table.Col>{oClient.birthdate ? Helpers.GetDateDiffNow(oClient.birthdate) : "S/D"}</Table.Col>
                      </Table.Row>
                      <Table.Row>
                        <Table.ColHeader>Documento</Table.ColHeader>
                        <Table.Col>{oClient.identificationcard}</Table.Col>
                      </Table.Row>
                      <Table.Row>
                        <Table.ColHeader>Sexo</Table.ColHeader>
                        <Table.Col>{oClient.sex}</Table.Col>
                      </Table.Row>
                      {
                        (!oCallAnswer || (oCallAnswer && !oCallAnswer.device_model)) &&
                        <Table.Row>
                          <Table.ColHeader>Aparato</Table.ColHeader>
                          <Table.Col>
                            {activeLoanTab && oClient.loan
                              ? oClient.loan.device_model
                              : oClient.show_device}
                          </Table.Col>
                        </Table.Row>
                      }
                      {oClient.emails !== undefined && oClient.emails.length > 0
                        ? oClient.emails.map((email, index) => {
                          return email.email !== "" ? (
                            <Table.Row key={"email" + index}>
                              <Table.ColHeader>
                                {index === 0 ? "Emails" : ""}
                              </Table.ColHeader>
                              <Table.Col>{email.email}</Table.Col>
                            </Table.Row>
                          ) : null;
                        })
                        : null}
                      {
                        (!oCallAnswer || (oCallAnswer && !oCallAnswer.device_model)) &&
                        <>
                          <Table.Row>
                            <Table.ColHeader>Cambio Bateria Pulsera</Table.ColHeader>
                            <Table.Col>
                              {oClient.change_battery_bracelet ? Helpers.CastDate(oClient.change_battery_bracelet) : "S/D"}
                            </Table.Col>
                          </Table.Row>
                          <Table.Row>
                            <Table.ColHeader>Cambio Bateria Aparato</Table.ColHeader>
                            <Table.Col>
                              {activeLoanTab && oClient.loan && oClient.loan.device ? oClient.loan.device.changebattery : oClient.change_battery ? oClient.change_battery : "S/D"}
                            </Table.Col>
                          </Table.Row>
                        </>
                      }
                      <Table.Row>
                        <Table.ColHeader>MIDES</Table.ColHeader>
                        <Table.Col>
                          {oClient.midespartner !== null ? "SI" : "NO"}
                        </Table.Col>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  {oClient.spouse && (
                    <Card>
                      <Card.Header>
                        <Card.Title>Datos esposa/o</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <Table className="table-hover table-sm">
                          <Table.Body>
                            <Table.Row>
                              <Table.ColHeader>Apellido Nombre</Table.ColHeader>
                              <TableColSmall value={oClient.spouse.fullname ? oClient.spouse.fullname : ""} />
                            </Table.Row>
                            <Table.Row>
                              <Table.ColHeader>Documento</Table.ColHeader>
                              <Table.Col>{oClient.spouse.identificationcard}</Table.Col>
                            </Table.Row>
                            <Table.Row>
                              <Table.ColHeader>Emergencia Móvil</Table.ColHeader>
                              <TableColSmall value={oClient.spouse.mobileEmergency ? oClient.spouse.mobileEmergency : ""} />
                            </Table.Row>
                            <Table.Row>
                              <Table.ColHeader>Sociedad Médica</Table.ColHeader>
                              <TableColSmall value={oClient.spouse.medicalSociety ? oClient.spouse.medicalSociety : ""} />
                            </Table.Row>
                            <Table.Row>
                              <Table.ColHeader>Teléfono</Table.ColHeader>
                              <Table.Col>{oClient.spouse.principal_phone}</Table.Col>
                            </Table.Row>
                            <Table.Row>
                              <Table.ColHeader>Celular/es</Table.ColHeader>
                              <Table.Col>
                                {oClient.spouse.phones !== undefined && oClient.spouse.phones.length > 0
                                  ? oClient.spouse.phones.map((phone, index) => (
                                    <>
                                      {
                                        phone.phonenumber !== "" && (phone.type === 'Celular' || phone.type === 'Vivienda') &&
                                        <Table.Row key={index + 'spousephones'}>
                                          {phone.phonenumber}
                                        </Table.Row>
                                      }
                                    </>
                                  ))
                                  : null}
                              </Table.Col>
                            </Table.Row>
                            <Table.Row>
                              <Table.ColHeader>Edad</Table.ColHeader>
                              <Table.Col>{oClient.spouse.birthdate ? Helpers.GetDateDiffNow(oClient.spouse.birthdate) : "S/D"}</Table.Col>
                            </Table.Row>
                            <Table.Row>
                              <Table.ColHeader>Sexo</Table.ColHeader>
                              <Table.Col>{oClient.spouse.sex}</Table.Col>
                            </Table.Row>
                            <Table.Row>
                              <Table.ColHeader>Fecha nacimiento</Table.ColHeader>
                              <Table.Col>
                                {oClient.spouse.birthdate ? Helpers.CastDate(oClient.spouse.birthdate) : ""}
                              </Table.Col>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </Card.Body>
                    </Card>
                  )}
                </> :
                <>
                  <Table className="table-hover table-sm">
                    <Table.Body>
                      {oClient.contracted_devices.map((oDevice, idx) => (
                        <Table.Row key={idx}>
                          <Table.ColHeader>{oDevice.show_device}</Table.ColHeader>
                          <Table.Col>{oDevice.devicephone}</Table.Col>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </>
            }
          </Card.Body>
        </Card>
      </>
    );
  };
}

const mapStateToProps = (state) => ({
  propsClient: state.clientsReducer,
  propsCall: state.callsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  get: (idClient) => {
    dispatch(actionGetClient(idClient));
  },
  getClientsFullList: () => {
    dispatch(actionGetClientsFullList());
  },
  resetClient: () => {
    dispatch(actionResetClient());
  },
  getCallList: (nClientId, nPage = 0, nCallsPerPage = 10, sSearch = "") => {
    dispatch(actionGetCalls(nClientId, nPage, nCallsPerPage, sSearch));
  },
  getClientsSearch: (sSearch) => {
    dispatch(actionGetClientsAutosuggest(sSearch))
  },
  cleanAutosuggest: () => {
    dispatch(actionCleanAutosuggest());
  }

});

export default connect(mapStateToProps, mapDispatchToProps)(CallerInfo);
