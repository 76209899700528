import { DATE_FORMAT_STANDAR, DATE_FORMAT_MYSQL } from 'constants/ConstantsDate';

var oMoment = require('moment');
var oMomentTz = require('moment-timezone');

export const Helpers = {
  isset: (varToCheck) => {
    if (typeof varToCheck !== undefined && varToCheck !== null && varToCheck !== "" && varToCheck !== undefined) {
      if (Array.isArray(varToCheck)) {
        if (varToCheck.length > 0)
          return true;
        return false;
      }
      return true;
    }
    return false;
  },
  formatDate: (dDate) => {
    if (typeof dDate !== undefined && dDate !== null && dDate !== "" && dDate !== undefined) {
      let dMonth = "" + (dDate.getMonth() + 1);
      let dDay = "" + dDate.getDate();
      let dYear = dDate.getFullYear();
      if (dMonth.length < 2) dMonth = "0" + dMonth;
      if (dDay.length < 2) dDay = "0" + dDay;
      return [dYear, dMonth, dDay].join("-");
    }
    return null;
  },
  formatDateFront: (dDate) => {
    if (typeof dDate !== undefined && dDate !== null && dDate !== "" && dDate !== undefined) {
      let dMonth = "" + (dDate.getMonth() + 1);
      let dDay = "" + dDate.getDate();
      let dYear = dDate.getFullYear();
      if (dMonth.length < 2) dMonth = "0" + dMonth;
      if (dDay.length < 2) dDay = "0" + dDay;
      return [dDay, dMonth, dYear].join("/");
    }
    return null;
  },
    
  validEmail: (strMail) => {
    var emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return strMail.search(emailRegEx) !== -1;
  },

  /**
   * Función que formatea un número de tarjeta para ocultarlo
   * 
   * @param {string} sCardNumber Numbero de tarjeta en formato de string
   * 
   * @returns {string}
   * 
   * @author Leandro Curbelo
   */
  hideCard: (sCardNumber) => {
    if (!sCardNumber)
      return '';
    let nLength = sCardNumber.length, sLastCardNumbers = sCardNumber.slice((nLength - 4));
    return sLastCardNumbers.padStart(nLength, '*');
  },
  CastDate: (dDate, sFormat = DATE_FORMAT_STANDAR) => {
    return oMoment(dDate).format(sFormat);
  },
  GetDateObject: (sDate) => {
    return sDate ? oMoment(sDate).utcOffset(-3).toDate() : Helpers.ConvertTZ(new Date());
  },
  GetDateDiffNow: (sDate, sFormat = DATE_FORMAT_MYSQL) => {
    return oMoment(oMoment(new Date()).utcOffset(-3).format(sFormat)).diff(oMoment(oMoment(sDate).format(sFormat)), 'years');
  },
  ConvertTZ: (oDate) => {
    return new Date((typeof oDate === "string" ? new Date(oDate) : oDate).toLocaleString("en-US", { timeZone: "America/Montevideo" }));
  },
  DateNowTz: () => {
    return oMomentTz().tz('America/Montevideo').toDate();
  },
  GetDateForDatepicker: () => {
    let dNow = new Date();
    let d1 = oMomentTz(dNow);
    let d2 = oMomentTz(dNow).tz('America/Montevideo');
    let dModified = oMomentTz(dNow);
    dModified.subtract(d1.utcOffset() - d2.utcOffset(), 'minutes');
    return dModified.valueOf();
  },
  UnixToDate: (nUnixTime) => {
    return oMoment(nUnixTime).toDate();
  },
  FormatDateToMysql: (dToCast, sFormat = DATE_FORMAT_MYSQL) => {
    return oMoment(dToCast).format(sFormat);
  },
  AddYearsToDate: (nYears, sType = 'y') => {
    return oMoment().add(nYears, sType);
  }
};
