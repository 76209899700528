import React from "react";
import { Button, Card, Form } from "tabler-react";
import { NewButton } from "components/buttons/NewButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

export function ListPageTitle(props) {
  let {
    children,
    addButton,
    searchField,
    searchPlaceholder,
    searchChange,
    exportExcel,
  } = props;
  return (
    <Card.Header>
      <Card.Title className="mr-auto">{children}</Card.Title>
      {searchField ? (
        <Form.Input
          icon="search"
          placeholder={searchPlaceholder}
          onChange={searchChange}
        />
      ) : (
        ""
      )}
      {addButton ? <NewButton /> : ""}

      <Button color="primary" className="ml-2" onClick={exportExcel}>
        <FontAwesomeIcon icon={faFileExcel} className="mr-2"></FontAwesomeIcon>
        Exportar a excel
      </Button>
    </Card.Header>
  );
}
