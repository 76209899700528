import { CONSTANTS_ACTIONS } from 'constants/ConstantsActions';

const aInitialState = {
  nCountNotifications: 0,
  aNotifications: {
    aNotificationsOverdueLoans: [],
    aNotificationsUnfiledContracts: [],
    aNotificationsDevicesShipped: []
  }
}
/**
 * Reducer encargado de manejar todos los datos correspondiente a la parte de usuarios.
 * 
 * @param {Array} aState Estado del reducer, al momento de inicializarse se tomara aInitialState
 * @param {object} oAction Objeto donde se encuentra el tipo de estado que se desea actualizar y los respectivos datos
 * 
 * @author 
 */
export const notificationsReducer = (aState = aInitialState, oAction) => {
  switch (oAction.type) {
    case CONSTANTS_ACTIONS.NOTIFICATIONS:
      return { ...aState, aNotifications: oAction.data }
    case CONSTANTS_ACTIONS.NOTIFICATIONS_COUNT:
      return { ...aState, nCountNotifications: oAction.data }
    case CONSTANTS_ACTIONS.RESET_NOTIFICATIONS:
      return { ...aState, bStatus: false }
    default:
      return aState;
  }
}
