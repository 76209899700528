import React, { Component } from "react";
import { Button, Card, Form, Table, Alert, Grid, Icon } from "tabler-react";
import { TableAction } from "components/buttons/TableAction";
import { connect } from "react-redux";
import Modal from "components/Modal";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import { DATE_FORMAT_MYSQL } from "constants/ConstantsDate";

import {
  actionGetInterruptionReasons,
  actionGetInterruptions,
  actionSaveInterruption,
  actionUpdateInterruption,
} from "redux/actions/InterruptionsActions";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CONSTANTS } from 'constants/Constants';
import { Helpers } from "helpers/Helpers";

class InterruptionsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      oInterruptionEmpty: {
        id: null,
        client_id: null,
        interruption_reason: { reason: "" },
        interruption_reason_id: 1,
        startDate: Helpers.GetDateForDatepicker(),
        startTime: "00:00:00",
        endDate: null,
        endTime: null,
        observation: "",
        reason: "",
      },
      oInterruption: {
        id: null,
        interruption_reason: { reason: "" },
        interruption_reason_id: 1,
        startDate: Helpers.GetDateForDatepicker(),
        startTime: "00:00:00",
        endDate: null,
        endTime: null,
        observation: "",
        reason: "",
      },
      isModalInterruptionOpen: false,
      midesInterruptionMotives: [],
      showEndInterruption: false,
    };

    this.props.getInterruptions(this.props.nClientId);
    this.props.getInterruptionReasons();
  }

  componentDidMount = () => {
    this.setState({ oInterruption: { ...this.state.oInterruptionEmpty } });
  };

  getInterruptions = () => {
    this.props.getInterruptions(this.props.nClientId);
  };
  _handleexportExcel = () => {
    window.open(CONSTANTS.API_URL + 'interruptions/clientreport/' + this.props.nClientId, '_blank');
  };

  _handleCheckedChange = (event) => {
    let { name, checked } = event.target;
    let oInterruption = this.state.oInterruption;
    this.setState({ showEndInterruption: checked });
    if (checked === true) {
      oInterruption.endDate = Helpers.GetDateForDatepicker();
      oInterruption.endTime = "00:00:00";
    }
    else {
      oInterruption.endDate = null;
      oInterruption.endTime = null;
    }
    this.setState({ oInterruption })
  };

  renderTable = (aInterruptions) => {
    return aInterruptions.map((oInterruption) => {
      return (
        <Table.Row key={oInterruption.id}>
          <Table.Col>
            {oInterruption.start &&
              oInterruption.start
                .split(" ")[0]
                .split("-")
                .reverse()
                .join("/")}{" "}
            {oInterruption.show_start_time}
          </Table.Col>
          <Table.Col>{oInterruption.interruption_reason.reason}</Table.Col>
          <Table.Col>
            {oInterruption.end &&
              oInterruption.end
                .split(" ")[0]
                .split("-")
                .reverse()
                .join("/")}{" "}
            {oInterruption.show_end_time}
          </Table.Col>
          <Table.Col>{oInterruption.duration}</Table.Col>
          <Table.Col>{oInterruption.observation}</Table.Col>
          <Table.Col className="text-center">
            <TableAction
              iconName="edit"
              tooltip="Editar"
              onClick={() => this._handleEditInterruption(oInterruption)}
            />
          </Table.Col>
        </Table.Row>
      );
    });
  };

  _handleEditInterruption = (interruption) => {
    let oInterruption = interruption; //this.state.oInterruption;
    let start = interruption.start + "";
    let aDateTimeStart = start.split(" ");
    let aDateStart = aDateTimeStart[0].split("-");
    oInterruption.startDate = Helpers.ConvertTZ(new Date(aDateStart[0], parseInt(aDateStart[1]) - 1, aDateStart[2]));
    oInterruption.startTime = aDateTimeStart[1];

    if (interruption.end)
      this.setState({ showEndInterruption: true });

    let End = interruption.end + "";
    let aDateTimeEnd = End.split(" ");
    let aDateEnd = aDateTimeEnd[0].split("-");

    oInterruption.endDate = Helpers.ConvertTZ(new Date(aDateEnd[0], parseInt(aDateEnd[1]) - 1, aDateEnd[2]));
    oInterruption.endTime = aDateTimeEnd[1];

    oInterruption.reason = interruption.interruption_reason.reason;
    oInterruption.interruption_reason_id = interruption.interruption_reason_id;

    this.setState({
      oInterruption: oInterruption,
      isModalInterruptionOpen: true,
    });
  };

  _handleChange = (event) => {
    const { name, value } = event.target;
    let oInterruption = this.state.oInterruption;
    oInterruption[name] = value;
    this.setState({ oInterruption });
  };

  _handlestartDateChange = (selectedDate) => {
    let oInterruption = this.state.oInterruption;
    oInterruption.startDate = selectedDate;
    this.setState({ oInterruption });
  };
  _handleendDateChange = (selectedDate) => {
    let oInterruption = this.state.oInterruption;
    oInterruption.endDate = selectedDate;
    this.setState({ oInterruption });
  };

  renderInterruptionModal = (oClient) => {
    const { oInterruption } = this.state;
    const { aInterruptionReasons } = this.props.oInterruptionReasons; //destructuracion de objeto
    return (
      <Modal
        isOpen={this.state.isModalInterruptionOpen}
        handleActionModal={this._handleSaveInterruption}
        handleCloseModal={this._handleCloseModal}
        modalTitle="Crear/Modificar interrupción"
        customStyles={{
          width: "700px",
        }}
      >
        <Grid.Row>
          {oClient.midespartner !== null ? (
            <Grid.Col width={12}>
              <Form.Group label="Motivo">
                <Form.Select
                  name="interruption_reason_id"
                  value={oInterruption.interruption_reason_id}
                  onChange={this._handleChange}
                >
                  {aInterruptionReasons && aInterruptionReasons.map((elem, index) => {
                    return (
                      <option key={elem.id} value={elem.id}>
                        {elem.reason}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
          ) : (
            <Grid.Col>
              <Form.Group label="Motivo">
                <Form.Input
                  name="reason"
                  placeholder="Motivo"
                  value={oInterruption.reason}
                  onChange={this._handleChange}
                />
              </Form.Group>
            </Grid.Col>
          )}
        </Grid.Row>
        <Grid.Row>
          <Grid.Col width={6}>
            <Form.Group label="Fecha inicio del corte">
              <DatePicker
                name="startDate"
                className="form-control"
                locale={es}
                dateFormat="dd/MM/yyyy"
                selected={oInterruption.startDate ? oInterruption.startDate : ""}
                onChange={this._handlestartDateChange}
                showYearDropdown
              />




            </Form.Group>
          </Grid.Col>
          <Grid.Col width={6}>
            <Form.Group label="Hora inicio del corte">
              <Form.MaskedInput
                name="startTime"
                value={oInterruption.startTime}
                onChange={this._handleChange}
                placeholder="00:00:00"
                mask={[/\d/, /\d/, ":", /\d/, /\d/, ":", /\d/, /\d/]}
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col width={12}>
            <Form.Group label="&nbsp;">
              <label className="custom-switch pt-2 mt-1">
                <input
                  type="checkbox"
                  name="showEndInterruption"
                  className="custom-switch-input"
                  defaultChecked={this.state.showEndInterruption}
                  value={this.state.showEndInterruption}
                  onChange={this._handleCheckedChange}
                />
                <span className="custom-switch-indicator"></span>
                <span className="custom-switch-description font-weight-bold">
                  Indicar Finalización del Corte
                </span>
              </label>
            </Form.Group>
          </Grid.Col>
          {this.state.showEndInterruption ? (
            <>
              <Grid.Col width={6}>
                <Form.Group label="Fecha fin del corte">
                  <DatePicker
                    name="endDate"
                    className="form-control"
                    locale={es}
                    dateFormat="dd/MM/yyyy"
                    selected={oInterruption.endDate ? oInterruption.endDate : ""}
                    onChange={this._handleendDateChange}
                    showYearDropdown
                  />
                </Form.Group>
              </Grid.Col>

              <Grid.Col width={6}>
                <Form.Group label="Hora fin del corte">
                  <Form.MaskedInput
                    name="endTime"
                    value={oInterruption.endTime}
                    onChange={this._handleChange}
                    placeholder="00:00:00"
                    mask={[/\d/, /\d/, ":", /\d/, /\d/, ":", /\d/, /\d/]}
                  />
                </Form.Group>
              </Grid.Col>
            </>
          ) : (
            ""
          )}
        </Grid.Row>
        <Grid.Row>
          <Grid.Col width={12}>
            <Form.Group label="Observaciones">
              <Form.Textarea
                name="observation"
                placeholder="Observaciones"
                value={oInterruption.observation}
                onChange={this._handleChange}
                rows={4}
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      </Modal>
    );
  };

  _handleOpenInterruptionModal = () => {
    this.setState({
      oInterruption: { ...this.state.oInterruptionEmpty },
      isModalInterruptionOpen: true,
    });
  };

  _handleSaveInterruption = () => {
    let { oInterruption } = this.state;
    if (oInterruption.id === null) {
      oInterruption.client_id = this.props.nClientId;
      oInterruption.startDate = Helpers.CastDate(oInterruption.startDate, DATE_FORMAT_MYSQL);
      oInterruption.endDate = oInterruption.endDate ? Helpers.CastDate(oInterruption.endDate, DATE_FORMAT_MYSQL) : null;
      this.props.saveInterruption(oInterruption);
    } else
      this.props.updateInterruption(oInterruption);
    this.setState({
      oInterruption: this.state.oInterruptionEmpty,
      isModalInterruptionOpen: false,
      showEndInterruption: false
    });
    this.getInterruptions();
  };

  _handleCloseModal = () => {
    this.setState({
      oInterruption: this.state.oInterruptionEmpty,
      isModalInterruptionOpen: false,
      showEndInterruption: false
    });
  };

  render() {
    const { aInterruptions } = this.props.oInterruptions; //destructuracion de objeto
    const { oClientBoard: oClient } = this.props.propsClient; //destructuracion de objeto

    return (
      <Card>
        <Card.Header>
          <Card.Title className="mr-auto">
            Listado de cortes / interrupciones
          </Card.Title>
          {aInterruptions.length > 0 ? (
            <>

              <Button color="primary" className="ml-2" onClick={this._handleexportExcel}>
                <FontAwesomeIcon icon={faFileExcel} className="mr-2"></FontAwesomeIcon>
                Exportar a excel
              </Button>
            </>) :
            ("")}
          {this.props.propsClient.oClientBoard.id ? (
            <>
              <Button
                className="btn btn-primary ml-3"
                onClick={() => this._handleOpenInterruptionModal(oClient)}
              >
                <Icon name="plus" className="mr-2" />
                Nuevo
              </Button>
            </>) :
            ("")}

        </Card.Header>
        {aInterruptions.length > 0 ? (
          <div className="table-responsive">
            <Table className="card-table table-vcenter table-sm table-hover text-nowrap">
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>Fecha de corte</Table.ColHeader>
                  <Table.ColHeader>Motivo</Table.ColHeader>
                  <Table.ColHeader>Fecha de restablecimiento</Table.ColHeader>
                  <Table.ColHeader>Duración</Table.ColHeader>
                  <Table.ColHeader>Observaciones</Table.ColHeader>
                  <Table.ColHeader></Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>{this.renderTable(aInterruptions)}</Table.Body>
            </Table>
          </div>
        ) : (
          <Card.Body>
            <Grid.Row>
              <Grid.Col>
                <Alert type="info" icon="info">
                  No hemos obtenido datos de cortes/interrupciones
                </Alert>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        )}
        {this.renderInterruptionModal(oClient)}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  oInterruptions: state.interruptionsReducer,
  oInterruptionReasons: state.interruptionsReducer,
  propsClient: state.clientsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getInterruptions: (nClientId) => {
    dispatch(actionGetInterruptions(nClientId));
  },
  getInterruptionReasons: () => {
    dispatch(actionGetInterruptionReasons());
  },
  saveInterruption: (oInterruption) => {
    dispatch(actionSaveInterruption(oInterruption));
  },
  updateInterruption: (oInterruption) => {
    dispatch(actionUpdateInterruption(oInterruption));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InterruptionsList);
