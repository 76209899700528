import { CONSTANTS } from 'constants/Constants';
import oAxiosInstance from 'redux/interceptors/Interceptors';

const CALLS_URL = CONSTANTS.API_URL + 'calls';

/**
 * Funcion para tomar todos los llamadas del sistema o un cliente
 * 
 * @param {number} nPage Numero de pagina que se intenta obtener
 * @param {number} nCallsPerPage Numero de llamadas por peticion
 * 
 * @author
 */
export const GetCalls = (nClientId = null, nPage = 0, nCallsPerPage = 10, sSearch = '') => {
    return oAxiosInstance.get(CALLS_URL, {
        params: {
            'client_id': nClientId,
            'skip': nPage,
            'take': nCallsPerPage,
            'search': sSearch
        }
    }).then(oResult => oResult)
}
export const SaveCall = (oData) => {
    return oAxiosInstance.post(CALLS_URL, oData).then(oResult => oResult.data)
}
export const UpdateCall = (oData) => {
    return oAxiosInstance.put(CALLS_URL + '/' + oData.id, oData).then(oResult => oResult.data)
}

export const CallListener = () => {
    return oAxiosInstance.get(`${CALLS_URL}/listener`).then(oResult => oResult.data);
}

export const CallAnswer = (nCallId) => {
    return oAxiosInstance.get(`${CALLS_URL}/answer/${nCallId}`).then(oResult => oResult.data);
}

export const EndCallAnswer = (nCallId) => {
    return oAxiosInstance.get(`${CALLS_URL}/end/${nCallId}`).then(oResult => oResult.data);
}

export const DeleteCall = (nCallId) => {
    return oAxiosInstance.get(`${CALLS_URL}/delete/${nCallId}`).then(oResult => oResult.data);
}