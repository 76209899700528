import React, { Component } from 'react';
import CallList from 'pages/calls/CallList';
import CallUpdate from 'pages/calls/CallUpdate';
import { connect } from 'react-redux';
import { Helpers } from 'helpers/Helpers';

class CallSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: true,
      oCallEmpty: {
        id: null,
        client_id: this.props.nClientId,
        duration: "00:00:00",
        type: 'entrante',
        observation: '',
        origin: 'Botón',
        result: 'positivo',
        show_reason: "sin querer",
        midesReason: "",
        show_type: "entrante",
        startDate: Helpers.ConvertTZ(new Date()),//start
        startTime: "00:00:00",//start
        rrdc: '',
        rrds: '',
        performances: [],

      },
      oCall: {},
      nCallsPerPage: this.props.nCallsPerPage,
      nClientId: this.props.nClientId
    }
  }

  activeEdit = () => {
    this.setState({ oCall: { ...this.state.oCallEmpty }, isEditing: !this.state.isEditing });
  }

  formatDate = (sDate) => {
    let aDate = sDate.split('-');
    let oDate = new Date(aDate[0], (parseInt(aDate[1]) - 1), aDate[2]);
    return oDate;
  }

  formatTime = (sTime) => {
    let atime = sTime.split(':');
    if (parseInt(atime[0]) < 10) { atime[0] = '0' + parseInt(atime[0]); }
    if (parseInt(atime[1]) < 10) { atime[1] = '0' + parseInt(atime[1]); }
    if (parseInt(atime[2]) < 10) { atime[2] = '0' + parseInt(atime[2]); }
    let sNewTime = atime.join(':');
    return sNewTime;
  }

  edit = async (call) => {
    let oCall = call;
    let start = oCall.start + "";
    let aDateTimeStart = start.split(' ');
    oCall.startDate = await this.formatDate(aDateTimeStart[0])
    oCall.startTime = await this.formatTime(aDateTimeStart[1]);
    this.setState({ oCall, isEditing: !this.state.isEditing });
  }

  render = () => {
    const { aCalls } = this.props.oCalls;
    const { oCall } = this.state;
    return this.state.isEditing ?
      <CallList activeEdit={this.activeEdit}
        aCalls={aCalls}
        nCallsPerPage={this.state.nCallsPerPage}
        nClientId={this.state.nClientId}
        edit={this.edit}
        oCall={this.state.oCall}
        nCallListPage={this.props.nCallListPage}
        fChangePage={this.props.fChangePage} />
      :
      <CallUpdate activeEdit={this.activeEdit}
        nClientId={this.state.nClientId}
        oClient={this.props.oClient}
        nCallListPage={this.props.nCallListPage}
        oCall={oCall} />
  }
}

const mapStateToProps = state => ({
  oCalls: state.callsReducer,
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(CallSection);
