import React, { Component } from 'react';
import { Button, Card, Form, Table, Alert, Grid, Icon } from 'tabler-react';
import { TableAction } from 'components/buttons/TableAction';
import { connect } from 'react-redux';
import Modal from 'components/Modal';
import { actionMessage, actionCleanMessage } from 'redux/actions/SharedActions';
import { CONSTANTS_MESSAGE } from 'constants/ConstantsMessages';
import { actionDeleteUser, actionGetUsers, actionSaveUser, actionUpdateUser } from 'redux/actions/UsersActions';

class UsersList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      oUserEmpty: {
        id: null,
        name: '',
        lastname: '',
        username: '',
        email: '',
        password: '',
        cpassword: '',
        is_active: 1,
        admin: 0
      },
      oUser: {},
      isModalUserOpen: false,
    }
  }

  componentDidMount = () => {
    const { oUserSession } = this.props.oUserSession;
    if (!oUserSession && oUserSession.data && oUserSession.data.admin) {
      this.props.history.push('/clients')
    }
    this.setState({ oUser: { ...this.state.oUserEmpty } });
    this.getUsers();
  }

  //detectar cambios en el state
  componentDidUpdate = () => {
  }

  getUsers = () => {
    this.props.getUsers();
  }

  renderTable = () => {
    return (
      this.props.propsUser.aUsers && this.props.propsUser.aUsers.data && this.props.propsUser.aUsers.data.map((oUser, nIndex) => {
        return (
          <Table.Row key={`${oUser.id}${nIndex}`}>
            <Table.Col>
              {oUser.lastname} {oUser.name}
            </Table.Col>
            <Table.Col>
              {oUser.email}
            </Table.Col>
            <Table.Col className='text-center isActive-indicator'>
              <Form.Checkbox className="d-inline" checked={oUser.is_active} label={" "} isInline={false} disabled />
            </Table.Col>
            <Table.Col className="text-center d-flex">
              <TableAction iconName="edit" tooltip="Editar" onClick={() => this._handleEditUser(oUser)} />
              <TableAction iconName="trash" tooltip="Eliminar" onClick={() => this._handleDeleteUser(oUser)} />
            </Table.Col>
          </Table.Row>
        )
      })
    )
  }

  _handleEditUser = (oUser) => {
    this.setState({
      oUser: oUser,
      isModalUserOpen: true
    });
  }

  _handleCheckedChange = (event) => {
    let { name, checked } = event.target;
    let oUser = this.state.oUser;
    oUser[name] = checked;
    this.setState({ oUser })
  }

  _handleDeleteUser = (oUser) => {
    this.props.deleteUser(oUser.id);
  }

  _handleChange = (event) => {
    const { name, value } = event.target;
    let oUser = this.state.oUser;
    oUser[name] = value;
    this.setState({ oUser });
  }

  renderUserModal = () => {
    const { oUser } = this.state;
    return (
      <Modal
        isOpen={this.state.isModalUserOpen}
        handleActionModal={this._handleSaveUser}
        handleCloseModal={this._handleCloseModal}
        modalTitle="Crear usuario"
        customStyles={{
          width: "750px"
        }}
      >
        <Grid.Row className="system-body">
          <Grid.Col width={6}>
            <Form.Group>
              <label className="custom-switch">
                <input type="checkbox"
                  name="is_active"
                  className="custom-switch-input"
                  defaultChecked={oUser.is_active}
                  value={oUser.is_active}
                  onChange={this._handleCheckedChange} />
                <span className="custom-switch-indicator"></span>
                <span className="custom-switch-description font-weight-bold">Activo</span>
              </label>
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={6}>
            <Form.Group>
              <label className="custom-switch">
                <input type="checkbox"
                  name="admin"
                  className="custom-switch-input"
                  defaultChecked={oUser.admin}
                  value={oUser.admin}
                  onChange={this._handleCheckedChange} />
                <span className="custom-switch-indicator"></span>
                <span className="custom-switch-description font-weight-bold">Administrador</span>
              </label>
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={6}>
            <Form.Group label="Nombre">
              <Form.Input name='name'
                placeholder='Nombre'
                value={oUser.name}
                onChange={this._handleChange} />
            </Form.Group>
          </Grid.Col>

          <Grid.Col width={6}>
            <Form.Group label="Apellidos">
              <Form.Input name='lastname'
                placeholder='Apellidos'
                value={oUser.lastname}
                onChange={this._handleChange} />
            </Form.Group>
          </Grid.Col>

          <Grid.Col width={6}>
            <Form.Group label="Usuario">
              <Form.Input name='username'
                placeholder='Usuario'
                value={oUser.username}
                onChange={this._handleChange} />
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={6}>
            <Form.Group label="Email">
              <Form.Input name='email'
                placeholder='Email'
                type="email"
                value={oUser.email}
                onChange={this._handleChange} />
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={6}>
            <Form.Group label="Contraseña">
              <Form.Input name='password'
                placeholder='Contraseña'
                type="password"
                value={oUser.password}
                onChange={this._handleChange} />
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={6}>
            <Form.Group label="Confirmar contraseña">
              <Form.Input name='cpassword'
                placeholder='Confirmar contraseña'
                type="password"
                value={oUser.cpassword}
                onChange={this._handleChange} />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      </Modal>
    )
  }

  _handleOpenUserModal = () => {
    this.setState({
      oUser: { ...this.state.oUserEmpty },
      isModalUserOpen: true
    });
  }

  _handleSaveUser = () => {
    let oUser = this.state.oUser;

    if (!oUser.name || oUser.name.trim() === "") {
      this.props.message("El campo Nombre es obligatorio.", CONSTANTS_MESSAGE.TYPE_ERROR);
    } else if (!oUser.email || oUser.email.trim() === "") {
      this.props.message("El campo Email es obligatorio.", CONSTANTS_MESSAGE.TYPE_ERROR);
    } else {
      if (this.state.oUser.id === null) {
        if (!oUser.password || oUser.password.trim() === "") {
          this.props.message("El campo Contraseña es obligatorio.", CONSTANTS_MESSAGE.TYPE_ERROR);
        } else if (oUser.password !== oUser.cpassword) {
          this.props.message("Las confirmación de la contraseña no coincide.", CONSTANTS_MESSAGE.TYPE_ERROR);
        } else
          this.props.saveUser(oUser);
      }
      else {
        if ((oUser.password || oUser.cpassword) && oUser.password !== oUser.cpassword)
          this.props.message("Las contraseñas no coinciden.", CONSTANTS_MESSAGE.TYPE_ERROR);
        else
          this.props.updateUser(this.state.oUser);
      }
      this.setState({
        oUser: { ...this.state.oUserEmpty },
        isModalUserOpen: false
      });
    }
  }

  _handleCloseModal = () => {
    this.setState({
      oUser: this.state.oUserEmpty,
      isModalUserOpen: false
    });
  }

  render() {
    const { aUsers } = this.props.propsUser; //destructuracion de objeto
    return (
      <Card>
        <Card.Header>
          <Card.Title className="mr-auto">
            Listado de usuarios
          </Card.Title>
          <Button className="btn btn-primary ml-3" onClick={() => this._handleOpenUserModal()}>
            <Icon name="plus" className="mr-2" />
            Nuevo
          </Button>
        </Card.Header>
        {aUsers && aUsers.data && aUsers.data.length > 0 ?
          <div className="table-responsive">
            <Table className="card-table table-vcenter table-sm table-hover text-nowrap">
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>
                    Apellidos y Nombres
                  </Table.ColHeader>
                  <Table.ColHeader>
                    Email
                  </Table.ColHeader>
                  <Table.ColHeader alignContent='center'>
                    Activo
                  </Table.ColHeader>
                  <Table.ColHeader>
                  </Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  this.renderTable()
                }
              </Table.Body>
            </Table>
          </div>
          :
          <Card.Body>
            <Grid.Row>
              <Grid.Col>
                <Alert type="info" icon="info">
                  No hemos obtenido ningún usuarios
                </Alert>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        }
        {
          this.renderUserModal()
        }
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  propsClient: state.clientsReducer,
  propsUser: state.usersReducer,
  oUserSession: state.loginReducer,
})

const mapDispatchToProps = dispatch => ({
  getUsers: () => {
    dispatch(actionGetUsers());
  },
  saveUser: (oUser) => {
    dispatch(actionSaveUser(oUser));
  },
  updateUser: (oUser) => {
    dispatch(actionUpdateUser(oUser));
  },
  deleteUser: (nUserId) => {
    dispatch(actionDeleteUser(nUserId));
  },
  message: (msg, type) => {
    dispatch(actionMessage(msg, type));
  },
  cleanMessage: () => {
    dispatch(actionCleanMessage());
  },

})

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);