import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'tabler-react';
import { actionMessage, actionCleanMessage } from 'redux/actions/SharedActions';
import { CONSTANTS_MESSAGE } from 'constants/ConstantsMessages';

class ExamplesMessages extends Component {
  render() {
    return (
      <div>
        <p>
          esta es la pagina de llamadas
          </p>
        <Button.List>
          <Button icon="heart" color="warning" onClick={() => this.props.message('Mensaje de alerta de ejemplo', CONSTANTS_MESSAGE.TYPE_WARNING)}>
            Warning
          </Button>
          <Button icon="check" color="success" onClick={() => this.props.message('Mensaje de exito de ejemplo', CONSTANTS_MESSAGE.TYPE_SUCCESS)}>
           Success
          </Button>
          <Button icon="link" color="danger" onClick={() => this.props.message('Mensaje de error de ejemplo', CONSTANTS_MESSAGE.TYPE_ERROR)}>
            Error
          </Button>
          <Button icon="message-circle" color="info" onClick={() => this.props.message('Mensaje de informacion de ejemplo', CONSTANTS_MESSAGE.TYPE_INFO)}>
            Info
          </Button>
        </Button.List>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  oShared: state.sharedReducer,
})

const mapDispatchToProps = dispatch => ({
  message: (msg, type) => {
    dispatch(actionMessage(msg, type));
  },
  cleanMessage: () => {
    dispatch(actionCleanMessage());
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ExamplesMessages);