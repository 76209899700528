import React from 'react';
import { Icon } from "tabler-react";
import { useRouteMatch, Link } from "react-router-dom";

export function NewButton() {
  let { url } = useRouteMatch();
  return (
    <Link className="btn btn-primary ml-3" to={`${url}/create`}>
      <Icon name="plus" className="mr-2" />
      Nuevo
    </Link>
  );
}