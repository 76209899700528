import React from "react";
import { Nav, Icon, Dropdown } from "tabler-react";
import { Link } from "react-router-dom";
import { CONSTANTS } from "constants/Constants";
import {
  actionGetClients,
  actionSetNavCurrentPageClients,
  actionSetNavItemsPerPageClients,
} from "redux/actions/ClientsActions";
import { connect } from "react-redux";

function NavigationMenu(props) {
  const aMenuOptions = [
    {
      value: "Inicio",
      icon: "home",
      to: "/board",
    },
    {
      value: "Clientes",
      icon: "users",
      to: "/clients"
    },
    {
      value: "Reportes",
      icon: "file-text",
      to: "/",
      items: [
        {
          value: "Bienvenida / Nuevos ingresos",
          icon: "file-text",
          to: "/reports/new-revenue",
        },
        {
          value: "Solicitud de baja",
          icon: "file-text",
          to: "/reports/unsubscribe-request",
        },
        {
          value: "Baja de servicio",
          icon: "file-text",
          to: "/reports/deaths",
        },
        {
          value: "Deudores",
          icon: "file-text",
          to: "/reports/debtors",
        },
        {
          value: "Cumpleaños",
          icon: "file-text",
          to: "/reports/birthday",
        },
        {
          value: "Cambio de batería",
          icon: "file-text",
          to: "/reports/battery-change",
        },
        {
          value: "Tiempo sin llamar",
          icon: "file-text",
          to: "/reports/time-without-calling",
        },
        {
          value: "Aparatos en Préstamo",
          icon: "file-text",
          to: "/reports/loan",
        },
        {
          value: "Envíos",
          icon: "file-text",
          to: "/reports/shipped",
          onClick: "",
        },
      ],
    }
  ];

  if (props.propsUserSession.oUserSession.data.admin) {
    aMenuOptions.push({
      value: "Cobros y Facturación",
      icon: "dollar-sign",
      to: "/billing",
    }, {
      value: "Reporte MIDES",
      icon: "file-text",
      to: "/",
      onClick: (e) => {
        e.preventDefault();
        window.open(CONSTANTS.API_URL + "midesresreports", "_blank");
      }
    });
  }

  return (
    <>
      <Dropdown
        type="button"
        toggle
        color="secondary"
        className="d-flex d-md-none"
        icon="menu"
        items={aMenuOptions.map((oOption, index) =>
          oOption.items ? (
            <div key={index}>
              <Link
                className="dropdown-item py-3"
                to={oOption.to}
                onClick={oOption.onClick ? oOption.onClick : null}
              >
                <Icon name={oOption.icon} className="pr-2" />
                {oOption.value}
              </Link>
              {oOption.items.map((oSubOption, idx) => (
                <Link
                  className="dropdown-item pl-7 py-3"
                  key={idx}
                  to={oSubOption.to}
                  onClick={oSubOption.onClick ? oSubOption.onClick : null}
                >
                  <Icon name={oSubOption.icon} className="pr-2" />
                  {oSubOption.value}
                </Link>
              ))}
            </div>
          ) : (
            <Link
              className="dropdown-item py-3"
              key={index}
              to={oOption.to}
              onClick={oOption.onClick ? oOption.onClick : null}
            >
              <Icon name={oOption.icon} className="pr-2" />
              {oOption.value}
            </Link>
          )
        )}
      />
      <Nav className="px-md-3 d-none d-md-flex">
        {aMenuOptions.map((oOption, index) =>
          oOption.items ? (
            <Nav.Item hasSubNav key={index} value="Reportes" icon="file-text">
              {oOption.items.map((oSubOption, idx) => (
                <Link
                  className="dropdown-item"
                  key={idx}
                  to={oSubOption.to}
                  onClick={oOption.onClick}
                >
                  <Icon name={oSubOption.icon} className="dropdown-icon" />
                  {oSubOption.value}
                </Link>
              ))}
            </Nav.Item>
          ) : (
            <li className="nav-item" key={index}>
              <Link
                className="nav-link"
                to={oOption.to}
                onClick={oOption.onClick}
              >
                <Icon name={oOption.icon} />
                {oOption.value}
              </Link>
            </li>
          )
        )}
      </Nav>
    </>
  );
}

const mapStateToProps = (state) => ({
  propsClients: state.clientsReducer,
  propsUserSession: state.loginReducer,
});

const mapDispatchToProps = (dispatch) => ({
  setNavCurrentPage: (nCurrentPage) => {
    dispatch(actionSetNavCurrentPageClients(nCurrentPage));
  },
  setNavItemsPerPage: (nItemsPerPage) => {
    dispatch(actionSetNavItemsPerPageClients(nItemsPerPage));
  },
  getClientsList: () => {
    dispatch(actionGetClients());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NavigationMenu);
