import { CONSTANTS_ACTIONS } from "constants/ConstantsActions";

const aInitialState = {
  aBatteryChanges: {
    allRecords: 0,
    data: [],
  },
  aBirthday: {
    allRecords: 0,
    data: [],
  },
  aDeaths: {
    allRecords: 0,
    data: [],
  },
  aDebtors: {
    allRecords: 0,
    data: [],
  },
  aDevicesShipped: {
    allRecords: 0,
    data: [],
  },
  aLoan: {
    allRecords: 0,
    data: [],
  },
  aNewRevenue: { allRecords: 0, data: [], },
  aTimeWithoutCalling: {
    allRecords: 0,
    data: [],
  },
  aUnsubscribeRequest: {
    allRecords: 0,
    data: [],
  },
};

export const reportsReducers = (aState = aInitialState, oAction) => {
  switch (oAction.type) {
    case CONSTANTS_ACTIONS.BATTERY_CHANGES:
      return {
        ...aState,
        aBatteryChanges: oAction.data,
      };
    case CONSTANTS_ACTIONS.BIRTHDAY:
      return {
        ...aState,
        aBirthday: oAction.data,
      };
    case CONSTANTS_ACTIONS.DEATHS:
      return {
        ...aState,
        aDeaths: oAction.data,
      };
    case CONSTANTS_ACTIONS.DEBTORS:
      return {
        ...aState,
        aDebtors: oAction.data,
      };
    case CONSTANTS_ACTIONS.DEVICES_SHIPPED:
      return {
        ...aState,
        aDevicesShipped: oAction.data,
      };
    case CONSTANTS_ACTIONS.NEW_REVENUE:
      return { ...aState, aNewRevenue: oAction.data, };
    case CONSTANTS_ACTIONS.TIME_WITHOUT_CALLING:
      return {
        ...aState,
        aTimeWithoutCalling: oAction.data,
      };
    case CONSTANTS_ACTIONS.UNSUBSCRIBE_REQUEST:
      return {
        ...aState,
        aUnsubscribeRequest: oAction.data,
      };
    case CONSTANTS_ACTIONS.CLEAN_REPORTS:
      return aInitialState;
    default:
      return aState;
  }
};
