import React from 'react';
import { Card, Button } from "tabler-react";
import { SaveButton } from 'components/buttons/SaveButton';
import { CancelButton } from 'components/buttons/CancelButton';
import { SharedButton } from 'components/buttons/SharedButton';

export function UpdatePageTitle(props) {
  let { children, fClientFile, bClient, BackTo, saveAction, bAddPartner, nPage, fChangePage } = props;
  return (
    <Card.Header>
      <Card.Title className="mr-auto">{children}</Card.Title>
      {
        bClient ?
          <SharedButton fAction={fClientFile} sLabel="Ficha de cliente" sStyles='btn btn-secondary ml-3' />
          : null
      }
      {
        bAddPartner ?
          nPage === 1 ?
            <SharedButton fAction={fChangePage} sLabel="Siguiente" sStyles='btn btn-secondary ml-3' />
            :
            <SharedButton fAction={fChangePage} sLabel="Anterior" sStyles='btn btn-secondary ml-3' />
          : null
      }     
      {
        ((bAddPartner && nPage !== 1) || !bAddPartner) && <SaveButton saveAction={saveAction} />
      }
      <CancelButton BackTo={BackTo} />
    </Card.Header>
  );
}