import React, { Component } from "react";
import { Card, Form, Table, Alert, Grid, Button } from "tabler-react";
import { connect } from "react-redux";
import {
  actionActiveClient,
  actionCancelRequest,
  actionGetClient,
  actionUpdateUnsubscribeRequestClient
} from "redux/actions/ClientsActions";
import { actionCleanReports, actionGetUnsubscriptionRequest } from "redux/actions/ReportsActions";
import { Pagination } from "components/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { CONSTANTS } from "constants/Constants";
import { TableAction } from "components/buttons/TableAction";
import { Helpers } from "helpers/Helpers";
import Modal from "components/Modal";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";

class ReportUnsubscribeRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nAllRecords: this.props.nAllRecords,
      search: "",
      currentPage: 1,
      nClientsPerPage: 10,
      oTimeOutId: null,
      UnsubscribeMotives: [
        { id: 1, name: "Fallecimiento" },
        { id: 2, name: "Residencial" },
        { id: 3, name: "Acompañado 24 horas" },
        { id: 4, name: "No lo usan" },
        { id: 5, name: "Problemas económicos" },
        { id: 6, name: "Otros" },
      ],
      midesUnsubscribeMotives: [
        { id: 1, name: "Fallecimiento del usuario" },
        { id: 2, name: "Pérdida de interés en el servicio" },
        { id: 3, name: "Incumplimiento de condiciones pautadas en el contrato" },
        { id: 4, name: "Mudanza a zona sin cobertura de la empresa" },
        { id: 5, name: "Dejo de ser perfil" },
        { id: 6, name: "Otro" },
      ],
      isModalUnsubscribeOpen: false,
      selectedMotive: 1,
      selectedDate: Helpers.ConvertTZ(new Date()),
      UnsubscribeMotivesToLoad: [],
      sObservation: "",
      nUnsubscription: null,
      oClientToUnsubscribeEdit: {}
    };
  }

  componentDidMount = () => {
    this.getYears();
    this.get();
  };

  componentWillUnmount = () => {
    this.props.cleanReports();
  };

  get = async () => {
    await this.props.get(0, this.state.nClientsPerPage, null);
  };

  getYears = () => {
    let requestdate = Helpers.ConvertTZ(new Date());
    let actualYear = requestdate.getFullYear();
    let actualMonth = requestdate.getMonth() + 1;
    if (this.state.nMonth == null) {
      this.setState({ nYear: actualYear, nMonth: actualMonth });
    }
    let yearsList = [];
    for (let i = actualYear; i > actualYear - 5; i--) {
      yearsList.push(i);
    }
    return yearsList;
  };

  _handleUnsubscribe = (nClientid) => {
    const { currentPage, nClientsPerPage, search } = this.state;
    this.props.activeClient(nClientid, (currentPage - 1) * nClientsPerPage, nClientsPerPage, search);
  };

  _handleCancelRequest = (nClientid) => {
    const { currentPage, nClientsPerPage, search } = this.state;
    this.props.cancelRequest(nClientid, (currentPage - 1) * nClientsPerPage, nClientsPerPage, search);
  };

  _handleSearchChange = (event) => {
    let { value } = event.target;
    clearTimeout(this.state.oTimeOutId);
    let oTimeOutId = setTimeout(() => {
      this.props.get(0, this.state.nClientsPerPage, value);
    }, 800);
    this.setState({ search: value, currentPage: 1, oTimeOutId });
  }

  _handlechangeCount = (event) => {
    let { value } = event.target;
    this.setState({ nClientsPerPage: value, currentPage: 1 });
    this.props.get(0, value, this.state.search);
  }

  _handleNextPage = async () => {
    let nClientsPerPage = this.state.nClientsPerPage;
    let nPage = this.state.currentPage * nClientsPerPage;
    await this.setState({ currentPage: this.state.currentPage + 1 });
    this.props.get(nPage, nClientsPerPage, this.state.search);
  }

  _handlePreviousPage = async () => {
    await this.setState({ currentPage: this.state.currentPage - 2 });
    let nClientsPerPage = this.state.nClientsPerPage, nPage;
    nPage = this.state.currentPage * nClientsPerPage;
    this.setState({ currentPage: this.state.currentPage + 1 });
    this.props.get(nPage, nClientsPerPage, this.state.search);
  }

  _handleGoToPage = async (nPage) => {
    let nClientsPerPage = this.state.nClientsPerPage, nSkip = 0;
    nSkip = (nPage - 1) * nClientsPerPage;
    await this.setState({ currentPage: nPage });
    this.props.get(nSkip, nClientsPerPage, this.state.search);
  }

  _handleexportExcel = () => {
    window.open(CONSTANTS.API_URL + "reports/excel/unsubscription/request?search=" + this.state.search, "_blank");
  }

  goToView = (event, nClientId, sRoute) => {
    event.stopPropagation();
    this.props.getClient(nClientId);
    this.props.history.push(sRoute);
  }

  _handleEditUnsubscribeRequest = (oElement) => {
    this.setState({
      UnsubscribeMotivesToLoad: this.state.UnsubscribeMotives,
      nUnsubscription: oElement.id,
      selectedMotive: oElement.reason,
      selectedDate: Helpers.GetDateObject(oElement.requestdate),
      sObservation: oElement.observation,
      isModalUnsubscribeOpen: true,
      oClientToUnsubscribeEdit: oElement.client
    });
  }

  _handleChange = (oEvent) => {
    const { name, value } = oEvent.target;
    this.setState({ [name]: value });
  }

  _handleDateChange = (selectedDate) => {
    this.setState({ selectedDate });
  };

  _handleCloseModal = () => {
    this.setState({
      oClientToUnsubscribeEdit: {},
      selectedMotive: 1,
      isModalUnsubscribeOpen: false,
      sObservation: "",
      nUnsubscription: null,
    });
  };

  _handleSaveUnsubscribe = async () => {
    // Manejar la baja del cliente
    const { selectedMotive, selectedDate, nUnsubscription, sObservation, oClientToUnsubscribeEdit, nClientsPerPage, search, currentPage } = this.state;
    await this.props.editUnsubscribe(nUnsubscription, oClientToUnsubscribeEdit.id, selectedMotive, selectedDate, sObservation, (currentPage - 1) * nClientsPerPage, nClientsPerPage, search);
    this.setState({
      oClientToUnsubscribeEdit: {},
      selectedMotive: 1,
      isModalUnsubscribeOpen: false,
      selectedDate: Helpers.ConvertTZ(new Date()),
      sObservation: "",
      nUnsubscription: null
    });
  };

  renderUnsubscribeModal = () => {
    const {
      selectedMotive,
      selectedDate,
      UnsubscribeMotivesToLoad,
      sObservation,
      isModalUnsubscribeOpen,
      oClientToUnsubscribeEdit,
      midesUnsubscribeMotives
    } = this.state;
    return (
      <Modal
        isOpen={isModalUnsubscribeOpen}
        handleActionModal={this._handleSaveUnsubscribe}
        handleCloseModal={this._handleCloseModal}
        modalTitle="Solicitar baja"
        customStyles={{
          width: "500px",
        }}
      >
        <Grid.Row>
          <Grid.Col>
            <Form.Group label="Motivo de baja">
              <Form.Select
                name="selectedMotive"
                value={selectedMotive}
                onChange={this._handleChange}
              >
                {!oClientToUnsubscribeEdit.midespartner && UnsubscribeMotivesToLoad.map((oElem) => {
                  return (
                    <option key={"reason" + oElem.id} value={oElem.id}>
                      {oElem.name}
                    </option>
                  );
                })}
                {oClientToUnsubscribeEdit.midespartner && midesUnsubscribeMotives.map((oElem) => {
                  return (
                    <option key={"reason" + oElem.id} value={oElem.id}>
                      {oElem.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group label="Fecha de Baja">
              <DatePicker
                className="form-control"
                locale={es}
                dateFormat="dd/MM/yyyy"
                selected={selectedDate}
                onChange={this._handleDateChange}
                showYearDropdown
              />
            </Form.Group>
            <Form.Group label="Observación">
              <Form.Textarea
                name="sObservation"
                placeholder="Observación"
                value={sObservation}
                onChange={this._handleChange}
                rows={4}
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      </Modal>
    );
  };

  findMotive = (bMidesPartner, nMotive) => {
    const { UnsubscribeMotives, midesUnsubscribeMotives } = this.state;
    let sMotivoRetorno = "";
    if (!bMidesPartner) {
      UnsubscribeMotives.forEach((oMotive) => {
        if (oMotive.id == nMotive)
          sMotivoRetorno = oMotive.name;
      });
    } else {
      midesUnsubscribeMotives.forEach(oMotive => {
        if (oMotive.id == nMotive)
          sMotivoRetorno = oMotive.name;
      });
    }
    return sMotivoRetorno;
  }

  renderTable = () => {
    return this.props.oUnsubscribeRequest.aUnsubscribeRequest.data.map((oElement, nIndex) => {
      const sName = `${oElement.client.lastname ? oElement.client.lastname : ""} 
       ${oElement.client.secondsurname ? oElement.client.secondsurname : ""}
       ${oElement.client.name ? oElement.client.name : ""}
       ${oElement.client.secondname ? oElement.client.secondname : ""}`;
       //console.log(this.props.propsLogin.oUserSession.data.admin);

      return (
        <Table.Row key={"client" + Math.random()}>
          <Table.Col className="cursor-pointer">
            <span onClick={(event) => this.goToView(event, oElement.client.id, "/clients/edit/" + oElement.client.id)}>
              {sName.trim() ? sName.trim() : <i>Cliente sin nombre</i>}
            </span>
          </Table.Col>
          <Table.Col className="text-center">{oElement.client.code}</Table.Col>
          <Table.Col>{oElement.client.principal_address ? (oElement.client.principal_address.domicile.length > 25 ? oElement.client.principal_address.domicile.substr(0, 22) + "..." : oElement.client.principal_address.domicile) : 'S/D'}</Table.Col>
          <Table.Col className="text-center">{oElement.observation ? (oElement.observation.length > 25 ? oElement.observation.substr(0, 22) + "..." : oElement.observation) : 'S/D'}</Table.Col>
          <Table.Col className="text-center isActive-indicator">
            {Helpers.CastDate(oElement.requestdate)}
          </Table.Col>
          <Table.Col className="text-center isActive-indicator">
            {oElement.created_at ? Helpers.CastDate(oElement.created_at) : 'S/D'}
          </Table.Col>
          <Table.Col className="text-center isActive-indicator">
            {this.findMotive(oElement.client.midespartner, oElement.reason)}
          </Table.Col>
       
          <Table.Col className="text-center isActive-indicator" alignContent="center">
            {oElement.date ? (
              oElement.date
            ) : (
              this.props.propsLogin.oUserSession && // Verificar si oUserSession existe
              this.props.propsLogin.oUserSession.data && // Verificar si data existe
              this.props.propsLogin.oUserSession.data.admin && (
                <TableAction
                  iconName="arrow-left"
                  tooltip="Anular Solicitud"
                  onClick={() => this._handleCancelRequest(oElement.client.id)}
                />
              )
            )}
          </Table.Col>
           
          <Table.Col className="text-center isActive-indicator" alignContent="center">
            {
              oElement.date ?
                oElement.date
                :
                this.props.propsLogin.oUserSession && // Verificar si oUserSession existe
                this.props.propsLogin.oUserSession.data && // Verificar si data existe
                this.props.propsLogin.oUserSession.data.admin && (
                <TableAction iconName="minus-square" tooltip="Ejecutar Baja" onClick={() => this._handleUnsubscribe(oElement.client.id)} />
                )
              }
          </Table.Col>
          <Table.Col>
                <TableAction iconName="edit" tooltip="Editar" onClick={() => this._handleEditUnsubscribeRequest(oElement)} />
            </Table.Col>
        </Table.Row>
      );
    }
    );
  }

  render = () => {
    const { aUnsubscribeRequest } = this.props.oUnsubscribeRequest; //destructuracion de objeto
    const { nClientsPerPage, search } = this.state; //destructuracion de objeto
    return (
      <Card>
        <Card.Header>
          <Card.Title className="mr-auto">Solicitudes de baja</Card.Title>
          <div className="card-filters mr-2">
            <Form.Input
              icon="search"
              placeholder={"Buscar"}
              value={search}
              onChange={this._handleSearchChange}
            />
          </div>
          {aUnsubscribeRequest.data && aUnsubscribeRequest.data.length > 0 ? (
            <>
              <Button
                color="primary"
                className="ml-2"
                onClick={this._handleexportExcel}
              >
                <FontAwesomeIcon
                  icon={faFileExcel}
                  className="mr-2"
                ></FontAwesomeIcon>
                Exportar a excel
              </Button>
            </>
          ) : (
            ""
          )}
        </Card.Header>
        {aUnsubscribeRequest.data && aUnsubscribeRequest.data.length > 0 ? (
          <>
            <div className="table-responsive">
              <Table className="card-table table-vcenter table-sm table-hover text-nowrap">
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Apellido y Nombre</Table.ColHeader>
                    <Table.ColHeader alignContent="center">Número de socio</Table.ColHeader>
                    <Table.ColHeader alignContent="center">Domicilio</Table.ColHeader>
                    <Table.ColHeader alignContent="center">Observación</Table.ColHeader>
                    <Table.ColHeader alignContent="center">
                      Vto de contrato
                    </Table.ColHeader>
                    <Table.ColHeader alignContent="center">
                      Fecha de solicitud
                    </Table.ColHeader>
                    <Table.ColHeader alignContent="center">
                      Motivo de baja
                    </Table.ColHeader>
                    <Table.ColHeader alignContent="center">
                      Anular
                    </Table.ColHeader>
                    <Table.ColHeader alignContent="center">
                      Ejecutar
                    </Table.ColHeader>
                    <Table.ColHeader alignContent="center">
                      Editar
                    </Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{this.renderTable()}</Table.Body>
              </Table>
            </div>
            <Card.Footer>
              <Pagination
                nextPageAction={this._handleNextPage}
                previousPageAction={this._handlePreviousPage}
                goToPageAction={this._handleGoToPage}
                currentPage={this.state.currentPage}
                allRecords={aUnsubscribeRequest.allRecords}
                showing={Array.isArray(aUnsubscribeRequest.data) ? aUnsubscribeRequest.data.length : 0}
                nLastPage={Math.ceil(parseInt(aUnsubscribeRequest.allRecords) / parseInt(nClientsPerPage))}
                showAllOptions={true}
                _handlechangeCount={this._handlechangeCount}
              />
            </Card.Footer>
          </>
        ) : (
          <Card.Body>
            <Grid.Row>
              <Grid.Col>
                <Alert type="info" icon="info">
                  No hemos obtenido datos de clientes
                </Alert>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        )}
        {this.renderUnsubscribeModal()}
      </Card>
    );
  };
}

const mapStateToProps = (state) => ({
  propsLogin: state.loginReducer,
  oClients: state.clientsReducer,
  oUnsubscribeRequest: state.reportsReducers,
});
const mapDispatchToProps = (dispatch) => ({
  getClient: (idClient) => {
    dispatch(actionGetClient(idClient));
  },
  get: (nPage, nClientsPerPage, sSearch) => {
    dispatch(actionGetUnsubscriptionRequest(nPage, nClientsPerPage, sSearch));
  },
  activeClient: (nClientId, nPage, nClientsPerPage, sSearch) => {
    dispatch(actionActiveClient(nClientId, nPage, nClientsPerPage, sSearch));
  },
  cancelRequest: (nClientId, nPage, nClientsPerPage, sSearch) => {
    dispatch(actionCancelRequest(nClientId, nPage, nClientsPerPage, sSearch));
  },
  cleanReports: () => {
    dispatch(actionCleanReports());
  },
  editUnsubscribe: (nUnsubscription, nClientId, sReason, dDate, sObservation, nPage, nClientsPerPage, sSearch) => {
    dispatch(actionUpdateUnsubscribeRequestClient(nUnsubscription, nClientId, sReason, dDate, sObservation, nPage, nClientsPerPage, sSearch));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportUnsubscribeRequest);
