import React, { Component } from "react";
import { Card, Form, Table, Alert, Grid, Button } from "tabler-react";
import { connect } from "react-redux";
import { actionGetClient } from "redux/actions/ClientsActions";
import {
  actionCleanReports,
  actionGetTimeWithoutCalling,
} from "redux/actions/ReportsActions";
import { Pagination } from "components/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { CONSTANTS } from "constants/Constants";

const FILTER_DAYS = 1;
const FILTER_MONTHS = 2;
const FILTER_YEARS = 3;

class ReportTimeWithoutCalling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aMonthList: [
        { id: 1, description: "45 dias" },
        { id: 2, description: "2 meses" },
        { id: 3, description: "3 meses" },
        { id: 4, description: "4 meses" },
        { id: 5, description: "5 meses" },
        { id: 6, description: "6 meses" },
        { id: 7, description: "7 meses" },
        { id: 8, description: "8 meses" },
        { id: 9, description: "9 meses" },
        { id: 10, description: "10 meses" },
        { id: 11, description: "11 meses" },
        { id: 12, description: "más de 1 año" },
        { id: 24, description: "más de 2 años" },
        { id: 36, description: "más de 3 años" },
      ],
      currentPage: 1,
      nClientsPerPage: 10,
      months: 1,
    };
  }
  componentDidMount = () => {
    this.get();
  };

  componentWillUnmount = () => {
    this.props.cleanReports();
  };

  get = async () => {
    await this.props.get(0, this.state.nClientsPerPage);
  }

  getFilterAndType = () => {
    const { months: nValue } = this.state;
    let nType = FILTER_DAYS, nFilter = 45;
    if (nValue > 1 && nValue < 12) {
      nType = FILTER_MONTHS;
      nFilter = nValue;
    }
    if (nValue >= 12) {
      nType = FILTER_YEARS;
      nFilter = nValue / 12;
    }
    return { type: nType, filter: nFilter };
  }

  _handleChangeMonth = async (event) => {
    let { value: nValue } = event.target;
    await this.setState({ months: nValue, currentPage: 1 });
    const { type: nType, filter: nFilter } = this.getFilterAndType();
    this.props.get(0, this.state.nClientsPerPage, nType, nFilter);
  }

  _handleexportExcel = () => {
    const { type: nType, filter: nFilter } = this.getFilterAndType();
    window.open(`${CONSTANTS.API_URL}reports/excel/calls?type=${nType}&count=${nFilter}`, '_blank');
  }

  _handlechangeCount = (event) => {
    const { value: nValue } = event.target;
    this.setState({ nClientsPerPage: nValue, currentPage: 1 });
    const { type: nType, filter: nFilter } = this.getFilterAndType();
    this.props.get(0, nValue, nType, nFilter);
  }

  _handleNextPage = async () => {
    let nClientsPerPage = this.state.nClientsPerPage;
    let nPage = this.state.currentPage * nClientsPerPage;
    await this.setState({ currentPage: this.state.currentPage + 1 });
    const { type: nType, filter: nFilter } = this.getFilterAndType();
    this.props.get(nPage, nClientsPerPage, nType, nFilter);
  }

  _handlePreviousPage = async () => {
    await this.setState({ currentPage: this.state.currentPage - 2 });
    let nClientsPerPage = this.state.nClientsPerPage;
    let nPage;
    if (this.state.currentPag <= 1) {
      nPage = 0;
    } else {
      nPage = this.state.currentPage * nClientsPerPage;
    }
    this.setState({ currentPage: this.state.currentPage + 1 });
    const { type: nType, filter: nFilter } = this.getFilterAndType();
    this.props.get(nPage, nClientsPerPage, nType, nFilter);
  };

  _handleGoToPage = async (nPage) => {
    let nClientsPerPage = this.state.nClientsPerPage;
    this.setState({ currentPage: nPage });
    let nSkip;
    if (this.state.currentPag >= 1) {
      nSkip = 0;
    } else {
      nSkip = (nPage - 1) * nClientsPerPage;
    }
    const { type: nType, filter: nFilter } = this.getFilterAndType();
    this.props.get(nSkip, nClientsPerPage, nType, nFilter);
  };

  goToView = (event, nClientId, sRoute) => {
    event.stopPropagation();
    this.props.getClient(nClientId);
    this.props.history.push(sRoute);
  };

  renderTable = () => {
    return this.props.oTimeWithoutCalling.aTimeWithoutCalling.data.map((oCall) => {
      const oClient = oCall.client;
      const sName = `${oClient.lastname} ${oClient.secondsurname} ${oClient.name} ${oClient.secondname}`.trim()
        ? `${oClient.lastname ? oClient.lastname : ""} ${oClient.secondsurname ? oClient.secondsurname : ""} ${oClient.name ? oClient.name : ""} ${oClient.secondname ? oClient.secondname : ""}`
        : <i className='text-muted'>Cliente sin nombre</i>
      return (
        <Table.Row key={"call_" + oCall.id}>
          <Table.Col className="cursor-pointer">
            <span onClick={(event) => this.goToView(event, oClient.id, "/clients/edit/" + oClient.id)}>{sName}</span>
          </Table.Col>
          <Table.Col>{oClient.code}</Table.Col>
          <Table.Col>{
            oClient.principal_phone ? oClient.principal_phone.phonenumber : 'S/D'
          }</Table.Col>
          <Table.Col>{
            oClient.principal_address ? oClient.principal_address.domicile : 'S/D'
          }</Table.Col>
          <Table.Col>{oCall.last_call_interval}</Table.Col>
          <Table.Col className="text-center isActive-indicator">
            <Form.Checkbox disabled className="d-inline" checked={oClient.midespartner ? true : false} onChange={() => { }} label={" "} disabledisInline={false} />
          </Table.Col>
        </Table.Row>
      );
    });
  };

  render = () => {
    const { aTimeWithoutCalling } = this.props.oTimeWithoutCalling;
    const { nClientsPerPage, aMonthList, currentPage } = this.state;
    return (
      <Card>
        <Card.Header>
          <Card.Title className="mr-auto">Tiempo sin llamar</Card.Title>
          <div className="card-filters mr-2">
            <Form.Select name="type" onChange={this._handleChangeMonth} value={this.state.months}>
              {
                aMonthList.map((oMonth) => (
                  <option key={oMonth.id} value={oMonth.id}>{oMonth.description}</option>
                ))
              }
            </Form.Select>
          </div>
          <Button color="primary" className="ml-2" onClick={this._handleexportExcel}>
            <FontAwesomeIcon icon={faFileExcel} className="mr-2"></FontAwesomeIcon>Exportar a excel
          </Button>
        </Card.Header>
        {aTimeWithoutCalling.data && aTimeWithoutCalling.data.length > 0 ? (
          <>
            <div className="table-responsive">
              <Table className="card-table table-vcenter table-sm table-hover text-nowrap">
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Apellido y Nombre</Table.ColHeader>
                    <Table.ColHeader>Número de socio</Table.ColHeader>
                    <Table.ColHeader>Teléfono</Table.ColHeader>
                    <Table.ColHeader>Domicilio</Table.ColHeader>
                    <Table.ColHeader>Ultima llamada</Table.ColHeader>
                    <Table.ColHeader alignContent="center">Socio Mides</Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{this.renderTable()}</Table.Body>
              </Table>
            </div>
            <Card.Footer>
              <Pagination
                nextPageAction={this._handleNextPage}
                previousPageAction={this._handlePreviousPage}
                goToPageAction={this._handleGoToPage}
                currentPage={this.state.currentPage}
                allRecords={aTimeWithoutCalling.allRecords}
                showing={Array.isArray(aTimeWithoutCalling.data) ? aTimeWithoutCalling.data.length : 0}
                nLastPage={Math.ceil(
                  parseInt(aTimeWithoutCalling.allRecords) /
                  parseInt(nClientsPerPage)
                )}
                showAllOptions={true}
                _handlechangeCount={this._handlechangeCount}
              />
            </Card.Footer>
          </>
        ) : (
          <Card.Body>
            <Grid.Row>
              <Grid.Col>
                <Alert type="info" icon="info">
                  No hemos obtenido datos de clientes
                </Alert>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        )}
      </Card>
    );
  };
}

const mapStateToProps = (state) => ({
  oClients: state.clientsReducer,
  oTimeWithoutCalling: state.reportsReducers,
});

const mapDispatchToProps = (dispatch) => ({
  getClient: (idClient) => {
    dispatch(actionGetClient(idClient));
  },
  get: (nPage = 1, nClientsPerPage = 10, nType = 1, nFilterCount = 45) => {
    dispatch(actionGetTimeWithoutCalling(nPage, nClientsPerPage, nType, nFilterCount));
  },
  cleanReports: () => {
    dispatch(actionCleanReports());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportTimeWithoutCalling);
