import React, { Component } from 'react';
import { Card, Table, Alert, Grid } from 'tabler-react';
import { ListPageTitle } from 'components/ListPageTitle';
import { TableAction } from 'components/buttons/TableAction';
import { connect } from 'react-redux';
import { actionGetMedicalSocieties } from 'redux/actions/MedicalSocietiesActions';

class SocietyList extends Component {

  componentDidMount = () => {
    this.get();
  }

  get = () => {
    this.props.get();
  }

  renderTable = () => {
    return (
      this.props.oSocieties.aMedicalSocieties.map(oSociety => {
        return (
          <Table.Row key={oSociety.name}>
            <Table.Col>
              {oSociety.name}
            </Table.Col>
            <Table.Col>
              {oSociety.contactphone}
            </Table.Col>
            <Table.Col>
              {oSociety.alternatecontactphone}
            </Table.Col>
            <Table.Col className="text-center">
              <TableAction iconName="eye" tooltip="Detalles" to='/' />
              <TableAction iconName="edit" tooltip="Editar" to='/' />
              <TableAction iconName="trash" tooltip="Eliminar" to='/' />
            </Table.Col>
          </Table.Row>
        )
      })
    )
  }

  render = () => {
    return (
      <Card>
        <ListPageTitle addButton={true}>
          Listado de Sociedades Medicas
        </ListPageTitle>
        {
          this.props.oSocieties.aMedicalSocieties.length > 0 ?
            <div className="table-responsive">
              <Table className="card-table table-vcenter table-sm table-hover text-nowrap">
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>
                      Nombre
                    </Table.ColHeader>
                    <Table.ColHeader>
                      Número de contacto
                    </Table.ColHeader>
                    <Table.ColHeader>
                      Número de contacto alternativo
                    </Table.ColHeader>
                    <Table.ColHeader>
                    </Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {
                    this.renderTable()
                  }
                </Table.Body>
              </Table>
            </div>
            :
            <Card.Body>
              <Grid.Row>
                <Grid.Col>
                  <Alert type="info" icon="info">
                    No hemos obtenido datos de sociedades medicas
                  </Alert>
                </Grid.Col>
              </Grid.Row>
            </Card.Body>
        }
        <Card.Footer />
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  oSocieties: state.medicalSocietiesReducer,
})

const mapDispatchToProps = dispatch => ({
  get: () => {
    dispatch(actionGetMedicalSocieties());
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(SocietyList);
