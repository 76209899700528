import React, { Component } from "react";
import { Grid } from "tabler-react";
import NavigationMenu from "components/layout/NavigationMenu";
import UserMenu from "components/layout/UserMenu";
import logo from "assets/images/logo_helpline.jpg";
import { connect } from "react-redux";
import { CONSTANTS } from 'constants/Constants';

class Header extends Component {
  render() {
    return (
      <>
        {this.props.propsLogin.bStatus && (
          <div className={`header py-0 ${CONSTANTS.DEBUG !== 'production' && 'background-staging'}`}>
            <div className="container-fluid px-md-5">
              <div className="system-menu">
                <Grid.Row>
                  <Grid.Col md={3}>
                    <div className="header-brand px-md-3 text-center">
                      <img src={logo} className="header-brand-img" alt="Logo" />
                    </div>
                  </Grid.Col>
                  <Grid.Col md={6} className="py-5">
                    <NavigationMenu />
                  </Grid.Col>
                  <Grid.Col md={3} className="py-4">
                    <UserMenu />
                  </Grid.Col>
                </Grid.Row>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  propsLogin: state.loginReducer,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
