import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AccountDropdown } from "tabler-react";
import NotificationMenu from './NotificationMenu';
import { actionLogout } from "redux/actions/LoginActions";
import account from "assets/images/account.png";

class UserMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oUserSession: props.oUserSession.oUserSession.data,
      aMenuOptions: []
    }
  }

  handleLogout = () => {
    this.props.logout();
  }

  componentDidMount = () => {
    const aMenuOptions = this.state.aMenuOptions;
    if (this.state.oUserSession.admin){
      aMenuOptions.push({ icon: "users", value: "Usuarios", to: "/users" });
      aMenuOptions.push("divider");
    }
    aMenuOptions.push({ icon: "log-out", value: "Salir", onClick: this.handleLogout });
    this.setState({aMenuOptions});
  }

  render() {
    const { oUserSession, aMenuOptions } = this.state;
    return (
      <div className="d-flex order-lg-2 justify-content-end px-md-3">
        <NotificationMenu />
        <AccountDropdown
          avatarURL={account}
          name={(oUserSession.lastname || '') + ' ' + oUserSession.name}
          description={oUserSession.admin ? "Administrador" : ""}
          options={aMenuOptions}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  oUserSession: state.loginReducer,
});
const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(actionLogout());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
