import React from "react";
import { Table, Tooltip } from "tabler-react";

function TableColSmall(oProps) {
  const { value: nValue = 'S/D', length: nLength = 30 } = oProps;
  return (
    <Table.Col>
      <Tooltip content={nValue && nValue} placement="bottom">
        <span>
          {
            nValue && nValue.length > nLength
              ? nValue.substring(0, nLength) + "..."
              : nValue
          }
        </span>
      </Tooltip>
    </Table.Col>
  );
}

export default TableColSmall;
