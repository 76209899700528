import { CONSTANTS_ACTIONS } from 'constants/ConstantsActions';

const aInitialState = {
    idClient: null,
    nAllRecords: null,
    aCalls: {
        allRecords: 0,
        data: [],
    },
    aCallSummary: [],
    oCall: {},
    bStatus: false,
    oCallAnswer: null,
    aCallIncoming: [],
    aCallIncomingNotified: [],

    oLastCall: null,
    aCallsNewIncoming: []
}
/**
 * Reducer encargado de manejar todos los datos correspondiente a la parte de llamadas.
 * 
 * @param {Array} aState Estado del reducer, al momento de inicializarse se tomara aInitialState
 * @param {object} oAction Objeto donde se encuentra el tipo de estado que se desea actualizar y los respectivos datos
 * 
 * @author 
 */
export const callsReducer = (aState = aInitialState, oAction) => {
    switch (oAction.type) {
        case CONSTANTS_ACTIONS.CALLS:
            return { ...aState, aCalls: oAction.data }
        case CONSTANTS_ACTIONS.CALLSUMMARY:
            return { ...aState, aCallSummary: oAction.data }
        case CONSTANTS_ACTIONS.CALL:
            return { ...aState, oCall: oAction.data }
        case CONSTANTS_ACTIONS.SAVE_CALLS:
            return { ...aState, bStatus: true }
        case CONSTANTS_ACTIONS.RESET_CALLS:
            return { ...aState, bStatus: false }
        case CONSTANTS_ACTIONS.LISTENER_CALLS:
            // Si se encuentra en una llamada y la proxima es null, significa que corto la llamada y debe dirijirse a editarla
            if (aState.oCallAnswer && !oAction.data.call)
                return { ...aState, aCallIncoming: oAction.data.calls, oCallAnswer: null, oLastCall: oAction.data.last_call };
            return { ...aState, aCallIncoming: oAction.data.calls, oCallAnswer: oAction.data.call };
        case CONSTANTS_ACTIONS.CLEAN_CALL_NAVIGATION:
            return { ...aState, oLastCall: null };
        case CONSTANTS_ACTIONS.NOTIFIED_CALLS:
            return { ...aState, aCallIncomingNotified: oAction.data };
        case CONSTANTS_ACTIONS.CALLSUMMARY_CLEAR:
            return { ...aState, aCallSummary: [] };
        case CONSTANTS_ACTIONS.CALLS_CLEAR:
            return { ...aState, aCalls: { allRecords: 0, data: [] } };
        case CONSTANTS_ACTIONS.CALLS_INCOMING:
            return { ...aState, aCallsNewIncoming: oAction.data };
        default:
            return aState;
    }
}
