import React, { Component } from "react";
import { connect } from "react-redux";
import { actionGetClient } from "redux/actions/ClientsActions";

class InCall extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount = () => {
    if (this.props.match.params.id !== null){
      this.props.getClient(this.props.match.params.id);
      this.props.history.push('/board');
    }
  }


  render = () => {
    return null;
  };
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getClient: (idClient) => {
    dispatch(actionGetClient(idClient));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InCall);
