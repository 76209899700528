import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actionCleanNavigation, actionCleanNavigationBilling, actionCleanNavigationClient } from 'redux/actions/SharedActions';

function Body(props) {
  const history = useHistory();

  useEffect(() => {
    if (!props.propsLogin.bStatus)
      history.push('/login');
    if (props.oSharedProps.bNavigateToBoard) {
      history.push('/board');
      props.clean();
    }
    if (props.oSharedProps.bNavigateToBoard) {
      history.push('/board');
      props.clean();
    }
    if (props.oSharedProps.bNavigateToClients) {
      history.push('/clients')
      props.cleanClients();
    }
    if (props.oSharedProps.bNavigateToBilling) {
      history.push('/billing')
      props.cleanBilling();
    }
  });

  return (
    <div className='my-3 my-md-3 mx-md-5'>
      <div className='container-fluid system-body'>
        {props.children}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  propsLogin: state.loginReducer,
  oSharedProps: state.sharedReducer,
})

const mapDispatchToProps = dispatch => ({
  clean: () => {
    dispatch(actionCleanNavigation());
  },
  cleanClients: () => {
    dispatch(actionCleanNavigationClient());
  },
  cleanBilling: () => {
    dispatch(actionCleanNavigationBilling());
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Body);