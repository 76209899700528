import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid } from "tabler-react";
import Modal from "./Modal";

class ProgressBar extends Component {
  render = () => {
    const { nTotal, nCompleted } = this.props.propsProgressBar;
    return (
      <Modal
        isOpen={this.props.propsProgressBar.isVisible}
        modalHideAction={true}
        modalHideCancel={true}
        modalTitle="Facturando..."
        customStyles={{
          width: "550px",
        }}
      >
        <Grid.Row className="system-body">
          <div style={oContainerStyles}>
            <div
              style={{
                height: "100%",
                width: `${this.props.propsProgressBar.nProgress}%`,
                backgroundColor: `${this.props.propsProgressBar.sBgcolor}`,
                textAlign: "right",
                transition: "width 1s ease-in-out",
              }}
            >
              <span style={oLabelStyles}>{`${this.props.propsProgressBar.nProgress} %`}</span>
            </div>
          </div>
          <div style={oCounterStyles}>
            <p>{nCompleted}/{nTotal}</p>
          </div>
        </Grid.Row>
      </Modal>
    );
  };
}

const mapStateToProps = (state) => ({
  propsProgressBar: state.progressBarReducer,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgressBar);

const oContainerStyles = {
  height: 20,
  width: "100%",
  backgroundColor: "#e0e0de",
  borderRadius: 50,
  margin: 20,
  position: "relative",
  overflow: "hidden",
};

const oLabelStyles = {
  padding: 10,
  color: "white",
  fontWeight: "bold",
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
};

const oCounterStyles = {
  width: '95%',
  textAlign: 'right'
}
