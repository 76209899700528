import { CONSTANTS_ACTIONS } from 'constants/ConstantsActions';

const aInitialState = {
  idUser: null,
  nAllRecords: null,
  aUsers: {
    allRecords: 0,
    data: [],
  },
  oUser: {},
  bStatus: false
}
/**
 * Reducer encargado de manejar todos los datos correspondiente a la parte de usuarios.
 * 
 * @param {Array} aState Estado del reducer, al momento de inicializarse se tomara aInitialState
 * @param {object} oAction Objeto donde se encuentra el tipo de estado que se desea actualizar y los respectivos datos
 * 
 * @author 
 */
export const usersReducer = (aState = aInitialState, oAction) => {
  switch (oAction.type) {
    case CONSTANTS_ACTIONS.USERS:
      return { ...aState, aUsers: oAction.data }
    case CONSTANTS_ACTIONS.USER:
      return { ...aState, oUser: oAction.data }
    case CONSTANTS_ACTIONS.SAVE_USER:
      return { ...aState, bStatus: true }
    case CONSTANTS_ACTIONS.UPDATE_USER:
      return { ...aState, bStatus: true }
    case CONSTANTS_ACTIONS.RESET_USERS:
      return { ...aState, bStatus: false }
    default:
      return aState;
  }
}
