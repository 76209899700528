import { CONSTANTS } from 'constants/Constants';
import oAxiosInstance from 'redux/interceptors/Interceptors';

const INTERRUPTIONS_URL = CONSTANTS.API_URL + 'interruptions';

/**
 * Funcion para tomar todos losDepartamentos de uruguay
 * 
 * 
 * @author Franko Zerpa
 */
export const GetInterruptions = (nClientId) => {
    return oAxiosInstance.get(INTERRUPTIONS_URL+'/client/'+nClientId).then(oResult => oResult.data)
}



/**
 * 
 * @param {*} oData 
 */
export const SaveInterruption = (oData) => {
    return oAxiosInstance.post(INTERRUPTIONS_URL,oData).then(oResult => oResult.data)
}

/**
 * 
 * @param {*} oData 
 */
export const UpdateInterruption = (oData) => {
    return oAxiosInstance.put(INTERRUPTIONS_URL+'/'+oData.id,oData).then(oResult => oResult.data)
}
/**
 * Funcion para tomar todos losDepartamentos de uruguay
 * 
 * 
 * @author Franko Zerpa
 */
export const GetInterruptionReasons = () => {
    return oAxiosInstance.get(INTERRUPTIONS_URL+'/get/reasons').then(oResult => oResult.data)
} 