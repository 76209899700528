import { CONSTANTS } from "constants/Constants";
import oAxiosInstance from "redux/interceptors/Interceptors";
const PAYMENTS_URL = CONSTANTS.API_URL + "payments";

export const SaveClientDetails = (oData) => {
  return oAxiosInstance
    .post(PAYMENTS_URL, oData)
    .then((oResult) => oResult.data);
};

export const ImportFile = async (oData, paymethod) => {
  return await oAxiosInstance
    .post(PAYMENTS_URL + "/import/" + paymethod.toLowerCase(), oData, {
      "content-Type": "multipart/form-data",
    })
    .then((oResult) => oResult.data);
};

/**
 *
 * @param {*} oData
 */
export const UpdateClientPayments = (oData, paymethod) => {
  return oAxiosInstance
    .put(PAYMENTS_URL + "/clientupdate/" + oData.id, oData)
    .then((oResult) => oResult.data);
};

export const ProcessPayments = (oPayment, paymethod) => {
  return oAxiosInstance.post(PAYMENTS_URL + "/process/" + paymethod, { payment: oPayment }).then((oResult) => oResult.data);
}

export const CancelPayment = (nPaymentId, sReason) => {
  return oAxiosInstance.post(`${PAYMENTS_URL}/cancel/${nPaymentId}`, { reason: sReason }).then((oResult) => oResult.data);
};

export const ProcessPaymentService = (oPayment, oReceipt) => {
  return oAxiosInstance
    .post(PAYMENTS_URL + "/service", {
      oPayment: oPayment,
      oReceipt: oReceipt
    })
    .then((oResult) => oResult.data);
};

export const ProgressBarService = () => {
  return oAxiosInstance
    .post(PAYMENTS_URL + "/progressBar")
    .then((oResult) => oResult.data);
};
