import { CONSTANTS_ACTIONS } from 'constants/ConstantsActions';
import { CONSTANTS_MESSAGE } from 'constants/ConstantsMessages';
import { actionActiveLoading, actionDesactiveLoading, actionDispatch, actionMessage, actionSetResponseCode } from 'redux/actions/SharedActions';
import { GetRelations,UpdateRelation,SaveRelation } from 'redux/services/RelationsServices';

/**
 * Funcion que retorna todos interrupciones de un cliente.
 * 
 * @author Franko Zerpa
 */
export const actionGetRelations = (nClientId) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        GetRelations(nClientId).then(oSuccess => {
            dispatch(actionDispatch(CONSTANTS_ACTIONS.RELATIONS, oSuccess.data));
        }).catch((oError) => {
            if (oError.response){
                dispatch(actionSetResponseCode(oError.response.status));
            }
            dispatch(actionDispatch(CONSTANTS_ACTIONS.RELATIONS, []));
            dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
};






/**
 * Funcion que registra un  cliente en el sistema.
 * 
 * @param {object} oData objeto cliente
 * 
 * @author 
 */

export const actionSaveRelation = (oData) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        SaveRelation(oData).then(oSuccess => {
            dispatch(actionMessage("Guardado correctamente",CONSTANTS_MESSAGE.TYPE_SUCCESS ));
            dispatch(actionGetRelations(oData.client_id)); //enviar a listado de interrupciones
            dispatch(actionDispatch(CONSTANTS_ACTIONS.SAVE_RELATION));
        }).catch((oError) => {
            if (oError.response){
                dispatch(actionSetResponseCode(oError.response.status));
            }
            dispatch(actionMessage(oError));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
};

/**
 * Funcion que registra un  cliente en el sistema.
 * 
 * @param {object} oData objeto cliente
 * 
 * @author 
 */

export const actionUpdateRelation = (oData) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        UpdateRelation(oData).then(oSuccess => {
            dispatch(actionMessage("Actualizado correctamente",CONSTANTS_MESSAGE.TYPE_SUCCESS ));
            dispatch(actionGetRelations(oData.client_id)); //enviar a listado de interrupciones
            dispatch(actionDispatch(CONSTANTS_ACTIONS.UPDATE_RELATION));
        }).catch((oError) => {
            if (oError.response){
                dispatch(actionSetResponseCode(oError.response.status));
            }
            dispatch(actionMessage(oError));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
};


export const actionResetRelations = () => ({
    type: CONSTANTS_ACTIONS.RESET_RELATIONS
});
