import { CONSTANTS_ACTIONS } from "constants/ConstantsActions";
import { CONSTANTS_MESSAGE } from "constants/ConstantsMessages";
import {
  actionActiveLoading,
  actionDesactiveLoading,
  actionDispatch,
  actionMessage,
  actionNavigateToBilling,
  actionSetResponseCode,
} from "redux/actions/SharedActions";
import { GetClientSinglePagination } from "redux/services/ClientServices";
import {
  UpdateClientPayments,
  ImportFile,
  ProcessPayments,
  ProcessPaymentService,
  ProgressBarService,
  CancelPayment,
} from "redux/services/PaymentServices";
import { actionProgressBarProgress } from "redux/actions/ProgressBarActions";
import { actionGetFeesByClientAndRange } from "./FeesActions";
import { actionGetReceipts } from "./ReceiptsActions";

export const actionUpdateClientPayments = (oData) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    UpdateClientPayments(oData).then((oSuccess) => {
      dispatch(actionMessage(oSuccess.message, CONSTANTS_MESSAGE.TYPE_SUCCESS));
      dispatch(actionDispatch(CONSTANTS_ACTIONS.UPDATE_CLIENTPAYMENTS));
      dispatch(actionNavigateToBilling());
    }).catch((oError) => {
      let sMessage = (oError && oError.response && oError.response.data) ? oError.response.data.message : CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR;
      dispatch(actionMessage(sMessage));
    });
  };
};
/**
 * Funcion que importa un  archivo de pago.
 *
 * @param {object} oData objeto cliente
 *
 * @author
 */

export const actionImportFile = (oData, paymethod) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    ImportFile(oData, paymethod).then((oSuccess) => {
      dispatch(actionDispatch(CONSTANTS_ACTIONS.PAYMENT_IMPORT, oSuccess.data));
      dispatch(actionMessage(oSuccess.message, CONSTANTS_MESSAGE.TYPE_SUCCESS));
    }).catch((oError) => {
      let sMessage = (oError && oError.response && oError.response.data) ? oError.response.data.message : CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR;
      dispatch(actionMessage(sMessage));
    }).finally(() => {
      dispatch(actionDesactiveLoading());
    });
  };
};

export const actionGetClientSinglePagination = (nIdClient, nItemIndex) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    GetClientSinglePagination(nIdClient, nItemIndex)
      .then((oSuccess) => {
        dispatch(
          actionDispatch(
            CONSTANTS_ACTIONS.CLIENTS_SINGLE_PAGINATION,
            oSuccess.data
          )
        );
      })
      .catch((oError) => {
        if (oError.response) {
          dispatch(actionSetResponseCode(oError.response.status));
        }
        dispatch(actionDispatch(CONSTANTS_ACTIONS.CLIENTS, []));
        dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
      })
      .finally(() => {
        dispatch(actionDesactiveLoading());
      });
  };
};

export const actionCleanClientDetails = () => {
  return (dispatch) => {
    dispatch(actionDispatch(CONSTANTS_ACTIONS.PAYMENT_DETAILS, null));
  };
};

export const actionProcessPayments = (oPayment, paymethod, nIdClient = 0) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    ProcessPayments(oPayment, paymethod)
      .then(() => {
        dispatch(actionGetClientSinglePagination(nIdClient, null));
        dispatch(actionGetFeesByClientAndRange(nIdClient, null));
        dispatch(actionMessage("Pago procesado correctamente", CONSTANTS_MESSAGE.TYPE_SUCCESS, true));
      })
      .catch((oError) => {
        if (oError.response)
          dispatch(actionSetResponseCode(oError.response.status));
        dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
      });
  };
};
/**
 * Función encargada de procesar un unico pago de las importaciones de pago
 *
 * @param {object} oPayment Arreglos de pagos a procesar
 * @param {string} paymethod Método de pago
 * @param {number} nCountPayment Contador de pagos procesados
 *
 * @author Leandro Curbelo
 */
export const actionProcessPayment = (oPayment, paymethod, nCountPayment, nPaymentErrors) => {
  return (dispatch) => {
    ProcessPayments(oPayment, paymethod).then((oResult) => {
      dispatch(actionProgressBarProgress(++nCountPayment));
      dispatch(actionDispatch(CONSTANTS_ACTIONS.PAYMENT_INCREMENT_ITERATION, nCountPayment));
    }).catch((oError) => {
      dispatch(actionPaymentErrorsCount(++nPaymentErrors));
      dispatch(actionPaymentError(oPayment));
      dispatch(actionProgressBarProgress(++nCountPayment));
      dispatch(actionDispatch(CONSTANTS_ACTIONS.PAYMENT_INCREMENT_ITERATION, nCountPayment));
    });
  };
};
/**
 * Función encargada de generar una nota de credito y anular un pago
 *
 * @param {number} nPaymentId Identificador del pago que se desea cancelar
 * @param {string} sReason Razón por el cual se rechaza la factura asociada al pago
 *
 * @author Leandro Curbelo
 */
export const actionCancelPayment = (nPaymentId, sReason, nIdClient) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    CancelPayment(nPaymentId, sReason).then(oSuccess => {
      dispatch(actionGetClientSinglePagination(nIdClient, null));
      dispatch(actionGetFeesByClientAndRange(nIdClient, null));
      dispatch(actionMessage(oSuccess.message, CONSTANTS_MESSAGE.TYPE_SUCCESS));
    }).catch(oError => {
      let sMessage = oError.response.data ? oError.response.data.message : null;
      dispatch(actionMessage(sMessage));
    }).finally(() => dispatch(actionDesactiveLoading()));
  };
};

/**
 * Función que limpia las variables del proceso de los pagos
 */
export const actionCleanProcessPayment = () => {
  return (dispatch) => {
    dispatch(
      actionDispatch(CONSTANTS_ACTIONS.PAYMENT_INCREMENT_ITERATION_CLEAN)
    );
  };
};

export const actionProcessPaymentService = (oPayment, oReceipt) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    ProcessPaymentService(oPayment, oReceipt).then((oSuccess) => {
      dispatch(actionGetReceipts(oPayment.client_id));
      dispatch(actionMessage(oSuccess.message, CONSTANTS_MESSAGE.TYPE_SUCCESS));
    }).catch((oError) => {
      if (oError.response) {
        dispatch(actionSetResponseCode(oError.response.status));
      }
      let sMessage = (oError && oError.response && oError.response.data) ? oError.response.data.message : CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR;
      dispatch(actionMessage(sMessage));
    });
  };
};

export const actionProgressBarService = (idx) => {
  return (dispatch) => {
    ProgressBarService()
      .then((oSuccess) => { })
      .catch((oError) => {
        if (oError.response) {
          dispatch(actionSetResponseCode(oError.response.status));
        }
        dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
      });
  };
};
/**
 * Función que limpia los datos del reducer de pagos
 * 
 * @author Leandro Curbelo
 */
export const actionClean = () => {
  return (dispatch) => {
    dispatch(actionDispatch(CONSTANTS_ACTIONS.PAYMENT_CLEAN));
  }
}

/**
 * 
 * @author Juan Andres Vega 
 * @param {*} nCount Cantidad de pagos con error
 */
export const actionPaymentErrorsCount = (nCount) => ({
  type: CONSTANTS_ACTIONS.PAYMENT_INCREMENT_ITERATION_ERRORS,
  nCount: nCount
});

/**
 * 
 * @author Juan Andres Vega 
 * @param {*} oPaymentError Pago con error
 */
export const actionPaymentError = (oPaymentError) => ({
  type: CONSTANTS_ACTIONS.PAYMENT_ERRORS,
  oPayment: oPaymentError
});

export const actionCleanPaymentsErrorCount = () => {
  return (dispatch) => {
    dispatch(actionDispatch(CONSTANTS_ACTIONS.PAYMENT_INCREMENT_ITERATION_ERRORS_CLEAN));
  }
}

export const actionCleanPaymentsError = () => {
  return (dispatch) => {
    dispatch(actionDispatch(CONSTANTS_ACTIONS.PAYMENT_ERRORS_CLEAN));
  }
}