import { CONSTANTS } from 'constants/Constants';
import oAxiosInstance from 'redux/interceptors/Interceptors';

const USERS_URL = CONSTANTS.API_URL + 'users';

/**
 * Funcion para tomar todos los useres del sistema
 * 
 * @param {number} nPage Numero de pagina que se intenta obtener
 * @param {number} nUsersPerPage Numero de useres por paginas
 * 
 * @author Leandro Curbelo
 */
export const GetUsers = (nPage = 0, nUsersPerPage = 10, sSearch ='') => {
    return oAxiosInstance.get(USERS_URL,{
        params: {
         'skip': nPage,
         'take': nUsersPerPage,
         'search': sSearch 
        }
        }).then(oResult => oResult)
}


export const GetUsersPerDate = (nPage = 0, nUsersPerPage = 10,sSearch = "",sFilterDate="",dStartDate=null,dEndDate=null) => {
    return oAxiosInstance.get(USERS_URL,{
        params: {
         'skip'         : nPage,
         'take'         : nUsersPerPage,
         'search'       : sSearch,
         'filterDate'   : sFilterDate,
         'startDate'    : dStartDate,
         'endDate'      : dEndDate,

        }
        }).then(oResult => oResult)
}


export const GetUser = (nUserId) => {
    return oAxiosInstance.get(USERS_URL+'/'+nUserId).then(oResult => oResult.data)
}

export const SaveUser = (oData) => {
    return oAxiosInstance.post(USERS_URL,oData).then(oResult => oResult.data)
}

/**
 * 
 * @param {*} oData 
 */
export const UpdateUser = (oData) => {
    return oAxiosInstance.put(USERS_URL+'/'+oData.id,oData).then(oResult => oResult.data)
}


export const DeleteUser = (nUserId) => {
    return oAxiosInstance.delete(USERS_URL+'/'+nUserId).then(oResult => oResult.data) 
}
