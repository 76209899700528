import React from 'react';
import { Switch, Route } from 'react-router-dom';

import LoginPage from 'pages/login/LoginPage';

import { UserProfile } from 'pages/UserProfile';
import ExamplesMessages from 'pages/docs/ExamplesMessages';
import { Clients } from 'pages/clients/Clients';
import ClientList from 'pages/clients/ClientList';
import ClientDetails from 'pages/clients/ClientDetails';
import ClientUpdate from 'pages/clients/ClientUpdate';
import { Billing } from 'pages/billing/Billing';
import BillingList from 'pages/billing/BillingList';
import BillingUpdate from 'pages/billing/BillingUpdate';
import PaymentReport from 'pages/billing/paymentReport';
import { Loans } from 'pages/loans/Loans';
import LoanList from 'pages/loans/LoanList';
import { DevicesShipped } from 'pages/devicesShipped/DevicesShipped';
import DevicesShippedList from 'pages/devicesShipped/DevicesShippedList';
import { Relations } from 'pages/relations/Relations';
import RelationList from 'pages/relations/RelationsList';
import { AdditionalServices } from 'pages/additionalServices/AdditionalServices';
import AdditionalServicesList from 'pages/additionalServices/AdditionalServicesLis';
import { Users } from 'pages/users/Users';
import UsersList from 'pages/users/UsersList';
import { Reports } from 'pages/reports/Reports';
import ReportDeaths from 'pages/reports/ReportDeaths';
import ReportDebtors from 'pages/reports/ReportDebtors';
import ReportBirthday from 'pages/reports/ReportBirthday';
import ReportNewRevenue from 'pages/reports/ReportNewRevenue';
import ReportUnsubscribeRequest from 'pages/reports/ReportUnsubscribeRequest';
import ReportBatteryChange from 'pages/reports/ReportBatteryChange';
import ReportTimeWithoutCalling from 'pages/reports/ReportTimeWithoutCalling';
import ReportLoan from 'pages/reports/ReportLoan';
import { Emergency } from 'pages/emergencies/Emergency';
import EmergencyList from 'pages/emergencies/EmergencyList';
import { Society } from 'pages/societies/Society';
import SocietyList from 'pages/societies/SocietyList';
import CallsBoard from 'pages/callsBoard/CallsBoard';
import InCall from 'pages/callsBoard/InCall';
import ReportDevicesShipped from 'pages/reports/ReportDevicesShipped';

// Some folks find value in a centralized route config.
// A route config is just data. React is great at mapping
// data into components, and <Route> is a component.

// Our route config is just an array of logical "routes"
// with `path` and `component` props, ordered the same
// way you'd do inside a `<Switch>`.
const routes = [

  {
    path: "/login",
    exact: true,
    component: LoginPage
  },
  {
    path: "/profile",
    component: UserProfile
  },
  {
    path: "/clients",
    component: Clients,
    routes: [
      {
        path: "/clients/create",
        exact: true,
        component: ClientUpdate
      },
      {
        path: "/clients/edit/:id",
        exact: true,
        component: ClientUpdate
      },
      {
        path: "/clients/callsboard/:nClientId",
        exact: true,
        component: CallsBoard
      },
      {
        path: "/clients/details",
        exact: true,
        component: ClientDetails
      },
      {
        path: "/clients",
        component: ClientList,
      }
    ]
  },
  {
    path: "/billing",
    component: Billing,
    routes: [
      {
        path: "/billing/edit/:id",
        exact: true,
        component: BillingUpdate
      },
      {
        path: "/billing/paymentreport/:paymethod",
        exact: true,
        component: PaymentReport
      },
      {
        path: "/billing",
        component: BillingList
      },
    ]
  },
  {
    path: "/loans",
    component: Loans,
    routes: [
      {
        path: "/loans/:nClientId",
        component: LoanList
      },
    ]
  },
  {
    path: "/devicesShipped",
    component: DevicesShipped,
    routes: [
      {
        path: "/devicesShipped",
        component: DevicesShippedList
      },
    ]
  },
  {
    path: "/relations",
    component: Relations,
    routes: [
      {
        path: "/relations",
        component: RelationList
      },
    ]
  },
  {
    path: "/additionalServices",
    component: AdditionalServices,
    routes: [
      {
        path: "/additionalServices",
        component: AdditionalServicesList
      },
    ]
  },
  {
    path: "/users",
    component: Users,
    routes: [
      {
        path: "/users",
        component: UsersList
      },
    ]
  },
  {
    path: "/reports",
    component: Reports,
    routes: [
      {
        path: "/reports/deaths",
        exact: true,
        component: ReportDeaths
      },
      {
        path: "/reports/debtors",
        exact: true,
        component: ReportDebtors
      },
      {
        path: "/reports/birthday",
        exact: true,
        component: ReportBirthday
      },
      {
        path: "/reports/birthday",
        exact: true,
        component: ReportBirthday
      },
      {
        path: "/reports/new-revenue",
        exact: true,
        component: ReportNewRevenue
      },
      {
        path: "/reports/unsubscribe-request",
        exact: true,
        component: ReportUnsubscribeRequest
      },
      {
        path: "/reports/battery-change",
        exact: true,
        component: ReportBatteryChange
      },
      {
        path: "/reports/time-without-calling",
        exact: true,
        component: ReportTimeWithoutCalling
      },
      {
        path: "/reports/loan",
        exact: true,
        component: ReportLoan
      },
      {
        path: "/reports/loan/:partner",
        exact: true,
        component: ReportLoan
      },
      {
        path: "/reports/shipped",
        exact: true,
        component: ReportDevicesShipped
      },
    ]
  },
  {
    path: "/emergencies",
    component: Emergency,
    routes: [
      {
        path: "/emergencies",
        exact: true,
        component: EmergencyList
      }
    ]
  },
  {
    path: "/societies",
    component: Society,
    routes: [
      {
        path: "/societies",
        exact: true,
        component: SocietyList
      }
    ]
  },
  {
    path: "/docs",
    component: ExamplesMessages,
  },
  {
    path: "/board",
    exact: true,
    component: CallsBoard,
  },
  {
    path: "/board/:id",
    exact: true,
    component: InCall,
  },
  {
    path: "/",
    exact: true,
    component: LoginPage
  },
];

export function RouteConfig() {
  return (
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Switch>
  );
}

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
export function RouteWithSubRoutes(route) {
  
  return (
    <Route
      path={route.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}