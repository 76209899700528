import React from 'react';
import { Icon, Tooltip } from "tabler-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


// # Function para detectar dispositivos mobiles
var isMobile = {
  Android: function() {
      return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function() {
      return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function() {
      return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function() {
      return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
  },
  any: function() {
      return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
  }
};

export function TableAction(props) {
  let { iconName, tooltip, onClick, bFontAwesome = false } = props;
  if(isMobile.any()){
    return (
        <div className="icon ml-3 icon-action cursor-pointer" onClick={onClick}>
          {
            !bFontAwesome ?
              <Icon name={iconName} /> :
              <FontAwesomeIcon icon={iconName}></FontAwesomeIcon>
          }
        </div>
    );
  }
  if(!isMobile.any()){
    return (
      <Tooltip content={tooltip} placement="left">
        <div className="icon ml-3 icon-action cursor-pointer" onClick={onClick}>
          {
            !bFontAwesome ?
              <Icon name={iconName} /> :
              <FontAwesomeIcon icon={iconName}></FontAwesomeIcon>
          }
        </div>
      </Tooltip>
    );
  }
}