import React, { Component } from "react";
import { Card, Form, Table, Alert, Grid, Button, Tooltip } from "tabler-react";
import { connect } from "react-redux";
import { actionGetClient } from "redux/actions/ClientsActions";
import {
  actionGetBatteryChanges,
  actionCleanReports,
} from "redux/actions/ReportsActions";
import { Pagination } from "components/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { CONSTANTS } from "constants/Constants";
import TableColAddress from "components/table/TableColAddress";
import { Helpers } from "helpers/Helpers";

class ReportBatteryChange extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nAllRecords: this.props.nAllRecords,
      nPage: 0,
      search: "",
      currentPage: 1,
      nClientsPerPage: 10,
      nclientId: null,
      sDevice: "bracelet",
      nMonth: parseInt(Helpers.ConvertTZ(new Date()).getMonth()) + 1,
      nYear: Helpers.ConvertTZ(new Date()).getFullYear(),
      clients: [],
    };
  }
  componentDidMount = () => {
    this.getYears();
    this.get();
  };
  componentWillUnmount = () => {
    this.props.cleanReports();
  };

  get = async () => {
    await this.props.get(
      this.state.nPage,
      this.state.nClientsPerPage,
      this.state.search,
      "changebattery" + this.state.sDevice,
      this.state.nYear + "-" + this.state.nMonth + "-01",
      this.state.nYear + "-" + this.state.nMonth + "-31"
    );
  };

  convertDate(sDate) {
    let aDate = sDate.split("-");
    let year = aDate[2];
    let month = aDate[1] - 1;
    let day = aDate[0];
    return Helpers.ConvertTZ(new Date(year, month, day));
  }

  getYears = () => {
    let date = Helpers.ConvertTZ(new Date());
    let actualYear = date.getFullYear();
    let actualMonth = date.getMonth() + 1;
    if (this.state.nMonth == null) {
      this.setState({ nYear: actualYear, nMonth: actualMonth });
    }
    let yearsList = [];
    for (let i = actualYear; i > actualYear - 5; i--) {
      yearsList.push(i);
    }
    return yearsList;
  };

  _handleSearchChange = (event) => {
    let { value } = event.target;
    this.setState({ search: value, currentPag: 1 });
  };

  _handleChangeMonth = async (event) => {
    let { value } = event.target;
    await this.setState({ currentPage: 1, nMonth: value });
    this.get();
  };

  _handleChangeDevice = async (event) => {
    let { value } = event.target;
    await this.setState({ currentPage: 1, sDevice: value });
    this.get();
  };

  _handleChangeYear = async (event) => {
    let { value } = event.target;
    await this.setState({ currentPage: 1, nYear: value });
    this.get();
  };

  _handleexportExcel = () => {
    window.open(
      CONSTANTS.API_URL.slice(0, -1) +
      "/reports?filterDate=changebattery" +
      this.state.sDevice +
      "&startDate=" +
      this.state.nYear +
      "-" +
      this.state.nMonth +
      "-01&endDate=" +
      this.state.nYear +
      "-" +
      this.state.nMonth +
      "-31",
      "_blank"
    );
  };

  _handlechangeCount = async (event) => {
    let { value } = event.target;
    await this.setState({ nClientsPerPage: value, nPage: 0, currentPage: 1 });
    this.get();
  };

  _handleNextPage = async () => {
    let nPage =
      parseInt(this.state.currentPage) * parseInt(this.state.nClientsPerPage);
    this.setState({ nPage });
    await this.setState({ currentPage: this.state.currentPage + 1 });
    this.get();
  };

  _handlePreviousPage = async () => {
    await this.setState({ currentPage: this.state.currentPage - 2 });
    let nPage;
    if (this.state.currentPag <= 1) {
      nPage = 0;
    } else {
      nPage =
        parseInt(this.state.currentPage) * parseInt(this.state.nClientsPerPage);
    }
    this.setState({ nPage });
    this.setState({ currentPage: this.state.currentPage + 1 });
    this.get();
  };

  _handleGoToPage = async (nPage) => {
    await this.setState({ currentPage: nPage });
    let nSkip;
    if (this.state.currentPag >= 1) {
      nSkip = 0;
    } else {
      nSkip = (nPage - 1) * parseInt(this.state.nClientsPerPage);
    }
    this.setState({ nPage: nSkip });

    this.get();
  };

  renderTable = () => {
    return this.props.oBatteryChange.aBatteryChanges.data.map(
      (oClient, index) => {
        return (
          <Table.Row key={"client" + oClient.id}>
            <Table.Col className="cursor-pointer">
              <span
                onClick={(event) =>
                  this.goToView(
                    event,
                    oClient.id,
                    "/clients/edit/" + oClient.id
                  )
                }
              >
                {oClient.fullname}
              </span>
            </Table.Col>
            <Table.Col>
              <Tooltip content={oClient.principal_phone} placement="bottom">
                <span>
                  {oClient.principal_phone &&
                    oClient.principal_phone.length > 15
                    ? oClient.principal_phone.substring(0, 15) + "..."
                    : oClient.principal_phone}
                </span>
              </Tooltip>
            </Table.Col>
            <TableColAddress address={oClient.principal_address} />
            <Table.Col className="text-center">
              {oClient.change_battery_bracelet !== null ||
                oClient.change_battery_bracelet !== undefined
                ? oClient.change_battery_bracelet.split("-").reverse().join("/")
                : ""}
            </Table.Col>
            <Table.Col className="text-center">
              {oClient.change_battery}
            </Table.Col>
            <Table.Col className="text-center">{oClient.show_device}</Table.Col>
          </Table.Row>
        );
      }
    );
  };

  goToView = (event, nClientId, sRoute) => {
    event.stopPropagation();
    this.props.getClient(nClientId);
    this.props.history.push(sRoute);
  };

  render = () => {
    const { aBatteryChanges } = this.props.oBatteryChange;
    const { nClientsPerPage } = this.state;

    return (
      <Card>
        <Card.Header>
          <Card.Title className="mr-auto">Cambios de Batería</Card.Title>
          <div className="card-filters mr-2">
            <Form.Select name="type" onChange={this._handleChangeDevice}>
              <option value="bracelet">Pulsera</option>
              <option value="device">Equipo</option>
            </Form.Select>
          </div>
          <div className="card-filters mr-2"></div>
          <div className="card-filters">
            <Form.Select
              name="type"
              onChange={this._handleChangeYear}
              value={this.state.nYear}
            >
              {this.getYears().map((oMonth, index) => {
                return (
                  <option key={index} value={oMonth}>
                    {oMonth}
                  </option>
                );
              })}
            </Form.Select>
          </div>
          <Button
            color="primary"
            className="ml-2"
            onClick={this._handleexportExcel}
          >
            <FontAwesomeIcon
              icon={faFileExcel}
              className="mr-2"
            ></FontAwesomeIcon>
            Exportar a excel
          </Button>
        </Card.Header>
        {aBatteryChanges.data.length > 0 ? (
          <>
            <div className="table-responsive">
              <Table className="card-table table-vcenter table-sm table-hover text-nowrap">
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Apellido y Nombre</Table.ColHeader>
                    <Table.ColHeader>Teléfono</Table.ColHeader>
                    <Table.ColHeader>Domicilio</Table.ColHeader>
                    <Table.ColHeader alignContent="center">
                      F. de cambio Pulsera
                    </Table.ColHeader>
                    <Table.ColHeader alignContent="center">
                      Año del aparato
                    </Table.ColHeader>
                    <Table.ColHeader className="text-center">
                      Tipo de aparato
                    </Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{this.renderTable()}</Table.Body>
              </Table>
            </div>
            <Card.Footer>
              <Pagination
                nextPageAction={this._handleNextPage}
                previousPageAction={this._handlePreviousPage}
                goToPageAction={this._handleGoToPage}
                currentPage={this.state.currentPage}
                allRecords={aBatteryChanges.allRecords}
                showing={Array.isArray(aBatteryChanges.data) ? aBatteryChanges.data.length : 0}
                nLastPage={Math.ceil(
                  parseInt(aBatteryChanges.allRecords) /
                  parseInt(nClientsPerPage)
                )}
                showAllOptions={true}
                _handlechangeCount={this._handlechangeCount}
              />
            </Card.Footer>
          </>
        ) : (
          <Card.Body>
            <Grid.Row>
              <Grid.Col>
                <Alert type="info" icon="info">
                  No hemos obtenido datos de clientes
                </Alert>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        )}
      </Card>
    );
  };
}

const mapStateToProps = (state) => ({
  oBatteryChange: state.reportsReducers,
});

const mapDispatchToProps = (dispatch) => ({
  get: (nPage, nClientsPerPage, sSearch, filterDate, startDate, endDate) => {
    dispatch(
      actionGetBatteryChanges(
        nPage,
        nClientsPerPage,
        sSearch,
        filterDate,
        startDate,
        endDate
      )
    );
  },
  getClient: (idClient) => {
    dispatch(actionGetClient(idClient));
  },
  cleanReports: () => {
    dispatch(actionCleanReports());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportBatteryChange);
