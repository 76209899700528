import React, { Component } from "react";
import { connect } from "react-redux";
import { actionClean, actionCleanProcessPayment, actionProcessPayment } from "redux/actions/PaymentsActions";
import { Card, Table, Alert, Grid, Button, Icon, Tag } from "tabler-react";
import { TableAction } from "components/buttons/TableAction";
import { actionImportFile, actionProgressBarService, actionCleanPaymentsError, actionCleanPaymentsErrorCount } from "../../redux/actions/PaymentsActions";
import {
  actionShowProgressBar,
  actionHideProgressBar,
  actionProgressBarSetTotal,
} from "../../redux/actions/ProgressBarActions";
import { CancelButton } from "components/buttons/CancelButton";
import Modal from "components/Modal";
import ProgressBar from "components/ProgressBar";
import { faTimes } from '@fortawesome/free-solid-svg-icons';

class PaymentReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymethod: this.props.match.params.paymethod,
      isModalConfirmPayment: false,
      isModalConfirmPaymentDuplicate: false,
      oPaymentDuplicate: {},
      isModalBillingProgress: false,
      isModalPaymentsErrors: false,
      nCompleted: 0,
      aPayments: [],
    };
  }

  componentDidMount = () => {
    this.props.hideProgressBar();
  };

  componentWillUnmount = () => {
    this.props.clean();
    this.props.cleanPaymentsErrorCount();
    this.props.cleanPaymentsError();
  }

  componentDidUpdate = (oLastProps) => {
    if (this.state.aPayments.length > 0 && this.props.propsPayment.nPaymentCount !== 0 && this.props.propsPayment.nPaymentCount !== oLastProps.propsPayment.nPaymentCount) {
      if (this.props.propsPayment.nPaymentCount < this.state.aPayments.length)
        this.props.processPayment(this.state.aPayments[this.props.propsPayment.nPaymentCount], this.state.paymethod.toLowerCase(), this.props.propsPayment.nPaymentCount, this.props.propsPayment.nPaymentErrors);
      else {
        this.props.cleanProcessPayment();
        this.setState({ aPayments: [] });
        if (this.props.propsPayment.nPaymentErrors > 0) {
          setTimeout(() => {
            this.props.hideProgressBar();
            this.setState({ isModalPaymentsErrors: true });
          }, 2000)
        } else {
          setTimeout(() => {
            this.goToBilling('/billing');
          }, 1200);
        }
      }
    }
  };

  handleClosePaymentsErrors = () => {
    setTimeout(() => {
      this.goToBilling('/billing');
    }, 1200);
  }

  getNumberByPaymentStatus = (status) => {
    switch (status[0]) {
      case 'N':
        return 0;
      case 'F':
        return 1;
      case 'D':
        return 2;
      case 'P':
        return 3;
    }
  }

  sortPayments = (aPayments) => {
    return aPayments.sort((a, b) => {
      const nStatusA = this.getNumberByPaymentStatus(a.status);
      const nStatusB = this.getNumberByPaymentStatus(b.status);
      return nStatusA - nStatusB;
    })
  }

  renderPaymentsError = () => {
    const { nPaymentErrors, aPaymentsError } = this.props.propsPayment;
    return (
      <Modal
        isOpen={this.state.isModalPaymentsErrors}
        modalHideAction={true}
        handleCloseModal={this.handleClosePaymentsErrors}
        modalCancelFaIcon={faTimes}
        modalCancelCaption={"Cerrar"}
        modalTitle={`Errores en pagos - ${nPaymentErrors} pagos con error`}
        customStyles={{
          width: "600px",
          maxHeight: "600px",
          overflowY: "scroll"
        }}
      >
        <Grid.Row className="system-body">
          <Grid.Col width={12}>
            <Table className="card-table table-vcenter table-sm table-hover text-nowrap">
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader className="text-center">Nro de Socio</Table.ColHeader>
                  <Table.ColHeader className="text-center">Nombre</Table.ColHeader>
                  <Table.ColHeader className="text-center">Monto ($)</Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  aPaymentsError.length > 0 && aPaymentsError.map((oPayment, nIndex) => {
                    return (
                      <Table.Row key={`oPaymentError${nIndex}`}>
                        <Table.Col className="text-center">{oPayment && oPayment.identificador ? oPayment.identificador : ""}</Table.Col>
                        <Table.Col className="text-center">{oPayment && oPayment.nombre ? oPayment.nombre : ""}</Table.Col>
                        <Table.Col className="text-center">{oPayment && oPayment.importe_abonado ? oPayment.importe_abonado : ""}</Table.Col>
                      </Table.Row>
                    );
                  })
                }
              </Table.Body>
            </Table>
          </Grid.Col>
        </Grid.Row>
      </Modal>
    );
  };

  renderConfirmPaymentModal = () => {
    return (
      <Modal
        isOpen={this.state.isModalConfirmPayment}
        handleActionModal={this.applyConfirmPayment}
        handleCloseModal={this._handleCloseConfirmPaymentModal}
        modalTitle="Confirmar"
        customStyles={{
          width: "550px",
        }}
      >
        <Grid.Row className="system-body">
          <Grid.Col width={12}>
            ¿Desea procesar los pagos de los clientes?
          </Grid.Col>
        </Grid.Row>
      </Modal>
    );
  };

  goToBilling = (sRoute) => {
    this.props.history.push(sRoute);
  };

  applyConfirmPayment = async () => {
    let aPayments = this.props.propsPayment.aDatosProcesados.filter((oPayment) => oPayment.status === "Procesar");
    let nTotal = aPayments.length;
    this.setState({ isModalConfirmPayment: false, aPayments: aPayments });
    this.props.progressBarSetTotal(nTotal);
    this.props.showBillingProgressBar();
    this.props.processPayment(aPayments[0], this.state.paymethod.toLowerCase(), 0);
  };

  _handleCloseConfirmPaymentModal = () => {
    this.setState({
      isModalConfirmPayment: false,
    });
  };
  loadConfirmPaymentModal = () => {
    this.setState({ isModalConfirmPayment: true });
  };
  renderConfirmPaymentDuplicate = () => {
    return (
      <Modal
        isOpen={this.state.isModalConfirmPaymentDuplicate}
        handleActionModal={this.applyConfirmPaymentDuplicate}
        handleCloseModal={this._handleCloseConfirmPaymentDuplicateModal}
        modalTitle="Confirmar"
        customStyles={{
          width: "550px",
        }}
      >
        <Grid.Row className="system-body">
          <Grid.Col width={12}>
            El cliente ya realizó el pago o no tiene cuota pendiente. Desea
            procesar el pago de todas formas?
          </Grid.Col>
        </Grid.Row>
      </Modal>
    );
  };
  applyConfirmPaymentDuplicate = async () => {
    let aDatosActualizados = this.props.propsPayment.aDatosProcesados.forEach((oDato) => {
      if (oDato.identificador === this.state.oPaymentDuplicate.identificador) {
        oDato.status = "Procesar";
        return oDato;
      }
    }
    );
    this.setState({
      aDatosProcesados: aDatosActualizados,
      oPaymentDuplicate: {},
      isModalConfirmPaymentDuplicate: false
    });
  };

  _handleCloseConfirmPaymentDuplicateModal = () => {
    this.setState({
      isModalConfirmPaymentDuplicate: false,
    });
  };
  loadConfirmPaymentDuplicate = (oPayment) => {
    this.setState({ oPaymentDuplicate: oPayment });
    this.setState({ isModalConfirmPaymentDuplicate: true });
  };

  handleCountPayments = () => {
    let nProcessed = 0, nDuplicates = 0, nErrors = 0;
    this.props.propsPayment.aDatosProcesados.forEach(oProcesado => {
      if (oProcesado["status"] === 'Procesar')
        nProcessed++;
      if (oProcesado["status"] === 'Duplicado')
        nDuplicates++;
      if (oProcesado["status"] === 'No encontrado' || oProcesado['status'] === "Formato de fecha inválido")
        nErrors++;
    });
    return { nProcessed, nDuplicates, nErrors, nTotals: this.props.propsPayment.aDatosProcesados.length };
  }

  renderTableHeader = () => {
    return (
      <>
        <Table.ColHeader className="text-center">Nro de Socio</Table.ColHeader>
        <Table.ColHeader className="text-center">Nombre</Table.ColHeader>
        <Table.ColHeader className="text-center">
          Valor de Cuota
        </Table.ColHeader>
        <Table.ColHeader className="text-center">Monto Pagado</Table.ColHeader>
        <Table.ColHeader className="text-center">Deuda</Table.ColHeader>
        <Table.ColHeader className="text-center">Fecha</Table.ColHeader>
        <Table.ColHeader className="text-center">Estado</Table.ColHeader>
        <Table.ColHeader className="text-center">Facturar</Table.ColHeader>
      </>
    );
  };
  renderTableBody = () => {
    return this.props.propsPayment.aDatosProcesados.map((oProcesado, nIndex) => {
      return (
        <>
          {
            <Table.Row key={oProcesado["identificador"] + nIndex}>
              <Table.Col className="text-center">
                {oProcesado["identificador"]}
              </Table.Col>
              <Table.Col className="text-center">
                {oProcesado["nombre"]}
              </Table.Col>
              <Table.Col className="text-center">
                {oProcesado["importe"]}
              </Table.Col>
              <Table.Col className="text-center">
                {oProcesado["importe_abonado"]}
              </Table.Col>
              <Table.Col className="text-center">
                {oProcesado["deuda_pendiente"]}
              </Table.Col>
              <Table.Col className="text-center">
                {oProcesado["fecha"]}
              </Table.Col>
              <Table.Col className="text-center">
                {oProcesado["status"] === "No encontrado" || oProcesado['status'] === "Formato de fecha inválido" ? (
                  <Tag color="red">{oProcesado["status"]}</Tag>
                ) : oProcesado["status"] === "Formato Invalido" ||
                  oProcesado["status"] === "Duplicado" ? (
                  <Tag color="orange">{oProcesado["status"]}</Tag>
                ) : oProcesado["status"] === "Procesar" ? (
                  <Tag color="green">{oProcesado["status"]}</Tag>
                ) : (
                  ""
                )}
              </Table.Col>
              <Table.Col className="text-center">
                {oProcesado["status"] === "No encontrado" || oProcesado['status'] === "Formato de fecha inválido" ? (
                  <TableAction iconName="x" tooltip="Cliente no encontrado" />
                ) : oProcesado["status"] === "Duplicado" ? (
                  <TableAction
                    iconName="check"
                    tooltip="Procesar"
                    onClick={() => this.loadConfirmPaymentDuplicate(oProcesado)}
                  />
                ) : oProcesado["status"] === "Procesar" ? (
                  <TableAction iconName="check" tooltip="Listo para procesar" />
                ) : (
                  ""
                )}
              </Table.Col>
            </Table.Row>
          }
        </>
      );
    });
  };
  render = () => {
    let { aDatosProcesados } = this.props.propsPayment;
    aDatosProcesados = this.sortPayments(aDatosProcesados);
    const { nProcessed, nDuplicates, nErrors, nTotals } = this.handleCountPayments();
    return (
      <Card>
        <Card.Header>
          <Card.Title className="mr-auto">
            Importación de pagos {this.state.paymethod}
          </Card.Title>
          {`${nProcessed} para facturar, ${nDuplicates} duplicados y ${nErrors} con error de ${nTotals} registros procesados en total.`}
          <CancelButton BackTo={"/billing"} />
          <Button
            className="btn btn-primary ml-3"
            type="button"
            color="primary"
            onClick={this.loadConfirmPaymentModal}
          >
            <Icon name="chevrons-up" className="mr-2" />
            Procesar Pagos
          </Button>
        </Card.Header>

        {Array.isArray(aDatosProcesados) && aDatosProcesados.length > 0 ? (
          <>
            <div className="table-responsive">
              <Table className="card-table table-vcenter table-sm table-hover text-nowrap">
                <Table.Header>
                  <Table.Row>{this.renderTableHeader()}</Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.paymethod.toLowerCase() === "redpagos" ||
                    this.state.paymethod.toLowerCase() === "abitab" ||
                    this.state.paymethod.toLowerCase() === "sistarbanc" ||
                    this.state.paymethod.toLowerCase() === "facilpago"
                    ? this.renderTableBody()
                    : ""}
                </Table.Body>
              </Table>
            </div>
          </>
        ) : (
          <Card.Body>
            <Grid.Row>
              <Grid.Col>
                <Alert type="info" icon="info">
                  No hemos obtenido datos de clientes
                </Alert>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        )}
        {this.renderConfirmPaymentModal()}
        {this.renderConfirmPaymentDuplicate()}
        {this.renderPaymentsError()}
        <ProgressBar />
      </Card>
    );
  };
}

const mapStateToProps = (state) => ({
  propsPayment: state.paymentsReducer,
  propsProgressBar: state.progressBarReducer
});

const mapDispatchToProps = (dispatch) => ({
  sendImportFile: (oPayment, paymethod) => {
    dispatch(actionImportFile(oPayment, paymethod));
  },
  processPayment: (oPayment, paymethod, nCount, nPaymentErrors = 0) => {
    dispatch(actionProcessPayment(oPayment, paymethod, nCount, nPaymentErrors));
  },
  cleanProcessPayment: () => {
    dispatch(actionCleanProcessPayment());
  },
  showBillingProgressBar: () => {
    dispatch(actionShowProgressBar());
  },
  hideProgressBar: () => {
    dispatch(actionHideProgressBar());
  },
  progressBarService: () => {
    dispatch(actionProgressBarService());
  },
  progressBarSetTotal: (nTotal) => {
    dispatch(actionProgressBarSetTotal(nTotal));
  },
  clean: () => {
    dispatch(actionClean());
  },
  cleanPaymentsError: () => {
    dispatch(actionCleanPaymentsError())
  },
  cleanPaymentsErrorCount: () => {
    dispatch(actionCleanPaymentsErrorCount())
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentReport);
