import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Form, Table, Alert, Grid, Icon } from 'tabler-react';
import { TableAction } from 'components/buttons/TableAction';
import { connect } from 'react-redux';
import Modal from 'components/Modal';
import { DATEPICKER } from 'constants/ConstantsComponents';
import { actionGetRelations, actionSaveRelation, actionUpdateRelation } from 'redux/actions/RelationsActions';
import Autosuggest from 'react-autosuggest';
import { actionGetMobileEmergencies } from 'redux/actions/MobileEmergenciesActions';
import { actionGetMedicalSocieties } from 'redux/actions/MedicalSocietiesActions';
import { actionGetStates } from 'redux/actions/StatesActions';
import { actionGetDevices } from 'redux/actions/DevicesActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { actionGetCities } from 'redux/actions/CitiesActions';
import { Helpers } from 'helpers/Helpers';
class RelationsList extends Component {
  constructor(props) {
    super(props);

    this.state = {

      oRelationEmpty: {
        id: null,
        identificationcard: '',
        name: '',
        secondname: '',
        lastname: '',
        secondsurname: '',
        email: '',
        affiliationdate: Helpers.ConvertTZ(new Date()),
        birthdate: Helpers.ConvertTZ(new Date()),
        sex: '',
        contacts: '',
        medicines: '',
        devicephone: '',
        observation: '',
        active: true,
        show_mobile_emergency: '',
        show_medical_society: '',
        addresses: [],
        client_id: props.propsClient.oClient.id,
        domicile: '',
        city_id: 1,
      },

      oRelation: {},
      isModalRelationOpen: false,

      lMobileEmergencySuggestions: [],
      oSelectedMobileEmergency: '',

      lMedicalSocietySuggestions: [],
      oSelectedMedicalSociety: '',

      departament: props.propsStates.aStates[0],
      city: props.propsCities.aCities[0],
      aCities: props.propsCities.aCities,
      address: "",
      zipcode: "",
      addressesList: [],
      email: "",
      emailsList: [],



    }
    this.props.getStates();
    this.props.getCities(1);

    this.props.getMobileEmergencies();
    this.props.getMedicalSocieties();
    //this.props.getRelations(this.props.nClientId); 
  }

  componentDidMount = () => {
    this.props.getRelations(this.props.nClientId);

    //this.props.getRelations(this.props.propsClient.oClient.id);
    this.setState({ oRelation: { ...this.state.oRelationEmpty } });
    this.getRelations();
  }

  //detectar cambis en el state
  componentDidUpdate = () => {

  }

  getRelations = () => {
    this.props.getRelations(this.props.nClientId);
  }



  _handleSaveRelation = () => {
    if (this.state.oRelation.id === null) {
      let oRelation = this.state.oRelation;
      oRelation.client_id = this.props.nClientId;
      this.setState({ oRelation });

      this.props.saveRelation(oRelation);
    }
    else {
      this.props.updateRelation(this.state.oRelation);
    }

    this.setState({
      oRelation: this.state.oRelationEmpty,
      isModalRelationOpen: false
    });
    this.getRelations();
  }


  _handleChangeState = (event) => {
    const { name, value } = event.target;
    const department = JSON.parse(value);
    this.setState({ department });
    this.props.getCities(department.id);
  };

  _handleChangeCity = (event) => {
    let { value } = event.target;
    const city = JSON.parse(value);
    this.setState({ city });
    let oRelation = this.state.oRelation;
    oRelation.city_id = city.id;
    this.setState({ oRelation });
  };


  _handleChangeAddress = (event) => {
    let { name, value } = event.target;
    this.setState({ address: value });
    let oRelation = this.state.oRelation;
    oRelation.domicile = value;
    this.setState({ oRelation });

  };

  _handleChangeZipCode = (event) => {
    let { name, value } = event.target;
    this.setState({ zipcode: value });
  };

  _handleAddAddress = async () => {
    if (!this.state.city || !this.state.department) {
      await this.setState({
        city: this.props.propsCities.aCities[0],
        department: this.props.propsStates.aStates[0],
      });
    }
    if (this.state.city !== {} && this.state.city !== undefined) {
      if (this.state.department.id !== null && this.state.city.id !== null) {
        let oRelation = this.state.oRelation;


        oRelation.addresses.push({
          city: {
            id: this.state.city.id,
            name: this.state.city.name,
            zipcode: this.state.city.zipcode,
            state: {
              id: this.state.department.id,
              name: this.state.department.name,
            },
          },
          domicile: this.state.address,
        }); /**/
        this.setState({ oRelation });
        this.setState({ address: "", zipcode: "" });
      }
    }
  };

  _handleRemoveAddress = (index) => {
    let oRelation = this.state.oRelation;
    oRelation.addresses.splice(index, 1);
    this.setState({ oRelation });
  };
  // =================================================================================================
  // ===================Autosuggest MobileEmergency functions=========================================
  // Use your imagination to render suggestions.
  renderMobileEmergencySuggestion = oMobileEmergencySuggestion => (
    <div className="suggestion-item">
      <span>{oMobileEmergencySuggestion.name}</span>
    </div>
  );
  // Funcion que se ejecuta cada vez que cambia el valor entrado en el input de emergencia movil
  onChangeMobileEmergency = (event, { newValue }) => {
    this.setState({
      oSelectedMobileEmergency: newValue
    });
    let oRelation = this.state.oRelation;
    oRelation.show_mobile_emergency = newValue;
    this.setState({ oRelation });
  };

  // Funcion que filtra la lista de emergencia movil y devuelve los sugeridos segun el valor introducido
  onMobileEmergencySuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const { aMobileEmergencies } = this.props.oMobileEmergencies; //destructuracion de objeto

    const lMobileEmergencySuggestions = inputLength === 0 ? [] : aMobileEmergencies.filter(oMobileEmergency =>
      oMobileEmergency.name.toLowerCase().slice(0, inputLength) === inputValue
    );
    this.setState({ lMobileEmergencySuggestions });
  };//

  // Autosuggest will call this function every time you need to clear suggestions.
  onMobileEmergencySuggestionsClearRequested = () => {
    this.setState({
      lMobileEmergencySuggestions: []
    });
  };
  // =================================================================================================
  // ====================Autosuggest MedicalSociety functions=========================================
  // Use your imagination to render suggestions.
  renderMedicalSocietySuggestion = oMedicalSocietySuggestion => (
    <div className="suggestion-item">
      <span>{oMedicalSocietySuggestion.name}</span>
    </div>
  )
  // Funcion que se ejecuta cada vez que cambia el valor entrado en el input de emergencia movil
  onChangeMedicalSociety = (event, { newValue }) => {
    this.setState({
      oSelectedMedicalSociety: newValue
    });
    let oRelation = this.state.oRelation;
    oRelation.show_medical_society = newValue;
    this.setState({ oRelation });
  };

  // Funcion que filtra la lista de emergencia movil y devuelve los sugeridos segun el valor introducido
  onMedicalSocietySuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const { aMedicalSocieties } = this.props.oMedicalSocieties; //destructuracion de objeto

    const lMedicalSocietySuggestions = inputLength === 0 ? [] : aMedicalSocieties.filter(oMedicalSociety =>
      oMedicalSociety.name.toLowerCase().slice(0, inputLength) === inputValue
    );
    this.setState({ lMedicalSocietySuggestions });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onMedicalSocietySuggestionsClearRequested = () => {
    this.setState({
      lMedicalSocietySuggestions: []
    });
  };
  // =================================================================================================
  // =================================================================================================







  renderTable = (aRelations) => {
    return (
      aRelations.map(oRelation => {
        return (
          <Table.Row key={oRelation.id}>
            <Table.Col>
              {oRelation.fullname}
            </Table.Col>
            <Table.Col>
              {oRelation.identificationcard}
            </Table.Col>
            <Table.Col>
              {oRelation.show_mobile_emergency}
            </Table.Col>
            <Table.Col>
              {oRelation.show_medical_society}
            </Table.Col>
            <Table.Col>
              {oRelation.age}
            </Table.Col>
            <Table.Col className='text-center isActive-indicator'>
              <Form.Checkbox className="d-inline"
                checked={oRelation.active}
                label={" "}
                isInline={false} />
            </Table.Col>
            <Table.Col className="text-center">
              <TableAction iconName="edit" tooltip="Editar" onClick={() => this._handleEditRelation(oRelation)} />
            </Table.Col>
          </Table.Row>
        )
      })
    )
  }

  _handleCheckedChange = (event) => {
    let { name, checked } = event.target;
    let oRelation = this.state.oRelation;
    oRelation[name] = checked;
    this.setState({ oRelation })
  }


  _handleEditRelation = async (relation) => {

    let oRelation = relation;

    let birthdate = relation.birthdate + "";
    let aBirthdate = birthdate.split('-');
    oRelation.birthdate = Helpers.ConvertTZ(new Date(aBirthdate[0], (parseInt(aBirthdate[1]) - 1), aBirthdate[2]));

    let affiliationdate = relation.affiliationdate + "";
    let aAffiliationdate = affiliationdate.split('-');
    oRelation.affiliationdate = Helpers.ConvertTZ(new Date(aAffiliationdate[0], (parseInt(aAffiliationdate[1]) - 1), aAffiliationdate[2]));

    oRelation.city_id = relation.city_id !== null ? relation.city_id.id : 1;
    oRelation.domicile = relation.domicile;
    this.setState({
      city: { id: relation.city_id.id, name: relation.city_id.name, zipcode: relation.city_id.zipcode, state: null },
      address: relation.domicile
    });
    await this.setState({
      oRelation: oRelation,
      isModalRelationOpen: true
    });
  }

  _handleChange = (event) => {
    const { name, value } = event.target;
    let oRelation = this.state.oRelation;
    oRelation[name] = value;
    this.setState({ oRelation });
  }

  _handleAffiliationdateChange = (selectedDate) => {
    let oRelation = this.state.oRelation;
    oRelation.affiliationdate = selectedDate;
    this.setState({ oRelation });
  }
  _handleBirthdateDateChange = (selectedDate) => {
    let oRelation = this.state.oRelation;
    oRelation.birthdate = selectedDate;
    this.setState({ oRelation });
  }

  renderRelationModal = () => {
    const { oRelation,
      lMobileEmergencySuggestions,
      oSelectedMobileEmergency,
      lMedicalSocietySuggestions,
      oSelectedMedicalSociety,
    } = this.state;
    const { aStates } = this.props.propsStates;
    const { aCities } = this.props.propsCities;

    return (
      this.state.isModalRelationOpen &&
      <Modal
        isOpen={this.state.isModalRelationOpen}
        handleActionModal={this._handleSaveRelation}
        handleCloseModal={this._handleCloseModal}
        modalTitle="Datos de Familiares"
        customStyles={{
          width: "850px"
        }}
      >
        <Grid.Row className="system-body">


          <Grid.Col width={12}>
            <Form.Group label="">
              <label className="custom-switch pt-2 mt-1">
                <input type="checkbox"
                  name="active"
                  className="custom-switch-input"
                  defaultChecked={oRelation.active}
                  value={oRelation.active}
                  onChange={this._handleCheckedChange} />
                <span className="custom-switch-indicator"></span>
                <span className="custom-switch-description font-weight-bold">Activo</span>
              </label>
            </Form.Group>
          </Grid.Col>







          <Grid.Col width={4}>

            <Grid.Col width={12}>
              <Form.Group label="Apellido">
                <Form.Input name='lastname' placeholder="Apellido" value={oRelation.lastname} onChange={this._handleChange} />
              </Form.Group>
            </Grid.Col>

            <Grid.Col width={12}>
              <Form.Group label="Segundo Apellido">
                <Form.Input name='secondsurname' placeholder="Segundo Apellido" value={oRelation.secondsurname} onChange={this._handleChange} />
              </Form.Group>
            </Grid.Col>

            <Grid.Col width={12}>
              <Form.Group label="Nombre">
                <Form.Input name='name' placeholder="Nombre" value={oRelation.name} onChange={this._handleChange} />
              </Form.Group>
            </Grid.Col>

            <Grid.Col width={12}>
              <Form.Group label="Segundo Nombre">
                <Form.Input name='secondname' placeholder="Segundo Nombre" value={oRelation.secondname} onChange={this._handleChange} />
              </Form.Group>
            </Grid.Col>

            <Grid.Col width={12}>
              <Form.Group label="Cédula">
                <Form.Input name='identificationcard' placeholder="Cedula Identidad" value={oRelation.identificationcard} onChange={this._handleChange} />
              </Form.Group>
            </Grid.Col>

          </Grid.Col>

          <Grid.Col width={4}>

            <Grid.Col width={12}>
              <Form.Group label="Sexo">
                <Form.Select
                  name="sex"
                  value={oRelation.sex}
                  onChange={this._handleChange}
                >
                  <option value="Mujer">Mujer</option>
                  <option value="Hombre">Hombre</option>
                </Form.Select>
              </Form.Group>
            </Grid.Col>

            <Grid.Col width={12}>
              <Form.Group label="F. Nacimiento">
                <Form.DatePicker
                  name='birthdate'
                  className={"datepicker"}
                  defaultDate={oRelation.birthdate}
                  monthLabels={DATEPICKER.MONTH}
                  onChange={this._handleBirthdateDateChange} />
              </Form.Group>
            </Grid.Col>

            <Grid.Col width={12}>
              <Form.Group label="F. Afiliación">
                <Form.DatePicker
                  name='affiliationdate'
                  className={"datepicker"}
                  defaultDate={oRelation.affiliationdate}
                  monthLabels={DATEPICKER.MONTH}
                  onChange={this._handleAffiliationdateChange} />
              </Form.Group>
            </Grid.Col>

            <Grid.Col width={12}>
              <Form.Group label="Emergencia móvil">
                <Autosuggest
                  suggestions={lMobileEmergencySuggestions}
                  onSuggestionsFetchRequested={this.onMobileEmergencySuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onMobileEmergencySuggestionsClearRequested}
                  getSuggestionValue={oMobileEmergencySuggestion => oMobileEmergencySuggestion.name}
                  renderSuggestion={this.renderMobileEmergencySuggestion}
                  inputProps={{
                    className: "form-control",
                    value: oSelectedMobileEmergency,
                    onChange: this.onChangeMobileEmergency
                  }}
                />
              </Form.Group>
            </Grid.Col>

            <Grid.Col width={12}>
              <Form.Group label="Sociedad médica">
                <Autosuggest
                  suggestions={lMedicalSocietySuggestions}
                  onSuggestionsFetchRequested={this.onMedicalSocietySuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onMedicalSocietySuggestionsClearRequested}
                  getSuggestionValue={oMedicalSocietySuggestion => oMedicalSocietySuggestion.name}
                  renderSuggestion={this.renderMedicalSocietySuggestion}
                  inputProps={{
                    className: "form-control",
                    value: oSelectedMedicalSociety,
                    onChange: this.onChangeMedicalSociety
                  }}
                />
              </Form.Group>
            </Grid.Col>

          </Grid.Col>

          <Grid.Col width={4}>
            <Grid.Col width={12}>
              <Form.Group label="Medicamentos">
                <Form.Textarea name='medicines'
                  placeholder='Medicamentos'
                  value={oRelation.medicines}
                  onChange={this._handleChange}
                  rows={3} />
              </Form.Group>
            </Grid.Col>

            <Grid.Col width={12}>
              <Form.Group label="Contactos">
                <Form.Textarea name='contacts'
                  placeholder='Contactos asociados a este familiar'
                  value={oRelation.contacts}
                  onChange={this._handleChange}
                  rows={3} />
              </Form.Group>
            </Grid.Col>

            <Grid.Col width={12}>
              <Form.Group label="Observaciones">
                <Form.Textarea name='observation'
                  placeholder='Observaciones, comentarios, informacion adicional'
                  value={oRelation.observation}
                  onChange={this._handleChange}
                  rows={3} />
              </Form.Group>
            </Grid.Col>

          </Grid.Col>

          <Grid.Col width={8}>
            <Grid.Col width={12}>
              <Form.Group label="Domicilio">
                <Form.InputGroup>
                  <Form.Input
                    name="address"
                    placeholder="Domicilio"
                    value={this.state.address}
                    onChange={this._handleChangeAddress}
                    className="field-address"
                  />
                  <Form.Select
                    name="cities"
                    className="col-3"
                    onChange={this._handleChangeCity}
                  >
                    {aCities.map((oCity) => {
                      return (
                        <option
                          key={"city" + oCity.id}
                          value={JSON.stringify(oCity)}
                        >
                          {oCity.name}
                        </option>
                      );
                    })}
                    {this._updateCity}
                  </Form.Select>
                  <Form.Select
                    name="states"
                    className="col-3"
                    onChange={this._handleChangeState}
                  >
                    {aStates.map((oState) => {
                      return (
                        <option
                          key={"state" + oState.id}
                          value={JSON.stringify(oState)}
                        >
                          {oState.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <Form.Input
                    name="zipcode"
                    placeholder="C. Postal"
                    value={this.state.zipcode}
                    onChange={this._handleChangeZipCode}
                    className="field-zipcode"
                  />
                  <Form.InputGroupAppend>
                    {/*<Button
                      RootComponent="button"
                      color="primary"
                      onClick={this._handleAddAddress}
                    >
                      <Icon name="plus" />
                    </Button>*/}
                  </Form.InputGroupAppend>
                </Form.InputGroup>
              </Form.Group>
              {/* Lista de Direcciones */}
              {Array.isArray(oRelation.addresses) && oRelation.addresses.length > 0
                ? oRelation.addresses.map((elem, index) => {
                  return (
                    <Form.Group
                      key={"address" + index}
                      className="mb-1"
                      width={12}
                    >
                      <Form.InputGroup>
                        <Form.InputGroupPrepend>
                          <Form.InputGroupText>
                            <FontAwesomeIcon
                              icon={faMapMarkerAlt}
                            ></FontAwesomeIcon>
                          </Form.InputGroupText>
                        </Form.InputGroupPrepend>
                        {
                          <Form.Input
                            disabled
                            value={
                              elem.domicile +
                              " " +
                              elem.city.name +
                              " " +
                              elem.city.state.name +
                              " " +
                              elem.city.zipcode
                            }
                          />
                        }
                        <Form.InputGroupAppend>
                          <Button
                            RootComponent="button"
                            color="danger"
                            onClick={() => this._handleRemoveAddress(index)}
                          >
                            <Icon name="trash" />
                          </Button>
                        </Form.InputGroupAppend>
                      </Form.InputGroup>
                    </Form.Group>
                  );
                })
                : ""}
            </Grid.Col>
          </Grid.Col>

        </Grid.Row>
      </Modal>
    )
  }

  _handleOpenRelationModal = async () => {
    await this.setState({
      oRelation: this.state.oRelationEmpty,
      isModalRelationOpen: true
    });

  }

  _handleSaveRelation = async () => {
    if (this.state.oRelation.id === null) {
      let oRelation = this.state.oRelation;
      oRelation.client_id = this.props.propsClient.oClient.id;
      await this.setState({ oRelation });
      this.props.saveRelation(this.state.oRelation);
    }
    else {
      this.props.updateRelation(this.state.oRelation);
    }


    this.getRelations();
    this.setState({
      oRelation: this.state.oRelationEmpty,
      isModalRelationOpen: false
    });
  }

  _handleCloseModal = () => {
    this.setState({
      oRelation: this.state.oRelationEmpty,
      isModalRelationOpen: false
    });
  }

  render() {
    const { oClient } = this.props.propsClient; //destructuracion de objeto
    const { aRelations } = this.props.oRelations;  //destructuracion de objeto

    return (
      <Card>
        <Card.Header>
          <Card.Title className="mr-auto">
            Listado Familiares
          </Card.Title>
          <Button className="btn btn-primary ml-3" onClick={() => this._handleOpenRelationModal(oClient)}>
            <Icon name="plus" className="mr-2" />
            Nuevo
          </Button>
          <Link className="btn btn-secondary ml-3" to={'/clients'}>
            <Icon name="corner-up-right" className="mr-2" />
            Volver
          </Link>
        </Card.Header>
        {aRelations.length > 0 ?
          <div className="table-responsive">
            <Table className="card-table table-vcenter table-sm table-hover text-nowrap">
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>
                    Apellido y Nombre
                  </Table.ColHeader>
                  <Table.ColHeader>
                    Cedula
                  </Table.ColHeader>
                  <Table.ColHeader>
                    Emergencia Movil
                  </Table.ColHeader>
                  <Table.ColHeader>
                    Sociedad Médica
                  </Table.ColHeader>
                  <Table.ColHeader>
                    Edad
                  </Table.ColHeader>
                  <Table.ColHeader className="text-center">
                    Activo
                  </Table.ColHeader>
                  <Table.ColHeader>
                  </Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  this.renderTable(aRelations)
                }
              </Table.Body>
            </Table>
          </div>
          :
          <Card.Body>
            <Grid.Row>
              <Grid.Col>
                <Alert type="info" icon="info">
                  No hemos obtenido datos de aparatos enviados para el cliente
                </Alert>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        }
        {
          this.renderRelationModal(oClient)
        }
      </Card>
    );
  }
}


const mapStateToProps = state => ({
  oRelations: state.relationsReducer,
  propsClient: state.clientsReducer,
  propsDevices: state.devicesReducer,
  oMobileEmergencies: state.mobileEmergenciesReducer,
  oMedicalSocieties: state.medicalSocietiesReducer,
  propsStates: state.statesReducer,
  propsCities: state.citiesReducer,
})

const mapDispatchToProps = dispatch => ({
  getRelations: (nClientId) => {
    dispatch(actionGetRelations(nClientId));
  },

  saveRelation: (oRelation) => {
    dispatch(actionSaveRelation(oRelation));
  },

  updateRelation: (oRelation) => {
    dispatch(actionUpdateRelation(oRelation));
  },
  getDevices: () => {
    dispatch(actionGetDevices());
  },
  getMobileEmergencies: () => {
    dispatch(actionGetMobileEmergencies());
  },
  getMedicalSocieties: () => {
    dispatch(actionGetMedicalSocieties());
  },

  getStates: () => {
    dispatch(actionGetStates());
  },
  getCities: (nStateId) => {
    dispatch(actionGetCities(nStateId));
  },

})

export default connect(mapStateToProps, mapDispatchToProps)(RelationsList);