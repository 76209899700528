import { createStore, combineReducers, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import oLocalStorage from "redux-persist/es/storage";
import thunk from "redux-thunk";
import { sharedReducer } from "redux/reducers/SharedReducers";
import { loginReducer } from "redux/reducers/LoginReducers";
import { sessionReducer } from "redux/reducers/SessionReducers";
import { clientsReducer } from "redux/reducers/ClientsReducers";
import { paymentsReducer } from "redux/reducers/PaymentReducers";
import { usersReducer } from "redux/reducers/UsersReducers";
import { statesReducer } from "redux/reducers/StatesReducers";
import { citiesReducer } from "redux/reducers/CitiesReducers";
import { voucherTypesReducer } from "redux/reducers/VoucherTypesReducers";
import { interruptionsReducer } from "redux/reducers/InterruptionsReducers";
import { receiptsReducer } from "redux/reducers/ReceiptsReducers";
import { loansReducer } from "redux/reducers/LoansReducers";
import { shipmentsReducer } from "redux/reducers/ShipmentsReducers";
import { relationsReducer } from "redux/reducers/RelationReducers";
import { devicesReducer } from "redux/reducers/DevicesReducers";
import { callsReducer } from "redux/reducers/CallsReducers";
import { notificationsReducer } from "redux/reducers/NotificationsReducers";
import { mobileEmergenciesReducer } from "redux/reducers/MobileEmergencyReducers";
import { medicalSocietiesReducer } from "redux/reducers/MedicalSocietyReducers";
import { feesReducer } from "redux/reducers/FeeReducers";
import { reportsReducers } from "redux/reducers/ReportsReducers";
import { progressBarReducer } from 'redux/reducers/ProgressBarReducer';

const oMetaData = require("meta.json");

const reducers = combineReducers({
  sharedReducer,
  loginReducer,
  sessionReducer,
  clientsReducer,
  paymentsReducer,
  notificationsReducer,
  usersReducer,
  statesReducer,
  citiesReducer,
  voucherTypesReducer,
  interruptionsReducer,
  receiptsReducer,
  loansReducer,
  shipmentsReducer,
  relationsReducer,
  devicesReducer,
  callsReducer,
  mobileEmergenciesReducer,
  medicalSocietiesReducer,
  feesReducer,
  reportsReducers,
  progressBarReducer,
});

const oPersistConfig = {
  key: oMetaData.commitHash,
  version: oMetaData.version,
  storage: oLocalStorage,
};

const persistedReducer = persistReducer(oPersistConfig, reducers);

const store = createStore(persistedReducer, applyMiddleware(thunk));

export default store;
export const persistor = persistStore(store);
