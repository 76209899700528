import { CONSTANTS_ACTIONS } from "constants/ConstantsActions";
import { CONSTANTS_PAGINATION } from "constants/ConstantsComponents";

const aInitialState = {
  nAllRecords: null,
  aDatosProcesados: [],
  aClients: {
    allRecords: 0,
    data: [],
  },
  aClientsFullList: [],

  oClient: {},
  bStatus: false,
  bEdit: false,
  nNavCurrentPage: CONSTANTS_PAGINATION.nCurrentPage,
  nNavClientsPerPage: CONSTANTS_PAGINATION.nItemsPerPage,
  clientIndex: 1,

  nPaymentCount: 0,
  nPaymentErrors: 0,
  aPaymentsError: []
};
/**
 * Reducer encargado de manejar todos los datos correspondiente a la parte de clientes.
 *
 * @param {Array} aState Estado del reducer, al momento de inicializarse se tomara aInitialState
 * @param {object} oAction Objeto donde se encuentra el tipo de estado que se desea actualizar y los respectivos datos
 *
 * @author Leandro Curbelo
 */
export const paymentsReducer = (aState = aInitialState, oAction) => {
  switch (oAction.type) {
    case CONSTANTS_ACTIONS.PAYMENT_DETAILS:
      return { ...aState, oClient: oAction.data, clientIndex: 1 };
    case CONSTANTS_ACTIONS.CLIENTS_SINGLE_PAGINATION:
      const oTmpData = oAction.data;
      return { ...aState, oClient: oTmpData.data, nAllRecords: oTmpData.allRecords, clientIndex: oTmpData.itemIndex };
    case CONSTANTS_ACTIONS.UPDATE_CLIENTPAYMENTS:
      return { ...aState, };
    case CONSTANTS_ACTIONS.PAYMENT_IMPORT:
      return { ...aState, aDatosProcesados: oAction.data, bStatus: true };
    case CONSTANTS_ACTIONS.PAYMENT_INCREMENT_ITERATION:
      return { ...aState, nPaymentCount: oAction.data };
    case CONSTANTS_ACTIONS.PAYMENT_INCREMENT_ITERATION_CLEAN:
      return { ...aState, nPaymentCount: 0 };
    case CONSTANTS_ACTIONS.PAYMENT_CLEAN:
      return { ...aState, aDatosProcesados: [] };
    case CONSTANTS_ACTIONS.PAYMENT_INCREMENT_ITERATION_ERRORS:
      return { ...aState, nPaymentErrors: oAction.nCount };
    case CONSTANTS_ACTIONS.PAYMENT_INCREMENT_ITERATION_ERRORS_CLEAN:
      return { ...aState, nPaymentErrors: 0 };
    case CONSTANTS_ACTIONS.PAYMENT_ERRORS:
      return { ...aState, aPaymentsError: [...aState.aPaymentsError, oAction.oPayment] };
    case CONSTANTS_ACTIONS.PAYMENT_ERRORS_CLEAN:
      return { ...aState, aPaymentsError: [] };
    default:
      return aState;
  }
};
