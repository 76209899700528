import { CONSTANTS_ACTIONS } from 'constants/ConstantsActions';

const aInitialState = {
    aShipments: [],
    oShipping: {},

}
/**
 * Reducer Mostrar las Ciudades y codigo de area por departamento.
 * 
 * @param {Array} aState Estado del reducer, al momento de inicializarse se tomara aInitialState
 * @param {object} oAction Objeto donde se encuentra el tipo de estado que se desea actualizar y los respectivos datos
 * 
 * @author Franko Zerpa
 */
export const shipmentsReducer = (aState = aInitialState, oAction) => {
    switch (oAction.type) {
        case CONSTANTS_ACTIONS.SHIPMENTS:
            return { ...aState, aShipments: oAction.data }
        case CONSTANTS_ACTIONS.SAVE_SHIPPING:
            return { ...aState, bStatus: true }
        case CONSTANTS_ACTIONS.UPDATE_SHIPPING:
            return { ...aState, bStatus: true }
        case CONSTANTS_ACTIONS.RESET_SHIPMENTS:
            return { ...aState, bStatus: false, aShipments: [] }
        case CONSTANTS_ACTIONS.DEVOLUTION_SHIPMENT:
            return { ...aState, bStatus: true }
        case CONSTANTS_ACTIONS.CLEAN_SHIPPMENTS:
            return { ...aState, aShipments: [] }
        default:
            return aState;
    }
}


