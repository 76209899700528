import React, { Component } from "react";
import { Page, Container, Grid, Card, Button, Form } from "tabler-react";
import logo from 'assets/images/logo_helpline.jpg';
import { connect } from "react-redux";
import { actionLogin } from "redux/actions/LoginActions";

class LoginPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sEmail: '',
      sPassword: '',
    };
  }

  componentDidMount = () => {
    if (this.props.oUserSession.bStatus) {
      this.props.history.push('/board');
    }
  }

  componentDidUpdate = () => {
    if (this.props.oUserSession.bStatus) {
      this.props.history.push('/board');
    }
  };


  login = () => {
    this.props.login(this.state.sEmail, this.state.sPassword);
  };

  accessKey = (event) => {
    if (event.charCode === 13 && this.state.sEmail !== '') {
      this.login();
    }
  };

  changeValue = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'sEmail':
        this.setState({ sEmail: value })
        break;
      case 'sPassword':
        this.setState({ sPassword: value })
        break;
      default:
        break;
    }
  };

  render() {
    const { sEmail } = this.state;
    const { sPassword } = this.state;
    return (
      <Page>
        <div className="page-single">
          <Container>
            <Grid.Row>
              <Grid.Col className="col-login mx-auto">
                <Card>
                  <Card.Body>
                    <div className="text-center mb-6">
                      <img src={logo} className="login-logo" alt="Logo" />
                    </div>
                    <hr />
                    <Form.Input type='text' name='sEmail' value={sEmail} label='Usuario' placeholder='Usuario' onChange={this.changeValue} />
                    <Form.Input type='password' name='sPassword' value={sPassword} label='Contraseña' placeholder='Contraseña' onChange={this.changeValue} onKeyPress={this.accessKey} />
                    <Button color="primary" className="btn-block" onClick={() => this.login()}>Entrar</Button>
                  </Card.Body>
                </Card>
              </Grid.Col>
            </Grid.Row>
          </Container>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  oUserSession: state.loginReducer,
})

const mapDispatchToProps = dispatch => ({
  login: (sEmail, sPassword) => {
    dispatch(actionLogin(sEmail, sPassword));
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);