import { CONSTANTS_ACTIONS } from "constants/ConstantsActions";
import { CONSTANTS_MESSAGE } from "constants/ConstantsMessages";
import {
  actionActiveLoading,
  actionDesactiveLoading,
  actionDispatch,
  actionMessage,
  actionSetResponseCode,
} from "redux/actions/SharedActions";
import {
  GetReceipts,
  SaveReceipt,
  UpdateReceipt,
  DeleteAdditionalService
} from "redux/services/ReceiptsServices";

/**
 * Funcion que retorna todo recibos de servicios de un cliente.
 *
 * @author Franko Zerpa
 */
export const actionGetReceipts = (nClientId) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    GetReceipts(nClientId).then((oSuccess) => {
      dispatch(actionDispatch(CONSTANTS_ACTIONS.RECEIPTS, oSuccess.data));
    }).catch((oError) => {
      if (oError.response)
        dispatch(actionSetResponseCode(oError.response.status));
      dispatch(actionDispatch(CONSTANTS_ACTIONS.RECEIPTS, []));
      dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
    }).finally(() => {
      dispatch(actionDesactiveLoading());
    });
  };
};

/**
 * Funcion que registra un  cliente en el sistema.
 *
 * @param {object} oData objeto cliente
 *
 * @author
 */

export const actionSaveReceipt = (oData) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    SaveReceipt(oData)
      .then((oSuccess) => {
        dispatch(
          actionMessage(
            "Guardado correctamente",
            CONSTANTS_MESSAGE.TYPE_SUCCESS
          )
        );
        dispatch(actionGetReceipts(oData.client_id)); //enviar a listado de interrupciones
        dispatch(actionDispatch(CONSTANTS_ACTIONS.SAVE_INTERRUPTION));
      })
      .catch((oError) => {
        if (oError.response) {
          dispatch(actionSetResponseCode(oError.response.status));
        }
        dispatch(actionMessage(oError));
      })
      .finally(() => {
        dispatch(actionDesactiveLoading());
      });
  };
};

/**
 * Funcion que registra un  cliente en el sistema.
 *
 * @param {object} oData objeto cliente
 *
 * @author
 */

export const actionUpdateReceipt = (oData) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    UpdateReceipt(oData)
      .then((oSuccess) => {
        dispatch(
          actionMessage(
            "Actualizado correctamente",
            CONSTANTS_MESSAGE.TYPE_SUCCESS
          )
        );
        dispatch(actionGetReceipts(oData.client_id)); //enviar a listado de interrupciones
        dispatch(actionDispatch(CONSTANTS_ACTIONS.UPDATE_RECEIPT));
      })
      .catch((oError) => {
        if (oError.response) {
          dispatch(actionSetResponseCode(oError.response.status));
        }
        dispatch(actionMessage(oError));
      })
      .finally(() => {
        dispatch(actionDesactiveLoading());
      });
  };
};

export const actionResetReceipts = () => {
  return (dispatch) => {
    dispatch(actionDispatch(CONSTANTS_ACTIONS.RESET_RECEIPTS));
  };
};

export const actionDeleteAdditionalService = (oData) => {
  return async dispatch => {
    DeleteAdditionalService(oData.id).then(oSuccess => {
      dispatch(actionGetReceipts(oData.client_id));
    }).catch(oError => {
      dispatch(actionMessage(oError.response.data.message ?? null, CONSTANTS_MESSAGE.TYPE_WARNING));
    });
  }
}
