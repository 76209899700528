import { CONSTANTS_ACTIONS } from 'constants/ConstantsActions';
import { CONSTANTS_MESSAGE } from 'constants/ConstantsMessages';
import { actionActiveLoading, actionDesactiveLoading, actionDispatch, actionMessage } from 'redux/actions/SharedActions';
import { GetMedicalSocieties, DeleteMedicalSociety } from 'redux/services/MedicalSocietyServices';


/**
 * Funcion que toma todos los departamentos de  Uruguay.
 * 
 * @author Franko Zerpa
 */
export const actionGetMedicalSocieties = () => {
    return dispatch => {
        GetMedicalSocieties().then(oSuccess => {
            dispatch(actionDispatch(CONSTANTS_ACTIONS.MEDICAL_SOCIETIES, oSuccess.data));
        }).catch(() => {
            dispatch(actionDispatch(CONSTANTS_ACTIONS.MEDICAL_SOCIETIES, []));
        });
    }
};

/**
 * !DEPRECATED - FUNCION QUE QUEDO EN DESUSO - Leandro Curbelo 01 de Julio de 2021
 * Función que elimina una sociedad médica
 * 
 * @param {number} nMedicalSocietyId 
 * 
 * @author Franko Zerpa
 */
export const actionDeleteMedicalSociety = (nMedicalSocietyId) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        DeleteMedicalSociety(nMedicalSocietyId).then(oSuccess => {
            dispatch(actionDispatch(CONSTANTS_ACTIONS.DELETE_MEDICAL_SOCIETY, oSuccess.data));
        }).catch((oError) => {
            dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
};
