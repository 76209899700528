import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Form, Table, Alert, Grid, Icon } from 'tabler-react';
import { TableAction } from 'components/buttons/TableAction';
import { connect } from 'react-redux';
import Modal from 'components/Modal';
import { actionDevolutionShipping, actionGetShipments, actionSaveShipping, actionUpdateShipping, actionCleanShippments, actionDeleteDeviceShipped } from 'redux/actions/ShipmentsActions';
import { faFileExcel, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CONSTANTS } from 'constants/Constants';
import TableColSmall from 'components/table/TableColSmall';
import { Helpers } from 'helpers/Helpers';
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import { DATE_FORMAT_MYSQL } from "constants/ConstantsDate";

class DevicesShippedList extends Component {
  constructor(props) {
    super(props);

    this.state = {

      oDeviceShippedEmpty: {
        id: null,
        shippingdate: Helpers.GetDateForDatepicker(),
        showshippingdate: Helpers.GetDateForDatepicker(),
        shippingagency: '',
        trackingcode: '',
        destination: '',
        observation: '',
        devolution: false,
        name: '',
        client_id: (this.props.propsClient.oClient && this.props.propsClient.oClient.id) || null,
      },
      oClient: {},

      oDeviceShipped: {},
      isModalDeviceShippedOpen: false,
      isModalDeleteDeviceShipped: false,
      oDeleteDevice: null
    }
  }

  componentDidMount = () => {
    this.setState({ oDeviceShipped: { ...this.state.oDeviceShippedEmpty } });
    window.addEventListener('beforeunload', this.cleanComponent);
  }

  componentWillUnmount = () => {
    this.cleanComponent();
    window.removeEventListener('beforeunload', this.cleanComponent);
  }

  cleanComponent = () => {
    this.props.clean();
  }

  componentWillUnmount = () => {
    this.props.cleanShippments();
  }

  //detectar cambis en el state
  componentDidUpdate = () => {
    if (this.props.propsClient.oClient !== this.state.oClient) {
      this.setClient();
    }
    if (this.props.propsClient.oClient !== this.state.oClient) {
      this.setClient();
    }
  }

  setClient = async () => {
    const { oClient } = this.props.propsClient;
    await this.setState({ oClient });
    this.getDevicesShipped();
  }


  getDevicesShipped = () => {
    if (this.props.propsClient.oClient) {
      this.props.getDevicesShipped(this.props.propsClient.oClient.id);
    }
  }

  _handleChangeDevolution = (event) => {
    let { checked } = event.target;
    const { oClient } = this.props.propsClient;
    let nDeviceShippedId = event.target.attributes.ndeviceshippedid.value;
    let oDeviceShipped = this.state.oDeviceShipped;
    oDeviceShipped.devolution = checked;
    this.setState({ oDeviceShipped });
    this.props.devolutionDeviceShipped(nDeviceShippedId, oClient.id);
  }

  _handleexportExcel = () => {
    window.open(CONSTANTS.API_URL + 'shipments/clientreport/' + this.props.propsClient.oClient.id, '_blank');
  };

  _delete = (oDeviceShipped) => {
    this.setState({ isModalDeleteDeviceShipped: true, oDeleteDevice: oDeviceShipped });
  }

  _applyDeleteDeviceShipped = () => {
    const { oDeleteDevice } = this.state;
    this.props.deleteDeviceShipped(oDeleteDevice);
    this.setState({ isModalDeleteDeviceShipped: false, oDeleteDevice: null });
  }

  _handleCloseDeleteDeviceShippedModal = () => {
    this.setState({ isModalDeleteDeviceShipped: false, oDeleteDevice: null });
  };

  renderDeleteDeviceShipped = () => {
    const { isModalDeleteDeviceShipped } = this.state;
    return (
      <Modal
        isOpen={isModalDeleteDeviceShipped}
        handleActionModal={this._applyDeleteDeviceShipped}
        handleCloseModal={this._handleCloseDeleteDeviceShippedModal}
        modalTitle="Confirmar"
        modalActionCaption="Eliminar"
        modalActionFaIcon={faTrash}
        customStyles={{
          width: "550px",
        }}
      >
        <Grid.Row className="system-body">
          <Grid.Col width={12}>¿Desea eliminar el aparato enviado?</Grid.Col>
        </Grid.Row>
      </Modal>
    );
  };



  renderTable = (aDevicesShipped) => {
    return (
      aDevicesShipped.map(oDeviceShipped => {
        return (
          <Table.Row key={oDeviceShipped.id}>
            <Table.Col>
              {oDeviceShipped.shippingdate.split('-').reverse().join('/')}
            </Table.Col>
            <Table.Col>
              {oDeviceShipped.name}
            </Table.Col>
            <TableColSmall value={oDeviceShipped.destination} />
            <Table.Col>
              {oDeviceShipped.shippingagency}
            </Table.Col>
            <Table.Col>
              {oDeviceShipped.trackingcode}
            </Table.Col>
            <TableColSmall value={oDeviceShipped.observation} />
            <Table.Col className='text-center isActive-indicator'>
              <Form.Group className='m-auto d-flex justify-content-center'>
                <label className="custom-switch">
                  <input type="checkbox" name="devolution" className="custom-switch-input" ndeviceshippedid={oDeviceShipped.id} defaultChecked={oDeviceShipped.devolution} onChange={this._handleChangeDevolution} />
                  <span className="custom-switch-indicator"></span>
                  <span className="custom-switch-description font-weight-bold"></span>
                </label>
              </Form.Group>
            </Table.Col>
            <Table.Col className="text-text-center d-flex px-2">
              <TableAction iconName="edit" tooltip="Editar" className='m-auto' onClick={() => this._handleEditDeviceShipped(oDeviceShipped)} />
              <TableAction iconName="trash" tooltip="Eliminar" className='m-auto' onClick={() => this._delete(oDeviceShipped)} />
            </Table.Col>
          </Table.Row>
        )
      })
    )
  }

  _handleCheckedChange = (event) => {
    let { name, checked } = event.target;
    let oDeviceShipped = this.state.oDeviceShipped;
    oDeviceShipped[name] = checked;
    this.setState({ oDeviceShipped })
  }


  _handleEditDeviceShipped = async (deviceShipped) => {
    let oDeviceShipped = deviceShipped;
    let shippingdate = deviceShipped.shippingdate + "";
    let ashippingdate = shippingdate.split('-');
    oDeviceShipped.showshippingdate = Helpers.ConvertTZ(new Date(ashippingdate[0], (parseInt(ashippingdate[1]) - 1), ashippingdate[2]));
    await this.setState({
      oDeviceShipped: oDeviceShipped,
      isModalDeviceShippedOpen: true
    });
  }

  _handleChange = (event) => {
    const { name, value } = event.target;
    let oDeviceShipped = this.state.oDeviceShipped;
    oDeviceShipped[name] = value;
    this.setState({ oDeviceShipped });
  }

  _handleStartDateChange = (selectedDate) => {
    let oDeviceShipped = this.state.oDeviceShipped;
    oDeviceShipped.showshippingdate = selectedDate;
    this.setState({ oDeviceShipped });
  }

  renderDeviceShippedModal = () => {
    const { oDeviceShipped } = this.state;
    return (
      this.state.isModalDeviceShippedOpen &&
      <Modal
        isOpen={this.state.isModalDeviceShippedOpen}
        handleActionModal={this._handleSaveDeviceShipped}
        handleCloseModal={this._handleCloseModal}
        modalTitle="Datos de envío de aparato"
        customStyles={{
          width: "750px"
        }}
      >
        <Grid.Row className="system-body">
          <Grid.Col width={6}>
            <Form.Group label="&nbsp;">
              <label className="custom-switch pt-2 mt-1">
                <input type="checkbox"
                  name="devolution"
                  className="custom-switch-input"
                  defaultChecked={oDeviceShipped.devolution}
                  value={oDeviceShipped.devolution}
                  onChange={this._handleCheckedChange} />
                <span className="custom-switch-indicator"></span>
                <span className="custom-switch-description font-weight-bold">Devolución</span>
              </label>
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={6}>

            <Form.Group label="Fecha">
              <DatePicker className="datepicker" locale={es} selected={oDeviceShipped.showshippingdate} dateFormat={"dd/MM/yyyy"} onChange={this._handleStartDateChange} showYearDropdown />
            </Form.Group>

          </Grid.Col>
          <Grid.Col width={6}>
            <Form.Group label="Nombre">
              <Form.Input name='name' placeholder="Nombre" value={oDeviceShipped.name} onChange={this._handleChange} />
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={6}>
            <Form.Group label="Destino">
              <Form.Input name='destination' placeholder="Destino" value={oDeviceShipped.destination} onChange={this._handleChange} />
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={6}>
            <Form.Group label="Agencia">
              <Form.Input name='shippingagency' placeholder="Agencia" value={oDeviceShipped.shippingagency} onChange={this._handleChange} />
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={6}>
            <Form.Group label="No. Rastreo">
              <Form.Input name='trackingcode' placeholder="No. Rastreo" value={oDeviceShipped.trackingcode} onChange={this._handleChange} />
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={12}>
            <Form.Group label="Observaciones">
              <Form.Textarea name='observation'
                placeholder='Observaciones (Puede usarse para comentarios respecto a si la devolución es de un contrato o un equipo roto, etc)'
                value={oDeviceShipped.observation}
                onChange={this._handleChange}
                rows={4} />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      </Modal>
    )
  }

  _handleOpenDeviceShippedModal = async () => {
    await this.setState({
      oDeviceShipped: { ...this.state.oDeviceShippedEmpty },
      isModalDeviceShippedOpen: true
    });
  }

  _handleSaveDeviceShipped = async () => {
    let { oDeviceShipped } = this.state;
    if (oDeviceShipped.id === null) {
      oDeviceShipped.client_id = this.props.propsClient.oClient.id;
      oDeviceShipped.showshippingdate = Helpers.CastDate(oDeviceShipped.showshippingdate, DATE_FORMAT_MYSQL);
      this.props.saveDeviceShipped(oDeviceShipped);
    }
    else
      this.props.updateDeviceShipped(oDeviceShipped, this.props.propsClient.oClient.id);
    this.setState({
      oDeviceShipped: { ...this.state.oDeviceShippedEmpty },
      isModalDeviceShippedOpen: false
    });
  }

  _handleCloseModal = () => {
    this.getDevicesShipped();
    this.setState({
      oDeviceShipped: { ...this.state.oDeviceShippedEmpty },
      isModalDeviceShippedOpen: false
    });
  }

  render() {
    const { oClient } = this.props.propsClient;
    const { aShipments } = this.props.oDevicesShipped;
    const aDevicesShipped = aShipments;
    if (!oClient)
      return null
    return (
      <Card>
        <Card.Header>
          <Card.Title className="mr-auto">
            {
              oClient.fullname.trim()
                ? <span className="alert alert-success">{oClient.fullname}</span>
                : <i className="alert alert-success mr-2">Cliente sin nombre</i>
            }
            Listado de envíos
          </Card.Title>
          <Button color="primary" className="ml-2" onClick={this._handleexportExcel}>
            <FontAwesomeIcon icon={faFileExcel} className="mr-2"></FontAwesomeIcon>
            Exportar a excel
          </Button>
          <Button className="btn btn-primary ml-3" onClick={() => this._handleOpenDeviceShippedModal(oClient)}>
            <Icon name="plus" className="mr-2" />
            Nuevo
          </Button>
          <Link className="btn btn-secondary ml-3" to={'/clients'}>
            <Icon name="corner-up-right" className="mr-2" />
            Volver
          </Link>
        </Card.Header>
        {aDevicesShipped.length > 0 ?
          <div className="table-responsive">
            <Table className="card-table table-vcenter table-sm table-hover text-nowrap">
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>
                    Fecha
                  </Table.ColHeader>
                  <Table.ColHeader>
                    Nombre
                  </Table.ColHeader>
                  <Table.ColHeader>
                    Destino
                  </Table.ColHeader>
                  <Table.ColHeader>
                    Agencia
                  </Table.ColHeader>
                  <Table.ColHeader>
                    No. Rastreo
                  </Table.ColHeader>
                  <Table.ColHeader>
                    Observación
                  </Table.ColHeader>
                  <Table.ColHeader className="text-center">
                    Devolución
                  </Table.ColHeader>
                  <Table.ColHeader>
                  </Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  this.renderTable(aDevicesShipped)
                }
              </Table.Body>
            </Table>
          </div>
          :
          <Card.Body>
            <Grid.Row>
              <Grid.Col>
                <Alert type="info" icon="info">
                  No hemos obtenido datos de aparatos enviados para el cliente
                </Alert>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        }
        {
          this.renderDeviceShippedModal(oClient)
        }
        {
          this.renderDeleteDeviceShipped()
        }
      </Card>
    );
  }
}


const mapStateToProps = state => ({
  propsClient: state.clientsReducer,
  oDevicesShipped: state.shipmentsReducer,
})

const mapDispatchToProps = dispatch => ({
  getDevicesShipped: (nClientId) => {
    dispatch(actionGetShipments(nClientId));
  },
  saveDeviceShipped: (oDeviceShipped) => {
    dispatch(actionSaveShipping(oDeviceShipped));
  },
  updateDeviceShipped: (oDeviceShipped, nClientId) => {
    dispatch(actionUpdateShipping(oDeviceShipped, nClientId));
  },
  devolutionDeviceShipped: (nDeviceShippedId, nClientId) => {
    dispatch(actionDevolutionShipping(nDeviceShippedId, nClientId));
  },
  cleanShippments: () => {
    dispatch(actionCleanShippments());
  },
  deleteDeviceShipped: (oDeviceShipped) => {
    dispatch(actionDeleteDeviceShipped(oDeviceShipped));
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(DevicesShippedList);