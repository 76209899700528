import React, { Component } from 'react';

export class UserProfile extends Component {
  render() {
    return (
      <>
        esta es la pagina de perfil de usuario
      </>
    );
  }
}