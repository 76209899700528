import React, { Component } from 'react';
import { Grid, Card, TabbedCard, Tab, Icon, Form, Button } from 'tabler-react';
import { Link } from "react-router-dom";
import profile from 'assets/svg/profile.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { actionGetClient } from 'redux/actions/ClientsActions';
import InterruptionsList from 'pages/interruptions/InterruptionsList';
import CallSection from 'pages/calls/CallSection';
import RelationsList from 'pages/relations/RelationsList';
import { Helpers } from 'helpers/Helpers';

class ClientDetails extends Component {

  render() {
    const { oClient } = this.props.propsClient; //destructuracion de objeto
    return (
      <Card>
        <Card.Header>
          <Card.Title className="mr-auto">
            Detalles del cliente
          </Card.Title>
          <Button color="primary" className="ml-auto">
            <FontAwesomeIcon icon={faFilePdf} className="mr-2"></FontAwesomeIcon>
            Exportar a pdf
          </Button>
          <Link className="btn btn-secondary ml-3" to={"/clients"}>
            <Icon name="corner-up-right" className="mr-2" />
            Volver
          </Link>
        </Card.Header>
        <Card.Body>
          <Grid.Row className="calls-board-container">
            <Grid.Col width={12} md={3}>
              <Card>
                <Card.Body className="text-center">
                  <img className="avata avatar-xxl" alt="Perfil" src={profile} />
                  <h4 className="h3 mt-0 mb-4 mb-3">{oClient.fullname}</h4>
                  <h4 className="h3 mt-0 mb-4 mb-3">{oClient.code}</h4>
                </Card.Body>
              </Card>
            </Grid.Col>
            <Grid.Col width={12} md={9}>
              <TabbedCard initialTab="Información">
                <Tab title="Información">
                  <Grid.Row>

                    {/* ==============================  Datos básicos ==============================   */}
                    <Card statusColor="green" title="Datos básicos" isCollapsible>
                      <Card.Body>
                        <Grid.Row>
                          <Grid.Col width={6}>
                            <Form.Group>
                              <Form.Label>
                                Primer Apellido
                              </Form.Label>
                              <Form.Label className="details-label">
                                {oClient.lastname}
                              </Form.Label>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={6}>
                            <Form.Group>
                              <Form.Label>
                                Segundo Apellido
                              </Form.Label>
                              <Form.Label className="details-label">
                                {oClient.secondsurname}
                              </Form.Label>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={6}>
                            <Form.Group>
                              <Form.Label>
                                Primer Nombre
                              </Form.Label>
                              <Form.Label className="details-label">
                                {oClient.name}
                              </Form.Label>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={6}>
                            <Form.Group>
                              <Form.Label>
                                Segundo Nombre
                              </Form.Label>
                              <Form.Label className="details-label">
                                {oClient.secondname}
                              </Form.Label>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={6}>
                            <Form.Group>
                              <Form.Label>
                                Sexo
                            </Form.Label>
                              <Form.Label className="details-label">
                                {oClient.sex}
                              </Form.Label>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={6}>
                            <Form.Group>
                              <Form.Label>
                                Cédula
                            </Form.Label>
                              <Form.Label className="details-label">
                                {oClient.identificationcard}
                              </Form.Label>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={6}>
                            <Form.Group>
                              <Form.Label>
                                Fecha nacimiento
                              </Form.Label>
                              <Form.Label className="details-label">
                                {/*Helpers.isset(oClient.birthdate) && oClient.birthdate.split('-').reverse().join('/')*/}
                              </Form.Label>
                            </Form.Group>
                          </Grid.Col>

                          <Grid.Col width={6}>
                          </Grid.Col>
                          
                          <Grid.Col width={4}>
                            <Form.Group>
                              <Form.Label>
                                Teléfono
                              </Form.Label>
                              <Form.Label className="details-label">
                                {oClient.principal_phone}
                              </Form.Label>
                            </Form.Group>
                          </Grid.Col>

                          <Grid.Col width={4}>
                            <Form.Group>
                              <Form.Label>
                                Otros teléfonos
                              </Form.Label>
                              <Form.Label className="details-label">
                                {Array.isArray(oClient.phones) ?
                                  oClient.phones.map((phone) => {
                                    return (
                                      <Form.Group key={phone.id}>
                                        <Tab>
                                          {phone.phonenumber} - {phone.type}
                                        </Tab>
                                      </Form.Group>
                                    )
                                  }) : ''}
                              </Form.Label>
                            </Form.Group>
                          </Grid.Col>

                          <Grid.Col width={4}>
                            <Form.Group>
                              <Form.Label>
                                Correo
                              </Form.Label>
                              <Form.Label className="details-label">
                                {oClient.email}
                              </Form.Label>
                            </Form.Group>
                          </Grid.Col>

                          <Grid.Col width={12}>
                            <Form.Group>
                              <Form.Label>
                                Domicilio
                              </Form.Label>
                              <Form.Label className="details-label">
                                {oClient.principal_address}
                              </Form.Label>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={12}>
                            <Form.Group>
                              <Form.Label>
                                Otros Domicilios
                              </Form.Label>
                              <Form.Label className="details-label">
                                {Array.isArray(oClient.addresses) ?
                                  oClient.addresses.map((address) => {
                                    return (
                                      <Form.Group key={address.id}>
                                        <Tab>
                                          {address.domicile} - {address.type}
                                        </Tab>
                                      </Form.Group>
                                    )
                                  }) : ''}
                              </Form.Label>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={12}>
                            <Form.Group>
                              <Form.Label>
                                Contactos
                              </Form.Label>
                              <Form.Label className="details-label">
                                {
                                  Helpers.isset(oClient.contacts) && oClient.contacts.split('\n').map((item, key) => {
                                    return <span key={key}>{item}<br/></span>
                                  })
                                }
                              </Form.Label>
                            </Form.Group>
                          </Grid.Col>
                        </Grid.Row>
                      </Card.Body>
                    </Card>

                    {/* ==============================  Información del servicio ==============================   */}
                    <Card statusColor="blue" title="Información del servicio" isCollapsible>
                      <Card.Body>
                        <Grid.Row>
                          <Grid.Col width={6}>
                            <Form.Group>
                              <Form.Label>
                                Teléfono aparato
                              </Form.Label>
                              <Form.Label className="details-label">
                                {oClient.show_device_phone}
                              </Form.Label>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={6}>
                            <Form.Group>
                              <Form.Label>
                                Contrato
                              </Form.Label>
                              <Form.Label className="details-label">
                                {oClient.contract ? 'SI' : 'NO'}
                              </Form.Label>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={6}>
                            <Form.Group>
                              <Form.Label>
                                Aparato
                              </Form.Label>
                              <Form.Label className="details-label">
                                {oClient.device ? oClient.device.code : ''}
                              </Form.Label>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={6}>
                            <Form.Group>
                              <Form.Label>
                                Fecha de afiliación
                              </Form.Label>
                              <Form.Label className="details-label">
                                {Helpers.isset(oClient.affiliationdate) && oClient.affiliationdate.split('-').reverse().join('/')}
                              </Form.Label>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={6}>
                            <Form.Group>
                              <Form.Label>
                                Cambio de batería de pulsera
                              </Form.Label>
                              <Form.Label className="details-label">
                                {/* Fecha de cambio de pulsera */}
                                {Helpers.isset(oClient.change_battery_bracelet) && oClient.change_battery_bracelet.split('-').reverse().join('/')}
                              </Form.Label>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={6}>
                            <Form.Group>
                              <Form.Label>
                                Cambio de batería aparato
                              </Form.Label>
                              <Form.Label className="details-label">
                                {/* Fecha de cambio del aparato */}
                                {oClient.change_battery}
                              </Form.Label>
                            </Form.Group>
                          </Grid.Col>
                        </Grid.Row>
                      </Card.Body>
                    </Card>

                    {/* ==============================  Datos medicos ==============================   */}
                    <Card statusColor="red" title="Datos médicos" isCollapsible>
                      <Card.Body>
                        <Grid.Row>
                          <Grid.Col width={4}>
                            <Form.Group>
                              <Form.Label>
                                Emergencia móvil
                              </Form.Label>
                              <Form.Label className="details-label">
                                {oClient.show_mobile_emergencies}                                 
                                {/*Array.isArray(oClient.mobile_emergencies) ?
                                  oClient.mobile_emergencies.map((mobileEmergency) => {
                                    return (
                                      <Form.Group key={mobileEmergency.id}>
                                        <Tab>
                                        </Tab>
                                      </Form.Group>
                                    )
                                  }) : ''*/}
                              </Form.Label>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={4}>
                            <Form.Group>
                              <Form.Label>
                                Sociedad médica
                              </Form.Label>
                              <Form.Label className="details-label">
                                {oClient.show_medical_societies}                                 
                                {/* oClient.medical_societies.map((medicalSociety) => {
                                    return (
                                      <Form.Group key={medicalSociety.id}>
                                        <Tab>
                                          {medicalSociety.name} 
                                        </Tab>
                                      </Form.Group>
                                    )
                                  }) : ''*/}
                              </Form.Label>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={4}>
                            <Form.Group>
                              <Form.Label>
                                Médico particular
                              </Form.Label>
                              <Form.Label className="details-label">
                                {Helpers.isset(oClient.show_private_doctor) && oClient.show_private_doctor}
                              </Form.Label>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={6}>
                            <Form.Group>
                              <Form.Label>
                                Ficha médica
                              </Form.Label>
                              <Form.Label className="details-label">
                                {Helpers.isset(oClient.show_medical_record) && oClient.show_medical_record} 
                              </Form.Label>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={6}>
                            <Form.Group>
                              <Form.Label>
                                Medicación
                              </Form.Label>
                              <Form.Label className="details-label">
                              {Helpers.isset(oClient.medicine) && oClient.medicine!==null && oClient.medicine.content}
                              </Form.Label>
                            </Form.Group>
                          </Grid.Col>
                        </Grid.Row>
                      </Card.Body>
                    </Card>

                    {/* ==============================  Pagos  ==============================   */}
                    <Card statusColor="red" title="Pagos" isCollapsible>
                      <Card.Body>
                        <Grid.Row>
                          <Grid.Col width={4}>
                            <Form.Group>
                              <Form.Label>
                                Cuota actual
                              </Form.Label>
                              <Form.Label className="details-label">
                                {oClient.feevalue && oClient.feevalue}
                              </Form.Label>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={4}>
                            <Form.Group>
                              <Form.Label>
                                Modo de pago
                              </Form.Label>
                              <Form.Label className="details-label">
                                {/* mthod pay*/}
                                </Form.Label>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={4}>
                            {oClient.midespartner!==null?
                              <Form.Group>
                                <Form.Label>
                                  % mides
                                </Form.Label>
                                <Form.Label className="details-label">
                                  {oClient.midespartner}
                                </Form.Label>
                              </Form.Group>
                              :
                              ''
                              
                            }
                          </Grid.Col>
                        </Grid.Row>
                      </Card.Body>
                    </Card>

                    {/* ==============================  Empresa  ==============================   */}
                    <Card statusColor="teal" title="Empresa" isCollapsible>
                      <Card.Body>
                        <Grid.Row>
                          <Grid.Col width={6}>
                            <Form.Group>
                              <Form.Label>
                                RUT
                              </Form.Label>
                              <Form.Label className="details-label">
                                {oClient.company && oClient.company.rut}
                              </Form.Label>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={6}>
                            <Form.Group>
                              <Form.Label>
                                Nombre empresa
                              </Form.Label>
                              <Form.Label className="details-label">
                              {oClient.company && oClient.company.name}
                                </Form.Label>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={12}>
                            <Form.Group>
                              <Form.Label>
                                Domicilio
                              </Form.Label>
                              <Form.Label className="details-label">
                              {oClient.company && oClient.company.domicile}
                                </Form.Label>
                            </Form.Group>
                          </Grid.Col>
                        </Grid.Row>
                      </Card.Body>
                    </Card>

                    <Grid.Col width={12}>
                      <Form.Group>
                        <Form.Label>
                          Observaciones
                        </Form.Label>
                        <Form.Label className="details-label">
                          {oClient.observation}
                        </Form.Label>
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>
                </Tab>
                <Tab title="Llamadas">
                  <CallSection
                    callsPerPage={20}
                    nClientId={oClient.id}
                    oClient={oClient}
                  />
                </Tab>
                <Tab title="Corte / Interrupciones">
                  <InterruptionsList
                    nClientId={oClient.id}
                  />
                </Tab>
                <Tab title="Familiares">
                  <RelationsList
                    nClientId={oClient.id}
                  />
                </Tab>
              </TabbedCard>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>
    );
  }
}



const mapStateToProps = state => ({
  propsClient: state.clientsReducer,
})

const mapDispatchToProps = dispatch => ({
  get: (idClient) => {
    dispatch(actionGetClient(idClient));
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetails);