import React, { Component } from 'react';
import { Button, Grid, Form, Header } from "tabler-react";
import { connect } from 'react-redux';
import { actionActiveLoading, actionMessage, actionCleanMessage } from 'redux/actions/SharedActions';
import DatePicker from "react-datepicker";

import { Multiselect } from 'multiselect-react-dropdown';
import { MultiselectStyle } from 'components/MultiselectStyle';
import { actionGetCalls, actionSaveCall, actionUpdateCall } from 'redux/actions/CallsActions';
import es from "date-fns/locale/es";
import { Helpers } from 'helpers/Helpers';

class CallUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oClient: this.props.oClient,
      oCall: this.props.oCall,
      bValidForm: false,
      oErrors: {
        start: "",
        origin: "",
        rrdc: '',
        rrds: '',
        result: '',
        ctime: "",
        duration: "",
        reason: "",
        client_id: null,
        observation: '',
        performances: [],
      },
      callTypes: [
        { id: "entrante", description: "Entrante" },
        { id: "saliente", description: "Saliente" }
      ],
      midesReasons: [
        { id: "Pruebas por alta o control del servicio (E/S)", description: "Pruebas por alta o control del servicio (E/S)", showInType: ['entrante', 'saliente'] },
        { id: "Pulsaciones por error (E)", description: "Pulsaciones por error (E)", showInType: ['entrante'] },
        { id: "Modificación de datos personales (E/S)", description: "Modificación de datos personales (E/S)", showInType: ['entrante', 'saliente'] },
        { id: "Llamadas por soledad o angustia (E)", description: "Llamadas por soledad o angustia (E)", showInType: ['entrante'] },
        { id: "Caídas (E)", description: "Caídas (E)", showInType: ['entrante'] },
        { id: "Otros accidentes físicos (E)", description: "Otros accidentes físicos (E)", showInType: ['entrante'] },
        { id: "Afecciones vinculadas a la salud (E)", description: "Afecciones vinculadas a la salud (E)", showInType: ['entrante'] },
        { id: "Accidentes domésticos (gas, electricidad, fuego o humo, etc) (E)", description: "Accidentes domésticos (gas, electricidad, fuego o humo, etc) (E)", showInType: ['entrante'] },
        { id: "Intrusos / violencia intrahogar (E)", description: "Intrusos / violencia intrahogar (E)", showInType: ['entrante'] },
        { id: "Comunicación del usuario por dificultades técnicas con el equipo (E)", description: "Comunicación del usuario por dificultades técnicas con el equipo (E)", showInType: ['entrante'] },
        { id: "Llamada por temas que exceden el servicio (E)", description: "Llamada por temas que exceden el servicio (E)", showInType: ['entrante'] },
        { id: "Motivos varios (festividades, cumpleaños, agenda, medicación) (S)", description: "Motivos varios (festividades, cumpleaños, agenda, medicación) (S)", showInType: ['saliente'] },
        { id: "Llamada de seguimiento de rutina (cada 45 días) (S)", description: "Llamada de seguimiento de rutina (cada 45 días) (S)", showInType: ['saliente'] },
        { id: "Llamada de seguimiento luego de accidente o afección (S)", description: "Llamada de seguimiento luego de accidente o afección (S)", showInType: ['saliente'] },
      ],
      performanceTypes: [
        { id: "Verificación de error", description: "Verificación de error" },
        { id: "Asesoramiento para el uso adecuado del servicio", description: "Asesoramiento para el uso adecuado del servicio" },
        { id: "Asesoramiento sobre el alcance del servicio", description: "Asesoramiento sobre el alcance del servicio" },
        { id: "Contacto RDC", description: "Contacto RDC" },
        { id: "Contacto RDServicios", description: "Contacto RDServicios" },
        { id: "Contacto Emergencia médica", description: "Contacto Emergencia médica" },
        { id: "Prueba", description: "Prueba" },
      ],
      showOtherReason: false,
    }
    if (this.props.oCall.id === null)
      this.setDateTime();
  }

  componentDidMount = () => {
    let sReason = JSON.parse(JSON.stringify(this.props.oCall.show_reason.trim().toLowerCase())), oCall = this.state.oCall;
    if (sReason && sReason !== "sin querer" && sReason !== "prueba" && sReason !== "cortesía" && sReason !== "cortesia" && sReason !== "asistencia" && sReason !== "inicial") {
      oCall.show_reason_personalized = this.props.oCall.show_reason;
      oCall.show_reason = 'otro';
      this.setState({ showOtherReason: true });
    } else {
      oCall.show_reason = sReason ? sReason : "sin querer";
      this.setState({ showOtherReason: false });
    }
  }

  setDateTime = () => {
    let oCall = this.state.oCall, cDate = Helpers.UnixToDate(Helpers.GetDateForDatepicker()),
      hour = cDate.getHours(), minute = cDate.getMinutes();
    if (parseInt(hour) < 10)
      hour = '0' + hour;
    if (parseInt(minute) < 10)
      minute = '0' + minute;
    let second = cDate.getSeconds();
    if (parseInt(second) < 10)
      second = '0' + second;
    let currentTime = hour + ":" + minute + ':' + second;
    oCall.startTime = currentTime;
    this.setState({ oCall });
  }

  onSelect = (selectedList) => {
    let oCall = this.state.oCall;
    oCall.performances = selectedList;
    this.setState({ oCall });
  }

  onRemove = (selectedList) => {
    let oCall = this.state.oCall;
    oCall.performances = selectedList;
    this.setState({ oCall });
  }

  _handleChange = async (event) => {
    const { name, value } = event.target;
    let oCall = this.state.oCall;
    let oErrors = this.state.oErrors;
    oCall[name] = value;
    if (name === 'show_reason')
      this.setState({ showOtherReason: value === 'otro' });
    await this.setState({ oErrors, oCall });
  }

  _handleCheckedChange = (event) => {
    let { checked } = event.target;
    this.setState({ showOtherReason: checked });
  }

  _handleCdateDateChange = (selectedDate) => {
    const oCall = this.state.oCall;
    oCall.startDate = selectedDate;
    this.setState({ oCall });
  }

  _save = () => {
    let oCall = JSON.parse(JSON.stringify(this.state.oCall));
    if (oCall.show_reason === 'otro')
      oCall.show_reason = (oCall.show_reason_personalized && oCall.show_reason_personalized.trim()) ? oCall.show_reason_personalized.trim() : 'otro';
    else
      oCall.show_reason = oCall.show_reason.toLowerCase();
    if (this.state.oCall.id === null) {
      oCall.client_id = this.props.propsClient.oClientBoard.id;
      this.setState({ oCall: { ...this.props.oCallEmpty } });
      this.props.save(oCall, this.props.nCallListPage);
    } else
      this.props.update(oCall, this.props.nCallListPage);
    this.props.activeEdit();
  }

  _handleCloseUpdate = () => {
    this.props.activeEdit();
    window.location.reload()
  }


  render() {
    const { oCall, callTypes, midesReasons, performanceTypes } = this.state;
    return (
      <>
        <Grid.Row>
          <Grid.Col width={12} className="text-right">
            <Button icon="save" color="primary" onClick={this._save}>Guardar</Button>
            <Button icon="slash" color="secundary" className="ml-3" onClick={this._handleCloseUpdate}>Cancelar</Button>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          {/* Tipo de llamada */}
          <Grid.Col width={6}>
            <Form.Group label="Tipo de llamada">
              <Form.Select name="type" onChange={this._handleChange} value={oCall.type}>
                {
                  callTypes.map((oType) => (
                    <option key={oType.id} value={oType.id}>{oType.description}</option>
                  ))
                }
              </Form.Select>
            </Form.Group>
          </Grid.Col>
          {/* Fecha */}
          <Grid.Col width={6}>
            <Form.Group label="Fecha">
              <DatePicker name="startDate" className="form-control" locale={es} dateFormat="dd/MM/yyyy"
                selected={oCall.startDate ? oCall.startDate : ""} onChange={this._handleCdateDateChange} showYearDropdown />
            </Form.Group>
          </Grid.Col>
          {/* Hora */}
          <Grid.Col width={3}>
            <Form.Group label="Hora">
              <Form.MaskedInput name='startTime' value={oCall.startTime} onChange={this._handleChange} placeholder="00:00:00"
                mask={[/\d/, /\d/, ":", /\d/, /\d/, ":", /\d/, /\d/]} />
            </Form.Group>
          </Grid.Col>
          {/* Duración */}
          <Grid.Col width={3}>
            <Form.Group label="Duración">
              <Form.MaskedInput name='duration' value={oCall.duration} onChange={this._handleChange} placeholder="HH:mm:ss"
                mask={[/\d/, /\d/, ":", /\d/, /\d/, ":", /\d/, /\d/]} />
            </Form.Group>
          </Grid.Col>
          {/* Motivo */}
          <Grid.Col width={6}>
            <Form.Group label="Motivo">
              <Form.Select name="show_reason" value={oCall.show_reason} onChange={this._handleChange}>
                <option value={"sin querer"}>Sin querer</option>
                <option value={"prueba"}>Prueba</option>
                <option value={"inicial"}>Inicial</option>
                <option value={"cortesía"}>Cortesía</option>
                <option value={"asistencia"}>Asistencia</option>
                <option value={"otro"}>Otro</option>
              </Form.Select>
            </Form.Group>
          </Grid.Col>
          {
            this.state.showOtherReason &&
            <Grid.Col width={6}>
              <Form.Group label="Motivo personalizado">
                <Form.Input type='text' name='show_reason_personalized' value={oCall.show_reason_personalized} placeholder='Motivo de la llamada' onChange={this._handleChange} />
                <small className="form-text text-muted">Dejar vacío para ingresar motivo "Otro"</small>
              </Form.Group>
            </Grid.Col>
          }
        </Grid.Row>
        {/* Mides */}
        {
          this.state.oCall.client_call && this.state.oCall.client_call.midespartner ?
            <Grid.Row>
              <Grid.Col width={12}>
                <Header.H4 className="mt-5">Mides</Header.H4>
                <hr className="py-0 mt-0 mb-3" />
              </Grid.Col>
              <Grid.Col width={4}>
                <Form.Group label="Motivo">
                  <Form.Select name="midesReason" value={oCall.midesReason} onChange={this._handleChange}>
                    {
                      midesReasons.map((oReason) => (
                        oReason.showInType.includes(oCall.type) &&
                        <option key={oReason.id} value={oReason.id}>
                          {oReason.description}
                        </option>
                      ))
                    }
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
              {/* Origen */}
              <Grid.Col width={4}>
                <Form.Group label="Origen">
                  <Form.Select name="origin" value={oCall.origin} onChange={this._handleChange} >
                    <option>Botón</option>
                    <option>Terminal</option>
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
              {/* R_RDC (Respuesta de la red de contactos) */}
              <Grid.Col width={4}>
                <Form.Group label="Respuesta de la red de contactos">
                  <Form.Select name="rrdc" value={oCall.rrdc} onChange={this._handleChange}>
                    <option>Sin contacto</option>
                    <option>Con contacto</option>
                    <option>No responde</option>
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
              {/* R_Red (Respuesta de la red de servicios) */}
              <Grid.Col width={4}>
                <Form.Group label="Respuesta de la red de servicios">
                  <Form.Select name="rrds" value={oCall.rrds} onChange={this._handleChange}>
                    <option>Sin contacto</option>
                    <option>Con contacto</option>
                    <option>No responde</option>
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
              {/* Resultado final */}
              <Grid.Col width={4}>
                <Form.Group label="Resultado Final">
                  <Form.Select name="result" value={oCall.result} onChange={this._handleChange}>
                    <option value="positivo">Positivo</option>
                    <option value="negativo">Negativo</option>
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
              {/* Tipo de actuación */}
              <Grid.Col width={4}>
                <Form.Group label="Tipo de Actuación" className="multiselect-react-dropdown" >
                  <Multiselect
                    options={performanceTypes} // Options to display in the dropdown
                    selectedValues={oCall.performances} // Preselected value to persist in dropdown
                    onSelect={this.onSelect} // Function will trigger on select event
                    onRemove={this.onRemove} // Function will trigger on remove event
                    displayValue="description" // Property name to display in the dropdown options
                    style={MultiselectStyle}
                    placeholder="Seleccione"
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row> : ''
        }
        <Grid.Row>
          <Grid.Col width={12}>
            <Form.Group label="Observaciones">
              <Form.Textarea name='observation' onChange={this._handleChange} placeholder='Observaciones' value={this.state.oCall.observation} rows={4} />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      </>
    );
  }
}

const mapStateToProps = state => ({
  oShared: state.sharedReducer,
  propsClient: state.clientsReducer,
});

const mapDispatchToProps = dispatch => ({
  showLoading: () => {
    dispatch(actionActiveLoading());
  },
  message: (msg, type) => {
    dispatch(actionMessage(msg, type));
  },
  cleanMessage: () => {
    dispatch(actionCleanMessage());
  },

  save: (oCall, nPage) => {
    dispatch(actionSaveCall(oCall, nPage));
  },
  update: (oCall, nPage) => {
    dispatch(actionUpdateCall(oCall, nPage));
  },

  get: (nClientId, nPage, nCallsPerPage) => {
    dispatch(actionGetCalls(nClientId, nPage, nCallsPerPage));
  },
});


export default connect(mapStateToProps, mapDispatchToProps)(CallUpdate);