import { CONSTANTS_ACTIONS } from 'constants/ConstantsActions';

const aInitialState = {
    aRelations: [],
    oRelation: {},

}
/**
 * Reducer Mostrar las Ciudades y codigo de area por departamento.
 * 
 * @param {Array} aState Estado del reducer, al momento de inicializarse se tomara aInitialState
 * @param {object} oAction Objeto donde se encuentra el tipo de estado que se desea actualizar y los respectivos datos
 * 
 * @author Franko Zerpa
 * 
 */
export const relationsReducer = (aState = aInitialState, oAction) => {
    switch (oAction.type) 
    {
        case CONSTANTS_ACTIONS.RELATIONS:
            return { ...aState, aRelations: oAction.data }
        case CONSTANTS_ACTIONS.SAVE_RELATION:
            return { ...aState, bStatus: true }
        case CONSTANTS_ACTIONS.UPDATE_RELATION:
            return { ...aState, bStatus: true }
        case CONSTANTS_ACTIONS.RESET_RELATIONS:
            return { ...aState, bStatus: false }
        default:
            return aState;
    }
}


