import { CONSTANTS_ACTIONS } from "constants/ConstantsActions";
import { CONSTANTS_MESSAGE } from "constants/ConstantsMessages";
import {
  actionActiveLoading,
  actionDesactiveLoading,
  actionDispatch,
  actionMessage,
  actionSetResponseCode,
} from "redux/actions/SharedActions";
import { GetFeesByClientAndRange } from "redux/services/FeeServices";

/**
 * Funcion que obtiene un cliente del sistema.
 *
 * @param {number} nClientId ide del cliente
 * @param {number} nYear año
 * @param {number} nMonth mes
 *
 * @author
 */
export const actionGetFeesByClientAndRange = (nClientId, dToDate) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    GetFeesByClientAndRange(nClientId, dToDate)
      .then((oSuccess) => {
        dispatch(
          actionDispatch(
            CONSTANTS_ACTIONS.FEE_GET_BY_CLIENT_AND_RANGE,
            oSuccess
          )
        );
      })
      .catch((oError) => {
        if (oError.response) {
          dispatch(actionSetResponseCode(oError.response.status));
        }
        dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
      })
      .finally(() => {
        dispatch(actionDesactiveLoading());
      });
  };
};
