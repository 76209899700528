import { CONSTANTS } from 'constants/Constants';
import oAxiosInstance from 'redux/interceptors/Interceptors';

const NOTIFICATIONS_URL = CONSTANTS.API_URL + 'notifications';

/**
 * Funcion para tomar todos los notificationes del sistema
 * 
 * 
 * @author 
 */
export const GetNotifications = () => {
    return oAxiosInstance.get(NOTIFICATIONS_URL).then(oResult => oResult)
}
export const SetViewedNotifications = () => {
    return oAxiosInstance.post(NOTIFICATIONS_URL).then(oResult => oResult)
}