import React, { Component } from "react";
import ReactModal from "react-modal";
import { Card, Icon, Button } from "tabler-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Default",
      actionCaption: "Guardar",
      actionButtonType: "primary",
      cancelCaption: "Cancelar",
      cancelButtonType: "secundary",
      customStyles: props.customStyles,
    };
  }

  render() {
    const { title, customStyles, actionCaption, actionButtonType, cancelCaption, cancelButtonType } = this.state;
    const {
      modalTitle,
      modalHideAction,
      modalActionCaption,
      modalActionFaIcon,
      modalActionButtonType,
      handleActionModal,
      modalHideCancel,
      modalCancelCaption,
      modalCancelFaIcon,
      modalCancelButtonType,
      handleCloseModal,
      children,
    } = this.props;
    return (
      <ReactModal isOpen={this.props.isOpen} contentLabel="onRequestClose" onRequestClose={handleCloseModal} className="system-modal" overlayClassName="system-overlay" ariaHideApp={false}>
        <div className="card system-modal-card" style={customStyles}>
          <Card.Header>
            <h3 className="card-title">{modalTitle ? modalTitle : title}</h3>
            <Card.Options>
              {
                !modalHideAction &&
                <Button color={modalActionButtonType ? modalActionButtonType : actionButtonType} onClick={handleActionModal}>
                  {
                    modalActionFaIcon ?
                      <FontAwesomeIcon icon={modalActionFaIcon} className="mr-2" />
                      :
                      <Icon name="save" className="mr-2" />
                  }
                  {modalActionCaption ? modalActionCaption : actionCaption}
                </Button>
              }
              {
                !modalHideCancel &&
                <Button color={modalCancelButtonType ? modalCancelButtonType : cancelButtonType} className="ml-2" onClick={handleCloseModal}>
                  {
                    modalCancelFaIcon ?
                      <FontAwesomeIcon icon={modalCancelFaIcon} className="mr-2" />
                      :
                      <Icon name="slash" className="mr-2" />
                  }
                  {modalCancelCaption ? modalCancelCaption : cancelCaption}
                </Button>
              }
            </Card.Options>
          </Card.Header>
          <Card.Body>{children}</Card.Body>
        </div>
      </ReactModal>
    );
  }
}
