import React, { Component } from "react";
import { Button, Table, Alert, Grid, Card, Tooltip } from "tabler-react";
import { TableAction } from "components/buttons/TableAction";
import { connect } from "react-redux";
import {
  actionCleanCalls,
  actionGetCalls,
  actionDeleteCall,
} from "redux/actions/CallsActions";
import { Pagination } from "components/Pagination";
import { faFileExcel, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CONSTANTS } from "constants/Constants";
import Modal from "components/Modal";

class CallList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      currentPage: 1, //this.props.nCallListPage,
      nCallsPerPage: 10,
      nCallId: null,
      nClientId: null,
      isModalDeleteCall: false,
      oDeleteCall: null,
    };
  }

  componentDidMount = () => {};

  componentWillUnmount = () => {
    this.props.fChangePage(this.state.currentPage);
  };

  componentDidUpdate = (oLastProps) => {
    const { currentPage, nCallsPerPage } = this.state,
      { oClientBoard } = this.props.propsClient;
    if (
      oLastProps.propsClient.oClient &&
      oLastProps.propsClient.oClient.id &&
      oClientBoard &&
      oClientBoard.id &&
      oLastProps.propsClient.oClient.id != oClientBoard.id
    ) {
      this.props.get(oClientBoard.id, currentPage, nCallsPerPage);
      this.setState({ nClientId: oClientBoard.id, currentPage: 1 });
    }
  };

  _handleSearchChange = (event) => {
    let { value } = event.target;
    let nPage = 0;
    this.props.get(
      this.props.propsClient.oClientBoard.id,
      nPage,
      this.state.nCallsPerPage,
      value
    );
    this.setState({ search: value, currentPage: 1 });
  };

  _handleexportExcel = () => {
    window.open(
      CONSTANTS.API_URL +
        "calls/clientreport/" +
        this.props.propsClient.oClientBoard.id,
      "_blank"
    );
  };

  _handlechangeCount = (event) => {
    let { value } = event.target;
    this.setState({ nCallsPerPage: value, currentPage: 1 });
    this.props.get(
      this.props.propsClient.oClientBoard.id,
      0,
      value,
      this.state.sSearch
    );
  };

  _handleNextPage = async () => {
    let nPage =
      parseInt(this.state.currentPage) * parseInt(this.state.nCallsPerPage);
    await this.setState({ currentPage: this.state.currentPage + 1 });
    this.props.get(
      this.props.propsClient.oClientBoard.id,
      nPage,
      this.state.nCallsPerPage,
      this.state.search
    );
  };

  _handlePreviousPage = async () => {
    await this.setState({ currentPage: this.state.currentPage - 2 });
    let nPage;
    if (this.state.currentPage <= 1) {
      nPage = 0;
    } else {
      nPage = this.state.currentPage * this.state.nCallsPerPage;
    }
    this.setState({ currentPage: this.state.currentPage + 1 });
    this.props.get(
      this.props.propsClient.oClientBoard.id,
      nPage,
      this.state.nCallsPerPage,
      this.state.search
    );
  };

  _handleGoToPage = async (nPage) => {
    this.setState({ currentPage: nPage });
    let nSkip;
    nSkip = (nPage - 1) * this.state.nCallsPerPage;
    this.props.get(
      this.props.propsClient.oClientBoard.id,
      nSkip,
      this.state.nCallsPerPage,
      this.state.search
    );
  };

  _delete = (oCall) => {
    this.setState({ isModalDeleteCall: true, oDeleteCall: oCall });
  };

  _applyDeleteCall = () => {
    const { oDeleteCall, currentPage } = this.state;
    this.props.deleteCall(oDeleteCall, currentPage);
    this.setState({ isModalDeleteCall: false, oDeleteCall: null });
  };

  _handleCloseDeleteCallModal = () => {
    this.setState({ isModalDeleteCall: false, oDeleteCall: null });
  };

  renderDeleteCallModal = () => {
    const { isModalDeleteCall } = this.state;
    return (
      <Modal
        isOpen={isModalDeleteCall}
        handleActionModal={this._applyDeleteCall}
        handleCloseModal={this._handleCloseDeleteCallModal}
        modalTitle="Confirmar"
        modalActionCaption="Eliminar"
        modalActionFaIcon={faTrash}
        customStyles={{
          width: "550px",
        }}
      >
        <Grid.Row className="system-body">
          <Grid.Col width={12}>¿Desea eliminar la llamada?</Grid.Col>
        </Grid.Row>
      </Modal>
    );
  };

  renderTable = () => {
    let { edit } = this.props;

    return this.props.propsCalls.aCalls.data.map((oCall) => {
      return (
        <Table.Row key={"cll" + oCall.id}>
          <Table.Col className="px-2">{oCall.show_type}</Table.Col>
          <Table.Col>
            {oCall.start
              ? oCall.start.split(" ")[0].split("-").reverse().join("/")
              : " "}
          </Table.Col>
          <Table.Col>{oCall.show_start_time}</Table.Col>
          {/* Comentado columna de Duración a petición del cliente */}
          {/* <Table.Col>{oCall.duration}</Table.Col> */}
          <Table.Col>
            <Tooltip content={oCall.show_reason} placement="bottom">
              <span>
                {oCall.show_reason && oCall.show_reason.length > 40
                  ? `${oCall.show_reason.slice(0, 40)}...`
                  : oCall.show_reason}
              </span>
            </Tooltip>
          </Table.Col>
          <Table.Col>
            <Tooltip content={oCall.observation} placement="bottom">
              <span>
                {oCall.observation && oCall.observation.length > 50
                  ? `${oCall.observation.slice(0, 50)}...`
                  : oCall.observation}
              </span>
            </Tooltip>
          </Table.Col>
          <Table.Col className="text-center d-flex px-2">
            <TableAction
              iconName="edit"
              tooltip="Editar"
              className="m-auto"
              onClick={() => edit(oCall)}
            />
            <TableAction
              iconName="trash"
              tooltip="Eliminar"
              className="m-auto"
              onClick={() => this._delete(oCall)}
            />
          </Table.Col>
        </Table.Row>
      );
    });
  };

  render = () => {
    const { aCalls } = this.props.propsCalls;
    const { nCallsPerPage, currentPage } = this.state;
    return (
      <Card>
        <Card.Header>
          <Card.Title className="mr-auto">Listado de llamadas</Card.Title>
          {aCalls.data && aCalls.data.length > 0 && (
            <Button
              color="primary"
              className="ml-2"
              onClick={this._handleexportExcel}
            >
              <FontAwesomeIcon
                icon={faFileExcel}
                className="mr-2"
              ></FontAwesomeIcon>
              Exportar a excel
            </Button>
          )}
          {this.props.propsClient.oClientBoard.id && (
            <Button
              icon="plus"
              color="primary"
              className="ml-3"
              onClick={this.props.activeEdit}
            >
              Nuevo
            </Button>
          )}
        </Card.Header>
        {aCalls.data && aCalls.data.length > 0 ? (
          <>
            <div className="table-responsive">
              <Table className="card-table table-vcenter table-sm table-hover text-nowrap">
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader className="px-2">Tipo</Table.ColHeader>
                    <Table.ColHeader>Fecha</Table.ColHeader>
                    <Table.ColHeader>Hora</Table.ColHeader>
                    {/* Comentado columna de Duración a petición del cliente */}
                    {/* <Table.ColHeader>Duración</Table.ColHeader> */}
                    <Table.ColHeader>Motivo</Table.ColHeader>
                    <Table.ColHeader>Observación</Table.ColHeader>
                    <Table.ColHeader className="px-2"></Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{this.renderTable()}</Table.Body>
              </Table>
            </div>
            <Card.Footer>
              <Pagination
                nextPageAction={this._handleNextPage}
                previousPageAction={this._handlePreviousPage}
                goToPageAction={this._handleGoToPage}
                currentPage={currentPage}
                allRecords={aCalls.allRecords}
                showing={Array.isArray(aCalls.data) ? aCalls.data.length : 0}
                nLastPage={Math.ceil(
                  parseInt(aCalls.allRecords) / parseInt(nCallsPerPage)
                )}
                _handlechangeCount={this._handlechangeCount}
              />
            </Card.Footer>
          </>
        ) : (
          <Card.Body>
            <Grid.Row>
              <Grid.Col>
                <Alert type="info" icon="info">
                  No hemos obtenido datos de llamadas
                </Alert>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        )}
        {this.renderDeleteCallModal()}
      </Card>
    );
  };
}

const mapStateToProps = (state) => ({
  propsClient: state.clientsReducer,
  propsCalls: state.callsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  get: (nClientId, nPage, nCallsPerPage, sSearch) => {
    dispatch(actionGetCalls(nClientId, nPage, nCallsPerPage, sSearch));
  },
  cleanCalls: () => {
    dispatch(actionCleanCalls());
  },
  deleteCall: (oCall, nPage) => {
    dispatch(actionDeleteCall(oCall, nPage));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CallList);
