import { CONSTANTS_ACTIONS } from 'constants/ConstantsActions';

const aInitialState = {
    aDevices: [],
}
/**
 * Reducer Mostrar las Ciudades y codigo de area por departamento.
 * 
 * @param {Array} aDevice Estado del reducer, al momento de inicializarse se tomara aInitialState
 * @param {object} oAction Objeto donde se encuentra el tipo de estado que se desea actualizar y los respectivos datos
 * 
 * @author Franko Zerpa
 */
export const devicesReducer = (aState = aInitialState, oAction) => {
    switch (oAction.type) {
        case CONSTANTS_ACTIONS.DEVICES:
            return { ...aState, aDevices: oAction.data }
        default:
            return aState;
    }
}
