import React, { Component } from "react";
import { connect } from "react-redux";
import { actionGetNotifications, actionSetViewedNotifications } from "redux/actions/NotificationActions";
import { actionGetClient } from "redux/actions/ClientsActions";
import { actionGetShipments } from 'redux/actions/ShipmentsActions';
import Modal from "components/Modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';

class NotificationMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalNotifications: false
    }
  }

  componentDidMount = () => {
    this.props.getNotifications();
  }

  componentDidUpdate = () => { }

  _setViewedNotifications = async () => {
    await this.props.setViewedNotification();
  }

  goToReportsLoan = (oNotificationOverdueLoan) => {
    this.setState({ isModalNotifications: false });
    this.props.history.push('/loans/' + oNotificationOverdueLoan.client_id);
  };

  goToClient = (oNotificationOverdueLoan) => {
    this.setState({ isModalNotifications: false });
    if (this.props.history.location.pathname.includes('/clients/edit/')) {
      this.props.getClient(oNotificationOverdueLoan.client_id);
      this.props.history.push('/clients/edit/' + oNotificationOverdueLoan.client_id);
    }
    else
      this.props.history.push('/clients/edit/' + oNotificationOverdueLoan.client_id);
  };

  goToDevicesShipped = (oNotificationDeviceShipped) => {
    this.setState({ isModalNotifications: false });
    if (this.props.history.location.pathname.includes('/devicesShipped')) {
      this.props.getClient(oNotificationDeviceShipped.client_id);
      this.props.getDevicesShipped(oNotificationDeviceShipped.client_id);
      this.props.history.push('/devicesShipped');
    }
    else {
      this.props.getClient(oNotificationDeviceShipped.client_id);
      this.props.history.push('/devicesShipped');
    }
  }

  _handleCloseNotifications = () => {
    this.setState({ isModalNotifications: false });
  };

  loadNotificationsModal = async () => {
    await this.props.getNotifications();
    const { nCountNotifications } = this.props.propsNotification;
    if (nCountNotifications > 0)
      await this.props.setViewedNotification();
    this.setState({ isModalNotifications: true });
  };

  render() {
    const { nCountNotifications } = this.props.propsNotification;
    return (
      <>
        {
          nCountNotifications < 1 ?
            <div className="badgeNotification" onClick={this.loadNotificationsModal}>
              <FontAwesomeIcon icon={faBell} />
            </div>

            :
            <div className="badgeNotificationAlert pulsate" onClick={this.loadNotificationsModal}>
              <FontAwesomeIcon icon={faBell} />
            </div>
        }
        {this.renderNotificationsModal()}
      </>
    );
  }

  renderNotificationsModal = () => {
    const { isModalNotifications } = this.state;
    const { aNotifications } = this.props.propsNotification;
    const { aNotificationsOverdueLoans, aNotificationsUnfiledContracts, aNotificationsDevicesShipped } = aNotifications;
    return (
      <Modal
        isOpen={isModalNotifications}
        handleCloseModal={this._handleCloseNotifications}
        modalHideAction={true}
        modalCancelCaption="Cerrar"
        modalTitle="Notificaciones"
        customStyles={{ width: "800px" }}
      >
        <div className="notifications-modal">
          <h4>Préstamos Vencidos</h4>
          <hr className="notifications_hr" />
          <div className="notifications_container">
            {
              (aNotificationsOverdueLoans && aNotificationsOverdueLoans.length > 0) ? (
                aNotificationsOverdueLoans.map((oNotificationOverdueLoan, index) => {
                  return <div key={index + "overdue_loan"} className="notifications_line" onClick={() => this.goToReportsLoan(oNotificationOverdueLoan)}>&nbsp;{oNotificationOverdueLoan.content}</div>
                })
              ) : ""
            }
          </div>
          <h4>Contratos sin archivar</h4>
          <hr className="notifications_hr" />
          <div className="notifications_container">
            {
              (aNotificationsUnfiledContracts && aNotificationsUnfiledContracts.length > 0) ? (
                aNotificationsUnfiledContracts.map((oNotificationUnfiledContract, index) => {
                  return <div key={index + "unfiled_contract"} className="notifications_line" onClick={() => this.goToClient(oNotificationUnfiledContract)}>&nbsp;{oNotificationUnfiledContract.content}</div>
                })
              ) : ""
            }
          </div>
          <h4>Envíos</h4>
          <hr className="notifications_hr" />
          <div className="notifications_container">
            {
              (aNotificationsDevicesShipped && aNotificationsDevicesShipped.length > 0) ? (
                aNotificationsDevicesShipped.map((oNotificationDeviceShipped, index) => {
                  return <div key={index + "devices_shipped"} className="notifications_line" onClick={() => this.goToDevicesShipped(oNotificationDeviceShipped)}>&nbsp;{oNotificationDeviceShipped.content}</div>
                })
              ) : ""
            }
          </div>
        </div>
      </Modal>
    );
  };
}

const mapStateToProps = (state) => ({
  propsNotification: state.notificationsReducer
});

const mapDispatchToProps = (dispatch) => ({
  getNotifications: () => {
    dispatch(actionGetNotifications());
  },
  setViewedNotification: () => {
    dispatch(actionSetViewedNotifications());
  },
  getClient: (idClient) => {
    dispatch(actionGetClient(idClient));
  },
  getDevicesShipped: (nClientId) => {
    dispatch(actionGetShipments(nClientId));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationMenu));
