import { CONSTANTS } from 'constants/Constants';
import oAxiosInstance from 'redux/interceptors/Interceptors';

const MOBILE_EMERGENCIES_URL = CONSTANTS.API_URL + 'mobileemergencies';

/**
 * Funcion para toma todas las emergencias moviles del sistema
 * 
 * @author Leandro Curbelo
 */
export const GetMobileEmergencies = () => {
    return oAxiosInstance.get(MOBILE_EMERGENCIES_URL).then(oResult => oResult.data)
}
/**
 * Funcion para toma todas las emergencias moviles de un cliente.
 * !Verificar el uso de esta funcion, de lo contrario eliminarla
 * 
 * @param {number} nClientId Identificador del cliente del cual se tomaran sus emergencias mobiles
 * 
 * @author Leandro Curbelo
 */
export const GetMobileEmergenciesByClient = (nClientId) => {
    return oAxiosInstance.post(MOBILE_EMERGENCIES_URL+'/client/', { client_id: nClientId }).then(oResult => oResult.data)
}

export const DeleteMobileEmergency = (nMobileEmergencyId) => {
    return oAxiosInstance.delete(MOBILE_EMERGENCIES_URL+'/'+nMobileEmergencyId).then(oResult => oResult.data) 
}
