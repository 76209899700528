import { CONSTANTS_ACTIONS } from "constants/ConstantsActions";

// Estado inicial del reducer
const aInitialState = {
  nTotal: 0,
  nCompleted: 0,
  nProgress: 0,
  sBgcolor: "#467fcf",
  isVisible: false,
};

export const progressBarReducer = (state = aInitialState, action) => {
  switch (action.type) {
    case CONSTANTS_ACTIONS.PROGRESSBAR_SET_TOTAL:
      return { ...state, nTotal: action.nTotal };
    case CONSTANTS_ACTIONS.PROGRESSBAR_PROGRESS:
      let nCompleted = action.nCompleted;
      let nProgress = (nCompleted * 100) / state.nTotal;
      if (nProgress >= 100) {
        setTimeout(() => {
          return { ...state, nCompleted: 0, nProgress: 0, isVisible: false };
        }, 1000);
        return { ...state, nCompleted: nCompleted, nProgress: 100 };
      } else {
        return { ...state, nCompleted: nCompleted, nProgress: Math.ceil(nProgress) };
      }
    case CONSTANTS_ACTIONS.PROGRESSBAR_SHOW:
      return { ...state, isVisible: true };
    case CONSTANTS_ACTIONS.PROGRESSBAR_HIDE:
      return { ...state, isVisible: false, nProgress: 0, nTotal: 0, nCompleted: 0 };
    default:
      return state;
  }
};
