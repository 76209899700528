import React, { Component, Fragment } from "react";
import { ListPageTitle } from "components/ListPageTitle";
import { connect } from "react-redux";
import {
  actionGetClient,
  actionGetClients,
  actionResetClient,
  actionUnsubscribeClient,
  actionCleanClients,
  actionUpdateUnsubscribeClient,
  actionDeleteConyuge
} from "redux/actions/ClientsActions";
import { Card, Form, Table, Alert, Grid, Tag } from "tabler-react";
import { TableAction } from "components/buttons/TableAction";
import { Pagination } from "components/Pagination";
import Modal from "components/Modal";
import { CONSTANTS } from "constants/Constants";
import TableColAddress from "components/table/TableColAddress";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import { Helpers } from "helpers/Helpers";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

class ClientList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeOutId: null,
      nAllRecords: this.props.nAllRecords,
      search: "",
      currentPage: 1,
      nClientsPerPage: 10,
      nclientId: null,
      oClientToUnsubscribe: {},
      isModalUnsubscribeOpen: false,
      isModalDeleteConyuge: false,
      nDeleteConyuge: null,
      sMessageDeleteConyuge: "",
      selectedMotive: 1,
      selectedDate: Helpers.DateNowTz(),
      UnsubscribeMotivesToLoad: [],
      UnsubscribeMotives: [
        { id: 1, name: "Fallecimiento" },
        { id: 2, name: "Residencial" },
        { id: 3, name: "Acompañado 24 horas" },
        { id: 4, name: "No lo usan" },
        { id: 5, name: "Problemas económicos" },
        { id: 6, name: "Otros" },
      ],
      midesUnsubscribeMotives: [
        { id: 1, name: "Fallecimiento del usuario" },
        { id: 2, name: "Pérdida de interés en el servicio" },
        { id: 3, name: "Incumplimiento de condiciones pautadas en el contrato" },
        { id: 4, name: "Mudanza a zona sin cobertura de la empresa" },
        { id: 5, name: "Dejo de ser perfil" },
        { id: 6, name: "Otro" },
      ],
      nUnsubscription: null,
      sObservation: "",
      bEditUnsubscribe: false
    };
  }

  componentDidMount = () => {
    this.props.search();
  }

  componentWillUnmount = () => {
    this.props.cleanClients();
  }

  _handleSearchChange = (event) => {
    let { value } = event.target;
    this.setState({ currentPage: 1 });
    clearTimeout(this.state.timeOutId);
    let timeOutId = setTimeout(() => {
      this.props.search(0, this.state.nClientsPerPage, value);
    }, 800);
    this.setState({ search: value, timeOutId });
  };

  _handlechangeCount = async (event) => {
    let { value } = event.target;
    let { search } = this.state;
    await this.setState({ nClientsPerPage: value, currentPage: 1 });
    this.props.search(0, value, search);
  };

  _handleNextPage = async () => {
    let nClientsPerPage = this.state.nClientsPerPage;
    let nPage = parseInt(this.state.currentPage) * parseInt(nClientsPerPage);
    let sSearch = this.state.search;
    await this.setState({ currentPage: this.state.currentPage + 1 });
    this.props.search(nPage, this.state.nClientsPerPage, sSearch);
  };
  _handlePreviousPage = async () => {
    await this.setState({ currentPage: this.state.currentPage - 2 });
    let nClientsPerPage = this.state.nClientsPerPage;
    let nPage;
    if (this.state.currentPage <= 1) {
      nPage = 0;
    } else {
      nPage = this.state.currentPage * nClientsPerPage;
    }
    this.setState({ currentPage: this.state.currentPage + 1 });
    this.props.search(nPage, nClientsPerPage, this.state.search);
  };
  _handleGoToPage = async (nPage) => {
    let nClientsPerPage = this.state.nClientsPerPage;
    this.setState({ currentPage: nPage });
    let nSkip;
    if (this.state.currentPag <= 1) {
      nSkip = 0;
    } else {
      nSkip = (nPage - 1) * nClientsPerPage;
    }
    this.props.search(nSkip, nClientsPerPage, this.state.search);
  };

  _handleChange = (oEvent) => {
    const { name, value } = oEvent.target;
    this.setState({ [name]: value });
  }
  _handleEditUnsubscription = (oEvent, oClient) => {
    let { unsubscriptions } = oClient;
    if (unsubscriptions)
      this.setState({
        UnsubscribeMotivesToLoad: oClient.midespartner ? this.state.midesUnsubscribeMotives : this.state.UnsubscribeMotives,
        nUnsubscription: unsubscriptions.id,
        bEditUnsubscribe: true,
        oClientToUnsubscribe: oClient,
        selectedMotive: unsubscriptions.reason,
        selectedDate: Helpers.GetDateObject(unsubscriptions.requestdate),
        sObservation: unsubscriptions.observation,
        isModalUnsubscribeOpen: true
      });
  }

  renderTable = () => {
    return this.props.propsClients.aClients.data.map((oClient) => {
      return (
        <Table.Row key={"client" + oClient.id}>
          <Table.Col className="cursor-pointer">
            <div className='w-100' onClick={(event) => this.goToView(event, oClient.id, "/clients/edit/" + oClient.id)}>
              <span className="custom-control" style={{ width: '150px' }} color="secondary">
                {
                  (oClient.fullname && oClient.fullname.trim())
                    ? oClient.fullname
                    : <i className='text-muted'>Cliente sin nombre</i>
                }
              </span>
            </div>
          </Table.Col>
          <Table.Col>{oClient.code}</Table.Col>
          <Table.Col>
            {oClient.principal_phone && oClient.principal_phone}
          </Table.Col>
          <TableColAddress address={oClient.principal_address} />
          <Table.Col>{oClient.show_device}</Table.Col>
          
            <Table.Col className="text-center isActive-indicator">
              {parseInt(oClient.active) === 2 ? (
                <Tag color="orange" onClick={(oEvent) => this._handleEditUnsubscription(oEvent, oClient)}>Pendiente</Tag>
              ) : (
                <label
                  className="custom-control custom-checkbox d-inline danger"
                  onClick={(event) => event.stopPropagation()}
                >
                  <input
                    className="custom-control-input custom-control custom-checkbox d-inline"
                    type="checkbox"
                    value=""
                    onChange={(event) =>
                      this._handleOpenUnsubscribeModal(event, oClient)
                    }
                    checked={parseInt(oClient.active) !== 0 ? 1 : 0}
                    color={parseInt(oClient.active) === 2 ? "orange" : "red"}
                  />
                  <span className="custom-control-label"> </span>
                </label>
              )}
            </Table.Col>
            
         

          
          <Table.Col className="text-center d-flex">
            <TableAction
              iconName="phone"
              tooltip="Historial de llamadas"
              onClick={(event) =>
                this.goToView(event, oClient.id, `/clients/callsboard/${oClient.id}`)
              }
            />
            <TableAction
              iconName="layers"
              tooltip="Préstamos"
              onClick={(event) => this.goToView(event, null, `/loans/${oClient.id}`)}
            />
            <TableAction
              iconName="box"
              tooltip="Envíos"
              onClick={(event) =>
                this.goToView(event, oClient.id, "/devicesShipped")
              }
            />
            {
              oClient.spouse && oClient.spouse != "0" ?
                <TableAction
                  bFontAwesome={true}
                  iconName={faTrash}
                  tooltip="Eliminar Cónyuge"
                  onClick={() => this.downConyuge(oClient)} /> : ""
            }
          </Table.Col>
        </Table.Row>
      );
    });
  };

  goToView = async (oEvent, nClientId, sRoute) => {
    oEvent.stopPropagation();
    if (nClientId)
      await this.props.getClient(nClientId);
    this.props.history.push(sRoute);
  };

  _handleDateChange = (selectedDate) => {
    this.setState({ selectedDate });
  };

  renderUnsubscribeModal = () => {
    const {
      oClientToUnsubscribe,
      selectedMotive,
      selectedDate,
      UnsubscribeMotivesToLoad,
      sObservation,
      isModalUnsubscribeOpen
    } = this.state;
    return (
      <Modal
        isOpen={isModalUnsubscribeOpen}
        handleActionModal={this._handleSaveUnsubscribe}
        handleCloseModal={this._handleCloseModal}
        modalTitle="Solicitar baja"
        customStyles={{
          width: "500px",
        }}
      >
        <Grid.Row>
          <Grid.Col>
            <Form.Group label="Motivo de baja">
              <Form.Select
                name="selectedMotive"
                value={selectedMotive}
                onChange={this._handleChange}
              >
                {UnsubscribeMotivesToLoad.map((oElem) => {
                  return (
                    <option key={"reason" + oElem.id} value={oElem.id}>
                      {oElem.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group label="Fecha de Baja">
              <DatePicker
                className="form-control"
                locale={es}
                dateFormat="dd/MM/yyyy"
                selected={selectedDate}
                onChange={this._handleDateChange}
                showYearDropdown
              />
            </Form.Group>
            <Form.Group label="Observación">
              <Form.Textarea
                name="sObservation"
                placeholder="Observación"
                value={sObservation}
                onChange={this._handleChange}
                rows={4}
              />
            </Form.Group>
            {oClientToUnsubscribe.midespartner !== null &&
              selectedMotive === 6 && (
                <Form.Group label="Especifique">
                  <Form.Textarea name="" placeholder="Especifique" rows={4} />
                </Form.Group>
              )}
          </Grid.Col>
        </Grid.Row>
      </Modal>
    );
  };

  _handleOpenUnsubscribeModal = (event, oClient) => {
    event.stopPropagation();
    this.setState({
      oClientToUnsubscribe: oClient,
      UnsubscribeMotivesToLoad:
        oClient.midespartner !== null
          ? this.state.midesUnsubscribeMotives
          : this.state.UnsubscribeMotives,
      isModalUnsubscribeOpen: true,

    });
  };

  _handleSaveUnsubscribe = () => {
    // Manejar la baja del cliente
    const { currentPage: nPage, nClientsPerPage, search: sSearch, oClientToUnsubscribe, selectedMotive, selectedDate, nUnsubscription, sObservation, bEditUnsubscribe } = this.state;
    let nSkip = 0;
    if (nPage > 1)
      nSkip = (nPage - 1) * nClientsPerPage;
    if (bEditUnsubscribe)
      this.props.editUnsubscribe(nUnsubscription, oClientToUnsubscribe.id, selectedMotive, selectedDate, sObservation, { nPage: nSkip, nClientsPerPage, sSearch });
    else
      this.props.unsubscribe(oClientToUnsubscribe.id, selectedMotive, selectedDate, sObservation, { nPage: nSkip, nClientsPerPage, sSearch });
    this.setState({
      oClientToUnsubscribe: {},
      selectedMotive: 1,
      isModalUnsubscribeOpen: false,
      selectedDate: Helpers.DateNowTz(),
      sObservation: "",
      nUnsubscription: null,
      bEditUnsubscribe: false
    });
  };
  _handleexportExcel = () => {
    window.open(CONSTANTS.API_URL + "reportclients?search=" + this.state.search, "_blank");
  };
  _handleCloseModal = () => {
    this.setState({
      oClientToUnsubscribe: {},
      selectedMotive: 1,
      isModalUnsubscribeOpen: false,
      sObservation: "",
      nUnsubscription: null,
      bEditUnsubscribe: false
    });
  };

  renderDeleteConyuge = () => {
    const { isModalDeleteConyuge, sMessageDeleteConyuge } = this.state;
    return (
      <Modal
        isOpen={isModalDeleteConyuge}
        handleActionModal={this._handleDeleteConyuge}
        handleCloseModal={this._handleCloseModalDeleteConyuge}
        modalTitle="Eliminar cónyuge?"
        modalActionFaIcon={faTrash}
        modalActionCaption="Eliminar"
        customStyles={{
          width: "500px",
        }}
      >
        <Grid.Row>
          <Grid.Col>{sMessageDeleteConyuge}</Grid.Col>
        </Grid.Row>
      </Modal>
    );
  };

  downConyuge = (oClient) => {
    let sMessage = `Desea eliminar el cónyuge ${oClient.lastname ? oClient.lastname : ""} ${oClient.name ? oClient.name : ""}?`;
    this.setState({ nDeleteConyuge: oClient.id, sMessageDeleteConyuge: sMessage, isModalDeleteConyuge: true });
  }

  _handleDeleteConyuge = () => {
    const { nDeleteConyuge, currentPage, nClientsPerPage, search } = this.state;
    this.props.deleteConyuge(nDeleteConyuge, currentPage, nClientsPerPage, search);
    this.setState({ nDeleteConyuge: null, sMessageDeleteConyuge: "", isModalDeleteConyuge: false });
  }

  _handleCloseModalDeleteConyuge = () => {
    this.setState({ nDeleteConyuge: null, sMessageDeleteConyuge: "", isModalDeleteConyuge: false });
  }


  render = () => {
    const { aClients } = this.props.propsClients; //destructuracion de objeto
    const { nClientsPerPage, currentPage } = this.state;

    return (
      <Card>
        <ListPageTitle
          addButton={true}
          searchField={true}
          searchPlaceholder="Buscar"
          searchChange={this._handleSearchChange}
          exportExcel={this._handleexportExcel}
        >
          Listado de clientes
        </ListPageTitle>
        {Array.isArray(aClients.data) && aClients.data.length > 0 ? (
          <>
            <div className="table-responsive">
              <Table className="card-table table-vcenter table-sm table-hover">
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Apellido Nombre</Table.ColHeader>
                    <Table.ColHeader>Nro de Socio</Table.ColHeader>
                    <Table.ColHeader>Teléfono</Table.ColHeader>
                    <Table.ColHeader>Domicilio</Table.ColHeader>
                    <Table.ColHeader>Aparato</Table.ColHeader>
                    <Table.ColHeader alignContent="center">
                      Activo
                    </Table.ColHeader>
                    <Table.ColHeader></Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{this.renderTable()}</Table.Body>
              </Table>
            </div>
            <Card.Footer>
              <Pagination
                nextPageAction={this._handleNextPage}
                previousPageAction={this._handlePreviousPage}
                goToPageAction={this._handleGoToPage}
                currentPage={currentPage}
                allRecords={aClients.allRecords}
                showing={Array.isArray(aClients.data) ? aClients.data.length : 0}
                nLastPage={Math.ceil(
                  parseInt(aClients.allRecords) / parseInt(nClientsPerPage)
                )}
                showAllOptions={true}
                _handlechangeCount={this._handlechangeCount}
              />
            </Card.Footer>
          </>
        ) : (
          <Card.Body>
            <Grid.Row>
              <Grid.Col>
                <Alert type="info" icon="info">
                  No hemos obtenido datos de clientes
                </Alert>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        )}
        {this.renderUnsubscribeModal()}
        {this.renderDeleteConyuge()}
      </Card>
    );
  };
}

const mapStateToProps = (state) => ({
  propsLogin: state.loginReducer,
  propsClients: state.clientsReducer,
  nAllRecords: state.nAllRecordsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getClient: (idClient) => {
    dispatch(actionGetClient(idClient));
  },
  resetClient: () => {
    dispatch(actionResetClient());
  },
  unsubscribe: (nClientId, sReason, dDate, sObservation, oPaginator) => {
    dispatch(actionUnsubscribeClient(nClientId, sReason, dDate, sObservation, oPaginator));
  },
  editUnsubscribe: (nUnsubscription, nClientId, sReason, dDate, sObservation, oPaginator) => {
    dispatch(actionUpdateUnsubscribeClient(nUnsubscription, nClientId, sReason, dDate, sObservation, oPaginator));
  },
  search: (nPage, nClientsPerPage, sSearch) => {
    dispatch(actionGetClients(nPage, nClientsPerPage, sSearch));
  },
  cleanClients: () => {
    dispatch(actionCleanClients());
  },
  deleteConyuge: (nClientId, nPage, nClientsPerPage, sSearch) => {
    dispatch(actionDeleteConyuge(nClientId, nPage, nClientsPerPage, sSearch));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientList);
