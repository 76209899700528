import { CONSTANTS_ACTIONS } from 'constants/ConstantsActions';
import { CONSTANTS_MESSAGE } from 'constants/ConstantsMessages';
import { actionActiveLoading, actionDesactiveLoading, actionDispatch, actionMessage, actionSetResponseCode } from 'redux/actions/SharedActions';
import { GetDevices, DeleteDevice } from 'redux/services/DevicesServices';

/**
 * Funcion que toma todos los departamentos de  Uruguay.
 * 
 * @author Franko Zerpa
 */
export const actionGetDevices = (nStateId) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        GetDevices(nStateId).then(oSuccess => {
            dispatch(actionDispatch(CONSTANTS_ACTIONS.DEVICES, oSuccess.data));
        }).catch((oError) => {
            dispatch(actionDispatch(CONSTANTS_ACTIONS.DEVICES, []));
            dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
};
export const actionDeleteDevice = (nDeviceid) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        DeleteDevice(nDeviceid).then(oSuccess => {
            dispatch(actionGetDevices());
            dispatch(actionMessage(oSuccess.message, CONSTANTS_MESSAGE.TYPE_SUCCESS))
        }).catch((oError) => {
            dispatch(actionDispatch(CONSTANTS_ACTIONS.DELETE_DEVICE, []));
            dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
        })
    }
};
