import { CONSTANTS_ACTIONS } from 'constants/ConstantsActions';
import { CONSTANTS_MESSAGE } from 'constants/ConstantsMessages';
import { actionActiveLoading, actionDesactiveLoading, actionDispatch, actionMessage, actionSetResponseCode } from 'redux/actions/SharedActions';
import { GetUser, GetUsers, GetUsersPerDate, SaveUser, UpdateUser, DeleteUser } from 'redux/services/UserServices';

/**
 * Funcion que toma todos los useres del sistema.
 * 
 * @param {number} nPage Numero de pagina que se intenta obtener
 * @param {number} nUsersPerPage Numero de useres por paginas
 * 
 * @author
 */
export const actionGetUsers = (nPage = 0, nUsersPerPage = 10,sSearch = "") => {
    return dispatch => {
        dispatch(actionActiveLoading());
        GetUsers(nPage=0, nUsersPerPage=10,sSearch=null).then(oSuccess => {
            dispatch(actionDispatch(CONSTANTS_ACTIONS.USERS, oSuccess.data));
        }).catch((oError) => {
            if (oError.response){
                dispatch(actionSetResponseCode(oError.response.status));
            }
            dispatch(actionDispatch(CONSTANTS_ACTIONS.USERS, []));
            dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
};
/** 
 * Funcion que toma todos los useres del sistema.
 * 
 * @param {number} nPage Numero de pagina que se intenta obtener
 * @param {number} nUsersPerPage Numero de useres por paginas
 * 
 * @author
 */
export const actionGeUsersPerDate = (nPage = 1, nUsersPerPage = 20,sSearch = "",sFilterDate="",dStartDate=null,dEndDate=null) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        GetUsersPerDate(
            nPage,
            nUsersPerPage,
            sSearch,
            sFilterDate,
            dStartDate,
            dEndDate).then(oSuccess => {
            dispatch(actionDispatch(CONSTANTS_ACTIONS.USERS, oSuccess.data));
        }).catch((oError) => {
            if (oError.response){
                dispatch(actionSetResponseCode(oError.response.status));
            }
            dispatch(actionDispatch(CONSTANTS_ACTIONS.USERS, []));
            dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
};


/**
 * Funcion que obtiene un usere del sistema.
 * 
 * @param {number} nUserId ide del usere
 * 
 * @author 
 */

export const actionGetUser = (nUserId) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        GetUser(nUserId).then(oSuccess => {
            
            dispatch(actionDispatch(CONSTANTS_ACTIONS.USER, oSuccess.data));
        }).catch((oError) => {
            if (oError.response){
                dispatch(actionSetResponseCode(oError.response.status));
            }
            dispatch(actionDispatch(CONSTANTS_ACTIONS.USER, []));
            dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
};

/**
 * Funcion que registra un  usere en el sistema.
 * 
 * @param {object} oData objeto usere
 * 
 * @author 
 */

export const actionSaveUser = (oData) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        SaveUser(oData).then((oSuccess) => {
          if (oSuccess.success){
            dispatch(actionMessage("Guardado correctamente",CONSTANTS_MESSAGE.TYPE_SUCCESS ));
            dispatch(actionGetUsers()); //enviar a listado de users
          }else{
            dispatch(actionMessage(oSuccess.message, CONSTANTS_MESSAGE.TYPE_ERROR));
          }
        }).catch((oError) => {
            if (oError.response){
                dispatch(actionSetResponseCode(oError.response.status));
            }
            dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
};


/**
 * Funcion que registra un  cliente en el sistema.
 * 
 * @param {object} oData objeto cliente
 * 
 * @author 
 */

export const actionUpdateUser = (oData) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        UpdateUser(oData).then(oSuccess => {
            dispatch(actionMessage("Actualizado correctamente",CONSTANTS_MESSAGE.TYPE_SUCCESS ));
            dispatch(actionGetUsers()); //enviar a listado de usuarios
        }).catch((oError) => {
            if (oError.response){
                dispatch(actionSetResponseCode(oError.response.status));
            }
            dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
};


export const actionDeleteUser = (nUserId) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        DeleteUser(nUserId).then(oSuccess => {
            dispatch(actionDispatch(CONSTANTS_ACTIONS.DELETE_USER, oSuccess.data));
            dispatch(actionGetUsers());
        }).catch((oError) => {
            if (oError.response){
                dispatch(actionSetResponseCode(oError.response.status));
            }
            dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
};


export const actionResetUsers = () => ({
        type: CONSTANTS_ACTIONS.RESET_USERS
    });
