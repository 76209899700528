import { CONSTANTS_ACTIONS } from 'constants/ConstantsActions';

export const actionProgressBarSetTotal = (nTotal) => ({
  type: CONSTANTS_ACTIONS.PROGRESSBAR_SET_TOTAL,
  nTotal: nTotal
});

export const actionShowProgressBar = () => ({
  type: CONSTANTS_ACTIONS.PROGRESSBAR_SHOW
});

export const actionHideProgressBar = () => ({
  type: CONSTANTS_ACTIONS.PROGRESSBAR_HIDE
});

export const actionProgressBarProgress = (nCount) => ({
  type: CONSTANTS_ACTIONS.PROGRESSBAR_PROGRESS,
  nCompleted: nCount
});
