import React, { Component } from "react";
import { Card, Form, Table, Alert, Grid, Button } from "tabler-react";
import { connect } from "react-redux";
import {
  actionGeClientsPerDate,
  actionGetClient,
} from "redux/actions/ClientsActions";
import {
  actionGetDevicesShipped,
  actionCleanReports,
} from "redux/actions/ReportsActions";
import { Pagination } from "components/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { CONSTANTS } from "constants/Constants";
import TableColSmall from "components/table/TableColSmall";
import { actionDevolutionShipping } from "redux/actions/ShipmentsActions";
import { Helpers } from "helpers/Helpers";

class ReportDevicesShipped extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      currentPage: 1,
      nClientsPerPage: 10,
      nclientId: null,
      nMonth: parseInt(Helpers.ConvertTZ(new Date()).getMonth()) + 1,
      nYear: Helpers.ConvertTZ(new Date()).getFullYear(),
      sFilterDate: "devicesShipped",
      oTimeOutId: null,
    };
  }

  get = async () => {
    await this.props.get(0, this.state.nClientsPerPage, this.state.search);
  };

  componentDidMount() {
    this.getYears();
    this.get();
  }
  componentWillUnmount = () => {
    this.props.cleanReports();
  };

  _handleChangeDevolution = (nShippmentId) => {
    this.props.devolutionDeviceShipped(nShippmentId);
  };

  getYears = () => {
    let date = Helpers.ConvertTZ(new Date());
    let actualYear = date.getFullYear();
    let actualMonth = date.getMonth() + 1;
    if (this.state.nMonth == null) {
      this.setState({ nYear: actualYear, nMonth: actualMonth });
    }
    let yearsList = [];
    for (let i = actualYear; i > actualYear - 5; i--) {
      yearsList.push(i);
    }
    return yearsList;
  };

  _handleSearchChange = (event) => {
    let { value } = event.target;
    clearTimeout(this.state.oTimeOutId);
    let oTimeOutId = setTimeout(() => {
      this.props.get(0, this.state.nClientsPerPage, value);
    }, 800);
    this.setState({ search: value, oTimeOutId });
  };

  _handleChangeMonth = (event) => {
    let { value } = event.target;
    this.setState({ currentPage: 1, nMonth: value });

    this.props.get(0, this.state.nClientsPerPage, null,);
  };

  _handleChangeYear = (event) => {
    let { value } = event.target;
    this.setState({ currentPage: 1, nYear: value });
    this.props.get(0, this.state.nClientsPerPage, null);
  };

  _handlechangeCount = async (oEvent) => {
    let { value } = oEvent.target;
    await this.setState({ nClientsPerPage: value, currentPage: 1 });
    this.props.get(0, this.state.nClientsPerPage, this.state.search);
  };

  _handleNextPage = async () => {
    let nClientsPerPage = this.state.nClientsPerPage;
    let nPage = this.state.currentPage * nClientsPerPage;
    await this.setState({ currentPage: this.state.currentPage + 1 });
    this.props.get(nPage, this.state.nClientsPerPage, this.state.search);
  };

  _handlePreviousPage = async () => {
    await this.setState({ currentPage: this.state.currentPage - 2 });
    let nClientsPerPage = this.state.nClientsPerPage;
    let nPage;
    nPage = this.state.currentPage * nClientsPerPage;
    this.setState({ currentPage: this.state.currentPage + 1 });
    this.props.get(nPage, this.state.nClientsPerPage, this.state.search);
  };

  _handleGoToPage = async (nPage) => {
    let nClientsPerPage = this.state.nClientsPerPage, nSkip = 0;
    nSkip = (nPage - 1) * nClientsPerPage;
    await this.setState({ currentPage: nPage });
    this.props.get(nSkip, nClientsPerPage, this.state.search);
  };

  _handleexportExcel = () => {
    window.open(CONSTANTS.API_URL + "reports/excel/devicesShipped?search=" + this.state.search, "_blank");
  };

  goToView = (event, nClientId, sRoute) => {
    event.stopPropagation();
    this.props.getClient(nClientId);
    this.props.history.push(sRoute);
  };

  renderTable = () => {
    const { aDevicesShipped } = this.props.oDevicesShipped;
    return aDevicesShipped.data.map((oShippment, index) => {
      return (
        <Table.Row key={"client" + oShippment.id}>
          <Table.Col className="cursor-pointer">
            <span onClick={(event) => this.goToView(event, oShippment.client_id, "/clients/edit/" + oShippment.client_id)}>
              {oShippment.shippingdate && oShippment.shippingdate.split("-").reverse().join("/")}
            </span>
          </Table.Col>
          <Table.Col>{oShippment.fullname.trim() ? oShippment.fullname : <i className='text-muted'>Cliente sin nombre</i>}</Table.Col>
          <Table.Col>{oShippment.shippingname}</Table.Col>
          <TableColSmall value={oShippment.destination} />
          <TableColSmall value={oShippment.shippingagency} />
          <Table.Col className="text-center isActive-indicator">
            {oShippment.trackingcode && oShippment.trackingcode}
          </Table.Col>
          <Table.Col className="text-center isActive-indicator">
            <div className='form-group vertical-align m-1'>
              <label className="custom-switch">
                <input type="checkbox" name="devolution" className="custom-switch-input" nDeviceShippedId={oShippment.shipping_id} defaultChecked={oShippment.devolution} onChange={() => this._handleChangeDevolution(oShippment.id)} />
                <span className="custom-switch-indicator"></span>
                <span className="custom-switch-description font-weight-bold"></span>
              </label>
            </div>
          </Table.Col>
          <TableColSmall value={oShippment.shippingobservation} />
        </Table.Row>
      );
    });
  };

  render = () => {
    const { aDevicesShipped } = this.props.oDevicesShipped;
    const { nClientsPerPage, search, currentPage } = this.state;
    return (
      <Card>
        <Card.Header>
          <Card.Title className="mr-auto">Envíos</Card.Title>
          <div className="card-filters mr-2">
            <Form.Input
              icon="search"
              placeholder={"Buscar"}
              value={search}
              onChange={this._handleSearchChange}
            />
          </div>
          {aDevicesShipped.data && aDevicesShipped.data.length > 0 ? (
            <Button
              color="primary"
              className="ml-2"
              onClick={this._handleexportExcel}
            >
              <FontAwesomeIcon
                icon={faFileExcel}
                className="mr-2"
              ></FontAwesomeIcon>
              Exportar a excel
            </Button>
          ) : (
            ""
          )}
        </Card.Header>
        {aDevicesShipped.data && aDevicesShipped.data.length > 0 ? (
          <>
            <div className="table-responsive">
              <Table className="card-table table-vcenter table-sm table-hover text-nowrap">
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Fecha</Table.ColHeader>
                    <Table.ColHeader>Socio</Table.ColHeader>
                    <Table.ColHeader>Nombre</Table.ColHeader>
                    <Table.ColHeader>Destino</Table.ColHeader>
                    <Table.ColHeader>Agencia</Table.ColHeader>
                    <Table.ColHeader alignContent="center">
                      Nro Rastreo
                    </Table.ColHeader>
                    <Table.ColHeader alignContent="center">
                      Devolución
                    </Table.ColHeader>
                    <Table.ColHeader alignContent="center">
                      Observación
                    </Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{this.renderTable()}</Table.Body>
              </Table>
            </div>
            <Card.Footer>
              <Pagination
                nextPageAction={this._handleNextPage}
                previousPageAction={this._handlePreviousPage}
                goToPageAction={this._handleGoToPage}
                currentPage={currentPage}
                allRecords={aDevicesShipped.allRecords}
                showing={Array.isArray(aDevicesShipped.data) ? aDevicesShipped.data.length : 0}
                nLastPage={Math.ceil(
                  parseInt(aDevicesShipped.allRecords) /
                  parseInt(nClientsPerPage)
                )}
                showAllOptions={true}
                _handlechangeCount={this._handlechangeCount}
              />
            </Card.Footer>
          </>
        ) : (
          <Card.Body>
            <Grid.Row>
              <Grid.Col>
                <Alert type="info" icon="info">
                  No hemos obtenido datos de clientes
                </Alert>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        )}
      </Card>
    );
  };
}

const mapStateToProps = (state) => ({
  oClients: state.clientsReducer,
  oDevicesShipped: state.reportsReducers,
});

const mapDispatchToProps = (dispatch) => ({
  getClient: (idClient) => {
    dispatch(actionGetClient(idClient));
  },
  get: (nPage, nClientsPerPage, sSearch) => {
    dispatch(actionGetDevicesShipped(nPage, nClientsPerPage, sSearch));
  },
  devolutionDeviceShipped: (nDeviceShippedId) => {
    dispatch(actionDevolutionShipping(nDeviceShippedId));
  },
  cleanReports: () => {
    dispatch(actionCleanReports());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportDevicesShipped);
