import { CONSTANTS_ACTIONS } from 'constants/ConstantsActions';
import { CONSTANTS_MESSAGE } from 'constants/ConstantsMessages';
import { actionActiveLoading, actionDesactiveLoading, actionDispatch, actionMessage, actionSetResponseCode } from 'redux/actions/SharedActions';
import { GetCities } from 'redux/services/CitiesServices';

/**
 * Funcion que toma todos los departamentos de  Uruguay.
 * 
 * @author Franko Zerpa
 */
export const actionGetCities = (nStateId) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        GetCities(nStateId).then(oSuccess => {
            dispatch(actionDispatch(CONSTANTS_ACTIONS.CITIES, oSuccess.data));
        }).catch((oError) => {
            if (oError.response){
                dispatch(actionSetResponseCode(oError.response.status));
            }
            dispatch(actionDispatch(CONSTANTS_ACTIONS.CITIES, []));
            dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
};
