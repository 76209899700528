import React from 'react';
import { Button, Icon } from "tabler-react";

export function SaveButton({ saveAction, activeSave }) {
  return (
    <Button className="btn btn-primary ml-3" onClick={saveAction}>
      <Icon name="save" className="mr-2" />
      Guardar
    </Button>
  );
}