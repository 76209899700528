import { CONSTANTS_ACTIONS } from 'constants/ConstantsActions';

const aInitialState = {
    aStates: [],
}
/**
 * Reducer Mostrar los departamentos de uruguay.
 * 
 * @param {Array} aState Estado del reducer, al momento de inicializarse se tomara aInitialState
 * @param {object} oAction Objeto donde se encuentra el tipo de estado que se desea actualizar y los respectivos datos
 * 
 * @author Franko Zerpa
 */
export const statesReducer = (aState = aInitialState, oAction) => {
    switch (oAction.type) {
        case CONSTANTS_ACTIONS.STATES:
            return { ...aState, aStates: oAction.data }
        default:
            return aState;
    }
}
