import { CONSTANTS_ACTIONS } from 'constants/ConstantsActions';
import { CONSTANTS_MESSAGE } from 'constants/ConstantsMessages';
import { actionActiveLoading, actionDesactiveLoading, actionDispatch, actionMessage, actionSetResponseCode } from 'redux/actions/SharedActions';
import { CallAnswer, CallListener, DeleteCall, EndCallAnswer, GetCalls, SaveCall, UpdateCall } from 'redux/services/CallServices';
import { actionGetClient } from 'redux/actions/ClientsActions';

/**
 * Funcion que toma todos los lllamadas del sistema.
 * 
 * @param {number} nPage Numero de pagina que se intenta obtener
 * @param {number} nCallsPerPage Numero de lllamadas por paginas
 * 
 * @author
 */
export const actionGetCalls = (nClientId = null, nPage = 0, nCallsPerPage = 10, sSearch = "") => {
    return dispatch => {
        dispatch(actionActiveLoading());
        GetCalls(nClientId, nPage, nCallsPerPage, sSearch).then(oSuccess => {

            if (nCallsPerPage >= 10) {
                dispatch(actionDispatch(CONSTANTS_ACTIONS.CALLS, oSuccess.data));
            }
            else {
                dispatch(actionDispatch(CONSTANTS_ACTIONS.CALLSUMMARY, oSuccess.data));
            }

        }).catch((oError) => {
            if (oError.response) {
                dispatch(actionSetResponseCode(oError.response.status));
            }
            dispatch(actionDispatch(CONSTANTS_ACTIONS.CALLS, []));
            dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
};
/**
 * Funcion que registra un  lllamada en el sistema.
 * 
 * @param {object} oData objeto lllamada
 * 
 * @author 
 */
export const actionSaveCall = (oData, nPage = 1) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        SaveCall(oData).then(oSuccess => {
            dispatch(actionMessage("Guardado correctamente", CONSTANTS_MESSAGE.TYPE_SUCCESS));
            dispatch(actionGetCalls(oData.client_id, (nPage - 1) * 10, 10, null)); //enviar a listado de llamadas
            dispatch(actionGetCalls(oData.client_id, 0, 3, null)); //enviar a listado de llamadas
            dispatch(actionDispatch(CONSTANTS_ACTIONS.SAVE_CALL));
        }).catch((oError) => {
            if (oError.response) {
                dispatch(actionSetResponseCode(oError.response.status));
            }
            dispatch(actionMessage(oError));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
};
export const actionUpdateCall = (oData, nPage = 1) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        UpdateCall(oData).then(oSuccess => {
            dispatch(actionMessage("Actualizado correctamente", CONSTANTS_MESSAGE.TYPE_SUCCESS));
            dispatch(actionGetCalls(oData.client_id, (nPage - 1) * 10, 10, null)); //enviar a listado de llamadas
            dispatch(actionGetCalls(oData.client_id, 0, 3, null)); //enviar a listado de llamadas
            dispatch(actionGetClient(oData.client_id));
            dispatch(actionDispatch(CONSTANTS_ACTIONS.UPDATE_CALL));
        }).catch((oError) => {
            if (oError.response) {
                dispatch(actionSetResponseCode(oError.response.status));
            }
            dispatch(actionMessage(oError));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
};

export const actionResetCalls = () => ({
    type: CONSTANTS_ACTIONS.RESET_CALLS
});


export const actionCallListener = () => {
    return async dispatch => {
        CallListener().then(oSuccess => {
            dispatch(actionDispatch(CONSTANTS_ACTIONS.LISTENER_CALLS, { calls: oSuccess.data, call: oSuccess.call, last_call: oSuccess.last_call }));
        }).catch(() => { });
    }
}
/**
 * Función que asigna una llamada entrante a un operador, de esta forma, 
 * @param {number} nCallId Identificador principal de la llamada que se desea atender
 * 
 * @author Leandro Curbelo
 */
export const actionCallAnswer = (nCallId) => {
    return async dispatch => {
        CallAnswer(nCallId).catch(oError => {
            dispatch(actionMessage(oError.response.data.message ?? null, CONSTANTS_MESSAGE.TYPE_WARNING));
        });
    }
}
/**
 * Función que finaliza una llamada atendida por un operador de forma manual.
 *  
 * @param {number} nCallId Identificador principal de la llamada que se desea cortar
 * 
 * @author Leandro Curbelo
 */
export const actionEndCallAnswer = (nCallId) => {
    return async dispatch => {
        EndCallAnswer(nCallId).catch(oError => {
            dispatch(actionMessage(oError.response.data.message ?? null, CONSTANTS_MESSAGE.TYPE_WARNING));
        });
    }
}

export const actionDeleteCall = (oData, nPage = 1) => {
    return async dispatch => {
        DeleteCall(oData.id).then(oSuccess => {
            dispatch(actionGetCalls(oData.client_id, (nPage - 1) * 10, 10, null)); //enviar a listado de llamadas
            dispatch(actionGetCalls(oData.client_id, 0, 3, null)); //enviar a listado de llamadas
        }).catch(oError => {
            dispatch(actionMessage(oError.response.data.message ?? null, CONSTANTS_MESSAGE.TYPE_WARNING));
        });
    }
}

export const actionSetCallsNotified = (aNotified) => {
    return async dispatch => {
        dispatch(actionDispatch(CONSTANTS_ACTIONS.NOTIFIED_CALLS, aNotified))
    }
}
export const actionSetCallsIncoming = (aCallsNewIncoming) => {
    return async dispatch => {
        dispatch(actionDispatch(CONSTANTS_ACTIONS.CALLS_INCOMING, aCallsNewIncoming))
    }
}

export const actionCleanCallNavigation = () => {
    return async dispatch => {
        dispatch(actionDispatch(CONSTANTS_ACTIONS.CLEAN_CALL_NAVIGATION));
    }
}

export const actionCleanCallSummary = () => {
    return (dispatch) => {
        dispatch(actionDispatch(CONSTANTS_ACTIONS.CALLSUMMARY_CLEAR));
    };
};
export const actionCleanCalls = () => {
    return (dispatch) => {
        dispatch(actionDispatch(CONSTANTS_ACTIONS.CALLS_CLEAR));
    };
};