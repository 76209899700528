import { CONSTANTS_ACTIONS } from 'constants/ConstantsActions';
import { CONSTANTS_MESSAGE } from 'constants/ConstantsMessages';
import { actionActiveLoading, actionDesactiveLoading, actionDispatch, actionMessage, actionSetResponseCode } from 'redux/actions/SharedActions';
import { GetVoucherTypes } from 'redux/services/VoucherTypeServices';

/**
 * Funcion que toma todos los departamentos de  Uruguay.
 * 
 * @author Franko Zerpa
 */
export const actionGetVoucherTypes = () => {
    return dispatch => {
        dispatch(actionActiveLoading());
        GetVoucherTypes().then(oSuccess => {
            dispatch(actionDispatch(CONSTANTS_ACTIONS.VOUCHER_TYPES, oSuccess.data));
        }).catch((oError) => {
            if (oError.response){
                dispatch(actionSetResponseCode(oError.response.status));
            }
            dispatch(actionDispatch(CONSTANTS_ACTIONS.VOUCHER_TYPES, []));
            dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
};
