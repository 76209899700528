import { CONSTANTS_ACTIONS } from 'constants/ConstantsActions';

const aInitialState = {
    status: false
}

export const sessionReducer = (state = aInitialState, action) => {
    switch (action.type) {
        case CONSTANTS_ACTIONS.LOGIN:
            return { status: true }
        default:
            return state;
    }
}
