import React from 'react';
import { Form, Grid, Icon } from "tabler-react";
import { CONSTANTS_PAGINATION } from 'constants/ConstantsComponents';

export function Pagination(props) {
  let { goToPageAction, nextPageAction, previousPageAction, currentPage, itemsPerPage, _handlechangeCount, nLastPage, showAllOptions, allRecords, showing } = props;
  let classNameShow = "page-item";

  let classNameDisabled = "page-item disabled";
  let classNameHide = "d-none";
  return (
    <Grid.Row>
      <Grid.Col width={3} className="page-quantity-container">
        <Form.Group className={"mb-0"}>
          <Form.InputGroup>
            <Form.InputGroupPrepend>
              <Form.InputGroupText>
                Mostrar
              </Form.InputGroupText>
            </Form.InputGroupPrepend>
            <Form.Select name={'changeCount'} value={itemsPerPage} onChange={_handlechangeCount}>
              {
                CONSTANTS_PAGINATION.selectableItemsPerPage.map((elem, idx) => <option key={idx}>{elem}</option>)
              }
              {
                  showAllOptions ? <option key={CONSTANTS_PAGINATION.allRegister.value} value={CONSTANTS_PAGINATION.allRegister.value} >{CONSTANTS_PAGINATION.allRegister.description}</option> : ""
              }
            </Form.Select>
          </Form.InputGroup>
        </Form.Group>
      </Grid.Col>
      {/* Incluida la información del paginado en caso de solicitarla el cliente solo descomentar */}
      {/* Yubisel Vega Alvarez */}
      {/* <Grid.Col className="py-2"> 
        Mostrando <b>{showing}</b> registros de un total de <b>{allRecords}</b>
      </Grid.Col> */}
      <Grid.Col>
        <nav>
          <ul className="pagination justify-content-end mb-0">



            <li className={currentPage===1?classNameDisabled:classNameShow}>
              <a className="page-link" href="#" onClick={previousPageAction} tabIndex="-1" aria-disabled={currentPage <= 1 ? true : false}>
                <Icon name="chevrons-left" />
              </a>
            </li>

            <li className={currentPage <= 4 ? classNameHide : classNameShow} onClick={()=>goToPageAction(1)} >
              <a className="page-link" href="#">{1}</a>
            </li>
            <li className={currentPage <= 4 ? classNameHide : classNameShow} >
              <a className="page-link" href="#">...</a>
            </li>

            <li className={currentPage <= 3 ? classNameHide : classNameShow} onClick={()=>goToPageAction(parseInt(currentPage) - 3)}><a className="page-link" href="#">{currentPage - 3}</a></li>
            <li className={currentPage <= 2 ? classNameHide : classNameShow} onClick={()=>goToPageAction(parseInt(currentPage) - 2)}><a className="page-link" href="#">{currentPage - 2}</a></li>
            <li className={currentPage <= 1 ? classNameHide : classNameShow} onClick={()=>goToPageAction(parseInt(currentPage) - 1)}><a className="page-link" href="#">{currentPage - 1}</a></li>

          
            <li className="page-item active"><a className="page-link" href="#">{currentPage}</a></li>

            <li className={(currentPage >= parseInt(nLastPage)) ? classNameHide : classNameShow} 
                onClick={()=>goToPageAction(parseInt(currentPage) + 1)}>
                  <a className="page-link" href="#">{currentPage + 1}</a>
            </li>
            <li className={(parseInt(currentPage)+1 >= parseInt(nLastPage)) ? classNameHide : classNameShow} 
                onClick={()=>goToPageAction(parseInt(currentPage) + 2)}  >
                  <a className="page-link" href="#">{currentPage + 2}</a>
            </li>
            <li className={(parseInt(currentPage) >= parseInt(nLastPage) || (parseInt(currentPage) + 2) >= parseInt(nLastPage)) ? classNameHide : classNameShow} 
                onClick={()=>goToPageAction(parseInt(currentPage) + 3)} >
                  <a className="page-link" href="#">{currentPage + 3}</a>
            </li>

            <li className={currentPage >= (nLastPage-3) ? classNameHide : classNameShow} >
              <a className="page-link" href="#">...</a>
            </li>
            <li className={currentPage >= (nLastPage-3) ? classNameHide : classNameShow} onClick={()=>goToPageAction(parseInt(nLastPage))} ><a className="page-link" href="#">{nLastPage}</a></li>
            <li className={currentPage===nLastPage?classNameDisabled:classNameShow}>
              <a className="page-link" href="#" onClick={nextPageAction}>
                <Icon name="chevrons-right" />
              </a>
            </li>
          </ul>
        </nav>
      </Grid.Col>
    </Grid.Row>
  );
}