import React, { Component } from "react";
import { Card, Form, Table, Alert, Grid, Button } from "tabler-react";
import { connect } from "react-redux";
import { actionGetClient } from "redux/actions/ClientsActions";
import { actionCleanReports } from "redux/actions/ReportsActions";
import { actionDevolutionLoans, actionGetLoanToReport } from "redux/actions/LoansActions";
import { Pagination } from "components/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { CONSTANTS } from "constants/Constants";
import TableColSmall from "components/table/TableColSmall";
import { Helpers } from "helpers/Helpers";

class ReportLoan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      nPage: 1,
      nRowsPerPage: 10,
      nMonth: parseInt(Helpers.ConvertTZ(new Date()).getMonth()) + 1,
      nYear: Helpers.ConvertTZ(new Date()).getFullYear(),
      oTimeOutId: null,
      nPartner: ""
    };
  }

  componentDidMount = () => {
    this.getYears();
    this.props.get();
  };

  componentDidUpdate = (oLastProps) => { }

  componentWillUnmount = () => {
    this.props.cleanReports();
  };

  get = async () => {
    await this.props.get();
  };

  getYears = () => {
    let date = Helpers.ConvertTZ(new Date());
    let actualYear = date.getFullYear();
    let actualMonth = date.getMonth() + 1;
    if (this.state.nMonth == null)
      this.setState({ nYear: actualYear, nMonth: actualMonth });
    let aYearsList = [];
    for (let i = actualYear; i > actualYear - 5; i--)
      aYearsList.push(i);
    return aYearsList;
  };

  _handleSearchChange = (event) => {
    let { value } = event.target;
    this.setState({ nPage: 1 });
    clearInterval(this.state.oTimeOutId);
    let oTimeOutId = setTimeout(() => {
      this.props.get(0, this.state.nRowsPerPage, value);
    }, 800);
    this.setState({ search: value, oTimeOutId });
  }

  _handleNextPage = async () => {
    let nRowsPerPage = this.state.nRowsPerPage;
    let nPage = parseInt(this.state.nPage) * parseInt(nRowsPerPage);
    await this.setState({ nPage: this.state.nPage + 1 });
    this.props.get(nPage, this.state.nRowsPerPage, this.state.search);
  }

  _handlePreviousPage = async () => {
    await this.setState({ nPage: this.state.nPage - 2 });
    let nRowsPerPage = this.state.nRowsPerPage;
    let nPage;
    if (this.state.nPage <= 1)
      nPage = 0;
    else
      nPage = this.state.nPage * nRowsPerPage;
    this.setState({ nPage: this.state.nPage + 1 });
    this.props.get(nPage, this.state.nRowsPerPage, this.state.search);
  }

  _handleGoToPage = (nPage) => {
    let nRowsPerPage = this.state.nRowsPerPage;
    this.setState({ nPage: nPage });
    let nSkip;
    if (nPage <= 1)
      nSkip = 0;
    else
      nSkip = (nPage - 1) * nRowsPerPage;
    this.props.get(nSkip, nRowsPerPage, this.state.search);
  }

  _handleexportExcel = () => {
    window.open(CONSTANTS.API_URL + "reports/loans?search=" + this.state.search, "_blank");
  }

  goToView = (event, nClientId, sRoute) => {
    event.stopPropagation();
    this.props.getClient(nClientId);
    this.props.history.push(sRoute);
  }

  _handleChangeDevolution = (oLoan) => {
    const { nPage, nRowsPerPage, sSearch } = this.state;
    this.props.devolutionDeviceLoans(oLoan.id, (nPage - 1) * nRowsPerPage, nRowsPerPage, sSearch);
  }

  _handlechangeCount = async (oEvent) => {
    let { value } = oEvent.target;
    let { search } = this.state;
    await this.setState({ nRowsPerPage: value, nPage: 1 });
    this.props.get(0, value, search);
  }

  render = () => {
    const { oLoansReport } = this.props.oLoanProps;
    const { nPage, search } = this.state;
    return (
      <Card>
        <Card.Header>
          <Card.Title className="mr-auto">Aparatos en préstamo</Card.Title>
          <div className="card-filters mr-2">
            <Form.Input icon="search" placeholder={"Buscar"} value={search} onChange={this._handleSearchChange} />
          </div>
          <Button color="primary" className="ml-2" onClick={this._handleexportExcel}>
            <FontAwesomeIcon icon={faFileExcel} className="mr-2"></FontAwesomeIcon>
            Exportar a excel
          </Button>
        </Card.Header>
        {
          oLoansReport && oLoansReport.data.length > 0 ?
            <>
              <div className="table-responsive">
                <Table className="card-table table-vcenter table-sm table-hover text-nowrap">
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>Apellido y Nombre</Table.ColHeader>
                      <Table.ColHeader className="text-center">Número de socio</Table.ColHeader>
                      <Table.ColHeader className="text-center">Teléfono</Table.ColHeader>
                      <Table.ColHeader>Domicilio</Table.ColHeader>
                      <Table.ColHeader className="text-center">Devolución</Table.ColHeader>
                      <Table.ColHeader className="text-center">Inicio</Table.ColHeader>
                      <Table.ColHeader className="text-center">Fin</Table.ColHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>{this.renderTable()}</Table.Body>
                </Table>
              </div>
              <Card.Footer>
                <Pagination
                  nextPageAction={this._handleNextPage}
                  previousPageAction={this._handlePreviousPage}
                  goToPageAction={this._handleGoToPage}
                  currentPage={nPage}
                  allRecords={oLoansReport.allRecords}
                  showing={Array.isArray(oLoansReport.data) ? oLoansReport.data.length : 0}
                  nLastPage={oLoansReport.pages}
                  showAllOptions={true}
                  _handlechangeCount={this._handlechangeCount} />
              </Card.Footer>
            </>
            :
            <Card.Body>
              <Grid.Row>
                <Grid.Col>
                  <Alert type="info" icon="info">
                    No hemos obtenido aparatos en préstamo
                  </Alert>
                </Grid.Col>
              </Grid.Row>
            </Card.Body>
        }
      </Card>
    );
  }

  renderTable = () => {
    const { oLoansReport } = this.props.oLoanProps;
    return oLoansReport.data.map((oLoan, index) => (
      < Table.Row key={"loan" + oLoan.id + index} >
        <Table.Col className="cursor-pointer">
          <span onClick={(event) => this.goToView(event, oLoan.client.id, "/clients/edit/" + oLoan.client.id)}>
            {
              `${oLoan.client.lastname} ${oLoan.client.secondsurname} ${oLoan.client.name} ${oLoan.client.secondname}`.trim()
                ? `${oLoan.client.lastname ? oLoan.client.lastname : ""} ${oLoan.client.secondsurname ? oLoan.client.secondsurname : ""} ${oLoan.client.name ? oLoan.client.name : ""} ${oLoan.client.secondname ? oLoan.client.secondname : ""}`
                : <i className='text-muted'>Cliente sin nombre</i>
            }
          </span>
        </Table.Col>
        <Table.Col className="text-center">{oLoan.client.code}</Table.Col>
        <Table.Col className="text-center">{oLoan.devicephone ? oLoan.devicephone : 'S/D'}</Table.Col>
        <TableColSmall value={oLoan.address} length={50} />
        <Table.Col className="text-center isActive-indicator m-auto">
          <div className='form-group vertical-align m-1'>
            <label className="custom-switch">
              <input type="checkbox" name="devolution" className="custom-switch-input" defaultChecked={oLoan.returned} onChange={() => this._handleChangeDevolution(oLoan)} />
              <span className="custom-switch-indicator"></span>
              <span className="custom-switch-description font-weight-bold"></span>
            </label>
          </div>
        </Table.Col>
        <Table.Col className="text-center">
          {oLoan.start ? Helpers.CastDate(oLoan.start) : '-'}
        </Table.Col>
        <Table.Col className="text-center">
          {oLoan.end ? Helpers.CastDate(oLoan.end) : '-'}
        </Table.Col>
      </Table.Row >
    ))
  }
}

const mapStateToProps = (state) => ({
  oLoanProps: state.loansReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getClient: (idClient) => {
    dispatch(actionGetClient(idClient));
  },
  get: (nPage, nRowsPerPage, sSearch) => {
    dispatch(actionGetLoanToReport(nPage, nRowsPerPage, sSearch));
  },
  devolutionDeviceLoans: (nDeviceLoansId, nPage, nRowsPerPage, sSearch) => {
    dispatch(actionDevolutionLoans(nDeviceLoansId, nPage, nRowsPerPage, sSearch));
  },
  cleanReports: () => {
    dispatch(actionCleanReports());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportLoan);
