import { CONSTANTS } from "constants/Constants";
import oAxiosInstance from "redux/interceptors/Interceptors";

const LOANS_URL = CONSTANTS.API_URL + "loans";

/**
 * Funcion para tomar todos los prestamos de un usuario
 *
 *
 * @author Franko Zerpa
 */
export const GetLoans = (nClientId) => {
  return oAxiosInstance.get(`${LOANS_URL}/client/${nClientId}`).then((oResult) => oResult.data);
}

/**
 *
 * @param {*} oData
 */
export const SaveLoan = (oData) => {
  return oAxiosInstance.post(LOANS_URL, oData).then((oResult) => oResult.data);
}

/**
 *
 * @param {*} oData
 */
export const UpdateLoan = (oData) => {
  return oAxiosInstance
    .put(LOANS_URL + "/" + oData.id, oData)
    .then((oResult) => oResult.data);
}
/**
 *
 * @param {*} oData
 */
export const ActiveLoan = (nLoanId) => {
  return oAxiosInstance.get(`${LOANS_URL}/active/${nLoanId}`).then((oResult) => oResult.data);
}

export const DevolutionLoan = (nDeviceLoansId) => {
  return oAxiosInstance.get(`${LOANS_URL}/devolution/${nDeviceLoansId}`).then((oResult) => oResult.data);
}

export const Get = (nPage, nRowsPerPage, sSearch) => {
  return oAxiosInstance.post(`${LOANS_URL}/report`, { page: nPage, count: nRowsPerPage, search: sSearch }).then((oResult) => oResult.data);
}
