import React, { Component } from "react";
import { Card, Form, Table, Alert, Grid, Button } from "tabler-react";
import { connect } from "react-redux";
import {
  actionCancelRequest,
  actionGetClient,
} from "redux/actions/ClientsActions";
import {
  actionCleanReports,
  actionGetUnsubscription,
} from "redux/actions/ReportsActions";
import { Pagination } from "components/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { CONSTANTS } from "constants/Constants";
import { TableAction } from "components/buttons/TableAction";
import { Helpers } from "helpers/Helpers";

class ReportDeaths extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      currentPage: 1,
      nClientsPerPage: 10,
      nMonth: parseInt(Helpers.ConvertTZ(new Date()).getMonth()) + 1,
      nYear: Helpers.ConvertTZ(new Date()).getFullYear(),
      oTimeOutId: null,
      UnsubscribeMotives: [
        { id: 1, name: "Fallecimiento" },
        { id: 2, name: "Residencial" },
        { id: 3, name: "Acompañado 24 horas" },
        { id: 4, name: "No lo usan" },
        { id: 5, name: "Problemas económicos" },
        { id: 6, name: "Otros" },
      ],
      midesUnsubscribeMotives: [
        { id: 1, name: "Fallecimiento del usuario" },
        { id: 2, name: "Pérdida de interés en el servicio" },
        { id: 3, name: "Incumplimiento de condiciones pautadas en el contrato" },
        { id: 4, name: "Mudanza a zona sin cobertura de la empresa" },
        { id: 5, name: "Dejo de ser perfil" },
        { id: 6, name: "Otro" },
      ],
    };
  }

  componentDidMount = () => {
    this.getYears();
    this.get();
  }

  componentWillUnmount = () => {
    this.props.cleanReports();
  }

  get = async () => {
    await this.props.get(0, this.state.nClientsPerPage, null);
  }

  getYears = () => {
    let date = Helpers.ConvertTZ(new Date());
    let actualYear = date.getFullYear();
    let actualMonth = date.getMonth() + 1;
    if (this.state.nMonth == null) {
      this.setState({ nYear: actualYear, nMonth: actualMonth });
    }
    let yearsList = [];
    for (let i = actualYear; i > actualYear - 5; i--)
      yearsList.push(i);
    return yearsList;
  }

  _handleSearchChange = (event) => {
    let { value } = event.target;
    clearTimeout(this.state.oTimeOutId);
    let oTimeOutId = setTimeout(() => {
      this.props.get(0, this.state.nClientsPerPage, value);
    }, 800);
    this.setState({ search: value, currentPag: 1, oTimeOutId });
  }

  _handleChangeMonth = (event) => {
    let { value } = event.target;
    this.setState({ currentPage: 1, nMonth: value });

    this.props.get(0, this.state.nClientsPerPage, null);
  }

  _handleChangeYear = (event) => {
    let { value } = event.target;
    this.setState({ currentPage: 1, nYear: value });
    this.props.get(0, this.state.nClientsPerPage, null);
  }

  _handlechangeCount = (event) => {
    let { value } = event.target;
    this.setState({ nClientsPerPage: value, currentPage: 1 });
    this.props.get(0, value, null);
  }

  _handleNextPage = async () => {
    let nClientsPerPage = this.state.nClientsPerPage;
    let nPage = this.state.currentPage * nClientsPerPage;
    await this.setState({ currentPage: this.state.currentPage + 1 });
    this.props.get(nPage, this.state.nClientsPerPage, null);
  }

  _handlePreviousPage = async () => {
    await this.setState({ currentPage: this.state.currentPage - 2 });
    let nClientsPerPage = this.state.nClientsPerPage, nPage;
    nPage = this.state.currentPage * nClientsPerPage;
    this.setState({ currentPage: this.state.currentPage + 1 });
    this.props.get(nPage, nClientsPerPage, this.state.search);
  }

  _handleGoToPage = async (nPage) => {
    let nClientsPerPage = this.state.nClientsPerPage, nSkip = 0;
    nSkip = (nPage - 1) * nClientsPerPage;
    await this.setState({ currentPage: nPage });
    this.props.get(nSkip, nClientsPerPage, this.state.search);
  }

  _handleexportExcel = () => {
    window.open(`${CONSTANTS.API_URL}reports/excel/unsubscription?search=${this.state.search}`, "_blank");
  }

  goToView = (event, nClientId, sRoute) => {
    event.stopPropagation();
    this.props.getClient(nClientId);
    this.props.history.push(sRoute);
  }

  _handleCancelRequest = (nClientid) => {
    const { currentPage, nClientsPerPage, search } = this.state;
    this.props.cancelRequest(nClientid, (currentPage - 1) * nClientsPerPage, nClientsPerPage, search);
  }

  findMotive = (bMidesPartner, nMotive) => {
    const { UnsubscribeMotives, midesUnsubscribeMotives } = this.state;
    let sMotivoRetorno = "";
    if (!bMidesPartner) {
      UnsubscribeMotives.forEach((oMotive) => {
        if (oMotive.id == nMotive)
          sMotivoRetorno = oMotive.name;
      });
    } else {
      midesUnsubscribeMotives.forEach(oMotive => {
        if (oMotive.id == nMotive)
          sMotivoRetorno = oMotive.name;
      });
    }
    return sMotivoRetorno;
  }

  renderTable = () => {
    return this.props.oDeath.aDeaths.data.map((oElement, nIndex) => {
      const sName = `${oElement.client.lastname ? oElement.client.lastname : ""} 
       ${oElement.client.secondsurname ? oElement.client.secondsurname : ""}
       ${oElement.client.name ? oElement.client.name : ""}
       ${oElement.client.secondname ? oElement.client.secondname : ""}`;
      // console.log(this.props.propsLogin.oUserSession.data.admin);
      return (
        <Table.Row key={"client_" + nIndex + oElement.client.id}>
          <Table.Col className="cursor-pointer">
            <span onClick={(event) => this.goToView(event, oElement.client.id, "/clients/edit/" + oElement.client.id)}>
              {
                sName.trim() ? sName.trim() : <i>Cliente sin nombre</i>
              }
            </span>
          </Table.Col>
          <Table.Col>{oElement.client.code}</Table.Col>
          <Table.Col>{oElement.client.principal_phone ? oElement.client.principal_phone.phonenumber : 'S/D'}</Table.Col>
          <Table.Col>{oElement.client.principal_address ? (oElement.client.principal_address.domicile.length > 38 ? oElement.client.principal_address.domicile.substr(0, 35) + "..." : oElement.client.principal_address.domicile) : 'S/D'}</Table.Col>
          <Table.Col className="text-center isActive-indicator">
            {Helpers.CastDate(oElement.date)}
          </Table.Col>
          <Table.Col className="text-center isActive-indicator">
            {Helpers.CastDate(oElement.requestdate)}
          </Table.Col>
          <Table.Col className="text-center isActive-indicator">
            {this.findMotive(oElement.client.midespartner, oElement.reason)}
          </Table.Col>
          <Table.Col className="text-center isActive-indicator">
            {
              this.props.propsLogin.oUserSession && // Verificar si oUserSession existe
              this.props.propsLogin.oUserSession.data && // Verificar si data existe
              this.props.propsLogin.oUserSession.data.admin && 
              (
                <TableAction iconName="arrow-left" tooltip="Anular Baja" onClick={() => this._handleCancelRequest(oElement.client.id)} />
              )
            }
            </Table.Col>
        </Table.Row>
      );
    });
  };

  render = () => {
    const { aDeaths } = this.props.oDeath; //destructuracion de objeto
    const { nClientsPerPage, search } = this.state; //destructuracion de objeto

    return (
      <Card>
        <Card.Header>
          <Card.Title className="mr-auto">Bajas del servicio</Card.Title>
          <div className="card-filters mr-2">
            <Form.Input
              icon="search"
              placeholder={"Buscar"}
              value={search}
              onChange={this._handleSearchChange}
            />
          </div>
          <Button
            color="primary"
            className="ml-2"
            onClick={this._handleexportExcel}
          >
            <FontAwesomeIcon
              icon={faFileExcel}
              className="mr-2"
            ></FontAwesomeIcon>
            Exportar a excel
          </Button>
        </Card.Header>
        {aDeaths.data && aDeaths.data.length > 0 ? (
          <>
            <div className="table-responsive">
              <Table className="card-table table-vcenter table-sm table-hover text-nowrap">
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Apellido y Nombre</Table.ColHeader>
                    <Table.ColHeader>Número de socio</Table.ColHeader>
                    <Table.ColHeader>Teléfono</Table.ColHeader>
                    <Table.ColHeader>Domicilio</Table.ColHeader>
                    <Table.ColHeader alignContent="center">Fecha de entrega</Table.ColHeader>
                    <Table.ColHeader alignContent="center">Fecha de baja</Table.ColHeader>
                    <Table.ColHeader alignContent="center">Motivo de baja</Table.ColHeader>
                    <Table.ColHeader alignContent="center"></Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{this.renderTable()}</Table.Body>
              </Table>
            </div>
            <Card.Footer>
              <Pagination
                nextPageAction={this._handleNextPage}
                previousPageAction={this._handlePreviousPage}
                goToPageAction={this._handleGoToPage}
                currentPage={this.state.currentPage}
                allRecords={aDeaths.allRecords}
                showing={Array.isArray(aDeaths.data) ? aDeaths.data.length : 0}
                nLastPage={Math.ceil(
                  parseInt(aDeaths.allRecords) / parseInt(nClientsPerPage)
                )}
                showAllOptions={true}
                _handlechangeCount={this._handlechangeCount}
              />
            </Card.Footer>
          </>
        ) : (
          <Card.Body>
            <Grid.Row>
              <Grid.Col>
                <Alert type="info" icon="info">
                  No hemos obtenido datos de clientes
                </Alert>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        )}
      </Card>
    );
  };
}

const mapStateToProps = (state) => ({
  propsLogin: state.loginReducer,
  oClients: state.clientsReducer,
  oDeath: state.reportsReducers,
});

const mapDispatchToProps = (dispatch) => ({
  getClient: (idClient) => {
    dispatch(actionGetClient(idClient));
  },
  cancelRequest: (nClientId, nPage, nClientsPerPage, sSearch) => {
    dispatch(actionCancelRequest(nClientId, nPage, nClientsPerPage, sSearch));
  },
  get: (nPage, nClientsPerPage, sSearch) => {
    dispatch(actionGetUnsubscription(nPage, nClientsPerPage, sSearch));
  },
  cleanReports: () => {
    dispatch(actionCleanReports());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportDeaths);
