import { CONSTANTS } from 'constants/Constants';
import oAxiosInstance from 'redux/interceptors/Interceptors';

const MEDICAL_SOCIETIES_URL = CONSTANTS.API_URL + 'medicalsocieties';

/**
 * Funcion para toma todas las emergencias moviles del sistema
 * 
 * @author Leandro Curbelo
 */
export const GetMedicalSocieties = () => {
    return oAxiosInstance.get(MEDICAL_SOCIETIES_URL).then(oResult => oResult.data)
}
/**
 * Funcion para toma todas las emergencias moviles de un cliente.
 * !Verificar el uso de esta funcion, de lo contrario eliminarla
 * 
 * @param {number} nClientId Identificador del cliente del cual se tomaran sus emergencias mobiles
 * 
 * @author Leandro Curbelo
 */
export const GetMedicalSocietiesByClient = (nClientId) => {
    return oAxiosInstance.post(MEDICAL_SOCIETIES_URL+'/client/', { client_id: nClientId }).then(oResult => oResult.data)
}

export const DeleteMedicalSociety = (nMedicalSocietyId) => {
    return oAxiosInstance.delete(MEDICAL_SOCIETIES_URL+'/'+nMedicalSocietyId).then(oResult => oResult.data) 
}
