import { CONSTANTS_ACTIONS } from "constants/ConstantsActions";
import { CONSTANTS_MESSAGE } from "constants/ConstantsMessages";
import {
  actionActiveLoading,
  actionDesactiveLoading,
  actionDispatch,
  actionMessage,
  actionSetResponseCode,
} from "redux/actions/SharedActions";
import {
  GetLoans,
  UpdateLoan,
  SaveLoan,
  ActiveLoan,
  DevolutionLoan,
  Get,
} from "redux/services/LoansServices";

/**
 * Funcion que retorna todos interrupciones de un cliente.
 *
 * @author Franko Zerpa
 */
export const actionGetLoans = (nClientId) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    GetLoans(nClientId).then((oSuccess) => {
      dispatch(actionDispatch(CONSTANTS_ACTIONS.LOANS, oSuccess));
    }).catch(oError => {
      let sMessage = (oError && oError.response && oError.response.data) ? oError.response.data.message : CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR;
      dispatch(actionMessage(sMessage));
    }).finally(() => dispatch(actionDesactiveLoading()));
  };
};
/**
 * Funcion que registra un  cliente en el sistema.
 *
 * @param {object} oData objeto cliente
 *
 * @author
 */
export const actionSaveLoan = (oData) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    SaveLoan(oData).then(oSuccess => {
      dispatch(actionMessage("Guardado correctamente", CONSTANTS_MESSAGE.TYPE_SUCCESS));
      dispatch(actionGetLoans(oData.client_id));
      dispatch(actionDispatch(CONSTANTS_ACTIONS.SAVE_LOAN));
    }).catch(oError => {
      dispatch(actionMessage(oError));
    }).finally(() => { dispatch(actionDesactiveLoading()); });
  };
};

/**
 * Funcion que registra un  cliente en el sistema.
 *
 * @param {object} oData objeto cliente
 *
 * @author
 */

export const actionUpdateLoan = (oData) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    UpdateLoan(oData)
      .then((oSuccess) => {
        dispatch(
          actionMessage(
            "Actualizado correctamente",
            CONSTANTS_MESSAGE.TYPE_SUCCESS
          )
        );
        dispatch(actionGetLoans(oData.client_id)); //enviar a listado de interrupciones
        dispatch(actionDispatch(CONSTANTS_ACTIONS.UPDATE_LOAN));
      })
      .catch((oError) => {
        if (oError.response) {
          dispatch(actionSetResponseCode(oError.response.status));
        }
        dispatch(actionMessage(oError));
      })
      .finally(() => {
        dispatch(actionDesactiveLoading());
      });
  };
};

/**
 * Funcion que registra un  cliente en el sistema.
 *
 * @param {object} oData objeto cliente
 *
 * @author
 */

export const actionActiveLoan = (nCientId, nLoanId) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    ActiveLoan(nLoanId).then((oSuccess) => {
      dispatch(actionMessage(oSuccess.message, CONSTANTS_MESSAGE.TYPE_SUCCESS));
      dispatch(actionDispatch(CONSTANTS_ACTIONS.LOANS, oSuccess));
    }).catch((oError) => {
      let sMessage = (oError && oError.response && oError.response.data) ? oError.response.data.message : CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR;
      dispatch(actionMessage(sMessage));
    }).finally(() => dispatch(actionDesactiveLoading()));
  };
};

export const actionDevolutionLoans = (nDeviceLoansId, nPage, nRowsPerPage, sSearch) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    DevolutionLoan(nDeviceLoansId).then((oSuccess) => {
      dispatch(actionMessage(oSuccess.message, CONSTANTS_MESSAGE.TYPE_SUCCESS));
      dispatch(actionGetLoanToReport(nPage, nRowsPerPage, sSearch));
    }).catch((oError) => {
      let sMessage = (oError && oError.response && oError.response.data) ? oError.response.data.message : CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR;
      dispatch(actionMessage(sMessage));
    }).finally(() => {
      dispatch(actionDesactiveLoading());
    });
  }
}
/**
 * 
 */
export const actionGetLoanToReport = (nPage = 0, nRowsPerPage = 10, sSearch = null) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    Get(nPage, nRowsPerPage, sSearch).then((oSuccess) => {
      dispatch(actionDispatch(CONSTANTS_ACTIONS.LOANS_REPORT, oSuccess));
    }).catch(oError => {
      let sMessage = (oError && oError.response && oError.response.data) ? oError.response.data.message : CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR;
      dispatch(actionMessage(sMessage));
    }).finally(() => {
      dispatch(actionDesactiveLoading());
    });
  }
}

export const actionCleanLoans = () => {
  return dispatch => {
    dispatch(actionDispatch(CONSTANTS_ACTIONS.CLEAN_LOANS));
  }
}