import { CONSTANTS_ACTIONS } from 'constants/ConstantsActions';
import { CONSTANTS_MESSAGE } from 'constants/ConstantsMessages';
import { actionActiveLoading, actionDesactiveLoading, actionDispatch, actionMessage } from 'redux/actions/SharedActions';
import { GetMobileEmergencies, DeleteMobileEmergency } from 'redux/services/MobileEmergencyServices';

/**
 * Funcion que toma todos los departamentos de  Uruguay.
 * 
 * @author Franko Zerpa
 */
export const actionGetMobileEmergencies = () => {
    return dispatch => {
        GetMobileEmergencies().then(oSuccess => {
            dispatch(actionDispatch(CONSTANTS_ACTIONS.MOBILE_EMERGENCIES, oSuccess.data));
        }).catch((oError) => {
            dispatch(actionDispatch(CONSTANTS_ACTIONS.MOBILE_EMERGENCIES, []));
        });
    }
}

/**
 * !DEPRECATED - FUNCION QUE QUEDO EN DESUSO - Leandro Curbelo 01 de Julio de 2021
 * Función que elimina una emergencia movil
 * 
 * @param {number} nMedicalSocietyId 
 * 
 * @author Franko Zerpa
 */
export const actionDeleteMobileEmergency = (nMobileEmergencyId) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        DeleteMobileEmergency(nMobileEmergencyId).then(oSuccess => {
            dispatch(actionDispatch(CONSTANTS_ACTIONS.DELETE_MOBILE_EMERGENCY, oSuccess.data));
        }).catch((oError) => {
            dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
        }).finally(() => { dispatch(actionDesactiveLoading()); });
    }
}
